const jsonResponse = `
{
  "status": 200,
  "products": [
    {
      "product_id": "6087d386686d263a000014c8",
      "group": "voucher",
      "name": "My Days Gutschein1",
      "category": "Partnerangebote",
      "main_category": "Trending Design",
      "image": "main",
      "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
      "sub_headline": "100€",
      "required_points": 1,
      "attributes": [
        "neues Attribut 1",
        "neues Attribut 2"
      ],
      "variants": [
        {
          "variant_id": "31b94f70-b283-11eb-bfcb-0242ac110005",
          "color_code": "#000000",
          "out_of_stock": false,
          "images": [
            "60ae3f2ec9600ef9f5883795"
          ],
          "voucher_url": "",
          "voucher_value": ""
        }
      ],
      "order_group": "z",
      "label": "Limited edition ",
      "label_color_code": "#D631F3"
    },
    {
      "product_id": "60a232087a2e905a5984e498",
      "group": "itg_product",
      "name": "Reisenthel Shopper",
      "category": "Koffer",
      "main_category": "Trending Design",
      "image": "main",
      "description": "Taschen kann „frau“ nie genug haben, oder? Vor allem, wenn sie durch ansprechendes Design, hochwertige Materialien und praktische Features gleichermaßen begeistern – wie der geräumige reisenthel Shopper M Dots!Der reisenthel shopper M Dots bietet viel Platz für Einkäufe und mehrGanze 15 Liter fasst der shopper M Dots aus dem Hause reisenthel! Darin lassen sich die alltäglichen Einkäufe verstauen, aber auch Pfandflaschen, Sportsachen, Wickelutensilien, Unterlagen fürs Büro und vieles mehr. Gut zu wissen: Die robuste Einkaufstasche kann mit bis zu zehn Kilogramm belastet werden und eignet sich daher auch für den Transport von schweren Gegenständen wie Wasserflaschen, Saftkartons und mehr.",
      "sub_headline": "Model Tanja",
      "required_points": 1,
      "attributes": [
        "Lieferung 1-3 Werktage",
        "kostenloser Versand",
        "1 Jahr Garantie"
      ],
      "variants": [
        {
          "variant_id": "231b6a1e-b6ef-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#000000",
          "out_of_stock": false,
          "images": [
            "60a232087a2e905a5984e48e",
            "60a232087a2e905a5984e490",
            "60a232087a2e905a5984e492"
          ]
        },
        {
          "variant_id": "231b6f00-b6ef-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#F0F8FF",
          "out_of_stock": false,
          "images": [
            "60a232087a2e905a5984e494",
            "60a232087a2e905a5984e496"
          ]
        }
      ],
      "order_group": "z"
    },
    {
      "product_id": "60a23073001f9f941384e489",
      "group": "itg_product",
      "name": "TRAVELITE",
      "category": "Koffer",
      "main_category": "Trending Design",
      "image": "main",
      "description": "Der travelite Basics Rucksack in Anthrazit ist der perfekte Begleiter für unterwegs. Der graumelierte Look wirkt sehr edel und das robuste Material macht alles mit. Zudem hat der Rucksack erstaunlich viele einzelne Fächer und ist damit ein wahres Raumwunder.Hier hat alles seinen PlatzSchluss mit der nervigen Suche nach Dingen im Rucksack! Mithilfe der vielen Reißverschlussfächer und Zusatztaschen des travelite Basics Rucksacks lässt sich alles einfach und schnell verstauen und wiederfinden. Sogar große Gegenstände wie etwa ein DIN A4-Ordner finden darin Platz. Zusätzlich verfügt der Rucksack über ein gepolstertes Fach, in dem sich ein Laptop oder Tablet bis 15 Zoll sicher unterbringen lässt. Die Organizerfunktion sorgt zudem für eine ideale Ordnung von kleineren Utensilien wie Smartphone, Kugelschreiber & Co.Bequemer unterwegs seinMithilfe der atmungsaktiven Schultergurte und des höhenverstellbaren Brustgurts lässt sich der travelite Rucksack besonders komfortabel tragen. Und wenn Du einmal mehr Gepäck dabeihaben solltest, lässt sich der 22 Liter-Rucksack ganz praktisch am Gestänge eines Trolleys befestigen. Zusätzlich kann die Tasche auf klassische Weise per Tragegriff transportiert werden. Gut zu wissen: Mit seinen Maßen von 31 x 45 x 16 cm und einem Gewicht von nur 700 g eignet sich der Rucksack auch perfekt als Bordgepäck.",
      "sub_headline": "Basics Rucksack",
      "required_points": 1,
      "attributes": [
        "Lieferung 1-3 Werktage",
        "Kostenloser Versand",
        "1 Jahr Garantie"
      ],
      "variants": [
        {
          "variant_id": "3182a65e-b6ee-11eb-905c-0242ac110005",
          "color_name": "",
          "color_code": "#000000",
          "out_of_stock": false,
          "images": [
            "60a23073001f9f941384e483",
            "60a23073001f9f941384e485",
            "60a23073001f9f941384e487"
          ]
        }
      ],
      "order_group": "z"
    }
  ],
  "categories_of_products": {
    "Trending Design": "Blablalba"
  }
}
`;

export default JSON.parse(jsonResponse);
