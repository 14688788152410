import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "w-5/6 md:w-full" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "w-5/6 md:w-auto mt-8 md:font-normal lg:mb-2"
}
const _hoisted_5 = { class: "text-sm w-5/6 md:w-full pt-xxs lg:pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.footer.image)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.footer.imageLink,
        class: "nav-footer block"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.getImageUrl(_ctx.footer.image),
            class: "w-full max-w-full object-contain"
          }, null, 8, _hoisted_3)
        ]),
        (_ctx.footer.text)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.footer.text), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.footer.ctaLabel), 1)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}