import { ActionContext, Mutation } from 'vuex';

import { ResponsiveImageFactory, SimpleImageFactory } from '@/utils/image';

import { ErrorResponse, MtnFinaleState, TrackingAction } from '../types';
import { postTrackingAction, PostTrackingActionType } from '../api/api';

export const STATIC_SERVER =
  'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com';

// Initial state
export const initialState = (): MtnFinaleState => ({
  consumedContentError: null,
  heroImageUrl: `${STATIC_SERVER}/images/Alternative_Hero_Desktop.jpg`,
  videos: [
    {
      title: 'Die Finale Highlights',
      name: 'video mtn finale',
      category: 'Video Thematik',
      description: `
        Sieh dir das ganze Video an, erfahre wer gewonnen hat & nimm
        automatisch an der Gutschein-Verlosung für den nachhaltigen Shop LOVECO teil.
      `,
      url: `${STATIC_SERVER}/MTN%20Videos/MTNF_TheJourney_OL_MASTER_SMALL.mp4`,
      trackingAction: 'MTNFINALVIDEO21',
      image: ResponsiveImageFactory(
        `${STATIC_SERVER}/images/Video_Desktop.png`,
        `${STATIC_SERVER}/images/Video_Mobile.png`,
        'Die Finale Highlights video'
      ),
    },
  ],
  podcasts: [
    {
      uid: '1',
      name: 'podcast mtn finale',
      audioUrl: `${STATIC_SERVER}/Podcasts/211027_MTN_FINALE_Edit_3.mp3`,
      trackingAction: 'MTNFINALPODCAST21',
      image: ResponsiveImageFactory(
        `${STATIC_SERVER}/images/Podcast_Desktop.jpg`,
        `${STATIC_SERVER}/images/Podcast_Mobile.jpg`,
        'Die Finale Highlights podcast'
      ),
      progress: {},
    },
  ],
  carouselImages: [
    // Images have been uploaded with different upper/lowercase formats
    SimpleImageFactory(`${STATIC_SERVER}/images/1.jpg`),
    SimpleImageFactory(`${STATIC_SERVER}/images/2.JPG`),
    SimpleImageFactory(`${STATIC_SERVER}/images/3.JPG`),
    SimpleImageFactory(`${STATIC_SERVER}/images/4.jpg`),
    SimpleImageFactory(`${STATIC_SERVER}/images/5.jpg`),
    SimpleImageFactory(`${STATIC_SERVER}/images/6.JPG`),
    SimpleImageFactory(`${STATIC_SERVER}/images/7.JPG`),
    SimpleImageFactory(`${STATIC_SERVER}/images/8.JPG`),
    SimpleImageFactory(`${STATIC_SERVER}/images/9.jpg`),
  ],
});

// @TODO: move this once the general state is typed
interface RootState {
  errors: Array<string>;
}

type MtnFinaleActionContext = ActionContext<MtnFinaleState, RootState>;

const SET_CONSUMED_CONTENT_ERROR = 'SET_CONSUMED_CONTENT_ERROR';

export const mutations: Record<string, Mutation<MtnFinaleState>> = {
  [SET_CONSUMED_CONTENT_ERROR]: (
    state,
    responseError: ErrorResponse['message']
  ): void => {
    state.consumedContentError = responseError;
    console.debug(SET_CONSUMED_CONTENT_ERROR, state.consumedContentError);
  },
};

export const actions = {
  consumedContent: (
    { commit, dispatch }: MtnFinaleActionContext,
    trackingAction: TrackingAction
  ): Promise<PostTrackingActionType> => {
    return (
      dispatch('authModule/refreshHeaders', null, { root: true })
        // @ts-expect-error we still need to type the basic src/api
        .then(postTrackingAction(trackingAction))
        .catch((error: ErrorResponse) =>
          commit(SET_CONSUMED_CONTENT_ERROR, error.message)
        )
    );
  },
};

const mtnModule = {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};

export default mtnModule;
