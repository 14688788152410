export default [
  {
    type: 'story',
    value: {
      title: 'Kool Savas',
      image: {
        id: 396,
        type: 'image',
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Bildschirmfoto_2024-05-13_um_18.56.41.png',
        title: 'Bildschirmfoto 2024-05-13 um 18.56.41',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.56.41.scale-50.png',
          width: 435,
          height: 653,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.56.41.scale-100.png',
          width: 870,
          height: 1306,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.56.41.scale-100.png',
          width: 870,
          height: 1306,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.56.41.scale-15.png',
          width: 130,
          height: 195,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.56.41.scale-25.png',
          width: 217,
          height: 326,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.56.41.scale-35.png',
          width: 304,
          height: 457,
        },
      },
      has_new_content: false,
      disabled: false,
      items: [
        {
          type: 'story',
          value: {
            artist_team: '',
            iqos_points: 25,
            iqos_points_reason: 'Togetherx Documentation',
            status_points_only: true,
            iqos_points_only: false,
            title: 'Danke f\u00fcr Deine Bewerbung',
            video_url:
              'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_KoolSavas_CTA07_MASTER.mp4',
            cta_url: '',
            poster: {
              id: 412,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-bl-mchen2x_IvI66Bf.png',
              title: 'picture-bl-mchen@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_IvI66Bf.scale-50.png',
                width: 1161,
                height: 629,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_IvI66Bf.scale-100.png',
                width: 2322,
                height: 1258,
              },
              image_desktop_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_IvI66Bf.scale-100.png',
                width: 2322,
                height: 1258,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_IvI66Bf.scale-15.png',
                width: 348,
                height: 188,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_IvI66Bf.scale-25.png',
                width: 580,
                height: 314,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_IvI66Bf.scale-35.png',
                width: 812,
                height: 440,
              },
            },
          },
          id: 'a3d2204a-7abe-419d-a130-84005a128b1a',
        },
      ],
    },
    id: '0c6b53ab-d717-4d20-9dfb-3f756894eb31',
  },
  {
    type: 'story',
    value: {
      title: 'Bluemchen',
      image: {
        id: 392,
        type: 'image',
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Bildschirmfoto_2024-05-13_um_18.50.10.png',
        title: 'Bildschirmfoto 2024-05-13 um 18.50.10',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.50.10.scale-50.png',
          width: 436,
          height: 654,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.50.10.scale-100.png',
          width: 872,
          height: 1308,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.50.10.scale-100.png',
          width: 872,
          height: 1308,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.50.10.scale-15.png',
          width: 130,
          height: 196,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.50.10.scale-25.png',
          width: 218,
          height: 327,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Bildschirmfoto_2024-05-13_um_18.50.10.scale-35.png',
          width: 305,
          height: 457,
        },
      },
      has_new_content: false,
      disabled: false,
      items: [
        {
          type: 'story',
          value: {
            artist_team: 'bluemchen',
            iqos_points: 25,
            iqos_points_reason: 'Togetherx Documentation',
            status_points_only: true,
            iqos_points_only: false,
            title: 'Bl\u00fcmchen sagt Danke',
            video_url:
              'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_Bluemchen_CTA07_MASTER.mp4',
            cta_url: '',
            poster: {
              id: 407,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-with-bg2x.png',
              title: 'picture-with-bg@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-50.png',
                width: 1438,
                height: 828,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-100.png',
                width: 2876,
                height: 1656,
              },
              image_desktop_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-100.png',
                width: 2876,
                height: 1656,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-15.png',
                width: 431,
                height: 248,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-25.png',
                width: 719,
                height: 414,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-35.png',
                width: 1006,
                height: 579,
              },
            },
          },
          id: 'a5cde4fc-a0f9-4f0a-98bd-8663e754dbbe',
        },
        {
          type: 'story',
          value: {
            artist_team: '',
            iqos_points: 25,
            iqos_points_reason: 'Togetherx Documentation',
            status_points_only: true,
            iqos_points_only: false,
            title: 'Komm in mein Team',
            video_url:
              'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_Bluemchen_CTA03_MASTER_1.webm',
            cta_url: '',
            poster: {
              id: 408,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-bl-mchen2x.png',
              title: 'picture-bl-mchen@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-50.png',
                width: 1161,
                height: 629,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-100.png',
                width: 2322,
                height: 1258,
              },
              image_desktop_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-100.png',
                width: 2322,
                height: 1258,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-15.png',
                width: 348,
                height: 188,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-25.png',
                width: 580,
                height: 314,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-35.png',
                width: 812,
                height: 440,
              },
            },
          },
          id: '6cf42a3a-b346-4fe2-ab2d-ffae779a163b',
        },
      ],
    },
    id: '4c622461-332c-4097-a803-b76adcce5558',
  },
];
