import { Router } from 'vue-router';
import { Store } from 'vuex';

import initialiseAdventRouter from './router';
import adventStore from './store/store';

function adventModule({
  store,
  router,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: Store<any>;
  router: Router;
}): void {
  store.registerModule('adventModule', adventStore);
  initialiseAdventRouter(router);
}

export default adventModule;
