
import { defineComponent, computed, type PropType } from 'vue';

const NAV_MOBILE_CLICK_EVENT = 'navMobileClick';

export default defineComponent({
  props: {
    theme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'light',
    },
  },
  emits: [NAV_MOBILE_CLICK_EVENT],
  setup(props, { emit }) {
    const themeClasses = computed(() =>
      props.theme === 'dark' ? 'bg-primary-slate' : 'bg-primary-soft-white'
    );
    return {
      themeClasses,
      onClick: () => emit(NAV_MOBILE_CLICK_EVENT),
    };
  },
});
