import { StoriesState, Story, Stories } from '../types';
import mutations from './mutations';
import actions from './actions';
import asyncActions from './asyncActions';

export const initialState = (): StoriesState => ({
  stories: null,
  storiesFetchError: null,
  readStoriesIds: [],
  provisionalReadStoriesIds: [],
  selectedStoryDropdownOption: {},
  storyDropdownError: {},
  readStoryId: null,
});

export const getters = {
  currentStories: (state: StoriesState): Stories =>
    state.selectedArchivedStory
      ? { items: [state.selectedArchivedStory], meta: { total_count: 1 } }
      : {
          meta: state?.stories?.meta,
          items: state?.stories?.items?.filter(
            (story) => !state.readStoriesIds.includes(story.id)
          ).sort((a, b) => { return Date.parse(b.publication_date) - Date.parse(a.publication_date)}),
        },
  allStories: (state: StoriesState): Stories =>
    ({
      meta: state?.stories?.meta,
      items: state?.stories?.items
    }),
  readStories: (state: StoriesState): Story[] =>
    state.stories.items.filter((story) =>
      state.readStoriesIds.includes(story.id)
    ),
};

const storiesModule = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: { ...actions, ...asyncActions },
};

export default storiesModule;
