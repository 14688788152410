import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nav-link__title font-iqosHeader font-bold" }
const _hoisted_2 = {
  key: 0,
  class: "text-fs-14-lh-20-ls-0.2 xl:whitespace-nowrap lg:text-fs-16-lh-24-ls-0 pt-xxs mt-0.5 lg:pt-2 lg:my-0.5"
}
const _hoisted_3 = { class: "md:hidden" }
const _hoisted_4 = {
  key: 0,
  class: "hidden md:block h-20"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicUnderline = _resolveComponent("DynamicUnderline")!
  const _component_IconArrowDown = _resolveComponent("IconArrowDown")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menu.handler ? 'div' : 'a'), {
    id: `DCE_nav_${_ctx.menu.id}`,
    class: "nav-link w-full block",
    href: _ctx.menu.href
  }, {
    default: _withCtx(() => [
      (_ctx.menu.handler)
        ? (_openBlock(), _createElementBlock("div", {
            key: _ctx.menu.id,
            class: "nav-link__container"
          }, [
            _createVNode(_component_DynamicUnderline, {
              active: _ctx.isItemSelected,
              hideUnderline: _ctx.hideUnderline,
              alwaysShowOnActive: _ctx.alwaysShowOnActive,
              gtmId: _ctx.menu.id,
              class: "text-left"
            }, {
              underlined: _withCtx(() => [
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.menu.text), 1)
              ]),
              default: _withCtx(() => [
                (_ctx.menu.subText)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.menu.subText), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["active", "hideUnderline", "alwaysShowOnActive", "gtmId"]),
            _createVNode(_component_IconArrowDown, { class: "nav-link__arrow h-6 w-6 transform -rotate-90 -translate-y-3" })
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: _ctx.menu.id,
            class: "nav-link__container flex flex-wrap md:flex-nowrap md:justify-between"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "linkImage")
            ]),
            _createVNode(_component_DynamicUnderline, {
              hideUnderline: _ctx.hideUnderline,
              alwaysShowOnActive: _ctx.alwaysShowOnActive,
              class: "w-full md:w-auto"
            }, {
              underlined: _withCtx(() => [
                _createElementVNode("p", {
                  class: _normalizeClass([{ 'nav-link__imageTitle': !!_ctx.menu.image }, "nav-link__title block"])
                }, _toDisplayString(_ctx.menu.text), 3)
              ]),
              default: _withCtx(() => [
                (_ctx.menu.subText)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: _normalizeClass([{ 'text-center md:text-left': !!_ctx.menu.image}, "text-fs-14-lh-20-ls-0.2 lg:text-fs-16-lh-24-ls-0 pt-xxs lg:mt-1"])
                    }, _toDisplayString(_ctx.menu.subText), 3))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["hideUnderline", "alwaysShowOnActive"]),
            (_ctx.menu.image)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "linkImage")
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 3
  }, 8, ["id", "href"]))
}