import { authApiGet, authApiPost } from '@/api/api.js';

const URLS = {
  CALENDAR: 'adventcalendar/',
  DOOR: (day) => `adventcalendar/door/${day}`,
  POEM: 'adventcalendar/poem',
  ACTION: (actionId) => `action/${actionId}`,
};

export const getCalendar = () => authApiGet(URLS.CALENDAR);
export const openDoor = (day) => authApiGet(URLS.DOOR(day));
export const createPoem = (data) =>
  authApiPost(URLS.POEM, { topic: String(data) });

export const postTrackingAction = (trackingAction) =>
  authApiPost(URLS.ACTION(trackingAction), {
    action_id: String(trackingAction),
  });
