import { HeroSliderItem } from '../../types';
import { HeroSliderDTO } from '../dtos';
import { normaliseCmsImage } from '@/components/ResponsiveImage/utils';

  export default (response: HeroSliderDTO): HeroSliderItem[] =>
  response.items.map(item => {
      const { image, image_mobile } = item;
      return {
      ...item,
      image: normaliseCmsImage({
        image: image_mobile ? {...image,
          image_mobile_1x: image_mobile.image_mobile_1x,
          image_mobile_2x: image_mobile.image_mobile_2x,
          image_mobile_3x: image_mobile.image_mobile_3x
          } : image,
        alt: item.title,
      }),
    }
    });
//TODO: remove image_mobile after backend fix
