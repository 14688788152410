import AcademyResponse from './academy';
import ChallengesResponse from './challenges';
import DraftsResponse from './drafts';
import GameStartResponse from './game-start';
import ArchiveResponse from './archive';
import HomepageResponse from './homepage';
import GroupsResponse from './groups';
import ConsumerResponse from './consumer';
import * as AmbassadorsResponse from './ambassadors';

// import EmptyAcademyResponse from './empty-academy';
// import EmptyDraftsResponse from './empty-drafts';
import ErrorResponse from './error-response';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

// Setup the mocks defined with axios-mock-adapter
// Bear in mind that the guards are lazy so:
// if we have 2 urls matching, the first one will be the one returned.
function setupMocks(mock) {
  mock.onGet('academy').reply(makeResponse('academy', AcademyResponse));
  // mock.onGet('academy').reply(makeResponse('empty academy', EmptyAcademyResponse));
  // mock.onGet('academy').reply(makeResponse('classes error', ErrorResponse));
  // mock.onGet('academy').networkError();

  mock.onGet(/drafts/).reply(makeResponse('drafts', DraftsResponse));
  // mock.onGet(/drafts/).reply(makeResponse('empty drafts', EmptyDraftsResponse));

  mock
    .onPost('academy/game/start')
    .reply(makeResponse('game/start', GameStartResponse));

  mock.onGet('academy/archive').reply(makeResponse('archive', ArchiveResponse));

  // mock.onGet(/academy2\/group\/.[\d]\/challenges/).reply(makeResponse('challenges', ChallengesResponse));
  // mock.onGet('academy2/groups').reply(makeResponse('groups', GroupsResponse));
  // mock.onGet('academy2/ambassador').reply(makeResponse('ambassador', AmbassadorsResponse.getReponse));

  // mock.onGet('academy2/consumer').reply(makeResponse('consumer', ConsumerResponse))
  // mock.onGet('academy2/consumer')
  //   .replyOnce(makeResponse('consumer', ConsumerResponse))
  //   .onGet('academy2/consumer')
  //   .replyOnce(makeResponse('consumer', ConsumerResponse))
  //   .onGet('academy2/consumer')
  //   .passThrough();

  // mock.onPut(/academy2\/consumer\/set_ambassador\/.[\d]/).reply(makeResponse('put_ambassador', { message: 'MOCK: Ambassador set'}));
  // mock.onGet('academy2/startpage').reply(makeResponse('homepage', HomepageResponse));

}
