const jsonResponse = `
{
  "product": {
    "product_id": "60f1646aa17a3445bcf02135",
    "group": "iqos_de",
    "name": "IQOS™ 3 DUO Kit",
    "category": "IQOS Devices",
    "image": "main",
    "description": "",
    "sub_headline": "Tabakerhitzer",
    "required_points": 250,
    "attributes": [],
    "variants": [
      {
        "variant_id": "9c7d7862-e623-11eb-b226-0242ac110003",
        "color_name": "",
        "color_code": "#72B6F3",
        "out_of_stock": false,
        "images": [
          "60f1646aa17a3445bcf02131"
        ]
      },
      {
        "variant_id": "9c7d8852-e623-11eb-b226-0242ac110003",
        "color_name": "",
        "color_code": "#ACB9C4",
        "out_of_stock": false,
        "images": [
          "60f1646aa17a3445bcf02133"
        ]
      }
    ],
    "order_group": "z",
    "url": "https://de.iqos.com/de/shop/iqos-geraete-kaufen/geraete-iqos-3-duo/iqos-3-kit",
    "label": "IQOS",
    "label_color_code": ""
  }
}
`;
export default JSON.parse(jsonResponse);
