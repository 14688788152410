import { RouteRecordRaw, Router } from 'vue-router';

const DEFAULT_GTAG_DOMAIN = 'earning-overview';

export const ROOT_URL = '/earning-overview';

export const routeBreadcrumbsMeta = {
  EARNING_OVERVIEW: { name: 'Earning Activity Overview', link: ROOT_URL },
};

export const routeNames = {
  EARNING_OVERVIEW: 'earning-overview',
};

const sharedMeta = {
  headerTheme: 'light',
};

const routes: RouteRecordRaw[] = [
  {
    path: ROOT_URL,
    name: routeNames.EARNING_OVERVIEW,
    component: () =>
      import(/* webpackChunkName: "Earnings" */ './views/EarningRoot.vue'),
    meta: {
      ...sharedMeta,
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: routeBreadcrumbsMeta.EARNING_OVERVIEW.name,
    },
    children: [],
  },
  {
    path: '/earning-overview',
    redirect: ROOT_URL,
  },
];

function initialiseEarningRouter(router: Router): void {
  routes.forEach(router.addRoute);
}

export default initialiseEarningRouter;
