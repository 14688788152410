const jsonResponse = `
{
  "status": 200,
  "products": [
    {
      "product_id": "60a22fd464b6824e0d84e481",
      "group": "itg_product",
      "name": "Wanderrucksack",
      "category": "Koffer",
      "main_category": "Exploring World",
      "image": "main",
      "description": "Der Heldenmut ACTIVE+-Rucksack ist ein großer Wanderrucksack für ambitionierte Bergtouren oder Outdoor-Aktivitäten. Sein körpernaher, komfortabler Sitz mit ergonomischen Tragegurten und der stabilisierten Lastenverteilung garantiert viel Bewegungsfreiheit und ein komfortables Tragegefühl.<br><br>Die verdeckten Reißverschlüsse halten den Rucksackinhalt auch bei schlechtem Wetter trocken. Diese aufwendige Technik ist auch an allen Nähten zu sehen. Dieser Rucksack hält auch schlechterem Wetter stand.<br><br>Diverse innenliegende Fächer helfen Ihnen bei der Organisation Ihrer Utensilien und ermöglichen Ihnen den schnellen Zugriff auf Karten oder andere wichtige Sachen. Reißfestes, wasserabweisende Nylon sorgt für die technische Ausstattung und die Langlebigkeit auf vielen Reisen und Unternehmungen.",
      "sub_headline": "Heldenmut ",
      "required_points": 1,
      "attributes": [
        "Lieferung 2-4 Werktage",
        "2 Jahre Garantie"
      ],
      "variants": [
        {
          "variant_id": "d2a3305e-b6ed-11eb-a7db-0242ac110005",
          "color_name": "",
          "color_code": "#000000",
          "out_of_stock": false,
          "images": [
            "60a22fd464b6824e0d84e47b",
            "60a22fd464b6824e0d84e47d",
            "60a22fd464b6824e0d84e47f"
          ]
        },
        {
          "variant_id": "d00a7d32-b6ef-11eb-a7db-0242ac110005",
          "color_name": "",
          "color_code": "#F0F8FF",
          "out_of_stock": false,
          "images": [
            "60a2332a64b6824e0d84e482",
            "60a2332a64b6824e0d84e484",
            "60a2332a64b6824e0d84e486"
          ]
        },
        {
          "variant_id": "d00a8368-b6ef-11eb-a7db-0242ac110005",
          "color_name": "",
          "color_code": "#90EE90",
          "out_of_stock": false,
          "images": [
            "60a2332a64b6824e0d84e488",
            "60a2332a64b6824e0d84e48a",
            "60a2332a64b6824e0d84e48c"
          ]
        }
      ],
      "order_group": "z"
    },
    {
      "product_id": "60a238217a2e905a5984e4b3",
      "group": "itg_product",
      "name": "Yogamatte",
      "category": "Freizeit",
      "main_category": "Exploring World",
      "image": "main",
      "description": "Die multifunktionale Fitnessmatte eignet sich für Übungen aller Art. Und die Matte ist gleich doppelt gut für deine Gesundheit, denn das Material XPE ist frei von Schadstoffen.Spezifikationen:- unbedenkliches Material frei von BPA, Phthalaten und anderen Schadstoffen- leicht zu reinigen mit einem feuchten Tuch- guter Grip, damit du bei deinen Übungen nicht wegrutschst- geringes Gewicht, sodass du die Fitnessmatte auch bequem mitnehmen kannst",
      "sub_headline": "Avento",
      "required_points": 5000,
      "attributes": [
        "Lieferzeit: 2-3 Tage",
        "Kostenloser Versand",
        "1 Jahr Garantie"
      ],
      "variants": [
        {
          "variant_id": "c5add64c-b6f2-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#22BAB1",
          "out_of_stock": false,
          "images": [
            "60a238217a2e905a5984e4ad",
            "60a238217a2e905a5984e4af"
          ]
        },
        {
          "variant_id": "c5addc82-b6f2-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#FF0000",
          "out_of_stock": false,
          "images": [
            "60a238217a2e905a5984e4b1"
          ]
        }
      ],
      "order_group": "z"
    },
    {
      "product_id": "60c1b8567fb353cb311ccf34",
      "group": "voucher",
      "name": "Apple Gutschein",
      "category": "Gutscheine",
      "main_category": "Exploring World",
      "image": "main",
      "description": "Einmalig einlösbar",
      "sub_headline": "itune Store",
      "required_points": 1,
      "attributes": [
        "Nur für kurze Zeit",
        "nicht umtauschbar"
      ],
      "variants": [
        {
          "variant_id": "13bcae88-c9ba-11eb-a968-0242ac110005",
          "color_code": "#FFFFFF",
          "out_of_stock": false,
          "images": [
            "60c1ba8ad67e1d6fe31ccf6d"
          ],
          "voucher_url": "https://www.loremipsum.de/",
          "voucher_value": "100€"
        }
      ],
      "order_group": "z",
      "label": "Neu",
      "label_color_code": "#A13899 "
    },
    {
      "product_id": "60c1b8e8d67e1d6fe31ccf68",
      "group": "voucher",
      "name": "Lufthansa Gutschein",
      "category": "Gutscheine",
      "main_category": "Exploring World",
      "image": "main",
      "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
      "sub_headline": "Flugreisen",
      "required_points": 1,
      "attributes": [
        "24 Monate gültig"
      ],
      "variants": [
        {
          "variant_id": "60208b1e-c9ba-11eb-a968-0242ac110005",
          "color_code": "#000000",
          "out_of_stock": false,
          "images": [
            "60c1b9f3d67e1d6fe31ccf6b"
          ],
          "voucher_url": "https://www.google.com/search?q=lufthansa+logo&rlz=1C5GCEM_enDE943DE943&sxsrf=ALeKk00w1MzBr9k59K4rHnPFWvk6gZuYlQ:1623308634573&tbm=isch&source=iu&ictx=1&fir=xnBfvypqS0T54M%252CTGGxXRtTiTNuXM%252C_%253BHLDN4N-6TnRBnM%252CJI8WzJ99J9mL5M%252C_%253BJ_L3wcwoEaMMuM%252CErqjvDG0R_LGQM%252C_%253BlWv6pL0QN23ksM%252CtfTqQ3mpvs6Z0M%252C_%253BnW5-W5NCSFA5JM%252Co3L5UhvLYggi4M%252C_%253BrMtrjkFApAQjAM%252CJI8WzJ99J9mL5M%252C_%253B2N3lbz_2HAAfRM%252Cy5tcv8zXvuHkEM%252C_%253BS1GDvV0fs7snSM%252Cpj7MWkJryePg7M%252C_%253Bw5o0fOB3GewPfM%252Cqmoj40xKmTBd8M%252C_%253B1g_mWM5QeamGrM%252CToeBaCM_P-XSNM%252C_%253BUm-_2IePTGknUM%252CUD1PndIqwOAMaM%252C_%253BIOJ9NOU5PJCFfM%252C5kU9h14Hfx372M%252C_%253BVo-uRbg9MhJz5M%252CC6gVQPkKOIzebM%252C_&vet=1&usg=AI4_-kTjYQ19AXJGX4gLa0oHlXfl7ZwKhQ&sa=X&ved=2ahUKEwjmzJj0v4zxAhXD3KQKHXyGBWUQ9QF6BAgMEAE&biw=2504&bih=1336#imgrc=lWv6pL0QN23ksM",
          "voucher_value": "50€"
        }
      ],
      "order_group": "z",
      "label": "Limited",
      "label_color_code": "#5EA138 "
    },
    {
      "product_id": "60f1646aa17a3445bcf02135",
      "group": "iqos_de",
      "name": "IQOS™ 3 DUO Kit",
      "category": "IQOS Devices",
      "main_category": "Exploring World",
      "image": "main",
      "description": "",
      "sub_headline": "Tabakerhitzer",
      "required_points": 250,
      "attributes": [],
      "variants": [
        {
          "variant_id": "9c7d7862-e623-11eb-b226-0242ac110003",
          "color_name": "",
          "color_code": "#72B6F3",
          "out_of_stock": false,
          "images": [
            "60f1646aa17a3445bcf02131"
          ]
        },
        {
          "variant_id": "9c7d8852-e623-11eb-b226-0242ac110003",
          "color_name": "",
          "color_code": "#ACB9C4",
          "out_of_stock": false,
          "images": [
            "60f1646aa17a3445bcf02133"
          ]
        }
      ],
      "order_group": "z",
      "url": "https://de.iqos.com/de/shop/iqos-geraete-kaufen/geraete-iqos-3-duo/iqos-3-kit",
      "label": "IQOS",
      "label_color_code": ""
    }
  ],
  "categories_of_products": {
    "World": "Blabllaasfad"
  }
}
`

export default JSON.parse(jsonResponse);
