import normalizeLesson from './lessons';

const addRemainingTime = (totalTime, lesson) =>
  lesson.value?.completed_in
    ? totalTime
    : totalTime + parseInt(lesson.value?.duration_in_minutes);

const addTotalTime = (totalTime, lesson) =>
  totalTime + parseInt(lesson.value?.duration_in_minutes);

const normalizeDate = (dateString) =>
  dateString ? new Date(dateString) : null;

export default ({ rawClasses, fromArchive }) =>
  rawClasses.map((academyClass, index) => {
    const classImage = academyClass?.main_image;
    return {
      category: academyClass.category,
      duration: academyClass.lessons_list?.reduce(addTotalTime, 0),
      expiresAt: normalizeDate(academyClass.expire_at),
      id: academyClass.class_id?.toString(),
      index,
      isArchived: fromArchive,
      isSpecial: academyClass.go_live_at || academyClass.expire_at,
      lessons: academyClass.lessons_list.map(normalizeLesson(fromArchive)),
      nextClassAvailableAfterDays: academyClass.waiting_time,
      points: parseInt(academyClass.points_awarded),
      remainingTime: academyClass.lessons_list?.reduce(addRemainingTime, 0),
      slug: academyClass.meta.slug,
      startsOn: normalizeDate(academyClass.go_live_at),
      subtitle: academyClass.description,
      title: academyClass.title,
      url: `/academy/${academyClass.meta.slug}`,
      image: {
        alt: academyClass.title,
        desktop: {
          x1:
            classImage?.image_desktop_1x?.url ||
            classImage?.image_desktop_2x?.url ||
            classImage?.image_desktop_3x?.url,
          x2:
            classImage?.image_desktop_2x?.url ||
            classImage?.image_desktop_3x?.url ||
            classImage?.image_desktop_1x?.url,
          x3:
            classImage?.image_desktop_3x?.url ||
            classImage?.image_desktop_2x?.url ||
            classImage?.image_desktop_1x?.url,
        },
        mobile: {
          x1:
            classImage?.image_mobile_1x?.url ||
            classImage?.image_mobile_2x?.url ||
            classImage?.image_mobile_3x?.url,
          x2:
            classImage?.image_mobile_2x?.url ||
            classImage?.image_mobile_3x?.url ||
            classImage?.image_mobile_1x?.url,
          x3:
            classImage?.image_mobile_3x?.url ||
            classImage?.image_mobile_2x?.url ||
            classImage?.image_mobile_1x?.url,
        },
      },
      info: academyClass.info,
    };
  });
