import ProductsListResponse from './products-list';
import ProductDetailResponse from './product-detail';
import ProductDetailResponseBose from './product-detail-bose';
import ProductDetailResponseBoseHeadphones from './product-detail-bose-headphones';
import ProductDetailIqosDevice from './product-detail-iqos-device';
import ProductOrderResponse from './product-order';
import ConfigResponse from './config';
import MyDaysVoucherResponse from './product-detail-mydays-voucher';
import Test from './product-detail-test';
import LufthansaVoucherResponse from './product-detail-lufthansa-voucher';
import ProductVoucherOrder, {
  ProductVoucherOrderError,
} from './product-voucher-order';
import ProductCategoryResponse from './product-categories';
import ProductCategoryTrendingDesign from './products-trending-design';
import ProductCategoryLivingDelight from './products-living-delight';
import ProductCategoryWorld from './products-world';
import NotificationListResponse from './product-notification';
import ErrorResponse from './error-response';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

// Setup the mocks defined with axios-mock-adapter
// Bear in mind that the guards are lazy so:
// if we have 2 urls matching, the first one will be the one returned.
function setupMocks(mock) {
  mock.onGet('products/').reply(makeResponse('products', ProductsListResponse));
  // mock.onGet('products/').reply(makeResponse('products error', ErrorResponse));
  // mock.onGet('products/').networkError();

  mock
    .onGet('products\/categories')
    .reply(makeResponse('categories', ProductCategoryResponse));

  mock
    .onGet('products\/filter\/Trending%20Design')
    .reply(makeResponse('Product by category: Trending Design', ProductCategoryTrendingDesign));

  mock
    .onGet('products\/filter\/Living%20Delight')
    .reply(makeResponse('Product by category: Living Delight', ProductCategoryLivingDelight));

  mock
    .onGet('products\/filter\/Exploring%20World')
    .reply(makeResponse('Product by category: World', ProductCategoryWorld));

  mock
    .onGet('products\/6087d386686d263a000014c8')
    .reply(
      makeResponse('My Days voucher product detail', MyDaysVoucherResponse)
    );

  mock
    .onGet('products\/652d23114718962c0be84bfd')
    .reply(
      makeResponse('My Days voucher product detail', Test)
    );

  mock
    .onGet('products\/60c1b8e8d67e1d6fe31ccf68')
    .reply(
      makeResponse('Lufthansa voucher product detail', LufthansaVoucherResponse)
    );

  mock
    .onPost('products\/6087d386686d263a000014c8')
    // .networkError()
    .reply(makeResponse('My Days voucher product order', ProductVoucherOrderError, 423));

  mock
    .onGet('products\/6086f24a896da8d2947a7191')
    .reply(makeResponse('product detail bose speaker', ProductDetailResponseBose));

  mock
    .onGet('products\/60a2347564b6824e0d84e498')
    .reply(makeResponse('product detail bose headphones', ProductDetailResponseBoseHeadphones));

  mock
    .onGet('products\/60f1646aa17a3445bcf02135')
    .reply(makeResponse('product detail iqos device', ProductDetailIqosDevice));

  mock
    .onGet(/products\/\w+/)
    .reply(makeResponse('product detail', ProductDetailResponse));
  mock
    .onPost(/products\/\w+/)
    .reply(makeResponse('product order', ProductOrderResponse));

  // mock.onGet('products/64e5f792d7b632a966bf4d59').reply(
  //   makeResponse('product detail KELINE VASE', {
  //     product: {
  //       product_id: '64e5f792d7b632a966bf4d59',
  //       group: 'itg_product',
  //       name: 'Kleine Vase',
  //       category: 'Wohnaccessoires',
  //       main_category: 'Partnerpr\u00e4mien',
  //       image: 'main',
  //       description:
  //         'Mit der kleinen schwarzen Vase holt ihr euch ein sehr klassisches, zeitloses und sch\u00f6nes Objekt nach Hause. Ob mit echten oder mit Trockenblumen - sie wird eure Wohnung mit Sicherheit schm\u00fccken. <br><br>Farbe: Schwarz, wei\u00df<br>Gr\u00f6\u00dfe: \u00d8 10 cm, H\u00f6he 12,5 cm<br>Material: Porzellan',
  //       sub_headline:
  //         'Ob mit frischen oder mit Trockenblumen - die kleine Vase in schwarz oder wei\u00df ist ein Hingucker f\u00fcr dein zu Hause.',
  //       required_points: 79,
  //       required_tierlevel: 'silver',
  //       attributes: ['Sofort versandbereit', 'Kostenloser Versand'],
  //       variants: [
  //         {
  //           variant_id: '4460f562-41ae-11ee-a0cc-0242ac130005',
  //           color_name: 'Schwarz',
  //           color_code: '#000000',
  //           out_of_stock: false,
  //           images: ['64eefec3d7b632a966c25784', '64eefec4d7b632a966c25786'],
  //           ean_code: '4035644530657',
  //         },
  //         {
  //           variant_id: '97d94e98-d64f-11ee-8ff5-0242c0a8a005',
  //           color_name: 'wei\u00df',
  //           color_code: '#F3F3F3',
  //           out_of_stock: false,
  //           images: ['65e18d8d422933ad65c7ff66'],
  //           ean_code: '4035644491996',
  //         },
  //       ],
  //       order_group: 'e',
  //       limited_edition: false,
  //       label: 'Neu',
  //       label_color_code: '',
  //     },
  //   })
  // );

  mock
    .onGet('notification/')
    .reply(makeResponse('notifications', NotificationListResponse));

  mock.onGet('config/').reply(makeResponse('config', ConfigResponse));
}
