export const GetTokyoUgcFeed = {
  video: {
    pagination: {
      total: 0,
      limit: 20,
      offset: 0,
    },
    results: [
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6617d057c04a58009e8132d4_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6618d4024f68597d2b812232_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661a8d058734f020923b44ec_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661d257881335a7c333b704a_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661e8c2481335a7c333b9351_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661f9aec81335a7c333baed0_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fc8878734f020923bf258_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fdb8f8734f020923c0f0a_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fec2c5ce40a9a043b6b19_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662002578734f020923c6360_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662006b58734f020923c6a91_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620183781335a7c333c307a_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620292b81335a7c333c3c30_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620e4ce81335a7c333c7054_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620f3b58734f020923cf70e_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662116825ce40a9a043ba54e_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66211aaf8734f020923d177f_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662156578734f020923d34fc_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6621584681335a7c333cb9d5_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66215bc581335a7c333cbb18_vid.mp4',
      },
    ],
  },
  photo: {
    pagination: {
      total: 0,
      limit: 20,
      offset: 0,
    },
    results: new Array(60).fill(0).map((_, i) => {
      const random = Math.floor(Math.random() * 100);
      const type =
        random > 33 ? 'avataaars-neutral' : random > 66 ? 'micah' : 'thumbs';
      return { url: `https://api.dicebear.com/9.x/${type}/svg?seed=${i}` };
    }),
  },
  userinfo: {
    count_approved: 3,
    todays_feed_ids: [],
  },
  status: 200,
};

export const GetTokyoUgcFeedWithUploadFeed = {
  ...GetTokyoUgcFeed,
  userinfo: {
    count_approved: 3,
    todays_feed_ids: ['66852cba3ab67b95ffd18f01'],
  },
};

export const GetPhotoUpload = {
  video: {
    pagination: {
      total: 2,
      limit: 20,
      offset: 0,
    },
    results: [
      {
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6682baeb3ab67b95ffd18c7d_vid.mp4',
      },
      {
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6682be293167b949e4d18bce_vid.mp4',
      },
    ],
  },
  photo: {
    pagination: {
      total: 5,
      limit: 20,
      offset: 0,
    },
    results: [
      {
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/667d4fb1bb39f22f7eefec56_photo',
      },
      {
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/667d85e6c1a5d5a3bba096dd_photo',
      },
      {
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/66825a843b1f1264feb19a98_photo',
      },
      {
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/66827b40e5841e766eb19ed1_photo',
      },
      {
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6682cc893ab67b95ffd18cd8_photo',
      },
    ],
  },
  userinfo: {
    count_approved: 0,
    todays_feed_ids: ['66852cba3ab67b95ffd18f01'],
  },
  status: 200,
};

export const GetPhotoUploadWithId = {
  status: 200,
  upload_url: {
    url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/',
    fields: {
      key: 'togetherx/66852cba3ab67b95ffd18f01_photo',
      'x-amz-algorithm': 'AWS4-HMAC-SHA256',
      'x-amz-credential':
        'AKIAU7H3JRBVTT7E4DGO/20240703/eu-central-1/s3/aws4_request',
      'x-amz-date': '20240703T104937Z',
      policy:
        'eyJleHBpcmF0aW9uIjogIjIwMjQtMDctMDNUMTA6NTk6MzdaIiwgImNvbmRpdGlvbnMiOiBbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDUwMDAsIDE1NzI4NjQwXSwgeyJidWNrZXQiOiAicG1nLWRjZS1zaG9wLWNtcy1wcmVwcm9kLWFzc2V0cyJ9LCB7ImtleSI6ICJ0b2dldGhlcngvNjY4NTJjYmEzYWI2N2I5NWZmZDE4ZjAxX3Bob3RvIn0sIHsieC1hbXotYWxnb3JpdGhtIjogIkFXUzQtSE1BQy1TSEEyNTYifSwgeyJ4LWFtei1jcmVkZW50aWFsIjogIkFLSUFVN0gzSlJCVlRUN0U0REdPLzIwMjQwNzAzL2V1LWNlbnRyYWwtMS9zMy9hd3M0X3JlcXVlc3QifSwgeyJ4LWFtei1kYXRlIjogIjIwMjQwNzAzVDEwNDkzN1oifV19',
      'x-amz-signature':
        '6c81269bd0c620007a6a45c846c540446f8449b22edee33c7e71ecdb5ba42e92',
    },
  },
  entry_id: '66852cba3ab67b95ffd18f01',
};

export const GetVideoUploadWithId = {
  status: 200,
  upload_url: {
    url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/',
    fields: {
      key: 'togetherx/66852cba3ab67b95ffd18f01_vid.mp4',
      'x-amz-algorithm': 'AWS4-HMAC-SHA256',
      'x-amz-credential':
        'AKIAU7H3JRBVTT7E4DGO/20240703/eu-central-1/s3/aws4_request',
      'x-amz-date': '20240703T104937Z',
      policy:
        'eyJleHBpcmF0aW9uIjogIjIwMjQtMDctMDNUMTA6NTk6MzdaIiwgImNvbmRpdGlvbnMiOiBbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDUwMDAsIDEzNDIwMDAwMF0sIHsiYnVja2V0IjogInBtZy1kY2Utc2hvcC1jbXMtcHJlcHJvZC1hc3NldHMifSwgeyJrZXkiOiAidG9nZXRoZXJ4LzY2ODUyY2JhM2FiNjdiOTVmZmQxOGYwMV92aWQubXA0In0sIHsieC1hbXotYWxnb3JpdGhtIjogIkFXUzQtSE1BQy1TSEEyNTYifSwgeyJ4LWFtei1jcmVkZW50aWFsIjogIkFLSUFVN0gzSlJCVlRUN0U0REdPLzIwMjQwNzAzL2V1LWNlbnRyYWwtMS9zMy9hd3M0X3JlcXVlc3QifSwgeyJ4LWFtei1kYXRlIjogIjIwMjQwNzAzVDEwNDkzN1oifV19',
      'x-amz-signature':
        '74c1ac31a5a4e65ab62810cffaa33d65a89f64c3095b347bd16cc96773cbc7c1',
    },
  },
  entry_id: '66852cba3ab67b95ffd18f01',
};
