import { authApiGet } from '@/api/api';

export const URLS = {
  CONSUMER: 'consumer/',
  CONSUMER_LOYALTY: 'consumer/loyalty',
};

export const fetchConsumer = authApiGet(URLS.CONSUMER);

export const fetchConsumerLoyalty = authApiGet(URLS.CONSUMER_LOYALTY);
