// keep the mocks as close as possible to the real returned response

const jsonResponse = `
{
    "status": 200,
    "loyalty": {
        "points": 2591,
        "status_points": 240,
        "status_level": "Silver"
    }
}
`;

export default JSON.parse(jsonResponse);
