const jsonResponse = `
{
  "message": "success",
  "status": 200,
  "notifications": [
    {
      "product_id": "6086f24a896da8d2947a7191",
      "variant_id": "f82265de-ada1-11eb-bc68-0242ac110005"
    },
    {
      "product_id": "60a2347564b6824e0d84e498",
      "variant_id": "23da0db4-b6f1-11eb-9867-0242ac110005"
    }
  ]
}
`;

export default JSON.parse(jsonResponse);
