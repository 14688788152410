import { ShopState } from '../types';
import mutations from './mutations';
import actions from './actions';

export const initialState = (): ShopState => ({
  config: null,
  configFetchError: null,
  currentProduct: null,
  additionalProduct: null,
  order: null,
  orderProductError: null,
  productFetchError: null,
  products: [],
  categories: [],
  heroSliders: [],
  notifications: [],
  productsFetchError: null,
  currentVariant: null,
  customerInfo: null,
  userInteractionToProductDetail: 'direct access',
});

const getters = {
  productType: ({ currentProduct }): string => currentProduct.type,
};

const shopModule = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

export default shopModule;
