import mutations from './mutations';
import asyncActions from './asyncActions';
import { NewsArticleRaffleState } from '../types';

export const initialState = (): NewsArticleRaffleState => ({
  newsArticleRaffleRegisterStatus: {
    action_done: false,
    button_text: '',
    success_button_text: '',
  },
});

export const getters = {};

const newsArticleRaffleModule = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: { ...asyncActions },
};

export default newsArticleRaffleModule;
