import { RouteRecordRaw, Router } from 'vue-router';

const DEFAULT_GTAG_DOMAIN = 'advent';

export const routeAdventBreadcrumbsMeta = {
  ADVENT_HOME: { name: 'Advent', link: '/adventcalendar2022' },
};
export const routeNames = {
  ADVENT_HOME: 'advent-home',
  ADVENT_CARD: 'advent-card',
  ADVENT_COUNTDOWN: 'advent-countdown',
  ADVENT_MAINTENANCE: 'advent-maintenance',
  ADVENT_TERMS: 'advent-terms',
};

const getCalendarOrCard = (): boolean | string => {
// True to continue navigation, otherwise redirect to card
const currentDay = new Date().getTime();
const targetDay = new Date('12.25.2022').getTime();
return targetDay > currentDay ? true : '/adventcalendar2022/card' ;
};

export default initialiseAdventRouter;

const routes: RouteRecordRaw[] = [
  {
    path: '/adventcalendar2022',
    name: routeNames.ADVENT_HOME,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/AdventHome.vue'),
    beforeEnter: getCalendarOrCard,
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Advent',
    },
  },
  {
    path: '/adventcalendar2022/card',
    name: routeNames.ADVENT_CARD,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/AdventCard.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventCard',
    },
  },
  {
    path: '/adventcalendar2022/maintenance',
    name: routeNames.ADVENT_MAINTENANCE,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/Maintenance.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventMaintenance',
    },
  },
  {
    path: '/adventcalendar2022/countdown',
    name: routeNames.ADVENT_COUNTDOWN,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/Countdown.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventCountdown',
    },
  },
  {
    path: '/adventcalendar2022/teilnahmebedingungen',
    name: routeNames.ADVENT_TERMS,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/Terms.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventTerms',
    },
  },
];

function initialiseAdventRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
