import { authApiGet, authApiPost } from '@/api/api';

export const URLS = {
  TEASER_ACTION: 'action/',
};

export const getPointsRedeemed = (actionId) =>
  authApiGet(`${URLS.TEASER_ACTION}${actionId}`);

export const redeemPoints = (actionId) =>
  authApiPost(`${URLS.TEASER_ACTION}${actionId}`);
