export default {
  product: {
      product_id: '65c29e3011d0b5aff6456f42',
      group: 'itg_product',
      name: '"Das Kochbuch"',
      notes: '<p><a href="#">Nur 10 Freunde empfehlen</a> um kostenlos erhalten</p>',
      category: '',
      main_category: 'Partnerpr\u00e4mien',
      image: 'main',
      description: 'Wie w\u00e4re es mit karamellisiertem Chicor\u00e9e mit Serranoschinken, Feigen mit jungem Pecorino und Honig, gegrillte Makrele mit S\u00fc\u00dfkartoffel-Pickle und Minzejoghurt? Wer ausgefallene und kreative Geschmackserlebnisse mag, der ist hier genau richtig. \'Das Kochbuch\' von Erfolgsautor Yotam Ottolenghi begeistert durch insgesamt 140 mediterrane, orientalische und raffinierte Rezeptideen. ',
      sub_headline: '140 mediterrane, orientalische und raffinierte Rezepideen von Erfolgsautor Yotam Ottolenghi.',
      required_points: 280,
      required_tierlevel: 'gold',
      attributes: [
          'Sofort versandbereit',
          'Kostenloser Versand'
      ],
      variants: [
          {
              variant_id: 'ea4293ca-c532-11ee-aa7a-0242c0a8f005',
              color_name: '',
              color_code: '',
              out_of_stock: false,
              images: [
                  '65c29e3011d0b5aff6456f3d'
              ],
              ean_code: '',
          }
      ],
      order_group: 'a',
      limited_edition: false,
      label: 'Neu',
      label_color_code: '',
  }
};
