import { Router } from 'vue-router';
import { Store } from 'vuex';

import mtnStore from './store/store';
import initialiseMtnRouter from './router';

import { RootState } from '@/store/types';

function mtnModule({
  store,
  router,
}: {
  store: Store<RootState>;
  router: Router;
}): void {
  store.registerModule('mtnModule', mtnStore);
  initialiseMtnRouter(router);
}

export default mtnModule;
