const jsonResponse = `
{
  "status": 200,
  "order_response": {
    "success": true,
    "tracking_url": "https://tracking.sendcloud.sc/forward?carrier=sendcloud&code=SCCWF277DH2V&destination=DE&lang=de-de&source=DE&type=letter&verification=81673&created_at=2021-06-09",
    "tracking_number": "SCCWF277DH2V",
    "order_id": "60c0e43d945ccfe52a9a0f5a"
  }
}
`;
export default JSON.parse(jsonResponse);
