// mutation types
import { SpringRaffleState } from '../types';
import { POINTS_REDEEMED } from './mutation-types';

const mutations = {
  [POINTS_REDEEMED]: (state: SpringRaffleState, payload: boolean): void => {
    state.hasRedeemedPoints = payload;
  },
};

export default mutations;
