
import { defineComponent, computed, ref, type PropType } from 'vue';

import Icon from '@/components/Icon/Icon.vue';
import NavFooter from '@/components/NavHeader/components/NavFooter.vue';
import NavLink from '@/components/NavHeader/components/NavLink.vue';
import NavLinkSmall from '@/components/NavHeader/components/NavLinkSmall.vue';
import TSlideInLeft from '@/components/Transitions/TSlideInLeft.vue';
import {
  globalNavState,
  useNavState,
} from '@/components/NavHeader/useNavState';
import { navItems } from '@/components/NavHeader/ItemsMenu';

import NavThirdLevel from '../NavThirdLevel.vue';
import NavMobileHeader from './NavMobileHeader.vue';

const LOGOUT_CLICK_EVENT = 'logoutClick'


const themeClasses = {
  'dark': {
    bgColor: 'bg-primary-soft-white',
    bgColor2: 'bg-primary-soft-white md:bg-background-hard-white',
    textColor: 'text-primary-slate',
    textColor2: 'text-primary-slate',
  },
  'light': {
    bgColor: 'bg-primary-slate',
    bgColor2: 'bg-primary-slate md:bg-background-hard-white',
    textColor: 'text-primary-soft-white md:text-primary-slate lg:text-primary-soft-white',
    textColor2: 'text-primary-soft-white',
  },
}

export default defineComponent({
  components: {
    Icon,
    NavFooter,
    NavLink,
    NavLinkSmall,
    NavMobileHeader,
    NavThirdLevel,
    TSlideInLeft,
  },
  props: {
    theme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'light',
    }
  },
  emits: [LOGOUT_CLICK_EVENT],
  setup(props, { emit }) {
    const handleLogoutClick = () => {
      closeAllMenus();
      emit(LOGOUT_CLICK_EVENT);
    };

    const {
      closeAllMenus,
      closeSecondLevelMenu,
      closeThirdLevelMenu,
      firstLevelClick,
      getImageUrl,
      secondLevelClick,
      transitionDurationCalc,
    } = useNavState();

    const firstLevelLength = navItems.length;

    const secondLevelLength = computed(
      () =>
        globalNavState.secondLevelItems.firstList.length +
        globalNavState.secondLevelItems.secondList.length
    );

    const animationTrigger = ref(false);
    const arrowBackClick = () => {
      globalNavState.isThirdLevelVisible
        ? closeThirdLevelMenu()
        : closeSecondLevelMenu();
      animationTrigger.value = !animationTrigger.value;
    };

    const hasNavFooter = computed(() =>
      globalNavState.isThirdLevelVisible
        ? globalNavState.thirdLevelItems.footer
        : globalNavState.secondLevelItems.footer
    );

    const currentTheme = computed(() => themeClasses[props.theme]);

    return {
      animationTrigger,
      arrowBackClick,
      closeAllMenus,
      currentTheme,
      firstLevelClick,
      firstLevelLength,
      getImageUrl,
      globalNavState,
      handleLogoutClick,
      hasNavFooter,
      navItems,
      secondLevelClick,
      secondLevelLength,
      transitionDurationCalc,
    };
  },
});
