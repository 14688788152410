import { Router } from 'vue-router';
import { maintenanceGuard } from './router-guard';

export const routeNames = {
  MAINTENANCE: 'maintenance',
  MAINTENANCE_ILUMA: 'iluma_maintenance',
};

const routes = [
  {
    path: `/${routeNames.MAINTENANCE}`,
    name: routeNames.MAINTENANCE,
    meta: {
      gtagDomain: 'maintenance',
    },
    component: () =>
      import(/* webpackChunkName: "Maintenance" */ './views/Maintenance.vue'),
  },
];


function initialiseMaintenanceRouter(router: Router): void {
  // adding the maintenance navigation guard before each route
  router.beforeEach(maintenanceGuard);
  routes.forEach(router.addRoute);
}

export default initialiseMaintenanceRouter;

