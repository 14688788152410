// keep the mocks as close as possible to the real returned response

const jsonResponse = '{ "status": 200 }';

export const podcastPostResponse = JSON.parse(jsonResponse);
export const podcastGetResponse = {
  progress: {
    '0': false,
    '10': false,
    '20': false,
    '30': false,
    '40': false,
    '50': false,
    '60': false,
    '70': false,
    '80': false,
    '90': false,
    '100': false,
  }
}