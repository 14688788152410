import { Store } from 'vuex';

import consumerStore from './store/store';

import { RootState } from '@/store/types';

function consumerModule({ store }: { store: Store<RootState> }): void {
    store.registerModule('consumerModule', consumerStore);
}

export default consumerModule;
