// import GigyaService from '../gigyaService';
import normalizeUser from './user-normalizer';
import { DCE_LOGIN_URL } from '../constants';
import { getJwtFromCookies } from '../jwt';

export const initialState = () => ({
  // user is logged in if we have the information from gigya
  user: {
    jwt: null,
    uid: null,
    email: null,
  },
  authError: null,
});

export const getters = {
  authHeaders: ({ user = {} }) => {
    const jwt = getJwtFromCookies();

    return {
      headers: {
        'x-login-token': jwt,
      },
    };
  },
  userEmail: ({ user = {} }) => user?.email,
  isUserAuthenticated: ({ user = {} }) => {
    console.log('isUserAuthenticated', user.jwt)
    return user.jwt !== null
  },
  isAuthProcessFinished: ({ authError, user }) =>
    Boolean(authError || user.jwt),
  authError: ({ authError }) => authError,
  userId: ({ user = {} }) => user?.uid,
};

export const actions = {
  async initAuth({ commit, dispatch }) {
    const jwt = getJwtFromCookies();
    const curryCommit = (message) => (payload) => commit(message, payload);
    // if (authService) {
    if (jwt) {
      // authService.clearIntervalsAndTimeouts();
      commit('SET_JWT_TOKEN', jwt);
      // authService.fetchJWT(curryCommit('SET_JWT_TOKEN'));
      // authService.fetchAccountInfo((user) =>
      //   commit('SET_USER', normalizeUser(user))
      // );
      return;
    } else {
      setTimeout(() => {
        location.href = DCE_LOGIN_URL;
      }, 1000)
    }
    // authService = new GigyaService({
    //   jwtCookieName: AUTH_COOKIE_NAME,
    //   jwtAction: curryCommit('SET_JWT_TOKEN'),
    //   userAction: (user) => commit('SET_USER', normalizeUser(user)),
    //   errorAction: (error) => commit('SET_AUTH_ERROR', error),
    //   logoutAction: () => {
    //     console.log('XXXX logoutAction XXXX');
    //     console.info('XXXX logoutAction XXXX');
    //     dispatch('logout');
    //   },
    // });
  },
  // stopAuth() {
  //   if (authService) {
  //     authService.clearIntervalsAndTimeouts();
  //   }
  // },
  // refreshToken({ commit }) {
  //   if (authService) {
  //     // return new Promise((resolve) => {
  //     //   authService.fetchJWT((jwt) => {
  //     //     commit('SET_JWT_TOKEN', jwt);
  //     //     resolve(jwt);
  //     //   });
  //     // });
  //     const jwt = getJwtFromCookies();
  //     commit('SET_JWT_TOKEN', jwt);
  //   } else {
  //     throw new Error('GigyaService is not running...');
  //   }
  // },
  refreshHeaders({ dispatch, getters, commit }) {
      // await dispatch('refreshToken');
      const jwt = getJwtFromCookies();
      commit('SET_JWT_TOKEN', jwt);
      if(!jwt) {
        throw new Error('Could\'nt refresh the JWT.', error);
      }
      return getters.authHeaders;
  },
  async logout({ commit }) {
    const jwt = getJwtFromCookies();
    if (jwt) {
      // unset all user/customer related data
      commit('SET_JWT_TOKEN', null);
      commit('SET_USER', { uid: null, email: null });
      commit('consumerModule/LOGOUT', null, { root: true });
      window.location = process.env.VUE_APP_DCE_LOGIN_URL;
    }
  },
};

export const mutations = {
  SET_JWT_TOKEN(state, jwt) {
    state.user.jwt = jwt;
    // TODO: Restore this line
    console.debug('SET_JWT_TOKEN', state.user.jwt);
  },
  SET_USER(state, { uid, email }) {
    state.user.uid = uid;
    state.user.email = email;
    console.debug('SET_USER', state.user);
  },
  SET_AUTH_ERROR(state, error) {
    state.authError = error;
    console.debug('SET_AUTH_ERROR', state.authError);
  },
  SET_AUTH_SERVICE_LOADED(state) {
    state.isAuthServiceLoaded = true;
    console.debug('SET_AUTH_SERVICE_LOADED', true);
  },
};

const authModule = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};

export default authModule;
