
import { defineComponent } from 'vue';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      ICON_THEMES,
    };
  },
});
