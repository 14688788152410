import { Router } from 'vue-router';

export const routeNames = {
  MTN_HOME: 'mtnfinalcountdown',
};

const routes = [
  {
    path: `/${routeNames.MTN_HOME}`,
    name: routeNames.MTN_HOME,
    meta: {
      gtagDomain: 'mtnfinalcountdown',
    },
    component: () =>
      import(/* webpackChunkName: "MTN_1" */ './views/MtnHome.vue'),
  }
];

function initialiseMtnRouter(router: Router): void {
  routes.forEach(router.addRoute);
}

export default initialiseMtnRouter;
