import { Router } from 'vue-router';
import { Store } from 'vuex';

import initialiseTogetherXRouter from './router';
import togetherXStore from './store/store';

function togetherXModule({
  store,
  router,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: Store<typeof togetherXStore>;
  router: Router;
}): void {
  store.registerModule('togetherXModule', togetherXStore);
  initialiseTogetherXRouter(router);
}

export default togetherXModule;
