import { ITGProductCheckout, VoucherProductCheckout } from '../../types';
import { ITGProductCheckoutDTO, VoucherProductCheckoutDTO } from '../dtos';

/**
 * Maps the information stored in camelCase to what the api expects
 * Format to send to the API:
 Request example {
  "variant_id": "c5add64c-b6f2-11eb-9867-0242ac110005",
  "shipping_address": {
    "first_name": "test",
    "last_name": "german87",
    "street": "fakestrasse",
    "street_number": "47",
    "zip_code": "80686",
    "city": "munchen",
    "country": "DE",
    "email": "testgerman87@gmail.com"
  }
 }
*/
export const itgProductSerializer = ({
  variantId: variant_id,
  shippingAddress,
  additionalProduct,
}: ITGProductCheckout): ITGProductCheckoutDTO => ({
  variant_id,
  shipping_address: {
    city: shippingAddress.city,
    country: shippingAddress.country || 'DE',
    email: shippingAddress.email,
    first_name: shippingAddress.firstName,
    last_name: shippingAddress.lastName,
    street: shippingAddress.street,
    street_number: shippingAddress.streetNumber,
    zip_code: shippingAddress.zipCode,
    ...(shippingAddress.company && { company: shippingAddress.company }),

  },
  additional_product_id: additionalProduct.additionalProductId,
  additional_variant_id: additionalProduct.additionalVariantId,
  additional_amount: additionalProduct.additionalAmount,
});

export const voucherSerializer = ({
  variantId: variant_id,
}: VoucherProductCheckout): VoucherProductCheckoutDTO => ({
  variant_id,
});
