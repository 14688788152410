import axios from 'axios';

const {
  NODE_ENV: ENV,
  VUE_APP_API_URL: API_URL,
  VUE_APP_SERVICES_API_URL: SERVICES_URL,
  VUE_APP_CMS_API_URL: CMS_URL,
} = process.env;

ENV === 'development' && require('./adapters.js').setupMocks(axios);
const createAPI = (baseURL) =>
  axios.create({
    baseURL,
    headers: {
      'Content-type': 'application/json',
    },
  });

const API_INSTANCES = {
  API: createAPI(API_URL),
  SERVICES: createAPI(SERVICES_URL),
  CMS: createAPI(CMS_URL),
};

const handleResponse = ({ data }) => {
  if (!data.error_message) return data;

  const err = new Error('ApiClientError');
  err.type = 'ApiClientError';
  err.response = {
    data,
  };
  throw err;
};

export const api = (config) => {
  const { instance = 'API', ..._config } = config;
  const instanceMapping = {
    API: API_INSTANCES['API'],
    SERVICES: API_INSTANCES['SERVICES'],
    CMS: API_INSTANCES['CMS'],
  };
  const apiInstance = instanceMapping[instance] || instanceMapping['API'];
  return apiInstance(_config).then(handleResponse);
};

const withHeaders =
  (apiCall, ...args) =>
  (headers) =>
    apiCall(...args, headers);

export const apiGet = (url, instance, config) =>
  api({ url, method: 'GET', instance, ...config });

export const apiPost = (url, data = {}, config) =>
  api({ url, method: 'POST', data, ...config });

export const apiPut = (url, data = {}, config) =>
  api({ url, method: 'PUT', data, ...config });

export const authApiGet = (url, instance) => withHeaders(apiGet, url, instance);
export const authApiPost = (url, data = {}) => withHeaders(apiPost, url, data);
export const authApiPut = (url, data = {}) => withHeaders(apiPut, url, data);
