import { ImageWithSources, ResponsiveImageSet } from '@/types';

export interface ShopState {
  config: Config | null;
  configFetchError: string | null;
  currentProduct: Product | null;
  additionalProduct: Product | null;
  currentVariant: Variant | null;
  order: Order | null;
  orderProductError: string | null;
  productFetchError: string | null;
  products: Product[];
  categories: Category[];
  heroSliders: HeroSliderItem[];
  notifications: Notification[];
  productsFetchError: string | null;
  customerInfo: CustomerInfo | null;
  userInteractionToProductDetail: string;
}

export type VoucherProductCheckout = {
  variantId: string;
};

export type ITGProductCheckout = {
  shippingAddress: CustomerInfo;
  variantId: string;
  additionalProduct: AdditionalProductInfo;
};
export type ProductCheckout = ITGProductCheckout | VoucherProductCheckout;

export type AdditionalProductInfo = {
  additionalProductId: string;
  additionalVariantId: string;
  additionalAmount: string;
};

export type CustomerInfo = {
  city: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  country?: string;
  email?: string;
  company?: string;
};

export type Variant = {
  color: string;
  images: string[];
  name: string;
  outOfStock: boolean;
  unitsLeft: number;
  variantId: string;
  voucherURL?: string;
  voucherValue?: string;
  index: number;
  variantURL: string;
};

export enum ProductTypesEnum {
  ITG = 'itg_product',
  VOUCHER = 'voucher',
  IQOS_DEVICE = 'iqos_de',
  ITG_ADDITIONAL = 'itg_additional',
  ADVERTISEMENT = 'werbekachel',
  ARVATO = 'arvato_product',
  ARVATO_ADDITIONAL = 'arvato_additional',
}

export const PRODUCT_TYPES = {
  ITG: ProductTypesEnum.ITG,
  VOUCHER: ProductTypesEnum.VOUCHER,
  IQOS_DEVICE: ProductTypesEnum.IQOS_DEVICE,
  ITG_ADDITIONAL: ProductTypesEnum.ITG_ADDITIONAL,
  ARVATO: ProductTypesEnum.ARVATO,
  ARVATO_ADDITIONAL: ProductTypesEnum.ARVATO_ADDITIONAL,
};

export enum OrderGroupsEnum {
  default = 'z',
  prio1 = 'a',
  prio2 = 'b',
  prio3 = 'c',
  prio4 = 'd',
}

export type Category = {
  name: string;
  description: string;
};

export type Product = {
  attributes: string[];
  category: string;
  mainCategory: string;
  description: string;
  image: string;
  label: string;
  labelColor: string;
  name: string;
  limitedEdition: boolean;
  orderGroup: OrderGroupsEnum;
  productId: string;
  requiredPointsBefore: number;
  requiredPoints: number;
  subHeadline: string | null;
  type: ProductTypesEnum;
  url: string;
  variants: Variant[];
  orderLimit: number;
  requiredTier: 'silver' | 'gold' | 'platinum';
  hiddenInListing: boolean;
  customCta?: string;
  notes?: string;
};

export type ITGOrder = {
  orderId: string;
  trackingURL?: string;
};

export type VoucherOrder = {
  orderId: string;
  voucherCode?: string;
};

export type Order = ITGOrder | VoucherOrder;

export type Config = {
  attributes: {
    // @TODO: Refer to PRODUCT_TYPES above
    [productType: string]: string[];
  };
  textCheckboxAddress: string | null;
  textCtaMissingPoints: string | null;
  textCtaOrder: string | null;
  textCtaRedeem: string | null;
  textThankyou1: string | null;
  textThankyou2: string | null;
  textThankyou3: string | null;
  textThankyouVoucher: string | null;
  customTexts: { [key: string]: string[] };
  errorMessage?: string | null;
};

export type ErrorMessage = {
  errorMessage: string | null;
};

export type Notification = {
  productId: string;
  variantId: string;
};

export type HeroSliderItem = {
  id: number;
  title: string;
  theme: string;
  headline: string;
  content: string;
  cta_name: string;
  cta_link: string;
  image: ImageWithSources;
};

export interface AdvertisingTileType {
  id: string;
  category: string;
  image: ResponsiveImageSet;
  points: number | string;
  color: string;
  subtitle: string;
  title: string;
  url: string;
  info?: string;
}
