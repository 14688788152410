const fontSizeConfig = require('./.tailwind/fontSize.js');
const spacingConfig = require('./.tailwind/spacing.js');
const colorConfig = require('./.tailwind/color.js');
const sizingConfig = require('./.tailwind/sizing.js');
const layeringConfig = require('./.tailwind/layering.js');

/** @type {import("@types/tailwindcss/tailwind-config").TailwindConfig } */
module.exports = {
  // mode: 'jit',
  important: '#app',
  purge: {
    content: ['./src/**/*.vue'],
    options: {
      // When using JIT mode, we might need to move this to a
      // safelist.txt file, as stated in:
      // https://tailwindcss.com/docs/just-in-time-mode#known-limitations
      safelist: [
        // Vue CSS transition animation classes
        /-(leave|enter|appear)(|-(to|from|active))$/,
        'academy-home__hero-content',
      ],
    },
  },
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: spacingConfig['xxl-2'],
        lg: '2.75rem', // 44px
        xl: '4rem', // 64px
      },
    },
    screens: {
      sm: '375px',
      md: '768px',
      lg: '1024px',
      xl: '1440px',
      xxl: '1608px',
    },
    extend: {
      backdropBrightness: {
        5: '.05',
      },
      fontFamily: {
        base: ['IQOS', 'Avenir', 'Helvetica', 'Arial', 'sans-serif'],
        iqosHeader: [
          'IQOS-Header',
          'Avenir',
          'Helvetica',
          'Arial',
          'sans-serif',
        ],
      },
      letterSpacing: {
        md: '0.0125rem', // 0.2px
      },
      backgroundOpacity: {
        15: '.15',
      },
      spacing: spacingConfig,
      colors: colorConfig,
      fontSize: fontSizeConfig,
      zIndex: layeringConfig,
      inset: {
        0: '0',
        '1/4': '25%',
        '2/5': '40%',
        '1/2': '50%',
        '3/5': '60%',
        '3/4': '75%',
        '4/5': '80%',
        full: '100%',
      },
      width: sizingConfig,
      height: sizingConfig,
    },
    maxWidth: {
      0: '0',
      '1/4': '25%',
      '2/5': '40%',
      '1/2': '50%',
      '3/5': '60%',
      '3/4': '75%',
      '4/5': '80%',
      full: '100%',
      '0-screen': '0vw',
      '1/4-screen': '25vw',
      '2/5-screen': '40vw',
      '1/2-screen': '50vw',
      '3/5-screen': '60vw',
      '3/4-screen': '75vw',
      '4/5-screen': '80vw',
      'full-screen': '100vw',
    },
    maxHeight: {
      0: '0',
      '1/4': '25%',
      '2/5': '40%',
      '1/2': '50%',
      '3/5': '60%',
      '3/4': '75%',
      '4/5': '80%',
      full: '100%',
      '0-screen': '0vh',
      '1/4-screen': '25vh',
      '2/5-screen': '40vh',
      '1/2-screen': '50vh',
      '3/5-screen': '60vh',
      '3/4-screen': '75vh',
      '4/5-screen': '80vh',
      'full-screen': '100vh',
    },
  },
  variants: {
    extend: {
      margin: ['last'], // Enable :last-child pseudo selector for margin utilities
      borderWidth: ['last'],
      borderColor: ['last'],
    },
  },
  plugins: [require('@tailwindcss/forms')],
};
