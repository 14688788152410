const video = {
  type: 'video',
  header: 'Behind the scenes mit Mischa & Julia',
  body: 'Was und wer steckt hinter Together DO? Wie lief die Planung und Umsetzung im Hintergrund ab? All das und noch mehr hört ihr im Podcast mit Mischa und Julia, die in den letzten Monaten maßgeblich an der Initiative gearbeitet haben, um sie für euch erlebbar zu machen. ',
  button: 'none',
  animation: 'animation1',
  url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/B390581_PT1_IQOS4_ILUMAONE_ROW_SIZZLE_CGLA_LAU_TEREA_1135_A_de-DE_95_1080x1920_25p_AE003_S.mp4',
  action_id: 'VIDEO1',
};

const leadgame = {
  type: 'leadgame',
  header: '',
  body: '',
  button: 'none',
  animation: 'animation1',
  game_url:
    'https://philip-morris-germany.campaign.playable.com/02-12-slot-machine-iqos-iluma-prime',
  game_id: 97358,
}

const wyng = {
  type: 'wyng',
  header: 'Teile mit uns!',
  body: 'Spürst du es auch? Zeige Deinen magischen Weihnachts-Moment.',
  button: 'none',
  animation: 'animation5',
}

const user_mgn = {
  type: 'user_mgn',
  header: 'Hier ist Dein Weihnachtcode.',
  body: 'Teile Deinen Code mit einem Freund. Kauft er sich ein \r\nIQOS Kit, erhält er 30% Freundschaftsrabatt.',
  button: 'none',
  animation: 'animation1',
  mgm: 'MGM-DZu2pO-3XsefX',
}

const typeMap = {
  leadgame,
  wyng,
  user_mgn,
  video,
}

const type = new URLSearchParams(window.location.search).get('type') || 'video';

export default {
  status: '200',
  door_content: {
    ...typeMap[type]
  },
};;
