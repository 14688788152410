import { api } from '@/api/api';
import { ApiGetType, DowntimeResponse } from '../types';

const APPLICATION_KEY = 'clubsubpages';
const DOWNTIME_URL = (key: string) => `downtime/${key || APPLICATION_KEY}`;

const apiGet: ApiGetType = (url, config = {}) =>
  api({ url, method: 'GET', instance: 'SERVICES', ...config });

export const fetchDowntime = (
  applicationKey: string
): Promise<DowntimeResponse> => apiGet(DOWNTIME_URL(applicationKey));
