import { RouteRecordRaw, Router } from 'vue-router';
import { type ArtistTeamUrlNormalised } from './types';

const DEFAULT_GTAG_DOMAIN = 'togetherx';

export const ROOT_URL = '/togetherx';

export const routeBreadcrumbsMeta = {
  TOGETHERX_HOME: { name: 'TogetherX Music', link: ROOT_URL },
  ANNOUNCEMENT_PHASE: { name: 'TogetherX Music', link: ROOT_URL },
  APPLICATION_UNEXPECTED_VOICES: { name: 'Together X Music', link: ROOT_URL },
};

export const routeNames = {
  TOGETHERX_ROOT: 'togetherx-root',
  TOGETHERX_HOME: 'togetherx-home',
  ANNOUNCEMENT_PHASE: 'announcement-phase',
  APPLICATION_PHASE: 'application-phase',
  APPLICATION_UNEXPECTED_VOICES: 'application-unexpected-voices',
  VOTING_PHASE: 'voting-phase',
  TOGETHERX_VIDEO_UPLOAD: 'togetherx-video-upload',
  DOCUMENTATION: 'documentation',
  DOCUMENTATION_ARTIST: 'documentation-artist',
  DATENSCHUTZ: 'datenschutz',
  TEILNAHME: 'teilnahme',
};

const sharedMeta = {
  headerTheme: 'light',
};

const routes: RouteRecordRaw[] = [
  {
    path: ROOT_URL,
    name: routeNames.TOGETHERX_ROOT,
    component: () =>
      import(/* webpackChunkName: "TogetherX" */ './views/TogetherXRoot.vue'),
    meta: {
      ...sharedMeta,
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Together X Music',
    },
    children: [
      {
        path: '',
        name: routeNames.TOGETHERX_HOME,
        component: () =>
          import(
            /* webpackChunkName: "TogetherX" */ './views/TogetherXHome/TogetherXHome.vue'
          ),
        meta: {
          gtagDomain: DEFAULT_GTAG_DOMAIN,
          routeBreadcrumbName: 'Together X Music',
        },
      },
      {
        path: 'voting',
        name: routeNames.VOTING_PHASE,
        component: () =>
          import(
            /* webpackChunkName: "TogetherX" */ './views/Voting/Voting.vue'
          ),
        meta: {
          gtagDomain: DEFAULT_GTAG_DOMAIN,
          routeBreadcrumbName: 'Together X Music',
        },
      },
      {
        path: 'application',
        name: routeNames.APPLICATION_PHASE,
        component: () =>
          import(
            /* webpackChunkName: "TogetherX" */ './views/Application/Application.vue'
          ),
        meta: {
          gtagDomain: DEFAULT_GTAG_DOMAIN,
          routeBreadcrumbName: 'Together X Music',
        },
      },
      {
        path: 'documentation',
        name: routeNames.DOCUMENTATION,
        component: () =>
          import(
            /* webpackChunkName: "TogetherX" */ './views/Documentation/Documentation.vue'
          ),
        meta: {
          gtagDomain: DEFAULT_GTAG_DOMAIN,
          routeBreadcrumbName: 'Together X Music',
        },
      },
      {
        path: 'documentation/:artistName',
        name: routeNames.DOCUMENTATION_ARTIST,
        component: () =>
          import(
            /* webpackChunkName: "TogetherX" */ './views/Documentation/ArtistDocumentation.vue'
          ),
        beforeEnter: (to) => {
          return (
            ['bluemchen', 'kool-savas', 'michael-schulte'].includes(
              to.params.artistName as ArtistTeamUrlNormalised
            ) || {
              name: routeNames.TOGETHERX_HOME,
            }
          );
        },
        meta: {
          gtagDomain: DEFAULT_GTAG_DOMAIN,
          routeBreadcrumbName: 'Together X Music',
        },
      },
      {
        path: 'application/veroeffentlichungsfreigabe',
        name: routeNames.DATENSCHUTZ,
        component: () =>
          import(/* webpackChunkName: "TogetherX" */ './views/Datenschutz.vue'),
        meta: {
          gtagDomain: DEFAULT_GTAG_DOMAIN,
          routeBreadcrumbName: 'Together X Music',
        },
      },
      {
        path: 'application/teilnahmebedingungen',
        name: routeNames.TEILNAHME,
        component: () =>
          import(/* webpackChunkName: "TogetherX" */ './views/Teilnahme.vue'),
        meta: {
          gtagDomain: DEFAULT_GTAG_DOMAIN,
          routeBreadcrumbName: 'Together X Music',
        },
      },
    ],
  },
  {
    path: '/together-x',
    redirect: ROOT_URL,
  },
];

function initialiseTogetherXRouter(router: Router): void {
  routes.forEach(router.addRoute);
}

export default initialiseTogetherXRouter;
