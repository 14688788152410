module.exports = {
  'fs-125-lh-133': [
    '7.8125rem',
    {
      lineHeight: '1.08',
      letterSpacing: '-1.28px',
    },
    {
      originalPxFontSize: 125,
      originaPxlLineHeight: 133,
    },
  ],
  'fs-96-lh-104-ls--1.28': [
    '6rem',
    {
      lineHeight: '1.08',
      letterSpacing: '-1.28px',
    },
    {
      originalPxFontSize: 96,
      originaPxlLineHeight: 104,
      fontNames: [
        'xxl-xl-desktop-call-out-headline-title-right-dark',
        'xxl-xl-desktop-call-out-headline-title-left-dark',
        'xxl-xl-desktop-call-out-headline-title-centre-dark',
      ],
    },
  ],
  'fs-72-lh-72-ls--0.8': [
    '4.5rem',
    {
      lineHeight: '1',
      letterSpacing: '-0.8px',
    },
    {
      originalPxFontSize: 72,
      originaPxlLineHeight: 72,
      fontNames: [
        'l-m-tablet-call-out-headline-title-left-dark',
        'l-m-tablet-call-out-headline-title-centre-dark',
        'xxl-xl-desktop-title-1-right-dark',
      ],
    },
  ],
  'fs-64-lh-72-ls--0.8': [
    '4rem',
    {
      lineHeight: '1.13',
      letterSpacing: '-0.8px',
    },
    {
      originalPxFontSize: 64,
      originaPxlLineHeight: 72,
      fontNames: [
        'l-m-tablet-call-out-headline-title-left-dark',
        'l-m-tablet-call-out-headline-title-centre-dark',
        'xxl-xl-desktop-title-1-right-dark',
      ],
    },
  ],
  'fs-56-lh-64-ls--0.75': [
    '3.5rem',
    {
      lineHeight: '1.14',
      letterSpacing: '-0.75px',
    },
    {
      originalPxFontSize: 56,
      originaPxlLineHeight: 64,
      fontNames: [
        'l-m-tablet-title-1-left-dark',
        'xxl-xl-desktop-title-2-centre-dark',
        'l-m-tablet-title-1-right-dark',
      ],
    },
  ],
  'fs-56-lh-56-ls--0.75': [
    '3.5rem',
    {
      lineHeight: '1',
      letterSpacing: '-0.75px',
    },
    {
      originalPxFontSize: 56,
      originaPxlLineHeight: 56,
      fontNames: [
        'l-m-tablet-title-1-left-dark',
        'xxl-xl-desktop-title-2-centre-dark',
        'l-m-tablet-title-1-right-dark',
      ],
    },
  ],
  'fs-51-lh-61-ls-0': [
    '3.188rem',
    {
      lineHeight: '1.19',
      letterSpacing: '0',
    },
    {
      originalPxFontSize: 51,
      originaPxlLineHeight: 61,
      fontNames: [
        'l-m-tablet-title-1-left-dark',
        'xxl-xl-desktop-title-2-centre-dark',
        'l-m-tablet-title-1-right-dark',
      ],
    },
  ],
  'fs-48-lh-48-ls--0.15': [
    '3rem',
    {
      lineHeight: '1',
      letterSpacing: '-0.15px',
    },
    {
      originalPxFontSize: 48,
      originaPxlLineHeight: 48,
      fontNames: [
        'xxl-xl-desktop-quotation-centre-dark',
        'xxl-xl-desktop-quotation-right-dark',
        'xxl-xl-desktop-quotation-left-dark',
      ],
    },
  ],
  'fs-48-lh-64-ls--0.15': [
    '3rem',
    {
      lineHeight: '1.33',
      letterSpacing: '-0.15px',
    },
    {
      originalPxFontSize: 48,
      originaPxlLineHeight: 64,
      fontNames: [
        'xxl-xl-desktop-quotation-centre-dark',
        'xxl-xl-desktop-quotation-right-dark',
        'xxl-xl-desktop-quotation-left-dark',
      ],
    },
  ],
  'fs-44-lh-56-ls-0': [
    '2.75rem',
    {
      lineHeight: '1.27',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 44,
      originaPxlLineHeight: 56,
      fontNames: [
        'l-m-tablet-title-2-right-dark',
        'l-m-tablet-title-2-left-dark',
        'l-m-tablet-title-2-centre-dark',
      ],
    },
  ],
  'fs-42-lh-56-ls-0': [
    '2.63rem',
    {
      lineHeight: '1.33',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 42,
      originaPxlLineHeight: 56,
      fontNames: [
        'xxl-xl-desktop-title-3-centre-dark',
        'xxl-xl-desktop-title-3-right-dark',
        'xxl-xl-desktop-title-3-left-dark',
      ],
    },
  ],
  'fs-40-lh-48-ls-0.1': [
    '2.5rem',
    {
      lineHeight: '1.2',
      letterSpacing: '0.1px',
    },
    {
      originalPxFontSize: 40,
      originaPxlLineHeight: 48,
      fontNames: [
        's-mobile-call-out-headline-title-centre-dark',
        's-mobile-call-out-headline-title-left-dark',
        's-mobile-call-out-headline-title-right-dark',
      ],
    },
  ],
  'fs-36-lh-48-ls-0': [
    '2.25rem',
    {
      lineHeight: '1.33',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 36,
      originaPxlLineHeight: 48,
      fontNames: [
        'l-m-tablet-quotation-left-dark',
        'l-m-tablet-title-3-right-dark',
        'l-m-tablet-quotation-centre-dark',
        'l-m-tablet-quotation-right-dark',
        'l-m-tablet-title-3-left-dark',
        'l-m-tablet-title-3-centre-dark',
      ],
    },
  ],
  'fs-36-lh-44-ls-0.01': [
    '2.25rem',
    {
      lineHeight: '1.22',
      letterSpacing: '0.01px',
    },
    {
      originalPxFontSize: 36,
      originaPxlLineHeight: 44,
      fontNames: [
        's-mobile-title-1-left-dark',
        's-mobile-title-1-centre-dark',
        's-mobile-title-1-right-dark',
      ],
    },
  ],
  'fs-32-lh-40-ls-0': [
    '2rem',
    {
      lineHeight: '1.25',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 32,
      originaPxlLineHeight: 40,
      fontNames: [
        's-mobile-title-2-left-dark',
        's-mobile-title-2-right-dark',
        's-mobile-title-2-centre-dark',
      ],
    },
  ],
  'fs-32-lh-42-ls-0': [
    '2rem',
    {
      lineHeight: '1.31',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 32,
      originaPxlLineHeight: 42,
      fontNames: [
        'xxl-xl-desktop-title-4-right-dark',
        'xxl-xl-desktop-title-4-left-dark',
        'xxl-xl-desktop-title-4-centre-dark',
      ],
    },
  ],
  'fs-32-lh-35-ls-0.46': [
    '2rem',
    {
      lineHeight: '1.094',
      letterSpacing: '0.46px',
    },
    {
      originalPxFontSize: 32,
      originaPxlLineHeight: 35,
      fontNames: [
        'xxl-xl-desktop-title-4-right-dark',
        'xxl-xl-desktop-title-4-left-dark',
        'xxl-xl-desktop-title-4-centre-dark',
      ],
    },
  ],
  'fs-28-lh-28-ls-0': [
    '1.75rem',
    {
      lineHeight: '1',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 28,
      originaPxlLineHeight: 28,
      fontNames: [
        'xxl-xl-desktop-title-5-centre-dark',
        'l-m-tablet-title-4-right-dark',
        'xxl-xl-desktop-title-5-left-dark',
      ],
    },
  ],
  'fs-28-lh-32-ls-0': [
    '1.75rem',
    {
      lineHeight: '1.33',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 28,
      originaPxlLineHeight: 32,
      fontNames: [
        'xxl-xl-desktop-title-5-centre-dark',
        'l-m-tablet-title-4-right-dark',
        'xxl-xl-desktop-title-5-left-dark',
      ],
    },
  ],
  'fs-28-lh-36-ls-0': [
    '1.75rem',
    {
      lineHeight: '1.29',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 28,
      originaPxlLineHeight: 36,
      fontNames: [
        'xxl-xl-desktop-title-5-centre-dark',
        'l-m-tablet-title-4-right-dark',
        'xxl-xl-desktop-title-5-left-dark',
      ],
    },
  ],
  'fs-28-lh-38-ls-0.1': [
    '1.75rem',
    {
      lineHeight: '1.36',
      letterSpacing: '0.1px',
    },
    {
      originalPxFontSize: 28,
      originaPxlLineHeight: 38,
      fontNames: [
        's-mobile-quotation-centre-dark',
        's-mobile-quotation-right-dark',
        's-mobile-quotation-left-dark',
      ],
    },
  ],
  'fs-28-lh-40-ls-0': [
    '1.75rem',
    {
      lineHeight: '1.43',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 28,
      originaPxlLineHeight: 40,
      fontNames: [
        's-mobile-title-3-left-dark',
        'xxl-xl-desktop-super-paragraph-large-centre-dark',
        's-mobile-title-3-centre-dark',
      ],
    },
  ],
  'fs-24-lh-24-ls-0': [
    '1.5rem',
    {
      lineHeight: '1.13',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 24,
      originaPxlLineHeight: 24,
      fontNames: [
        'xxl-xl-desktop-super-paragraph-large-left-dark',
        'xxl-xl-desktop-title-6-left-dark',
      ],
    },
  ],
  'fs-24-lh-36-ls-0': [
    '1.5rem',
    {
      lineHeight: '1.5',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 24,
      originaPxlLineHeight: 36,
      fontNames: [
        'xxl-xl-desktop-super-paragraph-large-left-dark',
        'xxl-xl-desktop-title-6-left-dark',
      ],
    },
  ],
  'fs-22-lh-27-ls-0': [
    '1.375rem',
    {
      lineHeight: '1.25',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 22,
      originaPxlLineHeight: 27,
      fontNames: [
        'xxl-xl-desktop-super-paragraph-large-left-dark',
        'xxl-xl-desktop-title-6-left-dark',
      ],
    },
  ],
  'fs-22-lh-36-ls-0': [
    '1.375rem',
    {
      lineHeight: '1.29',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 22,
      originaPxlLineHeight: 36,
      fontNames: [
        'xxl-xl-desktop-super-paragraph-large-left-dark',
        'xxl-xl-desktop-title-6-left-dark',
      ],
    },
  ],
  'fs-16-lh-20-ls-0': [
    '1rem',
    {
      lineHeight: '1.25',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 16,
      originaPxlLineHeight: 20,
      fontNames: ['global-body-big-left-dark'],
    },
  ],
  'fs-16-lh-24-ls-0': [
    '1rem',
    {
      lineHeight: '1.5',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 16,
      originaPxlLineHeight: 24,
      fontNames: ['global-body-big-left-dark'],
    },
  ],
  'fs-16-lh-24-ls-0.2': [
    '1rem',
    {
      lineHeight: '1.5',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 16,
      originaPxlLineHeight: 24,
      fontNames: ['global-captions-large-left-dark'],
    },
  ],
  'fs-14-lh-24-ls-0': [
    '0.875rem',
    {
      lineHeight: '1.715',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 14,
      originaPxlLineHeight: 24,
      fontNames: ['global-body-small-left-dark'],
    },
  ],
  'fs-14-lh-20-ls-0.4': [
    '0.88rem',
    {
      lineHeight: '1.43',
      letterSpacing: '0.4px',
    },
    {
      originalPxFontSize: 14,
      originaPxlLineHeight: 20,
      fontNames: ['global-body-small-left-dark'],
    },
  ],
  'fs-14-lh-20-ls-0.2': [
    '0.875rem',
    {
      lineHeight: '1.43',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 14,
      originaPxlLineHeight: 20,
      fontNames: ['global-captions-small-left-dark'],
    },
  ],
  'fs-12-lh-16-ls-0.2': [
    '0.75rem',
    {
      lineHeight: '1.33',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 12,
      originaPxlLineHeight: 16,
      fontNames: ['global-regulatorytext-large-left-dark'],
    },
  ],
  'fs-12-lh-20-ls-0.2': [
    '0.75rem',
    {
      lineHeight: '1.67',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 12,
      originaPxlLineHeight: 20,
      fontNames: ['global-hinttext-left-dark'],
    },
  ],
  'fs-10-lh-12-ls-0.2': [
    '0.63rem',
    {
      lineHeight: '1.2',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 10,
      originaPxlLineHeight: 12,
      fontNames: ['global-regulatorytext-small-left-dark'],
    },
  ],
  'fs-8-lh-12-ls-0.13': [
    '0.5rem',
    {
      lineHeight: '1.2',
      letterSpacing: '0.13px',
    },
    {
      originalPxFontSize: 8,
      originaPxlLineHeight: 12,
      fontNames: ['global-regulatorytext-small-left-dark'],
    },
  ],
  'fs-20-lh-32-ls-0': [
    '1.25rem',
    {
      lineHeight: '1.6',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 20,
      originaPxlLineHeight: 32,
      fontNames: [
        'xxl-xl-desktop-super-paragraph-small-left-dark',
        'l-m-tablet-title-6-left-dark',
      ],
    },
  ],
  'fs-24-lh-32-ls-0': [
    '1.5rem',
    {
      lineHeight: '1.33',
      letterSpacing: '0px',
    },
    {
      originalPxFontSize: 24,
      originaPxlLineHeight: 32,
      fontNames: ['l-m-tablet-title-5-left-dark'],
    },
  ],
  'fs-20-lh-24-ls-0.2': [
    '1.25rem',
    {
      lineHeight: '1.2',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 20,
      originaPxlLineHeight: 24,
      fontNames: ['s-mobile-title-5-left-dark'],
    },
  ],
  'fs-18-lh-28-ls-0.2': [
    '1.13rem',
    {
      lineHeight: '1.56',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 18,
      originaPxlLineHeight: 28,
      fontNames: ['s-mobile-title-6-left-dark'],
    },
  ],
  'fs-18-lh-24-ls-0.2': [
    '1.13rem',
    {
      lineHeight: '1.2',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 18,
      originaPxlLineHeight: 24,
      fontNames: ['s-mobile-title-6-left-dark'],
    },
  ],
  'fs-18-lh-18-ls-0.2': [
    '1.13rem',
    {
      lineHeight: '0',
      letterSpacing: '0.2px',
    },
    {
      originalPxFontSize: 18,
      originaPxlLineHeight: 18,
      fontNames: ['s-mobile-title-6-left-dark'],
    },
  ],
  'fs-20-lh-28-ls-0.1': [
    '1.25rem',
    {
      lineHeight: '1.4',
      letterSpacing: '0.1px',
    },
    {
      originalPxFontSize: 20,
      originaPxlLineHeight: 28,
      fontNames: ['s-mobile-super-paragraph-large-left-dark'],
    },
  ],
};
