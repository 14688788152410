const jsonResponse = `
{
 "product": {
      "product_id": "60a2347564b6824e0d84e498",
      "group": "itg_product",
      "name": "JBL Kopfhörer",
      "category": "Elektronik",
      "image": "main",
      "description": "Der JBL TUNE500 liefert kraftvolle Soundqualität, <a href='#' target='_blank'­>IQOS Store bzw. zu unseren IQOS Sammelstationen</a> die dich täglich neu begeistert. Dieser Kopfhörer bereitet dir mit tollem <a href='#'>Sound jederzeit</a> Vergnügen, denn er verfügt über JBL Pure Bass Sound, JBL 32-mm-Treiber und hat eine universelle Ein-Tasten-Fernbedienung, die mit den meisten Smartphones kompatibel ist. Der JBL TUNE500 Kopfhörer ist leicht und bequem dank der weichen Ohrpolster und des gepolsterten Kopfbügels.Außerdem erlaubt er die Verbindung zu Siri oder Google Now, ohne dass du dafür dein Mobilgerät verwenden musst. Den JBL TUNE500 Kopfhörer gibt es in vier frischen Farben, er hat ein verwicklungsfreies Flachkabel und er lässt sich für unterwegs einfach zusammenfalten. Er bietet damit eine Plug 'n' Go-Lösung, mit der du jeden Moment deines Lebens mit Musik bereichern kannst.JBL Pure Bass SoundDer Kopfhörer bietet den weltbekannten JBL Pure Bass Sound, der an fast jedem berühmten Veranstaltungsort rund um die Welt zu fi nden ist.Ein-Tasten-Fernbedienung mit MikrofonMusik und Anrufe kannst du über Zugriff auf Siri oder Google Now mit einem einzigen Knopfdruck leicht steuern.Leichtes und faltbares DesignDurch die leichten Materialien und das faltbare Design kann der Kopfhörer bequem überallhin mitgenommen werden, um Musik zu hören, wann immer du willst.",
      "sub_headline": "TUNE500",
      "required_points": 100,
      "limited_edition": "true",
      "attributes": [
        "Lieferung 1-3 Werktage",
        "Kostenloser Versand",
        "3 Jahre Garantie"
      ],
      "variants": [
        {
          "variant_id": "955782b0-b6f0-11eb-a7db-0242ac110005",
          "color_name": "",
          "color_code": "#000000",
          "out_of_stock": false,
          "units_left": 10,
          "images": [
            "60a2347564b6824e0d84e48e",
            "60a2347564b6824e0d84e490",
            "60a2347564b6824e0d84e492",
            "60a2347564b6824e0d84e494",
            "60a2347564b6824e0d84e496"
          ]
        },
        {
          "variant_id": "23da0db4-b6f1-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#ffffff",
          "out_of_stock": false,
          "units_left": 15,
          "images": [
            "60a235647a2e905a5984e499",
            "60a235647a2e905a5984e49b",
            "60a235647a2e905a5984e49d",
            "60a235647a2e905a5984e49f",
            "60a235647a2e905a5984e4a1"
          ]
        },
        {
          "variant_id": "23da1458-b6f1-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#F0F8FF",
          "out_of_stock": true,
          "images": [
            "60a235647a2e905a5984e4a3",
            "60a235647a2e905a5984e4a5",
            "60a235647a2e905a5984e4a7",
            "60a235647a2e905a5984e4a9",
            "60a235647a2e905a5984e4ab"
          ]
        }
      ],
      "order_group": "z",
      "label": "Supercool !",
      "label_color_code": "#FF0000"
    }
    }
`;
export default JSON.parse(jsonResponse);
