// use CMS API endpoints which serve also unpublished pages for testing purposes
const IS_TESTING_MODE = process.env.VUE_APP_TESTING_MODE === '1';
const useApiMock = process.env.VUE_APP_USE_API_MOCK === '1';

/**
 * Factory for declaring actions in a declarative syntax.
 * Please notice that before fetching, we refresh the request headers,
 *    obtaining a new JWT from gigya
 *
 * Usage:
 * ActionFactory({
 *    name: 'myAction',
 *    fetchFn: fnFetchingData,
 *    normalizeFn: fnNormalizingResponseData,
 *    committerFn: fnComittingBasedOnResponse,
 *    catchMutation: mutationNameWhenThingsGoBad,
 * })
 *
 */
// @TODO: Type this instead of the Error throwing
export const ActionFactory = ({
  context: { commit, dispatch },
  fetchFn,
  normalizeFn,
  committerFn,
  catchMutation,
}) => {
  if (!commit || !dispatch)
    throw new Error('ActionFactory didnt receive the proper context');
  if (!fetchFn) throw new Error('ActionFactory didnt receive the fetchFn');
  if (!normalizeFn)
    throw new Error('ActionFactory didnt receive the normalizeFn');
  if (!committerFn)
    throw new Error('ActionFactory didnt receive the committerFn');
  if (!catchMutation)
    throw new Error('ActionFactory didnt receive the catchMutation');
  return dispatch('authModule/refreshHeaders', null, { root: true })
    .then(fetchFn)
    .then(normalizeFn)
    .then(committerFn(commit))
    .catch((error) => commit(catchMutation, error));
};

export default {};
