import { Router } from 'vue-router';

export const routeNames = {
  PODCASTS_HOME: 'podcasts',
  ACADEMY_HOME: 'academy',
};

export default initialisePodcastsRouter;

const routes = [
  {
    path: `/${routeNames.PODCASTS_HOME}`,
    name: routeNames.PODCASTS_HOME,
    meta: {
      gtagDomain: 'podcasts',
    },
    component: () =>
      import(/* webpackChunkName: "Podcasts" */ './views/PodcastsHome.vue'),
    beforeEnter: () => routeNames.ACADEMY_HOME, // TODO: remove this once podcasts is ready
  },
];

function initialisePodcastsRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
