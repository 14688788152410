import { authApiGet, authApiPost } from '@/api/api';

const URL_PARAMS = [
  'limit=40',
]

const URL_FIELDS = [
  'page_content',
  'page_teaser',
  'iqos_points_only',
  'status_points_only',
  'iqos_points',
  'category',
  'category_image',
  'page_cta',
  'publication_date',
  'page_footnote',
];

export const URLS = {
  STORIES: `pages/?type=editorial.ArticlePage&fields=${URL_FIELDS.toString()}&${URL_PARAMS.toString()}`,
  DONE_STORIES: 'editorial/pages',
  STORY_MARK_READ: (pageId) => `editorial/${pageId}`,
  TRACK_STORY: (pageId) => `editorial/cta2/${pageId}`,
};

/*
 * Usage: fetchStories(headers)
 * */
export const fetchStories = authApiGet(URLS.STORIES, 'CMS');
export const fetchDoneStoriesIds = authApiGet(URLS.DONE_STORIES);

export const postStoryRead = ({ pageId, payload }) =>
  authApiPost(URLS.STORY_MARK_READ(pageId), payload);

export const postTrackStory = (pageId) => authApiGet(URLS.TRACK_STORY(pageId));
