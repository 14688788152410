import { Product, ErrorMessage } from '../../types';
import { ProductDTO, ProductDetailDTO } from '../dtos';
import ProductVariantNormalizer from './product-variant';
import ImageNormalizer from './image';

/**
 * Normalizes the product gotten from an API call
 * @param { ProductDTO } product - product from the API w/ its attributes in snake_case format
 * @returns { Product } product normalized with its attributes in camelCase format
 * */
export const productNormalizer = (product: ProductDTO): Product => ({
  attributes: product.attributes,
  category: product.category,
  mainCategory: product.main_category || '',
  description: product.description,
  image: ImageNormalizer(product.product_id, product.image),
  label: product.label,
  labelColor: product.label_color_code,
  name: product.name,
  limitedEdition: product.limited_edition,
  hiddenInListing: product.hidden_in_listing,
  orderGroup: product.order_group,
  productId: product.product_id,
  requiredPointsBefore: Number(product.required_points_before),
  requiredPoints: product.required_points,
  subHeadline: product.sub_headline,
  type: product.group,
  url: product.url,
  variants: product.variants.map((variant, index) =>
    ProductVariantNormalizer(product.product_id, variant, index)
  ),
  orderLimit: product.one_order_limit,
  requiredTier: product.required_tierlevel,
  customCta: product.custom_cta_title,
  notes: product.notes,
});

/**
 * Normalizes the response from the productDetail API call
 * @param { ProductDetailDTO } product
 * @returns {Product | { errorMessage: string }} requested Product or an errorMessage
 * if the API couldn't find the product by id.
 * */
export default (response: ProductDetailDTO): Product | ErrorMessage =>
  'error_message' in response
    ? { errorMessage: response.error_message }
    : productNormalizer(response.product);
