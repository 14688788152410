
import { defineComponent, ref } from 'vue';
import { NavFirstLevel, NavSecondLevel, NavThirdLevel } from '../NavHeader.types'
import DynamicUnderline from '@/components/DynamicUnderline/DynamicUnderline.vue';


export default defineComponent({
  components: { DynamicUnderline },
  props: {
    menu: {
      type: Object as () => NavFirstLevel | NavSecondLevel | NavThirdLevel,
    },
    transitionDelay: { type: String, default: '0' },
    isActive: { type: Boolean, default: true },
    hideUnderline: { type: Boolean, default: false },
  },
   setup() {
    const isHover = ref<boolean>(false);
    const triggerHover = () => isHover.value = true;

    return {
      triggerHover,
    };
   }
});
