export default (isArchived) => ({ value: lesson }, index, lessons) => ({
  estimatedTime: parseInt(lesson.duration_in_minutes),
  failureMessage: lesson.failed_lesson_message,
  gameUrl: lesson.leadfamly_game_url,
  index,
  isCompleted: isArchived ? false : lesson.completed_in,
  nextLessonSlug: lessons[index + 1] ?? `lesson-${index + 1}`,
  slug: `lesson-${index}`,
  subtitle: lesson.summary,
  successMessage: {
    image: lesson.key_message_image?.download_url,
    text: lesson.key_message,
  },
  title: lesson.name,
});
