
import { reactive } from 'vue';
import { NavFirstLevel, NavSecondLevel } from './NavHeader.types'


export const globalNavState = reactive({
  isFirstLevelVisible: false,
  firstLevelActive: null,
  firstLevelActiveLabel: null,
  isSecondLevelVisible: false,
  secondLevelItems: null,
  secondLevelActive: null,
  secondLevelActiveLabel: null,
  isThirdLevelVisible: false,
  thirdLevelItems: null,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useNavState = (): any => {

  const firstLevelClick = (menu: NavFirstLevel, isDesktop = true) => {
    if (globalNavState.firstLevelActive === menu.id && isDesktop) {
      closeAllMenus()
      return;
    }
    globalNavState.firstLevelActive = menu.id
    globalNavState.firstLevelActiveLabel = menu.text
    globalNavState.secondLevelItems = menu.secondLevel
    globalNavState.isSecondLevelVisible = true
    globalNavState.isThirdLevelVisible = false
    globalNavState.secondLevelActive = null
  }

  const secondLevelClick = (menu: NavSecondLevel) => {
    globalNavState.secondLevelActive = menu.id
    globalNavState.secondLevelActiveLabel = menu.text
    globalNavState.isThirdLevelVisible = true;
    globalNavState.thirdLevelItems = menu.thirdLevel;
  }
  const toggleMobileNav = () => {
    globalNavState.isFirstLevelVisible = !globalNavState.isFirstLevelVisible
  }
  const closeAllMenus = () => {
    globalNavState.isFirstLevelVisible = false
    globalNavState.firstLevelActive = null
    globalNavState.isSecondLevelVisible = false
    globalNavState.secondLevelActive = null
    globalNavState.isThirdLevelVisible = false
  }
  const closeSecondLevelMenu = () => {
    globalNavState.firstLevelActive = null
    globalNavState.isSecondLevelVisible = false
    globalNavState.secondLevelItems = null
    globalNavState.secondLevelActive = null
  }
  const closeThirdLevelMenu = () => {
    globalNavState.isThirdLevelVisible = false
    globalNavState.secondLevelActive = null
  }
  const transitionDurationCalc = (index) => {
    // index position * seconds between items + beging enter time
    const numb = index * 0.2 + 0.1;
    return numb.toFixed(2);
  }
  const getImageUrl = (imageRef) => {
    return require(`@/components/NavHeader/assets/images/${imageRef}`);
  }
  return {
    firstLevelClick,
    secondLevelClick,
    transitionDurationCalc,
    closeAllMenus,
    closeSecondLevelMenu,
    closeThirdLevelMenu,
    toggleMobileNav,
    getImageUrl
  }
};
