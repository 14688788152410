/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import asyncActions from './asyncActions.js';
import actions from './actions.js';

export const initialState = () => ({
  isSupportArtistTeamPickError: null,
});

export const mutationsFunctions = {
  SET_SUPPORT_ARTIST_TEAM_PICK_ERROR: (state, payload) => {
    state.isSupportArtistTeamPickError = payload;
    console.debug(
      'SET_SUPPORT_ARTIST_TEAM_PICK_ERROR',
      state.isSupportArtistTeamPickError
    );
  },
};

export const mutations: { [key: string]: string } = Object.keys(
  mutationsFunctions
).reduce((acc, keyName) => ({ ...acc, [keyName]: keyName }), {});

const togetherXModule = {
  namespaced: true,
  state: initialState(),
  mutations: mutationsFunctions,
  actions: { ...actions, ...asyncActions },
};

export default togetherXModule;
