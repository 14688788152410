export const GetApplicationResponse = {
  status: 200,
  application: false,
  // application: {
  //   artist_team: 'artist-1',
  //   terms_accepted: false,
  // }
};

export const PostApplicationResponse = {
  status: 200,
};

export const PostSupportArtistTeamResponse = {
  status: 200,
};
