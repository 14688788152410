import initState from './initial-state';
import {
  OPEN_COOKIE_SETTINGS,
  PUSH_ERROR,
  POP_ERROR,
  EMPTY_ERRORS,
} from './mutation-types';

const mutations = {
  // Cookies
  [OPEN_COOKIE_SETTINGS]: (state) => {
    state.showCookieSettingsModal = true;
  },
  [PUSH_ERROR]: (state, payload) => {
    state.errors.push(payload);
  },
  [POP_ERROR]: (state) => {
    state.errors.pop();
  },
  [EMPTY_ERRORS]: (state) => {
    state.errors = [];
  },
};

export default mutations;
