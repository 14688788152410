export default {
  current_applications: 1234,
  points_for_application: 150,
  points_for_team_support: 100,
  users_artist: null,
  users_support_team: 'bluemchen',
  team_support_count: {
    bluemchen: 1,
    kool_savas: 0,
    michael_schulte: 0,
  },
  teams_list: ['bluemchen', 'kool_savas', 'michael_schulte'],
};
