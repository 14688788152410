// mutation types
import {
  SET_STORIES,
  SET_READ_STORIES_IDS,
  SELECT_ARCHIVED_STORY,
  UNSELECT_ARCHIVED_STORY,
  SET_PROVISIONAL_READ_STORIES_IDS,
  SELECT_STORY_DROPDOWN_OPTION,
  UNSELECT_STORY_DROPDOWN_OPTION,
  UNSET_STORY_DROPDOWN_ERROR,
  SET_STORY_DROPDOWN_ERROR,
  READ_STORY,
  REMOVE_READ_STORY,
} from './mutation-types';

// types
import { Stories, StoriesState, Story } from '../types';

const mutations = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  [SET_STORIES]: (state: StoriesState, stories: Stories): void => {
    state.stories = stories;
    console.debug(SET_STORIES, state.stories);
  },
  [SET_READ_STORIES_IDS]: (
    state: StoriesState,
    readStoriesIds: number[]
  ): void => {
    state.readStoriesIds = readStoriesIds;
    console.debug(SET_READ_STORIES_IDS, state.readStoriesIds);
  },
  [SET_PROVISIONAL_READ_STORIES_IDS]: (
    state: StoriesState,
    provisionalReadStoriesIds: number[]
  ): void => {
    state.provisionalReadStoriesIds = provisionalReadStoriesIds;
    console.debug(
      SET_PROVISIONAL_READ_STORIES_IDS,
      state.provisionalReadStoriesIds
    );
  },
  [SELECT_ARCHIVED_STORY]: (state: StoriesState, story: Story): void => {
    state.selectedArchivedStory = { ...story, isArchived: true };
  },
  [UNSELECT_ARCHIVED_STORY]: (state: StoriesState): void => {
    state.selectedArchivedStory = null;
  },
  [SELECT_STORY_DROPDOWN_OPTION]: (
    state: StoriesState,
    payload: { key: string; option: string }
  ): void => {
    state.selectedStoryDropdownOption[payload.key] = payload.option;
  },
  [UNSELECT_STORY_DROPDOWN_OPTION]: (state: StoriesState): void => {
    state.selectedStoryDropdownOption = {};
  },
  [UNSET_STORY_DROPDOWN_ERROR]: (state: StoriesState): void => {
    state.storyDropdownError = {};
  },
  [SET_STORY_DROPDOWN_ERROR]: (
    state: StoriesState,
    payload: { key: string; error: string }
  ): void => {
    state.storyDropdownError[payload.key] = payload.error;
  },
  [READ_STORY]: (state: StoriesState, id: number): void => {
    state.readStoryId = id;
  },
  [REMOVE_READ_STORY]: (state: StoriesState): void => {
    state.stories.items = state.stories.items.filter(
      (item) => item.id !== state.readStoryId
    );
    state.readStoryId = null;
  }
};
export default mutations;
