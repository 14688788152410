import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "nav-desktop flex items-center flex-auto font-iqosHeader" }
const _hoisted_2 = { class: "w-full flex items-center lg:gap-3 lg:mt-0.5 lg:-ml-1 xl:ml-0" }
const _hoisted_3 = { class: "nav-desktop__overlay-list-container" }
const _hoisted_4 = { class: "nav-desktop__overlay-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavDesktopFirstLevel = _resolveComponent("NavDesktopFirstLevel")!
  const _component_TSlideInLeft = _resolveComponent("TSlideInLeft")!
  const _component_NavThirdLevel = _resolveComponent("NavThirdLevel")!
  const _component_NavFooter = _resolveComponent("NavFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItemsDesktop.itemsLeft, (item, index) => {
        return (_openBlock(), _createBlock(_component_NavDesktopFirstLevel, {
          id: `DCE_nav_${item.id}`,
          key: item.id,
          menu: item,
          isActive: _ctx.isFirstLinkActive(item.id),
          shouldBeGreyActive: _ctx.isLinkActiveOrInitialState(item.id),
          class: _normalizeClass(["nav-desktop__list-item", {
          'opacity-50': _ctx.shouldBeGreyHoverFirsLevel(item.id),
          'pl-0.5': index !== 0,
        }]),
          onFirstLevelClick: _ctx.firstLevelClick,
          onMouseover: ($event: any) => (_ctx.toggleMouseOverFirstLevelLink(item.id)),
          onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMouseOverFirstLevelLink(null)))
        }, null, 8, ["id", "menu", "isActive", "shouldBeGreyActive", "class", "onFirstLevelClick", "onMouseover"]))
      }), 128))
    ]),
    (_ctx.globalNavState.isSecondLevelVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "nav-desktop__overlay",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeAllMenus && _ctx.closeAllMenus(...args)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
          'nav-desktop__overlay-container',
          _ctx.contentClasses,
          'w-screen z-50',
        ]),
            onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.globalNavState.secondLevelItems.firstList, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id,
                    class: "nav-desktop__overlay-list-item"
                  }, [
                    _createVNode(_component_TSlideInLeft, {
                      style: _normalizeStyle(`--enter-delay: ${_ctx.transitionDurationCalc(index)}s;`)
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                          id: `DCE_nav_${item.id}`,
                          menu: item,
                          isActive: _ctx.isSecondLinkActive(item.id),
                          isItemSelected: _ctx.globalNavState.secondLevelActive === item.id,
                          class: "pl-12 xl:pl-0",
                          onMouseover: ($event: any) => (_ctx.toggleMouseOverSecondLevelLink(item.id)),
                          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleMouseOverSecondLevelLink(null))),
                          onClick: ($event: any) => (_ctx.hasSecondLevelMenu(item))
                        }, null, 40, ["id", "menu", "isActive", "isItemSelected", "onMouseover", "onClick"]))
                      ]),
                      _: 2
                    }, 1032, ["style"])
                  ]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.globalNavState.secondLevelItems
                .secondList, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id
                  }, [
                    _createVNode(_component_TSlideInLeft, {
                      style: _normalizeStyle(`--enter-delay: ${_ctx.transitionDurationCalc(
                  _ctx.globalNavState.secondLevelItems.firstList.length + index
                )}s;`)
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                          menu: item,
                          isActive: _ctx.isSecondLinkActive(item.id),
                          class: "pl-12 xl:pl-0",
                          onMouseover: ($event: any) => (_ctx.toggleMouseOverSecondLevelLink(item.id)),
                          onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleMouseOverSecondLevelLink(null)))
                        }, null, 40, ["menu", "isActive", "onMouseover"]))
                      ]),
                      _: 2
                    }, 1032, ["style"])
                  ]))
                }), 128))
              ]),
              (_ctx.globalNavState.isThirdLevelVisible)
                ? (_openBlock(), _createBlock(_component_NavThirdLevel, {
                    key: 0,
                    menu: _ctx.globalNavState.thirdLevelItems
                  }, null, 8, ["menu"]))
                : _createCommentVNode("", true),
              (_ctx.hasNavFooter)
                ? (_openBlock(), _createBlock(_component_TSlideInLeft, {
                    key: 1,
                    class: "nav-desktop__transition-footer"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_NavFooter, {
                        key: _ctx.hasNavFooter.id,
                        class: _normalizeClass([
                'absolute top-16 right-0',
                'mr-12 pl-5 xl:mr-32 xxl:mr-0',
                'w-1/3 xl:w-102',
                'z-50',
              ]),
                        footer: _ctx.hasNavFooter
                      }, null, 8, ["footer"]))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}