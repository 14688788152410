<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fill-rule="evenodd" stroke-linecap="round">
          <g stroke="currentColor">
              <g>
                  <g>
                      <path d="M0 0L5 5 10 0" transform="translate(-96 -556) translate(96 556) matrix(1 0 0 -1 3 10.5) matrix(1 0 0 -1 0 5)"></path>
                  </g>
              </g>
          </g>
      </g>
  </svg>
</template>

<script>
import { svg } from '@/mixins/svgMixin';
export default svg({
  name: 'IconArrowDown',
  width: 13,
  height: 7,
  color: 'currentColor',
});
</script>

<style></style>
