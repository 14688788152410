import VoucherUsed from './voucher-used';
import VoucherValid from './voucher-valid';
import VoucherInvalid from './voucher-invalid';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  mock.onGet('custom_voucher\/BKTESTG9VLZRM')
    .reply(makeResponse('Voucher used', VoucherUsed, 404));

  mock.onGet('custom_voucher\/NOT_VALID')
    .reply(makeResponse('Voucher invalid', VoucherInvalid, 401));

  mock.onGet('custom_voucher\/BKTEST4DT5Y5QP')
    .reply(makeResponse('Voucher Valid', VoucherValid));
}
