const jsonResponse = `
{
    "status": 200,
    "order_response": {
        "voucher_code": "Awetwatqt",
        "order_id": "60c1f13ad9a72a201bf74fca",
        "success": true
    }
}
`;

export const ProductVoucherOrderError = JSON.parse(`
{
    "status": 423,
    "error_type": "limit",
    "show_to_user": true,
    "custom_error": "Lorem ipsum dolor sit amet 423 error mock"
}
`);

export default JSON.parse(jsonResponse);
