import { NewsArticleRaffleState } from '../types';
import { NEWS_ARTICLE_RAFFLE_REGISTERED } from './mutation-types';

const mutations = {
  [NEWS_ARTICLE_RAFFLE_REGISTERED]: (
    state: NewsArticleRaffleState,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    payload
  ): void => {
    state.newsArticleRaffleRegisterStatus = payload;
  },
};

export default mutations;
