import { createStore, Store, StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';

import initState from './initial-state';
import mutations from './mutations';

import { RootState } from './types';

const vuexLocal = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage,
  reducer: () => ({}),
});

const getters = {};

export const initialStore: StoreOptions<RootState> = {
  state: initState(),
  mutations,
  getters,
  plugins: [vuexLocal.plugin],
};

const store: Store<RootState> = createStore(initialStore);

store.subscribeAction({
  error: (action, state, error) => {
    console.debug(`Unhandled Vuex action error on: ${action.type}`);
    console.error(error);
    store.commit('PUSH_ERROR', error);
  },
});

export default store;
