const jsonResponse = `
{
  "product": {
    "product_id": "6086f24a896da8d2947a7191",
    "group": "itg_product",
    "name": "Bose Lautsprecher",
    "category": "Elektronik",
    "image": "main",
    "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. <br><br>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. <br><br>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    "sub_headline": "Sub-Headline",
    "required_points": 17,
    "attributes": [
      "Lieferung 1-3 Werktage",
      "Kostenloser Versand",
      "1 Jahr Garantie"
    ],
    "variants": [
      {
        "variant_id": "f82265de-ada1-11eb-bc68-0242ac110005",
        "color_name": "Schwarz",
        "color_code": "#000000",
        "out_of_stock": true,
        "images": [
          "6087cd6df7a95b5ecb356f7e",
          "6087cd6df7a95b5ecb356f80",
          "6098fcdde7f155ce99ef12b8",
          "6098fcdde7f155ce99ef12ba"
        ]
      },
      {
        "variant_id": "f8226fac-ada1-11eb-bc68-0242ac110005",
        "color_name": "Rot",
        "color_code": "#FF0000",
        "out_of_stock": true,
        "images": [
          "6087cd54f7a95b5ecb356f7c",
          "6098fcdee7f155ce99ef12bc",
          "6098fcdee7f155ce99ef12bf"
        ]
      }
    ],
    "label": "Limited Edition 1",
    "required_points_before": "500",
    "label_color_code": ""
  }
}
`;
export default JSON.parse(jsonResponse);
