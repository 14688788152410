import CalendarResponse from './calendar';
import PoemResponse from './poem';
import DoorResponse from './door';
import ActionResponse from './action';

//import ErrorResponse from './error-response';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  // mock
  //   .onGet('adventcalendar/')
  //   .reply(makeResponse('calendar', CalendarResponse));
  // mock.onPost('adventcalendar/poem').reply(makeResponse('poem', PoemResponse));
  // mock
  //   .onGet(/adventcalendar\/door\/\d*/)
  //   .reply(makeResponse('door', DoorResponse));
  // This one interferes with other modules action requests
  // mock.onPost(/action\/[A-Z\d]*/).reply(makeResponse('action', ActionResponse));
}
