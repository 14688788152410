
import Button from '@/components/Button/Button.vue';
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';
import { watch, watchEffect } from 'vue';
import { DCE_LOGIN_URL, CLUB_LOYALTY_URL } from '../constants';

const userAgent: string = navigator.userAgent.toLowerCase();
const isNotSafari = !(userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1);

export default defineComponent({
  components: { Button },
  setup() {
    const store = useStore();

    const authError = computed(() => store.getters['authModule/authError']);
    const isUserAuthenticated = computed(
      () => store.getters['authModule/isUserAuthenticated']
    );
    const clubError = computed(() => store.getters['consumerModule/clubError']);
    const isClubUser = computed(() => store.getters['consumerModule/isClubUser']);

    const consumerFetchError = computed(
      () => store.state.consumerModule.consumerFetchError
    );

    const isAuthProcessFinished = computed(
      () => store.getters['authModule/isAuthProcessFinished']
    );
    const loggedOut = computed(
      () => store.state.consumerModule.loggedOut
    );

    const shouldDisplayLoginButton = computed(() =>
      isAuthProcessFinished.value
      && (
        !isUserAuthenticated.value    // user not auth
        || isClubUser.value === false // not a club member
        || authError.value            // error when authenticating
        || consumerFetchError.value   // error when fetching it's details
      )
      || loggedOut.value
    );

    const loginUrl = computed(() =>
      isUserAuthenticated.value && !isClubUser.value
        ? CLUB_LOYALTY_URL
        : DCE_LOGIN_URL
    );

    store.dispatch('authModule/initAuth');

    const stoptHandlingGetConsumer = watchEffect(() => {
      if (isAuthProcessFinished.value && isUserAuthenticated.value) {
        store.dispatch('consumerModule/getConsumer');
        setTimeout(() => stoptHandlingGetConsumer(), 0);
      }
    });

    const stopWatchingAuthError = watch(authError, (newAuthError) => {
      if (isNotSafari && newAuthError) {
        location.href = DCE_LOGIN_URL;
        stopWatchingAuthError();
      }
    });

    const stopWatchingClubError = watch(clubError, (newClubError) => {
      if (isNotSafari && newClubError) {
        location.href = CLUB_LOYALTY_URL;
        stopWatchingClubError();
      }
    });

    const stopWatchingIsClubUser = watch(isClubUser, (newIsClubUser) => {
      if (isNotSafari && newIsClubUser === false) {
        location.href = DCE_LOGIN_URL;
        stopWatchingIsClubUser();
      }
    });

    const stopWatchingconsumerFetchError = watch(consumerFetchError, (newConsumerFetchError) => {
      if (isNotSafari && newConsumerFetchError) {
        location.href = CLUB_LOYALTY_URL;
        stopWatchingconsumerFetchError();
      }
    });

    return {
      loginUrl,
      isUserAuthenticated,
      isClubUser,
      shouldDisplayLoginButton,
    };
  },
});
