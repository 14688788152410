import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrowDown = _resolveComponent("IconArrowDown")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass([
      'nav-desktop-first-level',
      'm-0',
      'hover:opacity-100',
      'text-fs-16-lh-24-ls-0 lg:text-fs-14-lh-20-ls-0.2',
      { 'opacity-50': !_ctx.shouldBeGreyActive },
    ]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("button", {
      id: _ctx.id,
      class: "flex items-center"
    }, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.menu.text), 1),
      _createVNode(_component_IconArrowDown, {
        class: _normalizeClass(["nav-desktop-first-level__arrow ml-1 duration-300 pointer-events-none", { 'transform rotate-180': _ctx.isActive }]),
        width: "11"
      }, null, 8, ["class"])
    ], 8, _hoisted_1)
  ], 2))
}