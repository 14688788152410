const jsonResponse = `
{
  "product": {
    "product_id": "652d23114718962c0be84bfd",
    "group": "itg_additional",
    "name": "IQOS Sammelbeutel f\u00fcr Tabacksticks",
    "category": "",
    "main_category": null,
    "image": "main",
    "description": "Wirf Deine gebrauchten Tabaksticks nicht auf die Stra\u00dfe oder in die Umgebung, sondern entsorge sie im Restm\u00fcll oder sammle sie und bringe sie in Deinen IQOS Store bzw. zu unseren IQOS Sammelstationen. Wir geben ihnen eine neue Verwendung. So geht\u2019s: F\u00fcge Deiner Bestellung bis zu f\u00fcnf kostenlose IQOS Sammelt\u00fcten hinzu. In einer kannst Du bis zu 400 Tabaksticks sammeln.",
    "sub_headline": "Hilf uns dabei, Abfall zu reduzieren und Ressourcen zu schonen. Bringe uns Deine gebrauchten HEETS und TEREA Tabaksticks zur\u00fcck. Wir geben ihnen eine neue Verwendung.",
    "required_points": 0,
    "attributes": [],
    "variants": [
        {
            "variant_id": "eef24982-6c19-11ee-98c4-0242c0a84005",
            "color_name": "",
            "color_code": "",
            "out_of_stock": false,
            "images": [
                "653b877323daf0a27c73d638",
                "653b80cc23daf0a27c73d61e",
                "653b80cc23daf0a27c73d620",
                "653b80cc23daf0a27c73d622",
                "653b80cc23daf0a27c73d624"
            ],
            "ean_code": ""
        }
    ],
    "order_group": "a",
    "limited_edition": false,
    "one_order_limit": 5
}
}
`;
export default JSON.parse(jsonResponse);
