import { authApiGet, authApiPost } from '@/api/api';

export const URLS = {
  NEWS_ARTICLE_RAFFLE_ACTION: 'action/',
};

export const getNewsArticleRaffleRegister = (actionId) =>
  authApiGet(URLS.NEWS_ARTICLE_RAFFLE_ACTION + actionId);

export const newsArticleRaffleRegister = (actionId) =>
  authApiPost(URLS.NEWS_ARTICLE_RAFFLE_ACTION + actionId);
