import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';

import { ImageWithSources, Podcast } from '@/types';

import { postListenToPodcast, fetchAudioFragments, postListenToAudioFragment } from '../api/api';
import { ErrorMessage, ErrorResponse, PodcastState } from '../types';

import rightPodcastImage from '@/assets/podcasts/podcasts.right.showcase.jpg';
import rightPodcastImagex2 from '@/assets/podcasts/podcasts.right.showcase@2x.jpg';
import rightPodcastImagex3 from '@/assets/podcasts/podcasts.right.showcase@3x.jpg';
import rightPodcastImage2 from '@/assets/podcasts/podcasts.right.showcase2.jpg';
import rightPodcastImage2x2 from '@/assets/podcasts/podcasts.right.showcase2@2x.jpg';
import rightPodcastImage2x3 from '@/assets/podcasts/podcasts.right.showcase2@3x.jpg';
import leftPodcastImage from '@/assets/podcasts/podcasts.left.showcase.jpg';
import leftPodcastImagex2 from '@/assets/podcasts/podcasts.left.showcase@2x.jpg';
import leftPodcastImagex3 from '@/assets/podcasts/podcasts.left.showcase@3x.jpg';
import leftPodcastImage3 from '@/assets/podcasts/podcasts.left.showcase3.jpg';
import rightPodcastImage4 from '@/assets/podcasts/podcasts.right.showcase4.jpg';
import rightPodcastImage5 from '@/assets/podcasts/podcasts.left.showcase5.jpg';
import rightPodcastImage6 from '@/assets/podcasts/podcasts.right.showcase6.jpg';

type GenerateImageFromSourcesType = (
  image: string,
  imagex2: string,
  imagex3: string
) => ImageWithSources;
const generateImageFromSources: GenerateImageFromSourcesType = (
  image,
  imagex2,
  imagex3
) => ({
  alt: 'person holding IQOS device',
  mobile: {
    x1: image,
    x2: imagex2,
    x3: imagex3,
  },
  desktop: {
    x1: image,
    x2: imagex2,
    x3: imagex3,
  },
});

export const initialState = (): PodcastState => ({
  podcasts: [
    {
      uid: '1',
      name: 'Nuria',
      audioUrl:
        'https://pmg-dce-shop-cms-stag-assets.s3.amazonaws.com/Podcasts/210709_IQOSClubPodcast_Nuria_Edit_6.mp3',
      leadfamlyUrl:
        'https://philip-morris-germany.leadfamly.com/podcast-1-nuria-feedback-survey',
      trackingAction: 'PODCAST1',
      image: generateImageFromSources(
        rightPodcastImage,
        rightPodcastImagex2,
        rightPodcastImagex3
      ),
      progress: {},
    },
    {
      uid: '2',
      name: 'Sascha',
      audioUrl:
        'https://pmg-dce-shop-cms-stag-assets.s3.amazonaws.com/Podcasts/210709_IQOS_Sascha_Edit_2.mp3',
      leadfamlyUrl:
        'https://philip-morris-germany.leadfamly.com/podcast-2-sascha-feedback-survey',
      trackingAction: 'PODCAST2',
      image: generateImageFromSources(
        leftPodcastImage,
        leftPodcastImagex2,
        leftPodcastImagex3
      ),
      progress: {},
    },
    {
      uid: '3',
      name: 'Originals',
      audioUrl:
        'https://pmg-dce-shop-cms-prod-assets.s3.eu-central-1.amazonaws.com/Podcasts/221101_IQOS_Nuria_edit04_md.mp3',
      leadfamlyUrl:
        'https://philip-morris-germany.leadfamly.com/podcast-3-originals',
      trackingAction: 'PODCAST3',
      image: generateImageFromSources(
        rightPodcastImage2,
        rightPodcastImage2x2,
        rightPodcastImage2x3
      ),
      progress: {},
    },
    {
      uid: '4',
      name: 'Alessandro',
      audioUrl:
        'https://pmg-dce-shop-cms-prod-assets.s3.eu-central-1.amazonaws.com/Podcasts/221118_IQOS_Alessandro_edit04_md__1.mp3',
      leadfamlyUrl:
        '',
      trackingAction: 'PODCAST4',
      image: generateImageFromSources(
        leftPodcastImage3,
        leftPodcastImage3,
        leftPodcastImage3
      ),
      progress: {},
    },
    {
      uid: '5',
      name: 'BeateAndKunta',
      audioUrl:
        'https://pmg-dce-shop-cms-prod-assets.s3.eu-central-1.amazonaws.com/Podcasts/02_-_221117_IQOS_BeateXKunta_Part2_edit03_md.mp3',
      leadfamlyUrl:
        '',
      trackingAction: 'PODCAST5',
      image: generateImageFromSources(
        rightPodcastImage4,
        rightPodcastImage4,
        rightPodcastImage4
      ),
      progress: {},
    },
    {
      uid: '6',
      name: 'MischaXJulia',
      audioUrl:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/Podcasts/02_-_221129_IQOS_MischaXJulia_edit03_V1_md.mp3',
      leadfamlyUrl:
        '',
      trackingAction: 'PODCAST6',
      image: generateImageFromSources(
        rightPodcastImage5,
        rightPodcastImage5,
        rightPodcastImage5
      ),
      progress: {},
    },
    {
      uid: '7',
      name: 'e.V.',
      audioUrl:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/Podcasts/01_-_221129_IQOS_Florian_edit03_md.mp3',
      leadfamlyUrl:
        '',
      trackingAction: 'PODCAST7',
      image: generateImageFromSources(
        rightPodcastImage6,
        rightPodcastImage6,
        rightPodcastImage6
      ),
      progress: {},
    },
  ],
  podcastsError: null,
});

export const mutations = {
  SET_PODCASTS_ERROR: (state: PodcastState, error: ErrorMessage): void => {
    state.podcastsError = error;
    console.debug('SET_PODCASTS_ERROR', state.podcastsError);
  },
  SET_PROGRESS: (state: PodcastState, payload: { trackingAction: string, progress: {[key: string]: boolean }}): void => {
    state.podcasts.find(item => item.trackingAction === payload.trackingAction).progress = payload.progress
    console.debug('SET_PROGRESS', state.podcasts);
  },
  SET_PROGRESS_CHECKPOINT: (state: PodcastState, payload: { trackingAction: string, checkpoint: number }): void => {
    state.podcasts.find(item => item.trackingAction === payload.trackingAction).progress[String(payload.checkpoint)] = true
    console.debug('SET_PROGRESS_CHECKPOINT', state.podcasts);
  },
};

export const actions: ActionTree<PodcastState, RootState> = {
  listenedToPodcast: (
    { commit, dispatch },
    trackingAction: Podcast['trackingAction']
  ): Promise<void> => {
    return dispatch('authModule/refreshHeaders', null, { root: true })
      // @ts-expect-error we are not going to type authApiPost
      .then(postListenToPodcast(trackingAction))
      .catch((error: ErrorResponse) =>
        commit('SET_PODCASTS_ERROR', error.message)
      );
  },
  listenedToAudioFragment:(
    { commit, dispatch },
    { trackAction, progress }: { trackAction: string, progress: number },
  ): Promise<void> => {
    return dispatch('authModule/refreshHeaders', null, { root: true })
      // @ts-expect-error we are not going to type authApiPost
      .then(postListenToAudioFragment(trackAction, progress))
      .then(() => {
        commit('SET_PROGRESS_CHECKPOINT', { trackingAction: trackAction, checkpoint: progress })
      })
      .catch((error: ErrorResponse) =>
        commit('SET_PODCASTS_ERROR', error.message)
      );
  },
  getAudioFragmentsFromPodcast: (
    { commit, dispatch },
    trackingAction: string,
  ): void => {
    dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchAudioFragments(trackingAction))
      .then(podcastStatus => {
        commit('SET_PROGRESS', { trackingAction, progress: podcastStatus.progress })
      })
      .catch((error: ErrorResponse) =>
        commit('SET_PODCASTS_ERROR', error.message)
      );
  },
};

const podcastsModule = {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations,
  actions,
};

export default podcastsModule;
