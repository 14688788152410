import { RootState } from '@/store/types';
import { Router } from 'vue-router';
import { Store } from 'vuex';

import authRouter from './router';
import authStore from './store/store';

function authModule({
    store,
    router,
}: {
    store: Store<RootState>;
    router: Router;
}): void {
    store.registerModule('authModule', authStore);
    authRouter(router);
}

export default authModule;
