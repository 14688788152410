// mutation types
import { POINTS_REDEEMED } from './mutation-types';

import { redeemPoints, getPointsRedeemed } from '../api/api.js';

import normalizeRedeemedPoints from './normalizers/redeemedpoints';

async function checkPointsRedeemed({ commit, dispatch }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });

  const response = await getPointsRedeemed('TEASER')(headers);
  const normalizeResponse = normalizeRedeemedPoints(response);

  commit(POINTS_REDEEMED, normalizeResponse);
}

async function triggerRedeemPoints({ commit, dispatch }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  try {
    const response = await redeemPoints('TEASER')(headers);
    const normalizeResponse = normalizeRedeemedPoints(response);

    commit(POINTS_REDEEMED, normalizeResponse);
  } catch {
    commit(
      'PUSH_ERROR',
      {
        message: 'Die 50 Punkte wurden dir schon gut geschrieben.',
        buttonText: 'Okay',
        buttonLink: 'teaser',
      },
      { root: true }
    );
  }
}

export default {
  checkPointsRedeemed,
  triggerRedeemPoints,
};
