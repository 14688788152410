import { ActionContext } from 'vuex';
import { AdventState, AdventCalendar } from './types';
import text from '../assets/copy.json';
import {
  getCalendar,
  openDoor,
  postTrackingAction,
} from '../api/api';
import { Door } from '../types';

export const initialState = (): AdventState => ({
  t: { ...text },
  calendar: {},
  door: {
    type: '',
    link: '',
    open: false,
    points: '',
    day: 1,
    header: '',
    body: '',
    game_url: '',
    game_id: 1,
    action: false,
    animation: '',
  },
  today: null,
});

// @TODO: move this once the general state is typed
interface RootState {
  errors: Array<string>;
}
type AdventContext = ActionContext<AdventState, RootState>;

const mutations = {
  SAVE_CALENDAR(state: AdventState, content: AdventCalendar): void {
    state.calendar = content;
  },
  OPEN_DOOR(state: AdventState, content: Door): void {
    state.door = { ...state.door, ...content };
  },
  SET_POINTS(state: AdventState, content: string): void {
    state.door.points = content;
  },
};

const actions = {
  getCalendar: ({ dispatch, commit }: AdventContext): Promise<void> => {
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(async (headers) => {
        const calendar = (await getCalendar()(headers)) as AdventCalendar;
        commit('SAVE_CALENDAR', calendar);
      })
      .catch((error) => {
        console.debug(error.response);
      });
  },
  openDoor: (
    { dispatch, commit }: AdventContext,
    day: number
  ): Promise<void> => {
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(async (headers) => {
        const door = (await openDoor(day)(headers)) as Door;
        commit('OPEN_DOOR', door['door_content']);
        if (door['points_given']) commit('SET_POINTS', door['points_given']);
      })
      .catch((error) => {
        console.debug(error.response);
      });
  },
  consumedContent: (
    { dispatch, commit }: AdventContext,
    trackingAction: string
  ): Promise<string | void> => {
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(async (headers) => {
        const points = await postTrackingAction(trackingAction)(headers);
        commit('SET_POINTS', points['given_points']);
      })
      .catch((error) => console.debug(error.response));
  },
  sendCustomAction: ({ dispatch }: AdventContext, trackingAction: string): Promise<string | void> => {
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(async (headers) => postTrackingAction(trackingAction)(headers))
      .catch((error) => console.debug(error.response));
  },
};

const getters = {};

const adventModule = {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions: actions,
};

export default adventModule;
