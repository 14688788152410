
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    hideUnderline: {
      type: Boolean,
      default: false,
    },
    alwaysShowOnActive: {
      type: Boolean,
      default: false,
    },
    // We need to set a default ID in case no props passed
    // to ensure the ID of the element will be unique
    gtmId: {
      type: [String, Number],
      default: Math.floor(Math.random() * 1000)
    }
  },
  setup(props) {
    const isHover = ref<boolean>(false);
    const triggerEnterHover = () => isHover.value = true;
    const triggerLeaveHover = () => isHover.value = false;

    const shouldDisplayLine = computed(() => props.active || isHover.value && !props.hideUnderline);

    return {
      shouldDisplayLine,
      triggerEnterHover,
      triggerLeaveHover,
    };
  }
});
