import {
  fetchCategories,
  fetchConfig,
  fetchProduct,
  fetchProducts,
  orderProduct,
  fetchProductsByCategory,
  fetchNotifications,
  activateNotifications,
  fetchHeroSliders,
} from '../api/api';

import { FAKE_USER_ERROR_MESSAGE, LIMIT_ERROR_MESSAGE } from '@/constants/messages';

import { PRODUCT_TYPES } from '../types';

// serializers
import {
  itgProductSerializer,
  voucherSerializer,
} from './serializers/product-checkout';

// normalizers
import configNormalizer from './normalizers/config';
import productDetailNormalizer from './normalizers/product';
import {
  OrderNormalizerType,
  itgProductNormalizer,
  voucherNormalizer,
} from './normalizers/product-order';
import productsNormalizer from './normalizers/products';

// Vuex types
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';

// mutation types
import {
  SET_CONFIG,
  SET_CONFIG_FETCHING_ERROR,
  SET_CURRENT_PRODUCT,
  SET_CURRENT_VARIANT,
  SET_PRODUCTS,
  SET_PRODUCTS_FETCHING_ERROR,
  SET_PRODUCT_ORDER,
  SET_PRODUCT_ORDERING_ERROR,
  SET_PRODUCT_ORDER_CUSTOMER_INFO,
  SET_USER_INTERACTION,
  SET_CATEGORIES,
  SET_HERO_SLIDERS,
  SET_NOTIFICATIONS,
  SET_ADDITIONAL_PRODUCT,
} from './mutation-types';

// types
import {
  Config,
  ErrorMessage,
  ITGOrder,
  Order,
  Product,
  Category,
  ProductCheckout,
  ShopState,
  VoucherOrder,
  Notification,
  HeroSliderItem,
} from '../types';
import categoryNormalizer from './normalizers/categories';
import heroSliderNormalizer from './normalizers/hero-sliders';
import notificationNormalizer from './normalizers/notifications';
import { ROOT_URL } from '../router';

const actions: ActionTree<ShopState, RootState> = {
  getCategories: ({ commit, dispatch }): Promise<Category[]> => {
    const categoriesCommitter = (categories: Category[]) => {
      commit(SET_CATEGORIES, categories);
      return categories;
    };

    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchCategories)
      .then(categoryNormalizer)
      .then(categoriesCommitter);
  },
  getHeroSliders: ({ commit, dispatch }): Promise<HeroSliderItem[]> => {
    const slidersCommitter = (sliders: HeroSliderItem[]) => {
      commit(SET_HERO_SLIDERS, sliders);
      return sliders;
    };

    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchHeroSliders)
      .then(heroSliderNormalizer)
      .then(slidersCommitter);
  },
  // Fetch all products
  getProducts: ({ commit, dispatch }): Promise<Product[]> => {
    const productsCommitter = (products: Product[]) => {
      commit(SET_PRODUCTS, products); // store new products information
      return products;
    };
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchProducts)
      .then(productsNormalizer) // normalize
      .then(productsCommitter); // commit
  },
  setAdditionalProducts ({ commit }, products): void {
    commit(SET_ADDITIONAL_PRODUCT, products);
  },
  resetAdditionalProducts ({ commit }): void {
    commit(SET_ADDITIONAL_PRODUCT, null);
  },
  getProductsByCategory: ({ commit, dispatch }, { categoryName }): Promise<Product[]> => {
    const productsCommitter = (products: Product[]) => {
      commit(SET_PRODUCTS, products); // store new products information
      return products;
    };
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchProductsByCategory(categoryName))
      .then(productsNormalizer) // normalize
      .then(productsCommitter); // commit
  },
  // Fetch a single product by its id
  getProduct: (
    { commit, dispatch },
    { productId, variantId }: { productId: string; variantId?: string }
  ): Promise<Product | ErrorMessage> => {
    const getVariantById = (_product: Product, _variantId: string) =>
      _product.variants.find(
        (variantItem) => variantItem.variantId === _variantId
      );
    const commitProduct = (product: Product | ErrorMessage) => {
      const mutationHandler =
        'errorMessage' in product
          ? () => commit(SET_PRODUCTS_FETCHING_ERROR, product.errorMessage)
          : () => {
              commit(SET_CURRENT_PRODUCT, product);
              commit(
                SET_CURRENT_VARIANT,
                variantId
                  ? getVariantById(product, variantId)
                  : product.variants[0]
              );
            };
      mutationHandler();
      return product;
    };
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchProduct(productId))
      .then(productDetailNormalizer) // normalize
      .then(commitProduct); // commit
  },
  // Reset and cleanup product order data
  resetProductOrder: ({ commit }): void => {
    commit(SET_PRODUCT_ORDER, null);
  },
  // Checkout a product
  orderProduct: async (
    { commit, dispatch, rootGetters },
    {
      productId,
      productCheckout,
    }: { productId: string; productCheckout: ProductCheckout }
  ): Promise<Order | ErrorMessage> => {
    // Defaults to voucherSerializer
    let serializer = voucherSerializer;
    let normalizer: OrderNormalizerType = voucherNormalizer;

    // Change it to shipabble product if needed
    if ([PRODUCT_TYPES.ITG, PRODUCT_TYPES.ARVATO].includes(rootGetters['shopModule/productType'])) {
      // ITG product
      productCheckout['shippingAddress'].email =
        rootGetters['authModule/userEmail'];
      serializer = itgProductSerializer;
      normalizer = itgProductNormalizer;
    }

    const commitOrder = (order: ITGOrder | VoucherOrder | ErrorMessage) => {
      // Validate if there is an error message and commit
      const mutationHandler =
        'errorMessage' in order
          ? () => commit(SET_PRODUCT_ORDERING_ERROR, order.errorMessage)
          : () => {
              commit(SET_PRODUCT_ORDER, order);
              commit(SET_PRODUCT_ORDER_CUSTOMER_INFO, productCheckout);
            };
      mutationHandler();
      return order;
    };
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(orderProduct(productId, serializer(productCheckout)))
      .then(normalizer)
      .then(commitOrder)
      .catch((error) => {
        commit(
          'PUSH_ERROR',
          {
            ...error,
            message: [402, 406, 423].includes(error?.response?.status)
              ? error?.response?.data?.error_type === 'limit'
                ? LIMIT_ERROR_MESSAGE
                : FAKE_USER_ERROR_MESSAGE
              : error.message,
            ...([402, 406, 423].includes(error?.response?.status) && {
              title: 'Hinweis',
              buttonText: 'Zurück',
            }),
            buttonLink: ROOT_URL,
          },
          { root: true }
        );
        return error
      })
  },
  // Fetch CMS texts
  getConfig: ({ commit, dispatch }): Promise<Config | ErrorMessage> => {
    const commitConfig = (config: Config | ErrorMessage) => {
      // Validate if there is an error message or not
      const mutationHandler: [string, Config | string] =
        'errorMessage' in config
          ? [SET_CONFIG_FETCHING_ERROR, config.errorMessage]
          : [SET_CONFIG, config];
      commit(...mutationHandler);
      return config;
    };
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchConfig)
      .then(configNormalizer)
      .then(commitConfig);
  },
  setUserInteraction: ({ commit }, payload): void => {
    commit(SET_USER_INTERACTION, payload);
  },
  // Fetch all notifications
  getNotifications: ({ commit, dispatch }): Promise<Notification[]> => {
    const notificationCommitter = (notifications: Notification[]) => {
      commit(SET_NOTIFICATIONS, notifications); // store new notifications information
      return notifications;
    };
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(fetchNotifications)
      .then(notificationNormalizer) // normalize
      .then(notificationCommitter); // commit
  },
  // activate Notifications
  activateNotifications: async (
    { dispatch },
    {
      productId,
      variantId,
    }: { productId: string; variantId: string }
  ): Promise< Notification[] | ErrorMessage> => {
    return dispatch('authModule/refreshHeaders', null, { root: true })
      .then(activateNotifications(productId, variantId))
  },
};
export default actions;
