import mutations from './mutations';
import asyncActions from './asyncActions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initialState = () => ({
  redeemedPointsStatus: {
    action_done: false,
    button_text: '',
    success_button_text: '',
  },
});

export const getters = {};

const storiesModule = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: { ...asyncActions },
};

export default storiesModule;
