import { ITGOrderDTO, VoucherOrderDTO } from '../dtos';
import { ITGOrder, VoucherOrder, ErrorMessage } from '../../types';

type ITGNormalizerType = (arg0: ITGOrderDTO) => ITGOrder | ErrorMessage;
type VoucherNormalizerType = (
  arg0: VoucherOrderDTO
) => VoucherOrder | ErrorMessage;

export const itgProductNormalizer: ITGNormalizerType = (response) =>
  'error_message' in response
    ? { errorMessage: response.error_message }
    : {
        orderId: response.order_response.order_id,
        trackingURL: response.order_response.tracking_url,
      };

export const voucherNormalizer: VoucherNormalizerType = (response) =>
  'error_message' in response
    ? { errorMessage: response.error_message }
    : {
        orderId: response.order_response.order_id,
        voucherCode: response.order_response.voucher_code,
      };

export type OrderNormalizerType = ITGNormalizerType | VoucherNormalizerType;
