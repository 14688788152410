import { Store } from 'vuex';

import voucherStore from './store/store';

import { RootState } from '@/store/types';
import { Router } from 'vue-router';
import initialiseVoucherRouter from './router';

function voucherModule({ store, router }: {
  store: Store<RootState>,
  router: Router,
 }): void {
  store.registerModule('voucherModule', voucherStore);
  initialiseVoucherRouter(router);
}

export default voucherModule;
