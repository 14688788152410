import { Router } from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import initialiseNewsArticleRouter from './router';
import newsArticleRaffleStore from './store/store';

function newsArticleRaffleModule({
  store,
  router,
}: {
  store: Store<RootState>;
  router: Router;
}): void {
  store.registerModule('newsArticleRaffleModule', newsArticleRaffleStore);
  initialiseNewsArticleRouter(router);
}

export default newsArticleRaffleModule;
