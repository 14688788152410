import { routeNames } from '@/router/index';
import { mutations } from './store';
import { ROOT_URL } from '../router';
import { FAKE_USER_ERROR_MESSAGE } from '@/constants/messages';

function updateLessonCompleted({ getters, commit }, payload) {
  const { currentClass, currentLesson } = getters;
  commit(mutations.SET_LESSON_COMPLETION_STATUS, {
    value: payload,
    classIndex: currentClass.index,
    lessonIndex: currentLesson.index,
    isArchived: currentClass.isArchived,
  });
}
function handleNonExistingClass({ getters }, routerInstance) {
  const { currentClass } = getters;
  const classExists = currentClass?.lessons;
  if (!classExists) routerInstance.push({ name: routeNames.NOT_FOUND });
  return !!classExists;
}

const arePreviousLessonsCompleted = (currentClass, currentLesson) => {
  if (!currentLesson) return false;
  const previousLessons = currentClass.lessons.slice(0, currentLesson.index);
  return previousLessons.reduce(
    (acc, lesson) => acc && lesson.isCompleted,
    true
  );
};

function handleNonConsecutiveLesson({ getters, state }, routerInstance) {
  const { currentClass, currentLesson } = getters;
  const isValidLesson = arePreviousLessonsCompleted(
    currentClass,
    currentLesson
  );
  if (!state.featureFlags.FLAG_IQA_19 && !isValidLesson) {
    routerInstance.push({ name: 'academy-class' });
  }
  return !!isValidLesson;
}

const setIsChallengePlayWithFakeUser = ({ commit }, payload) => {
  commit(mutations.SET_IS_CHALLENGE_PLAY_WITH_FAKE_USER, payload);
};

const setChallengeDone = ({ state, commit }, payload) => {
  commit(mutations.SET_CHALLENGE_DONE, payload);

  if (state.isChallengePlayWithFakeUser)
    commit(
      'PUSH_ERROR',
      {
        response: {
          data: { custom_error: FAKE_USER_ERROR_MESSAGE },
        },
        title: 'Hinweis',
        buttonText: 'Zurück',
        buttonLink: ROOT_URL,
      },
      { root: true }
    );
};

const resetChallengeDone = ({ commit }) => {
  commit(mutations.RESET_CHALLENGE_DONE);
};

const resetChallengeDoneError = ({ commit }) => {
  commit(mutations.RESET_CHALLENGE_DONE_ERROR);
};

const setHomepageFilter = ({ commit }, payload) => {
  commit(mutations.SET_HOMEPAGE_FILTER, payload);
};

const setHomepagePagination = ({ commit }, payload) => {
  commit(mutations.SET_HOMEPAGE_PAGINATION, payload);
};

export default {
  handleNonConsecutiveLesson,
  handleNonExistingClass,
  resetChallengeDone,
  resetChallengeDoneError,
  setChallengeDone,
  setHomepageFilter,
  setHomepagePagination,
  updateLessonCompleted,
  setIsChallengePlayWithFakeUser,
};
