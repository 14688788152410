import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav-third-level" }
const _hoisted_2 = {
  key: 0,
  class: "nav-third-level__list"
}
const _hoisted_3 = ["onMouseover"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "pl-4 md:pl-0"
}
const _hoisted_6 = ["onMouseover"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TSlideInBottom = _resolveComponent("TSlideInBottom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("div", {
      key: _ctx.menu.id,
      class: "w-full lg:w-1/2 lg:pr-20 mt-20 sm:mt-0"
    }, [
      (_ctx.menu.firstList.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.firstList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id,
                class: "nav-third-level__list-item",
                onMouseover: ($event: any) => (_ctx.toggleMouseOver(item.id)),
                onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMouseOver(null)))
              }, [
                _createVNode(_component_TSlideInBottom, {
                  style: _normalizeStyle(`--enter-delay: ${_ctx.transitionDurationCalc(index)}s;`),
                  class: "nav-third-level__transition-bottom"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                      menu: item,
                      hideUnderline: true,
                      class: _normalizeClass({ 'lg:opacity-60': _ctx.shouldBeGreyHover(item.id) })
                    }, _createSlots({ _: 2 }, [
                      (item.image)
                        ? {
                            name: "linkImage",
                            fn: _withCtx(() => [
                              _createElementVNode("img", {
                                src: _ctx.getImageUrl(item.image),
                                class: "nav-third-level__first-list h-fit md:h-18 w-auto object-contain"
                              }, null, 8, _hoisted_4)
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1032, ["menu", "class"]))
                  ]),
                  _: 2
                }, 1032, ["style"])
              ], 40, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.menu.secondList.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.secondList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id,
                onMouseover: ($event: any) => (_ctx.toggleMouseOver(item.id)),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleMouseOver(null)))
              }, [
                _createVNode(_component_TSlideInBottom, {
                  style: _normalizeStyle(`--enter-delay: ${_ctx.transitionDurationCalc(_ctx.menu.firstList.length + index)}s;`),
                  class: "nav-third-level__transition-bottom"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                      menu: item,
                      hideUnderline: true,
                      class: _normalizeClass(["my-2 text-fs-16-lh-24-ls-0", { 'lg:opacity-60': _ctx.shouldBeGreyHover(item.id) }])
                    }, null, 8, ["menu", "class"]))
                  ]),
                  _: 2
                }, 1032, ["style"])
              ], 40, _hoisted_6))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]))
  ]))
}