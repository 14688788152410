import ConsumerResponse from './consumer';
import LoyaltyResponse from './loyalty';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  mock.onGet('consumer/').reply(makeResponse('consumer', ConsumerResponse));
  mock
    .onGet('consumer/loyalty')
    .reply(makeResponse('loyalty', LoyaltyResponse));
}
