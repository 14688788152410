const jsonResponse = {
  status: '200',
  next_class_availability: '2021-07-21T06:19:42.074000+02:00',
  classes: [
    {
      id: 8,
      meta: {
        type: 'academy.ClassPage',
        detail_url:
          'https://iqos-club-cms-rd-master.pm.avadigi.de/api/v2/drafts/15/',
        html_url: 'http://reward.iqosplus.de/academy/01-challenge-1/',
        slug: '01-challenge-5',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2021-06-07T15:53:11.047797+02:00',
        locale: 'en',
      },
      title: '01 - Challenge 1',
      class_id: 1,
      category: 'Challenge 1',
      lessons_list: [
        {
          type: 'lessons',
          value: {
            name: 'Eine Alternative zur Zigarette.',
            summary: 'Teste dein Wissen rund um Tabak, verbrennen und erhitzen.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/testing-game-copy-from-challenge-3',
            leadfamly_game_id: '60422',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '<p>Test</p>',
            completed_in: '2021-07-07T06:19:42.074000+02:00',
          },
          id: '724e77ec-20be-4d5a-9c83-1e1197e7c81e',
        },
      ],
      main_image: {
        id: 416,
        url:
          'https://pmg-iqos-club-cms-rd-prod-assets.s3.amazonaws.com/original_images/Challenge3.original.png',
        title: 'Challenge3.original.png',
        image_desktop_1x: {
          url:
            'http://placekitten.com/600/400',
          width: 1038,
          height: 558,
        },
        image_desktop_2x: {
          url:
            'http://placekitten.com/1200/800',
          width: 1038,
          height: 558,
        },
        image_desktop_3x: {
          url:
            'http://placekitten.com/1800/1200',
          width: 1038,
          height: 558,
        },
        image_mobile_1x: {
          url:
            'http://placekitten.com/400/300',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url:
            'http://placekitten.com/800/600',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url:
            'http://placekitten.com/1200/900',
          width: 842,
          height: 760,
        },
      },
      description: 'Challenge 5 - Description',
      points_awarded: '10',
      waiting_time: '0',
      go_live_at: null,
      expire_at: null,
      completed_in: '2021-07-07T06:19:42.074000+02:00',
    },
    {
      id: 7,
      meta: {
        type: 'academy.ClassPage',
        detail_url:
          'https://iqos-club-cms-rd-master.pm.avadigi.de/api/v2/drafts/15/',
        html_url: 'http://reward.iqosplus.de/academy/02-challenge-1/',
        slug: '02-challenge-1',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2021-06-07T15:53:11.047797+02:00',
        locale: 'en',
      },
      title: '02 - Challenge 1',
      class_id: 2,
      category: 'Challenge 1',
      lessons_list: [
        {
          type: 'lessons',
          value: {
            name: 'Lesson 2',
            summary: 'Teste dein Wissen rund um Tabak, verbrennen und erhitzen.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/testing-game-copy-from-challenge-3',
            leadfamly_game_id: '60422',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '<p>Test</p>',
            completed_in: '2021-07-07T06:19:42.074000+02:00',
          },
          id: '724e77ec-20be-4d5a-9c83-1e1197e7c81f',
        },
      ],
      main_image: {
        id: 416,
        url:
          'https://pmg-iqos-club-cms-rd-prod-assets.s3.amazonaws.com/original_images/Challenge3.original.png',
        title: 'Challenge3.original.png',
        image_desktop_1x: {
          url:
            'http://placekitten.com/600/400',
          width: 1038,
          height: 558,
        },
        image_desktop_2x: {
          url:
            'http://placekitten.com/1200/800',
          width: 1038,
          height: 558,
        },
        image_desktop_3x: {
          url:
            'http://placekitten.com/1800/1200',
          width: 1038,
          height: 558,
        },
        image_mobile_1x: {
          url:
            'http://placekitten.com/400/300',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url:
            'http://placekitten.com/800/600',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url:
            'http://placekitten.com/1200/900',
          width: 842,
          height: 760,
        },
      },
      description: 'Challenge 5 - Description',
      points_awarded: '10',
      waiting_time: '0',
      go_live_at: null,
      expire_at: null,
      completed_in: '2021-07-07T06:19:42.074000+02:00',
    },
  ],
};
export default jsonResponse;
