export default {
  toggles: [
    {
      name: 'IQOS-1436-neon-teaser',
      enabled: false,
    },
    {
      name: 'IQOS-1231', // TogetherX Application phase toggle
      enabled: true,
    },
    {
      name: 'IQOS-1302-voting-phase',
      enabled: true,
    },
    {
      name: 'IQOS-1467-editorial-tnc-update-ii',
      enabled: true,
    },
  ],
}