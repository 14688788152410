import { ResponsiveImageSet, ResponsiveImageDTO } from '@/types';

export type NormaliseCmsImageParams = {
  alt?: string;
  image: ResponsiveImageDTO;
};
export const normaliseCmsImage = ({
  alt,
  image,
}: NormaliseCmsImageParams): ResponsiveImageSet => {
  const placeholder = 'https://placehold.co/100/999/999';
  return {
    alt: alt || '',
    desktop: {
      x1:
        image.image_desktop_1x?.url ||
        image.image_desktop_2x?.url ||
        image.image_desktop_3x?.url ||
        placeholder,
      x2:
        image.image_desktop_2x?.url ||
        image.image_desktop_3x?.url ||
        image.image_desktop_1x?.url ||
        placeholder,
      x3:
        image.image_desktop_3x?.url ||
        image.image_desktop_2x?.url ||
        image.image_desktop_1x?.url ||
        placeholder,
    },
    mobile: {
      x1:
        image.image_mobile_1x?.url ||
        image.image_mobile_2x?.url ||
        image.image_mobile_3x?.url ||
        placeholder,
      x2:
        image.image_mobile_2x?.url ||
        image.image_mobile_3x?.url ||
        image.image_mobile_1x?.url ||
        placeholder,
      x3:
        image.image_mobile_3x?.url ||
        image.image_mobile_2x?.url ||
        image.image_mobile_1x?.url ||
        placeholder,
    },
  };
};
