const jsonResponse = {
  status: '200',
  items: [
    {
      id: 15,
      meta: {
        type: 'academy.ClassPage',
        detail_url:
          'https://iqos-club-cms-rd-master.pm.avadigi.de/api/v2/drafts/15/',
        html_url: 'http://reward.iqosplus.de/academy/03-draft-99/',
        slug: '03-draft-99',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2021-06-07T15:53:11.047797+02:00',
        locale: 'en',
      },
      title: 'Draft class',
      class_id: 99,
      category: 'Draft',
      lessons_list: [
        {
          type: 'lessons',
          value: {
            name: 'draft 5 Step 1:',
            summary: 'Geruch, der schnell verfliegt.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/draft-5-step-1',
            leadfamly_game_id: '60422',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '<p>Test</p>',
            completed_in: null,
          },
          id: 'ce28407f-ae88-4e61-901a-97abf01ad7cb',
        },
        {
          type: 'lessons',
          value: {
            name: 'draft 5 - Step 2:',
            summary: 'Tabakgeschmack - ungewohnt einzigartig',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/draft-5-step-2-quiz',
            leadfamly_game_id: '60550',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '<p>Test</p>',
            completed_in: null,
          },
          id: 'a9e71c07-2e41-4bf8-86cc-0a77da32f82b',
        },
      ],
      main_image: null,
      description: 'draft 99 - Description',
      points_awarded: '10',
      waiting_time: '0',
      go_live_at: null,
      expire_at: null,
      completed_in: null,
    },
  ],
};
export default jsonResponse;
