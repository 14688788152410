// mutation types
import {
  SET_STORIES,
  SET_READ_STORIES_IDS,
  SET_PROVISIONAL_READ_STORIES_IDS,
} from './mutation-types';

import {
  fetchStories,
  fetchDoneStoriesIds,
  postStoryRead,
  postTrackStory,
} from '../api/api.js';

import { FAKE_USER_ERROR_MESSAGE } from '@/constants/messages';
import { ROOT_URL } from '../router';

async function getStories({ commit, dispatch }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });

  const [storiesResponse, doneStoriesIds] = await Promise.all([
    fetchStories(headers),
    fetchDoneStoriesIds(headers),
  ]);

  commit(SET_STORIES, storiesResponse);
  commit(
    SET_READ_STORIES_IDS,
    doneStoriesIds.map((id) => parseInt(id))
  );

  return storiesResponse;
}

async function markStoryRead({ commit, dispatch, state }, { pageId, payload }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });

  try {
    await postStoryRead({ pageId: String(pageId), payload })(headers);
    commit(SET_PROVISIONAL_READ_STORIES_IDS, [
      ...state.provisionalReadStoriesIds,
      pageId,
    ]);
  } catch (error) {
    commit( 'PUSH_ERROR', {
      ...error,
      ...(error?.response?.status === 402 && {
        title: 'Hinweis',
        buttonText: 'Zurück',
      }),
    }, { root: true });
  }
}

async function trackStory({ dispatch }, pageId) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  try {
    postTrackStory(String(pageId))(headers);
  } catch (error) {
    if (error?.response?.status !== 402) throw error;
  }
}

export default {
  getStories,
  markStoryRead,
  trackStory,
};
