// mutation types
import { POINTS_REDEEMED } from './mutation-types';

const mutations = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  [POINTS_REDEEMED]: (state, payload): void => {
    state.redeemedPointsStatus = payload;
  },
};

export default mutations;
