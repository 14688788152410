import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    id: "DCE_nav_open_menu",
    class: "burger-menu-icon",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(`burger-menu-icon__element ${_ctx.themeClasses}`)
    }, null, 2),
    _createElementVNode("span", {
      class: _normalizeClass(`burger-menu-icon__element ${_ctx.themeClasses}`)
    }, null, 2)
  ]))
}