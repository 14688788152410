import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs.types';

const NotFound = () => import('../views/NotFound.vue');
const OneTrustCookiePage = () => import('../views/OneTrustCookiePage.vue');
const LeadfamilyGame = () => import('../views/LeadfamilyGame.vue');
const LeadfamilyGameTesting = () =>
  import('../views/LeadfamilyGameTesting.vue');
const TermsConditionsGame = () => import('../views/TermsConditionsGame.vue');
const CustomizationSurvey = () => import('../views/CustomizationSurvey.vue');
const CustomizationSurveyTC = () =>
  import('../views/CustomizationSurveyTC.vue');
const Survey = () => import('../views/Survey.vue');
const CommunityWithFeatureFlag = () => import('../views/CommunityWithFeatureFlag.vue');
const TermsConditionsCMS = () => import('../views/TermsConditionsCMS.vue');
const DocumentCMS = () => import('../views/DocumentCMS.vue');
const TogetherDay = () => import('../views/TogetherDay.vue');
const TogetherDayTC = () => import('../views/TogetherDayTC.vue');
const TogetherDo = () => import('../views/TogetherDo.vue');
const TogetherDoTC = () => import('../views/TogetherDoTC.vue');
const WarmFuseTeaser = () => import('../views/WarmFuseTeaser.vue');
const TenYears = () => import('../views/TenYears.vue');
const Wave2B = () => import('../views/Wave2B.vue');
const Levia = () => import('../views/Levia.vue');
const HistoryOfIQOS = () => import('../views/HistoryOfIQOS.vue');
const NeonLETeaser = () => import('../views/NeonLETeaser.vue');
const NeonLETeaserTC = () => import('../views/NeonLETeaserTC.vue');

export const routeNames = {
  HOME: 'home',
  NOT_FOUND: 'not-found',
  ONETRUST: 'onetrust',
  LEADFAMILY_PAGE: 'leadfamily-game',
  LEADFAMILY_PAGE_TESTING: 'leadfamily-game-testing',
  LEADFAMILY_PAGE_TC: 'leadfamily-game-tc',
  SURVEY: 'survey',
  TOGETHERDAY: 'togetherday',
  TOGETHERDAY_TC: 'togetherday-tc',
  COMMUNITY: 'community',
  CUSTOMIZATION_SURVEY: 'customization-survey',
  CUSTOMIZATION_SURVEY_TC: 'customization-survey-tc',
  TOGETHERDO: 'togetherdo2022',
  TOGETHERDO_TC: 'togetherdo2022-tc',
  WARM_FUSE_TEASER: 'warm-fuse-teaser',
  TEN_YEARS: 'ten-years',
  HISTORY_OF_IQOS: 'history-of-iqos',
  WAVE_2B: 'wave-2b',
  LEVIA: 'levia',
  NEON_LE_TEASER: 'neon-purple-teaser',
  NEON_LE_TEASER_TC: 'neon-purple-teaser/teilnahmebedingungen',
  TNC_CMS: 'terms-and-conditions-cms',
  DOCUMENT_CMS: 'documents-cms',
};

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
    routeBreadcrumbName?: string;
    breadcrumbs?: Breadcrumb[];
    /** gtagDomain is used by analytics to group data by this gtagDomain name */
    gtagDomain: string;
  }
}

const baseRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: routeNames.HOME,
    redirect: '/rewardshop',
  },
  {
    path: '/404',
    name: routeNames.NOT_FOUND,
    component: NotFound,
  },
  {
    path: '/onetrust',
    name: routeNames.ONETRUST,
    component: OneTrustCookiePage,
  },
  {
    path: '/scanandwin',
    name: routeNames.LEADFAMILY_PAGE,
    component: LeadfamilyGame,
  },
  {
    path: '/adventskalendertesting',
    name: routeNames.LEADFAMILY_PAGE_TESTING,
    component: LeadfamilyGameTesting,
  },
  {
    path: '/survey',
    name: routeNames.SURVEY,
    component: Survey,
  },
  {
    path: '/survey',
    name: routeNames.SURVEY,
    component: Survey,
  },
  {
    path: '/togetherday',
    name: routeNames.TOGETHERDAY,
    component: TogetherDay,
  },
  {
    path: '/togetherday/teilnahmebedingungen',
    name: routeNames.TOGETHERDAY_TC,
    component: TogetherDayTC,
  },
  {
    path: '/customization-survey',
    name: routeNames.CUSTOMIZATION_SURVEY,
    component: CustomizationSurvey,
  },
  {
    path: '/customization-survey/teilnahmebedingungen',
    name: routeNames.CUSTOMIZATION_SURVEY_TC,
    component: CustomizationSurveyTC,
  },
  {
    path: '/teilnahmebedingungen/:cmsFlexPageId',
    name: routeNames.TNC_CMS,
    props: (route) => ({ pageId: route.params.cmsFlexPageId }),
    component: TermsConditionsCMS,
  },
  {
    path: '/document/:cmsFlexPageId',
    name: routeNames.DOCUMENT_CMS,
    props: (route) => ({ pageId: route.params.cmsFlexPageId }),
    component: DocumentCMS,
  },
  {
    path: '/scanandwin/teilnahmebedingung',
    name: routeNames.LEADFAMILY_PAGE_TC,
    component: TermsConditionsGame,
  },
  {
    path: '/iqos-community',
    name: routeNames.COMMUNITY,
    component: CommunityWithFeatureFlag,
  },
  {
    path: '/togetherdoedition2022',
    name: routeNames.TOGETHERDO,
    component: TogetherDo,
  },
  {
    path: '/togetherdoedition2022/teilnahmebedingungen',
    name: routeNames.TOGETHERDO_TC,
    component: TogetherDoTC,
  },
  {
    path: '/warm-fuse-teaser',
    name: routeNames.WARM_FUSE_TEASER,
    component: WarmFuseTeaser,
  },
  {
    path: '/minimission',
    name: routeNames.TEN_YEARS,
    component: TenYears,
  },
  {
    path: '/levia-teaser',
    name: routeNames.LEVIA,
    component: Levia,
  },
  {
    path: '/history-of-iqos',
    name: routeNames.HISTORY_OF_IQOS,
    component: HistoryOfIQOS,
  },
  {
    path: '/10-years-limited-edition',
    name: routeNames.WAVE_2B,
    component: Wave2B,
  },
  {
    path: '/neon-purple-teaser',
    name: routeNames.NEON_LE_TEASER,
    component: NeonLETeaser,
  },
  {
    path: '/neon-purple-teaser/teilnahmebedingungen',
    name: routeNames.NEON_LE_TEASER_TC,
    component: NeonLETeaserTC,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: routeNames.NOT_FOUND },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...baseRoutes],
  scrollBehavior(to) {
    return new Promise((resolve) => {
      /*
      When there's a hash in the URL adds a longer timeout to try to "wait" for the element to appear in the screen.
      Although there's no guarantee that the element will be loaded by the end of the timeout in cases with a slower internet connection.
      */

      const defaulTimeout = 100;
      // when there's a hash and element is present in the document use a shorter timeout
      const whenTheElementIsOnTheScreenTimeout = 300;
      const longerTimeout = 4500; // estimated longer guess
      const navigationHeight = 70;

      const scrollTimeout = to.hash
        ? document.getElementById(to.hash.replace('#', ''))
          ? whenTheElementIsOnTheScreenTimeout
          : longerTimeout
        : defaulTimeout;

      setTimeout(
        () =>
          resolve(
            to.hash
              ? { behavior: 'smooth', el: to.hash, top: navigationHeight } // hash config
              : { behavior: 'smooth', left: 0, top: 0 }
          ), // regular config
        scrollTimeout
      );
    });
  },
});

export default router;
