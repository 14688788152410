export const GetDocumentationBluemchen = {
  status: 200,
  items: [
    {
      id: 160,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/160/',
        html_url: 'https://localhost/togx-documentation-bluemchen/',
        slug: 'togx-documentation-bluemchen',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-05-12T15:56:37.643555+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'https://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-documentation-bluemchen',
      page_content: [
        {
          type: 'image',
          value: {
            obj_id: 'hero-header',
            image: {
              id: 407,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-with-bg2x.png',
              title: 'picture-with-bg@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-50.png',
                width: 1438,
                height: 828,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-100.png',
                width: 2876,
                height: 1656,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-15.png',
                width: 431,
                height: 248,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-25.png',
                width: 719,
                height: 414,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-35.png',
                width: 1006,
                height: 579,
              },
            },
            header: '',
            content:
              '<p data-block-key="r2n4n">Willkommen auf meiner Seite.</p><hr/><p data-block-key="16he4">Hier kannst Du mehr \u00fcber meinen Chor und mich erfahren.<br/>Sei dabei, gestalte mit und freu Dich auf exklusive Einblicke hinter die Kulissen, Interviews und vieles mehr. Schau regelm\u00e4\u00dfig rein, es gibt immer wieder etwas Neues zu entdecken!</p>',
          },
          id: 'fe8e7c44-e9d9-4986-81ca-11c2a10c10d2',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-choir-carousel-bluemchen-hero-header-title',
            content: '<p data-block-key="phy9x">Bl\u00fcmchens Chor</p>',
          },
          id: 'c4913a7a-7160-4231-951d-dfa96f0e01f1',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-choir-carousel-bluemchen-hero-image',
            image: {
              id: 408,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-bl-mchen2x.png',
              title: 'picture-bl-mchen@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-50.png',
                width: 1161,
                height: 629,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-100.png',
                width: 2322,
                height: 1258,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-15.png',
                width: 348,
                height: 188,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-25.png',
                width: 580,
                height: 314,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x.scale-35.png',
                width: 812,
                height: 440,
              },
            },
            header: '',
            content: '',
          },
          id: '62a2ef03-fee7-456a-9904-b9ed1dc8e5fd',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-cta',
            content:
              '<p data-block-key="rdami">Dran bleiben lohnt sich.</p><hr/><p data-block-key="cb5dl">Ab sofort erwarten Dich regelm\u00e4\u00dfig Updates von den Artists, Ch\u00f6ren und Festivals.<br/>Dabei kannst Du nicht nur aktiv mitgestalten, sondern auch Tickets gewinnen und mit etwas Gl\u00fcck Deinen Artist pers\u00f6nlich treffen.</p>',
          },
          id: '24f91391-c668-43da-a827-a7b010827454',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-discovery-carousel-hero-image',
            image: {
              id: 407,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-with-bg2x.png',
              title: 'picture-with-bg@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-50.png',
                width: 1438,
                height: 828,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-100.png',
                width: 2876,
                height: 1656,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-15.png',
                width: 431,
                height: 248,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-25.png',
                width: 719,
                height: 414,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x.scale-35.png',
                width: 1006,
                height: 579,
              },
            },
            header: '',
            content: '',
          },
          id: 'd12d7180-03db-4241-8bc8-6fc6ca99a2d7',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-discovery-carousel-hero-video-desktop',
            content:
              '<p data-block-key="nqa2p"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/TogetherXMusic_UnexpectedVoices_CampaignVideo_MASTER-comp_new.mp4">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/TogetherXMusic_UnexpectedVoices_CampaignVideo_MASTER-comp_new.mp4</a></p>',
          },
          id: 'afeb53f8-9a63-4ed2-81e7-8cd9eb1856b1',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-discovery-carousel-hero-video-mobile',
            content:
              '<p data-block-key="nqa2p"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/TogetherXMusic_UnexpectedVoices_CampaignVideo_9x16_MASTER-comp_new.mp4">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/TogetherXMusic_UnexpectedVoices_CampaignVideo_9x16_MASTER-comp_new.mp4</a></p>',
          },
          id: 'e6e3c58f-c36c-4271-8b81-6c8059baa16f',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-discovery-carousel-hero-header',
            content: '<p data-block-key="p3j9n">Bl\u00fcmchens Essentials</p>',
          },
          id: 'd17b5b2d-5ac5-464f-b806-e70e938e5590',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-discovery-carousel-item-1',
            image: {
              id: 389,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture3x_4DUxPpO.png',
              title: 'picture@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-50.png',
                width: 595,
                height: 744,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-100.png',
                width: 1191,
                height: 1488,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-15.png',
                width: 178,
                height: 223,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-25.png',
                width: 297,
                height: 372,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-35.png',
                width: 416,
                height: 520,
              },
            },
            header: '',
            content:
              '<p data-block-key="7atlt">this is header</p><hr/><p data-block-key="etvj7">category text</p><hr/><p data-block-key="28acl">/rewardshop/product/662a2b0f81335a7c333e0ea4/6f3020f2-02eb-11ef-bece-0242ac1c0005</p>',
          },
          id: '96a4a8e1-d8d7-4d0f-a296-26c5da37fa4c',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-discovery-carousel-item-2',
            image: {
              id: 334,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/464x590-quote-chris.png',
              title: '464x590-quote-chris',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris.scale-50.png',
                width: 464,
                height: 590,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris.scale-100.png',
                width: 928,
                height: 1180,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris.scale-15.png',
                width: 139,
                height: 177,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris.scale-25.png',
                width: 232,
                height: 295,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris.scale-35.png',
                width: 324,
                height: 413,
              },
            },
            header: '',
            content:
              '<p data-block-key="0ehxl">this is header</p><hr/><p data-block-key="cke2t">category text</p><hr/><p data-block-key="2ke33">/rewardshop/product/662a2b0f81335a7c333e0ea4/6f3020f2-02eb-11ef-bece-0242ac1c0005</p>',
          },
          id: '7fc62002-f25d-4616-ac30-091c59e9b3bb',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-discovery-carousel-item-3',
            image: {
              id: 388,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture3x_CtrG3eX.png',
              title: 'picture@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-50.png',
                width: 595,
                height: 744,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-100.png',
                width: 1191,
                height: 1488,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-15.png',
                width: 178,
                height: 223,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-25.png',
                width: 297,
                height: 372,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-35.png',
                width: 416,
                height: 520,
              },
            },
            header: '',
            content:
              '<p data-block-key="iixp4">this is header</p><hr/><p data-block-key="6lv1g">category text</p><hr/><p data-block-key="2pk0s">/rewardshop/product/662a2b0f81335a7c333e0ea4/6f3020f2-02eb-11ef-bece-0242ac1c0005</p>',
          },
          id: 'edba7a00-81b2-4080-ab0a-40af3948e8d4',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-discovery-carousel-item-4',
            image: {
              id: 358,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/464x590-quote-chris2x.png',
              title: '464x590-quote-chris@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris2x.scale-50.png',
                width: 464,
                height: 590,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris2x.scale-100.png',
                width: 928,
                height: 1180,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris2x.scale-15.png',
                width: 139,
                height: 177,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris2x.scale-25.png',
                width: 232,
                height: 295,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x590-quote-chris2x.scale-35.png',
                width: 324,
                height: 413,
              },
            },
            header: '',
            content:
              '<p data-block-key="9g6ir">this is header</p><hr/><p data-block-key="11gc4">category text</p><hr/><p data-block-key="9gcrr">/rewardshop/product/662a2b0f81335a7c333e0ea4/6f3020f2-02eb-11ef-bece-0242ac1c0005</p>',
          },
          id: '90388e16-9eac-4b8b-ad29-c9ad3cfd3aa8',
        },
        {
          type: 'media',
          value: {
            obj_id: 'artist-media-1',
            image: {
              id: 426,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-artist22x.png',
              title: 'picture-artist2@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist22x.scale-50.png',
                width: 654,
                height: 522,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist22x.scale-100.png',
                width: 1308,
                height: 1044,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist22x.scale-15.png',
                width: 196,
                height: 156,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist22x.scale-25.png',
                width: 327,
                height: 261,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist22x.scale-35.png',
                width: 457,
                height: 365,
              },
            },
            video: {
              id: 38,
              type: 'media',
              download_url:
                'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/141123_IQOS_TOGEHTER_X_ATHEN_OUTRO_ANPASSUNG.mp4',
            },
            header: '',
            content:
              '<p data-block-key="tjhc3">My story</p><p data-block-key="3qrun">to the stage</p><hr/><p data-block-key="a8221">Mit IQOS habe ich einen kleinen Blick zur\u00fcck auf meine Karriere gemacht. Meine Highlights, ein paar Tiefpunkte und die wichtigen Entscheidungen, die meinen Weg positiv gepr\u00e4gt hat. Viel Spa\u00df beim Anschauen!</p><hr/><p data-block-key="5dn2t">+25 Statuspunkte</p><hr/><p data-block-key="c8liv">Bl\u00fcmchen</p><p data-block-key="bea1e">reagiert</p>',
          },
          id: '7353e03d-952c-43da-a394-6e351a245b90',
        },
        {
          type: 'media',
          value: {
            obj_id: 'artist-media-2',
            image: {
              id: 425,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-artist2x.png',
              title: 'picture-artist@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist2x.scale-50.png',
                width: 655,
                height: 523,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist2x.scale-100.png',
                width: 1310,
                height: 1046,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist2x.scale-15.png',
                width: 196,
                height: 156,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist2x.scale-25.png',
                width: 327,
                height: 261,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-artist2x.scale-35.png',
                width: 458,
                height: 366,
              },
            },
            video: {
              id: 41,
              type: 'media',
              download_url:
                'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/240426_IQOS_TogetherXMusic_Kickoff_v2_BhWQkGO.mp4',
            },
            header: '',
            content:
              '<p data-block-key="tjhc3">Mein kleines</p><p data-block-key="a4fqv">Artist Portr\u00e4t.</p><hr/><p data-block-key="fo2ur">Warum ich bei den IQOS Unexpected Voices mitmache? Und was mich sonst so bewegt? Das und noch viel mehr, erf\u00e4hrst Du hier in dieser kurzen Doku \u00fcber mich.</p><hr/><p data-block-key="3942j">+25 Statuspunkte</p><hr/><p data-block-key="21nqs">Behind the scenes</p>',
          },
          id: '2607c1b0-d90c-40e7-b99c-ce367dd77e04',
        },
        {
          type: 'image',
          value: {
            obj_id: 'co-creation-results',
            image: {
              id: 398,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-with-bg3x_JQ2MH09.png',
              title: 'picture-with-bg@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-50.png',
                width: 2160,
                height: 1242,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-100.png',
                width: 4320,
                height: 2484,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-15.png',
                width: 648,
                height: 372,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-25.png',
                width: 1080,
                height: 621,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-35.png',
                width: 1512,
                height: 869,
              },
            },
            header: '',
            content:
              '<p data-block-key="24pql">Main title</p><hr/><p data-block-key="84ktg">Main text</p><hr/><p data-block-key="4rr63">Over options title</p>',
          },
          id: '2d0a35c4-9c62-456f-b548-5846b1220194',
        },
        {
          type: 'image',
          value: {
            obj_id: 'co-creation-results-option-1',
            image: {
              id: 424,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/image3x_TddQrLd.png',
              title: 'image@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_TddQrLd.scale-50.png',
                width: 459,
                height: 687,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_TddQrLd.scale-100.png',
                width: 918,
                height: 1375,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_TddQrLd.scale-15.png',
                width: 137,
                height: 206,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_TddQrLd.scale-25.png',
                width: 229,
                height: 343,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_TddQrLd.scale-35.png',
                width: 321,
                height: 481,
              },
            },
            header: '',
            content:
              '<p data-block-key="24pql">Option 1 name</p><hr/><p data-block-key="3c7gn">is_winner</p>',
          },
          id: '6bc29cf9-83e7-480d-a880-2543014324d3',
        },
        {
          type: 'image',
          value: {
            obj_id: 'co-creation-results-option-2',
            image: {
              id: 365,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Julian_Mathieu_Cover-01669.JPG',
              title: 'Julian_Mathieu_Cover-01669',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Julian_Mathieu_Cover-01669.scale-50.jpg',
                width: 1000,
                height: 1500,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Julian_Mathieu_Cover-01669.scale-100.jpg',
                width: 2000,
                height: 3000,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Julian_Mathieu_Cover-01669.scale-15.jpg',
                width: 300,
                height: 450,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Julian_Mathieu_Cover-01669.scale-25.jpg',
                width: 500,
                height: 750,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Julian_Mathieu_Cover-01669.scale-35.jpg',
                width: 700,
                height: 1050,
              },
            },
            header: '',
            content: '<p data-block-key="24pql">Option 2 name</p>',
          },
          id: '179f31e2-498b-44a5-a3e4-7c01e06dc9b1',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-documentation-archive-grid-title',
            content: '<p data-block-key="7nmjp">Noch mehr Content von mir.</p>',
          },
          id: '4b12d5ce-8bac-4ff1-a964-8df151d16115',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-documentation-archive-grid-text',
            content:
              '<p data-block-key="7nmjp">Hier findest Du alle Fotos und Videos von meiner Reise mit den IQOS Unexpected Voices. So kannst Du auch im Nachhinein sehen, was wir erlebt haben, selbst wenn Du etwas verpasst hattest.</p>',
          },
          id: 'ba42f7f0-aa3e-48ef-a2fe-51aa07c78ba7',
        },
      ],
    },
  ],
};

export const GetDocumentationMichaelSchulte = {
  status: 200,
  items: [
    {
      id: 161,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/161/',
        html_url: 'https://localhost/togx-documentation-michael-schulte/',
        slug: 'togx-documentation-michael-schulte',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-05-13T17:53:57.623103+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'https://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-documentation-michael-schulte',
      page_content: [
        {
          type: 'image',
          value: {
            obj_id: 'hero-header',
            image: {
              id: 411,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-with-bg2x_BQyzMiW.png',
              title: 'picture-with-bg@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_BQyzMiW.scale-50.png',
                width: 1438,
                height: 828,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_BQyzMiW.scale-100.png',
                width: 2876,
                height: 1656,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_BQyzMiW.scale-15.png',
                width: 431,
                height: 248,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_BQyzMiW.scale-25.png',
                width: 719,
                height: 414,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_BQyzMiW.scale-35.png',
                width: 1006,
                height: 579,
              },
            },
            header: '',
            content:
              '<p data-block-key="zg876">Willkommen!</p><hr/><p data-block-key="8oet2">Sch\u00f6n, dass Du da bist! Hier bekommst Du exklusive Einblicke in die spannende Reise mit meinem Chor und erf\u00e4hrst mehr \u00fcber mich. Begleite uns, gestalte mit und freu Dich auf Insider-Einblicke, Interviews und mehr. Bleib dran und schau regelm\u00e4\u00dfig vorbei!</p>',
          },
          id: 'a080c8b3-d499-4abb-94b2-d3565f9b3f63',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-choir-carousel-michael-schulte-hero-header-title',
            content: '<p data-block-key="nr5gj">Michaels Chor</p>',
          },
          id: '107197fb-4893-47dd-a0e2-0be93d0464c5',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-choir-carousel-michael-schulte-hero-image',
            image: {
              id: 410,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-bl-mchen2x_h5jrAwI.png',
              title: 'picture-bl-mchen@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_h5jrAwI.scale-50.png',
                width: 1161,
                height: 629,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_h5jrAwI.scale-100.png',
                width: 2322,
                height: 1258,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_h5jrAwI.scale-15.png',
                width: 348,
                height: 188,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_h5jrAwI.scale-25.png',
                width: 580,
                height: 314,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen2x_h5jrAwI.scale-35.png',
                width: 812,
                height: 440,
              },
            },
            header: '',
            content: '',
          },
          id: '690b2104-9124-4a2b-9713-d3ba00eb3c4e',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-cta',
            content:
              '<p data-block-key="s1r73">Dran bleiben lohnt sich.</p><hr/><p data-block-key="9222e">Ab sofort erwarten Dich regelm\u00e4\u00dfig Updates von den Artists, Ch\u00f6ren und Festivals.<br/>Dabei kannst Du nicht nur aktiv mitgestalten, sondern auch Tickets gewinnen und mit etwas Gl\u00fcck Deinen Artist pers\u00f6nlich treffen.</p>',
          },
          id: '90f8b54d-0312-43b2-8ae0-13f4a4f680ff',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-documentation-archive-grid-title',
            content: '<p data-block-key="4cqso">Noch mehr Content von mir.</p>',
          },
          id: '89306d96-9624-428e-ab2d-05275ce1944e',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-documentation-archive-grid-text',
            content:
              '<p data-block-key="4cqso">Hier findest Du alle Fotos und Videos von meiner Reise mit den IQOS Unexpected Voices. So kannst Du auch im Nachhinein sehen, was wir erlebt haben, selbst wenn Du etwas verpasst hattest.</p>',
          },
          id: '82c34c83-9ced-47a5-b352-8e6744197f84',
        },
      ],
    },
  ],
};

export const GetDocumentationKoolSavas = {
  status: 200,
  items: [
    {
      id: 162,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/162/',
        html_url: 'https://localhost/togx-documentation-kool-savas/',
        slug: 'togx-documentation-kool-savas',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-05-13T17:55:02.721476+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'https://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-documentation-kool-savas',
      page_content: [
        {
          type: 'image',
          value: {
            obj_id: 'hero-header',
            image: {
              id: 409,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-with-bg2x_e6x6lvP.png',
              title: 'picture-with-bg@2x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_e6x6lvP.scale-50.png',
                width: 1438,
                height: 828,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_e6x6lvP.scale-100.png',
                width: 2876,
                height: 1656,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_e6x6lvP.scale-15.png',
                width: 431,
                height: 248,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_e6x6lvP.scale-25.png',
                width: 719,
                height: 414,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg2x_e6x6lvP.scale-35.png',
                width: 1006,
                height: 579,
              },
            },
            header: '',
            content:
              '<p data-block-key="wl3d0">Willkommen auf meiner Page!</p><hr/><p data-block-key="7m8mk">Hier bekommst Du exklusive Einblicke von mir und meinem Chor. Verfolge unsere Reise auf die B\u00fchne, mach mit und freu Dich auf Behind-the-Scenes Videos, Interviews und mehr. Es lohnt sich, immer wieder reinzuschauen.</p>',
          },
          id: '14d85035-4f26-455d-b461-eb05cea09f21',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-choir-carousel-kool-savas-hero-header-title',
            content: '<p data-block-key="4aiq0">Kool Savas Chor</p>',
          },
          id: '1b2b4eba-e038-47e8-b10c-8db6ceb138bf',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-choir-carousel-kool-savas-hero-image',
            image: {
              id: 413,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-bl-mchen3x_9Gjc8kv.png',
              title: 'picture-bl-mchen@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x_9Gjc8kv.scale-50.png',
                width: 1741,
                height: 943,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x_9Gjc8kv.scale-100.png',
                width: 3483,
                height: 1887,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x_9Gjc8kv.scale-15.png',
                width: 522,
                height: 283,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x_9Gjc8kv.scale-25.png',
                width: 870,
                height: 471,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x_9Gjc8kv.scale-35.png',
                width: 1219,
                height: 660,
              },
            },
            header: '',
            content: '',
          },
          id: '8ea39c2c-2718-40bb-a65e-760260aec133',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-cta',
            content:
              '<p data-block-key="4aiq0">Dran bleiben lohnt sich.</p><hr/><p data-block-key="dikqk">Ab sofort erwarten Dich regelm\u00e4\u00dfig Updates von den Artists, Ch\u00f6ren und Festivals.<br/>Dabei kannst Du nicht nur aktiv mitgestalten, sondern auch Tickets gewinnen und mit etwas Gl\u00fcck Deinen Artist pers\u00f6nlich treffen.</p>',
          },
          id: 'a2ec55ad-838c-4eab-a9e5-38a8e37390d0',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-documentation-archive-grid-title',
            content: '<p data-block-key="ob643">Noch mehr Content von mir.</p>',
          },
          id: '774411aa-b120-4b99-8675-89d2089fa828',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-documentation-archive-grid-text',
            content:
              '<p data-block-key="ob643">Hier findest Du alle Fotos und Videos von meiner Reise mit den IQOS Unexpected Voices. So kannst Du auch im Nachhinein sehen, was wir erlebt haben, selbst wenn Du etwas verpasst hattest.</p>',
          },
          id: 'cedac181-d8ea-45c2-908a-4d384e797513',
        },
      ],
    },
  ],
};
