// Vuex types
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  READ_STORY,
  REMOVE_READ_STORY,
  SELECT_ARCHIVED_STORY,
  SELECT_STORY_DROPDOWN_OPTION,
  SET_PROVISIONAL_READ_STORIES_IDS,
  SET_STORY_DROPDOWN_ERROR,
  UNSELECT_ARCHIVED_STORY,
  UNSELECT_STORY_DROPDOWN_OPTION,
  UNSET_STORY_DROPDOWN_ERROR,
} from './mutation-types';

// types
import { StoriesState } from '../types';

const actions: ActionTree<StoriesState, RootState> = {
  selectArchivedStory({ commit }, payload) {
    commit(SELECT_ARCHIVED_STORY, payload);
  },
  unselectArchivedStory({ commit }) {
    commit(UNSELECT_ARCHIVED_STORY);
  },
  selectStoryDropdownOption({ commit }, payload) {
    commit(SELECT_STORY_DROPDOWN_OPTION, payload);
  },
  unselectStoryDropdownOption({ commit }, payload) {
    commit(UNSELECT_STORY_DROPDOWN_OPTION, payload);
  },
  unsetStoryDropdownError({ commit }, payload) {
    commit(UNSET_STORY_DROPDOWN_ERROR, payload);
  },
  setStoryDropdownError({ commit }, payload) {
    commit(SET_STORY_DROPDOWN_ERROR, payload);
  },
  readStory({ commit }, payload) {
    commit(READ_STORY, payload.id);
  },
  removeReadStory({ commit }) {
    commit(REMOVE_READ_STORY);
  },
  unsetProvisionalReadStoriesIds({ commit }) {
    commit(SET_PROVISIONAL_READ_STORIES_IDS, []);
  }
};

export default actions;
