export const GetVotingHomepageContent = {
  status: 200,
  items: [
    {
      page_content: [
        {
          type: 'strings',
          value: {
            obj_id: 'hero-header-title',
            content: '<p data-block-key="7cgte">CMS mocked text 1</p>',
          },
          id: 'f7d59806-4386-4311-9557-335b68ad0afd',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'hero-header-paragraph',
            content: '<p data-block-key="7wcgte">CMS mocked text 1</p>',
          },
          id: '8cb71b7d-8ad0-497f-9001-075c7816ed2a',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'event-date-1',
            content: '07.09 - 08.09.9999',
          },
          id: '8cb71b7d-8ad0-497f-9001-075c7816ef4a',
        },
        {
          type: 'image',
          value: {
            obj_id: 'test',
            image: {
              id: 362,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/800x800-img-care-challenge-12-1318.png',
              title: '800x800-img-care-challenge-12-13[18]',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-50.png',
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-100.png',
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-15.png',
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-25.png',
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-35.png',
              },
            },
            header: '',
            content: '',
          },
          id: '4649ae4e-5cc1-46e9-b73d-758340a15a99',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-events-title',
            content: '<p>Die nächsten IQOS TogetherXMusic events.</p>',
          },
          id: '8cb71b7d-8ad0-4s7f-9001-075c7816ed2x',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-events-sub-title',
            content:
              '<p>Auf der IQOS TogetherXMusic Tour wird es viele musikalische Highlightsgeben. Die nächsten Gelegenheiten, IQOS oder unsere Artists zuerleben, findest Du hier. Übrigens: Tickets, um live dabei zu sein,kannst Du immer wieder hier im Club gewinnen!</p>',
          },
          id: '8cbd71b7d-8ad0-4k7f-9001-075c7816ed2x',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-1',
            content:
              '<p data-block-key="date">11.07 - 13.07.2024</p><hr/><p data-block-key="name">EDM Masterclass <br/> </p><hr/><p data-block-key="description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p><hr/><p data-block-key="location">Iqos Store</p><hr/><p data-block-key="icsFile">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="trackingAction">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="isTicketGewinnen">Tickets gewinnen</p><hr/>',
          },
          id: '8cb71b7d-8ad0-497f-9001-075c7816ef4a',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-2',
            content:
              '<p data-block-key="date">11.07 - 13.07.2024</p><hr/><p data-block-key="name">EDM Masterclass <br/> </p><hr/><p data-block-key="description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p><hr/><p data-block-key="location">Iqos Store</p><hr/><p data-block-key="icsFile">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="trackingAction">TogetherXMusic_MELT_Reminder</p><hr/>',
          },
          id: '8cb7b7d-8ad0-497f-9001-075c7816ef4a',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-3',
            image: {
              id: 363,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/800x800-img-care-challenge-12-1318.png',
              title: '800x800-img-care-challenge-12-13[18]',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-50.png',
                width: 1280,
                height: 1344,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-100.png',
                width: 2560,
                height: 2688,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-15.png',
                width: 384,
                height: 403,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-25_OeNwxWX.png',
                width: 640,
                height: 672,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-35.png',
                width: 896,
                height: 940,
              },
            },
            header: '',
            content:
              '<p data-block-key="date">11.07 - 13.07.2024</p><hr/> <p data-block-key="name">IQOS Unexpected Voice <br/>@ Melt</p><hr/><p data-block-key="description">Erlebe Blümchen und ihren Community Chor live auf dem MELT.</p><hr/><p data-block-key="location">MELT</p><hr/> <p data-block-key="icsFile">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="trackingAction">TogetherXMusic_MELT_Reminder</p>',
          },
          id: '4649ae4e-5cc1-46e9-b73d-758340a1599',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-4',
            content:
              '<p data-block-key="date">11.07 - 13.07.2024</p><hr/><p data-block-key="name">EDM Masterclass <br/> </p><hr/><p data-block-key="description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p><hr/><p data-block-key="location">Iqos Store</p><hr/><p data-block-key="icsFile">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="trackingAction">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="isTicketGewinnen">Tickets gewinnen</p><hr/>',
          },
          id: '8cb7b7d-8ad0-497f-9001-075c7816ef4a',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-5',
            content:
              '<p data-block-key="date">11.07 - 13.07.2024</p><hr/><p data-block-key="name">EDM Masterclass <br/> </p><hr/><p data-block-key="description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p><hr/><p data-block-key="location">Iqos Store</p><hr/><p data-block-key="icsFile">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="trackingAction">TogetherXMusic_MELT_Reminder</p><hr/>',
          },
          id: '8cb7b7d-8ad0-497f-9001-075c7816ef4a',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-6',
            image: {
              id: 364,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/800x800-img-care-challenge-12-1318.png',
              title: '800x800-img-care-challenge-12-13[18]',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-50.png',
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-100.png',
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-15.png',
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-25.png',
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-35.png',
              },
            },
            header: '',
            content:
              '<p data-block-key="date">09.08 - 10.08.2024</p><hr/><p data-block-key="name">IQOS Unexpected Voices <br/>@ HIPHOP OPEN</p><hr/><p data-block-key="description">Schau Dir Kool Savas und seinen Community Chor live auf dem HipHop Open an.</p><hr/><p data-block-key="location">HipHop Open</p><hr/><p data-block-key="icsFile">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_HIP-HOP_OPEN.ics</p><hr/> <p data-block-key="trackingAction">TogetherXMusic_HIPHOP_OPEN_Reminder</p>',
          },
          id: '4649ae4e-5cc1-469-b73d-758340a15a99',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-7',
            image: {
              id: 365,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/800x800-img-care-challenge-12-1318.png',
              title: '800x800-img-care-challenge-12-13[18]',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-50.png',
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-100.png',
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-15.png',
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-25.png',
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/800x800-img-care-challenge-12-1318.scale-35.png',
              },
            },
            header: '',
            content:
              '<p data-block-key="date">07.09 - 08.09.2024</p><hr/><p data-block-key="name">IQOS Unexpected Voices<br/>@ SUPERBLOOM</p><hr/><p data-block-key="description">Triff Michael Schulte und seinen Community Chor live auf dem SUPERBLOOM.</p><hr/><p data-block-key="location">SUPERBLOOM</p><hr/> <p data-block-key="icsFile">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_SUPERBLOOM.ics</p><hr/> <p data-block-key="trackingAction">TogetherXMusic_SUPERBLOOM_Reminder</p>',
          },
          id: '464ae4e-5cc1-46e9-b73d-758340a15a99',
        },
      ],
    },
  ],
};

export const GetVotingPageContent = {
  status: 200,
  items: [
    {
      id: 146,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/146/',
        html_url: 'https://localhost/togx-voting-page/',
        slug: 'togx-voting-page',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-04-29T13:37:15.606936+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'https://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-voting-page',
      page_content: [
        {
          type: 'strings',
          value: {
            obj_id: 'footer-disclaimer',
            content:
              '<p data-block-key="7cgte">*100 IQOS Points werden bei täglicher Teilnahme am Voting im Zeitraum vom 07.05. - 20.05. gutgeschrieben.</p>',
          },
          id: 'f7d59806-4386-4311-9557-335b68ad0afd',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'hero-header-title',
            content: '<p data-block-key="e24be"><b>Jetzt bist Du dran!</b></p>',
          },
          id: '66a6dd5f-c67e-43cd-9311-f0b6861db34c',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'hero-sub-title',
            content:
              '<p data-block-key="dd9oe">Jetzt t\u00e4glich f\u00fcr Deine Favoriten abstimmen und Punkte sammeln. Du entscheidest, wer Teil der IQOS Unexpected Voices wird.</p>',
          },
          id: '524eb44f-274e-449d-9a96-95ca5ff99fe3',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'intro-text',
            content:
              '<p data-block-key="dd9oe">Vote Deine Favoriten auf die B\u00fchne.</p><hr/><p data-block-key="dd9oe">Vote Deine Favoriten auf die B\u00fchne.</p>',
          },
          id: '5bcc6414-62c7-47f7-b661-d9dff64bd1ea',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'intro-title',
            content:
              '<p data-block-key="dd9oe">Vote Deine Favoriten auf die B\u00fchne.</p>',
          },
          id: '5bcc6414-62c7-47f7-b661-d9dff64bd1ea',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'voting-sub-title',
            content:
              '<p data-block-key="dd9oe">Stimme jeden Tag f\u00fcr Deinen Favoriten und sammle dabei Punkte. Sichere Dir on top noch 100 IQOS Points, wenn Du t\u00e4glich votest.</p>',
          },
          id: 'e6fe0156-2db6-4363-8f7d-ed4d8ca09350',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'thank-you-overlay',
            content:
              '<p data-block-key="80fgt">Jetzt dranbleiben und t\u00e4glich voten, um Deine 100 IQOS Points zu erhalten.</p><hr/><p data-block-key="1fv5v">Du hast inzwischen xxx Punkte gesammelt.</p>',
          },
          id: '53f76072-caaa-4626-88a0-6474e1ed8acf',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'friends-reminder-overlay',
            content:
              '<p data-block-key="uo87i">Erz\u00e4hle Deinen Freunden davon.*</p><hr/><p data-block-key="2pt4m">Nur f\u00fcr registrierte IQOS Nutzer mit Wohnsitz in Deutschland.</p><hr/><p data-block-key="87i46">Bist Du dabei? Bei den IQOS Unexpected Voices ist Deine Meinung gefragt. Jetzt t\u00e4glich voten und mitbestimmen wer mit Bl\u00fcmchen, Kool Savas und Michael Schulte auf der gro\u00dfen Festivalb\u00fchne stehen wird. Zus\u00e4tzlich bekommst Du f\u00fcr jeden Vote 10 Statuspunkte. Sei t\u00e4glich dabei und sichere Dir on top 100 IQOS Points.<br/>Mehr dazu findest Du im IQOS CLUB: iqosclub.com</p><hr/><p data-block-key="7dee7">Mit Bl\u00fcmchen, Kool Savas oder Michael Schulte auf der B\u00fchne \u2013 Bist Du dabei?</p><hr/><p data-block-key="e3a4d">Bist Du dabei? Bei den IQOS Unexpected Voices ist Deine Meinung gefragt. Jetzt t\u00e4glich voten und mitbestimmen wer mit Bl\u00fcmchen, Kool Savas und Michael Schulte auf der gro\u00dfen Festivalb\u00fchne stehen wird. Zus\u00e4tzlich bekommst Du f\u00fcr jeden Vote 10 Statuspunkte. Sei t\u00e4glich dabei und sichere Dir on top 100 IQOS Points.<br/>Mehr dazu findest Du im IQOS CLUB: iqosclub.com</p>',
          },
          id: 'c71b4186-9f2b-4f4f-bb40-3322c0fc25fa',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'bar-tab-voting-info',
            content:
              '<p data-block-key="uo87i">Der Sommer der Unexpected Voices.</p><hr/><p data-block-key="56uf7">3 Artists. 3 Ch\u00f6re. 48 Pl\u00e4tze.<br/>Jetzt mitbestimmen, wer Teil der IQOS Unexpected Voices wird. T\u00e4glich f\u00fcr Deine Favoriten abstimmen und die Reise der Ch\u00f6re mit unseren Artists Bl\u00fcmchen, Kool Savas und Michael Schulte mitverfolgen.</p>',
          },
          id: '908dac19-0c7c-4f7a-9848-8f5768044a93',
        },
      ],
    },
  ],
};

export const GetDocumentationHomepageContent = {
  status: 200,
  items: [
    {
      id: 151,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/151/',
        html_url: 'https://localhost/togx-documentation-home-page/',
        slug: 'togx-documentation-home-page',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-05-07T15:19:16.809756+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'https://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-documentation-home-page',
      page_content: [
        {
          type: 'strings',
          value: {
            obj_id: 'hero-header-paragraph',
            content:
              '<p data-block-key="gzrv1">Du hast mitentschieden: die IQOS Unexpected Voices stehen fest. Die Reise der Ch\u00f6re beginnt und Du kannst im IQOS CLUB exklusiv dabei sein. Dabei kannst du hinter die Kulissen blicken, besondere Musik-Momente erleben und ihre einzigartige Reise mitgestalten.</p>',
          },
          id: '7b93cf29-ae8a-41b0-83c0-5eacc5c59f71',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'hero-header-title',
            content:
              '<p data-block-key="mfy5o">3 ARTISTS. 3 CH\u00d6RE. 48 UNEXPECTED VOICES.</p>',
          },
          id: 'fb643acc-9a66-4074-a79d-5e8e58785176',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'intro-text',
            content:
              '<p data-block-key="mfy5o">Mit IQOS TogetherXMusic erlebst Du jetzt Musik auf eine ganz neue Art: Erfahre und erschaffe \u00fcberraschende Musikmomente gemeinsam mit der IQOS Community. F\u00fcr Dich und andere IQOS Nutzer.</p>',
          },
          id: '9fe936bc-4afb-4346-b0e3-27bc35b8c4dd',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'media-stage-title',
            content:
              '<p data-block-key="mfy5o">VOM TRAININGSCAMP AUF DIE B\u00dcHNE.</p>',
          },
          id: 'e1c61a6a-b387-4df7-a58e-91c4d13d9bbb',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'media-stage-text',
            content:
              '<p data-block-key="mfy5o">Du hast gevotet und kannst jetzt die Reise der IQOS Unexpected Voices vom Trainings Camp bis zu den Festivals verfolgen und dabei Punkte sammeln.</p>',
          },
          id: 'a8aa56b6-3b1d-4531-a3d8-a21c7b621e58',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-events-title',
            content:
              '<p data-block-key="qey5s">Die n\u00e4chsten IQOS</p><p data-block-key="6n8th">TogetherXMusic Events</p>',
          },
          id: '51511071-b125-4897-96c8-e70ac90f7e25',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-events-sub-title',
            content:
              '<p data-block-key="qey5s">Auf der TogetherXMusic Tour wird es viele musikalische Highlights geben. Die n\u00e4chsten Gelegenheiten, IQOS oder unsere Artists zu erleben, findest Du immer hier. \u00dcbrigens: Tickets, um live dabei zu sein, kannst Du immer wieder hier im Club gewinnen!</p>',
          },
          id: 'e474135d-701a-4f95-ac52-97e4f1b2ae7c',
        },
        {
          type: 'image',
          value: {
            obj_id: 'raffle-popup-1',
            image: {
              id: 415,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture2x.998eb41b.png',
              title: 'picture@2x.998eb41b',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="bhw4r">+20 Statuspunkte</p><hr/><p data-block-key="3mj63">Mach es zu Deinem Musiksommer: 10 x 200 \u20ac Eventim Gutscheine gewinnen.</p><hr/><p data-block-key="c16al">Wir dr\u00fccken dir die Daumen.</p><hr/><p data-block-key="a501q"><a href="https://de.pp.iqosclub.com/togetherx/documentation/bluemchen">https://de.pp.iqosclub.com/togetherx/documentation/bluemchen</a></p><hr/><p data-block-key="dptns"></p><hr/><p data-block-key="3rqs3">bin dabei</p><hr/><p data-block-key="7bl1h">Vielen Dank f\u00fcrs Mitmachen.</p><hr/><p data-block-key="gu1s">Wir dr\u00fccken dir die Daumen.</p><hr/><p data-block-key="5ujc">N\u00e4chstes Gewinnspiel: n\u00e4chste Woche</p>',
          },
          id: '5e53ab41-1df8-47db-97bb-3fdf95cff3dd',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-2',
            content:
              '<p data-block-key="0tz28">20.05.2024</p><hr/><p data-block-key="8i4k0">Luft &amp; Liebe</p><hr/><p data-block-key="5fl1i">Das atemberaubende, farbenfrohe Festival im Herzen des Ruhrgebiets.</p><hr/><p data-block-key="9k20t">Duisburg</p><hr/><p data-block-key="6uk2u">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="enopj">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="dsol0">Tickets gewinnen</p><hr/><p data-block-key="enopj">TogetherXMusic_LuftundLiebe_Gewinnspiel</p>',
          },
          id: '4e69bb48-fc70-428f-aca7-9bb77ac6843d',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-4',
            content:
              '<p data-block-key="mfy5o">31.5.- 1.06.2024</p><hr/><p data-block-key="b6qlh">Kesselfestival</p><hr/><p data-block-key="6naoo">Musik, Kultur und Du!</p><hr/><p data-block-key="eoap8">Stuttgart</p><hr/><p data-block-key="baphp">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="acb8k">TogetherXMusic_Kesselhaus_Reminder</p><hr/><p data-block-key="10ats"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'cbb1e8c4-1de6-40d9-9654-cba7ad08229e',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-3',
            content:
              '<p data-block-key="181nm">11.07 - 13.07.2024</p><hr/><p data-block-key="6dl6c">EDM Masterclass</p><hr/><p data-block-key="7nod0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p><hr/><p data-block-key="8fq06">IQOS Store</p><hr/><p data-block-key="j130">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="da3h1">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="4el9r"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: '103e3e78-7eb9-419e-baba-deda1f965ec8',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-1',
            image: {
              id: 405,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/464x464-img-fly-with-me.png',
              title: '464x464-img-fly-with-me',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-fly-with-me.scale-50.png',
                width: 464,
                height: 464,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-fly-with-me.scale-100.png',
                width: 928,
                height: 928,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-fly-with-me.scale-15.png',
                width: 139,
                height: 139,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-fly-with-me.scale-25.png',
                width: 232,
                height: 232,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-fly-with-me.scale-35.png',
                width: 324,
                height: 324,
              },
            },
            header: '',
            content:
              '<p data-block-key="uy5c5">08.06.24</p><hr/><p data-block-key="b8gcp">Fly with Me Festival</p><hr/><p data-block-key="6vcua">Das gr\u00f6\u00dfte elektronische Festival in Leipzig.</p><hr/><p data-block-key="eonsv">Leipzig</p><hr/><p data-block-key="fl741">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="2svbg">TogetherXMusic_FlyWithMe_Reminder</p><hr/><p data-block-key="e67o3"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'e188cb6f-2d67-451a-9b1e-6ea8b35cc2db',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-5',
            image: {
              id: 381,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/464x464-img-saluti-di-capri.png',
              title: '464x464-img-saluti-di-capri',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-saluti-di-capri.scale-50.png',
                width: 464,
                height: 464,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-saluti-di-capri.scale-100.png',
                width: 928,
                height: 928,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-saluti-di-capri.scale-15.png',
                width: 139,
                height: 139,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-saluti-di-capri.scale-25.png',
                width: 232,
                height: 232,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/464x464-img-saluti-di-capri.scale-35.png',
                width: 324,
                height: 324,
              },
            },
            header: '',
            content:
              '<p data-block-key="uy5c5">21.-23.6.24</p><hr/><p data-block-key="adh12">Salute da Capri</p><hr/><p data-block-key="6skou">Dolce Vita im Olympiapark M\u00fcnchen.</p><hr/><p data-block-key="968qr">M\u00fcnchen</p><hr/><p data-block-key="nlr0">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="f1t2">TogetherXMusic_SalutiDaCapri_Reminder</p><hr/><p data-block-key="6i12c"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: '75333f9d-7c17-4a03-855c-0e543f49c1bc',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-2',
            image: {
              id: 416,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture2x.4d0e25a5.png',
              title: 'picture@2x.4d0e25a5',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="2vq34">09.08 - 10.08.2024</p><hr/><p data-block-key="a3dpt">IQOS UNEXPECTED VOICES @ HIPHOP OPEN</p><hr/><p data-block-key="evacl">Schau Dir Kool Savas und seinen Community Chor live auf dem HipHop Open an.</p><hr/><p data-block-key="6ln2b">HipHop Open</p><hr/><p data-block-key="1pf9l"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Unexpected_Voices__HIP-HOP_OPEN.ics">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</a></p><hr/><p data-block-key="935lt">TogetherXMusic_HIPHOP_OPEN_Reminder</p><hr/><p data-block-key="bk1ma"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'd1122c68-ff2d-4377-989e-7826b7c5d9fc',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-6',
            image: {
              id: 415,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture2x.998eb41b.png',
              title: 'picture@2x.998eb41b',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="2vq34">11.07 - 13.07.2024</p><hr/><p data-block-key="fk7pl">IQOS UNEXPECTED VOICES @ MELT</p><hr/><p data-block-key="agrfk">Erlebe Bl\u00fcmchen und ihren Community Chor live auf dem MELT.</p><hr/><p data-block-key="62t3d">MELT</p><hr/><p data-block-key="27aur">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="5kt30">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="6bmga"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'ead08c9e-4610-49bd-b079-300e5e8466d4',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-3',
            image: {
              id: 415,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture2x.998eb41b.png',
              title: 'picture@2x.998eb41b',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="2vq34">11.07 - 13.07.2024</p><hr/><p data-block-key="fk7pl">IQOS UNEXPECTED VOICES @ MELT</p><hr/><p data-block-key="agrfk">Erlebe Bl\u00fcmchen und ihren Community Chor live auf dem MELT.</p><hr/><p data-block-key="62t3d">MELT</p><hr/><p data-block-key="27aur">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="5kt30">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="6bmga"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'ead08c9e-4610-49bd-b079-300e5e8466d4',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-7',
            image: {
              id: 415,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture2x.998eb41b.png',
              title: 'picture@2x.998eb41b',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="2vq34">11.07 - 13.07.2024</p><hr/><p data-block-key="fk7pl">IQOS UNEXPECTED VOICES @ MELT</p><hr/><p data-block-key="agrfk">Erlebe Bl\u00fcmchen und ihren Community Chor live auf dem MELT.</p><hr/><p data-block-key="62t3d">MELT</p><hr/><p data-block-key="27aur">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="5kt30">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="6bmga"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'ead08c9e-4610-49bd-b079-300e5e8466d4',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-8',
            image: {
              id: 415,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture2x.998eb41b.png',
              title: 'picture@2x.998eb41b',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="2vq34">11.07 - 13.07.2024</p><hr/><p data-block-key="fk7pl">IQOS UNEXPECTED VOICES @ MELT</p><hr/><p data-block-key="agrfk">Erlebe Bl\u00fcmchen und ihren Community Chor live auf dem MELT.</p><hr/><p data-block-key="62t3d">MELT</p><hr/><p data-block-key="27aur">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="5kt30">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="6bmga"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'ead08c9e-4610-49bd-b079-300e5e8466d4',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-9',
            image: {
              id: 415,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture2x.998eb41b.png',
              title: 'picture@2x.998eb41b',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture2x.998eb41b.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="2vq34">11.07 - 13.07.2024</p><hr/><p data-block-key="fk7pl">IQOS UNEXPECTED VOICES @ MELT</p><hr/><p data-block-key="agrfk">Erlebe Bl\u00fcmchen und ihren Community Chor live auf dem MELT.</p><hr/><p data-block-key="62t3d">MELT</p><hr/><p data-block-key="27aur">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="5kt30">TogetherXMusic_MELT_Reminder</p><hr/><p data-block-key="6bmga"><a href="https://de.pp.iqosclub.com/togetherx/documentation">Tickets gewinnen</a></p>',
          },
          id: 'ead08c9e-4610-49bd-b079-300e5e8466d4',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'highlight-carousel',
            content:
              '<p data-block-key="l21gl">Das erwartet Dich in den kommenden Monaten.</p><hr/><p data-block-key="bs4nd">IQOS TogehterXMusic h\u00e4lt jede Menge f\u00fcr Dich bereit. Du kannst nicht nur viele Punkte sammeln, sondern auch Tickets gewinnen, hinter die Kulissen blicken und sogar unsere Artists treffen.</p>',
          },
          id: '3ee8023a-60df-4f4f-9681-7f7222834e32',
        },
      ],
    },
  ],
};

export const GetDocumentationPageContent = {
  status: 200,
  items: [
    {
      id: 158,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/158/',
        html_url: 'https://localhost/togx-documentation-page/',
        slug: 'togx-documentation-page',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-05-08T18:50:09.654334+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'https://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-documentation-page',
      page_content: [
        {
          type: 'image',
          value: {
            obj_id: 'hero-header',
            image: {
              id: 398,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-with-bg3x_JQ2MH09.png',
              title: 'picture-with-bg@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-50.png',
                width: 2160,
                height: 1242,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-100.png',
                width: 4320,
                height: 2484,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-15.png',
                width: 648,
                height: 372,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-25.png',
                width: 1080,
                height: 621,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-with-bg3x_JQ2MH09.scale-35.png',
                width: 1512,
                height: 869,
              },
            },
            header: '',
            content:
              '<p data-block-key="z5qh5">DIE REISE DER IQOS UNEXPECTED VOICES.</p><hr/><p data-block-key="14ngo">Die Ch\u00f6re von Bl\u00fcmchen, Kool Savas und Michael Schulte stehen jetzt fest.<br/>Hier kannst Du die Reise verfolgen und aktiv mitgestalten. Dabei warten immer wieder Chancen, um Punkte zu sammeln und Tickets zu gewinnen.</p>',
          },
          id: '3f6574fa-777a-45ef-bd63-5aedc4a1ef9e',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'footer-disclaimer',
            content:
              '<p data-block-key="7cgte">*100 IQOS Points werden bei täglicher Teilnahme am Voting im Zeitraum vom 07.05. - 20.05. gutgeschrieben.</p>',
          },
          id: 'f7d59806-4386-4311-9557-335b68ad0afd',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'artist-cta-list-header',
            content:
              '<p data-block-key="utyxh">Diese Artists warten auf Dich.</p>',
          },
          id: '1df61905-7fd4-4248-a6db-0b6234bec820',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-cta-list-kool-savas',
            image: {
              id: 385,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-kool-savas3x.png',
              title: 'picture-kool-savas@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-kool-savas3x.scale-50.png',
                width: 495,
                height: 395,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-kool-savas3x.scale-100.png',
                width: 991,
                height: 790,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-kool-savas3x.scale-15.png',
                width: 148,
                height: 118,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-kool-savas3x.scale-25.png',
                width: 247,
                height: 197,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-kool-savas3x.scale-35.png',
                width: 346,
                height: 276,
              },
            },
            header: '',
            content:
              '<p data-block-key="qydh0">ERFAHRE MEHR</p><hr/><p data-block-key="btpdj">ERFAHRE MEHR</p><hr/><p data-block-key="77ttt">/togetherx/documentation/kool-savas</p>',
          },
          id: '8bf1bfef-6410-441a-a7b3-2c661f2fe78e',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-cta-list-michael-schulte',
            image: {
              id: 386,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-michael-schulte3x.png',
              title: 'picture-michael-schulte@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-michael-schulte3x.scale-50.png',
                width: 495,
                height: 395,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-michael-schulte3x.scale-100.png',
                width: 991,
                height: 790,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-michael-schulte3x.scale-15.png',
                width: 148,
                height: 118,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-michael-schulte3x.scale-25.png',
                width: 247,
                height: 197,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-michael-schulte3x.scale-35.png',
                width: 346,
                height: 276,
              },
            },
            header: '',
            content:
              '<p data-block-key="vjzhx">ERFAHRE MEHR</p><hr/><p data-block-key="eq6an">ERFAHRE MEHR</p><hr/><p data-block-key="fbck0">/togetherx/documentation/michael-schulte</p>',
          },
          id: '5c66c5c2-14dd-408e-80fc-b4990219bb33',
        },
        {
          type: 'image',
          value: {
            obj_id: 'artist-cta-list-bluemchen',
            image: {
              id: 387,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture-bl-mchen3x.png',
              title: 'picture-bl-mchen@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x.scale-50.png',
                width: 495,
                height: 394,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x.scale-100.png',
                width: 990,
                height: 789,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x.scale-15.png',
                width: 148,
                height: 118,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x.scale-25.png',
                width: 247,
                height: 197,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture-bl-mchen3x.scale-35.png',
                width: 346,
                height: 276,
              },
            },
            header: '',
            content:
              '<p data-block-key="vjzhx">ERFAHRE MEHR</p><hr/><p data-block-key="fct28">ERFAHRE MEHR</p><hr/><p data-block-key="6aea3">/togetherx/documentation/bluemchen</p>',
          },
          id: '50f26471-d914-45e4-83ef-69ba17e392e1',
        },
        // {
        //   type: 'image',
        //   value: {
        //     obj_id: 'coach-carousel-card-alyssa-and-gia',
        //     image: {
        //       id: 388,
        //       type: 'image',
        //       url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture3x_CtrG3eX.png',
        //       title: 'picture@3x',
        //       image_desktop_1x: {
        //         url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-50.png',
        //         width: 595,
        //         height: 744,
        //       },
        //       image_desktop_2x: {
        //         url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-100.png',
        //         width: 1191,
        //         height: 1488,
        //       },
        //       image_mobile_1x: {
        //         url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-15.png',
        //         width: 178,
        //         height: 223,
        //       },
        //       image_mobile_2x: {
        //         url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-25.png',
        //         width: 297,
        //         height: 372,
        //       },
        //       image_mobile_3x: {
        //         url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_CtrG3eX.scale-35.png',
        //         width: 416,
        //         height: 520,
        //       },
        //     },
        //     header: '',
        //     content:
        //       '<p data-block-key="pha2h">Alyssa &amp; Gia</p><hr/><p data-block-key="4l17g">Wir waren schon immer sehr performerisch unterwegs. Und haben als Kinder zusammen Musikvideos nachgetanzt \u2013 stundenlang! haben wir performt und gesungen.</p>',
        //   },
        //   id: 'c0d80a34-cc14-4107-bc15-698ac3b9337e',
        // },
        {
          type: 'image',
          value: {
            obj_id: 'coach-carousel-card-paula',
            image: {
              id: 389,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture3x_4DUxPpO.png',
              title: 'picture@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-50.png',
                width: 595,
                height: 744,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-100.png',
                width: 1191,
                height: 1488,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-15.png',
                width: 178,
                height: 223,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-25.png',
                width: 297,
                height: 372,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_4DUxPpO.scale-35.png',
                width: 416,
                height: 520,
              },
            },
            header: '',
            content:
              '<p data-block-key="pha2h">Paula</p><hr/><p data-block-key="aagsp">Ich liebe es, Menschen durch meinen Gesang und meine Performance Energie in den K\u00f6rper und ein L\u00e4cheln ins Gesicht zu zaubern.</p>',
          },
          id: '436684ac-7eeb-4c09-87b5-9b6bc6a2a603',
        },
        {
          type: 'image',
          value: {
            obj_id: 'coach-carousel-card-afra',
            image: {
              id: 390,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/picture3x_Q07Z7mb.png',
              title: 'picture@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_Q07Z7mb.scale-50.png',
                width: 375,
                height: 744,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_Q07Z7mb.scale-100.png',
                width: 750,
                height: 1488,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_Q07Z7mb.scale-15.png',
                width: 112,
                height: 223,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_Q07Z7mb.scale-25.png',
                width: 187,
                height: 372,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/picture3x_Q07Z7mb.scale-35.png',
                width: 262,
                height: 520,
              },
            },
            header: '',
            content:
              '<p data-block-key="pha2h">Afra</p><hr/><p data-block-key="c8ca4">Bei allem was ich mache geht&#x27;s mir darum gutes Entertainment mit echter Lebensfreude und Authentizit\u00e4t zu kombinieren.</p>',
          },
          id: '1870024f-ad9a-4cf5-ac74-5654ce180b76',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'discovery-hero-header-title',
            content:
              '<p data-block-key="wrf9v">ENTDECKE DIE IQOS TOGETHERXMUSIC TOUR.</p>',
          },
          id: 'e482b282-f616-4e06-b08c-0e4ffaf70511',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'discovery-hero-sub-title',
            content:
              '<p data-block-key="wrf9v">Mit IQOS TogetherXMusic erlebst Du jetzt Musik auf eine ganz neue Art: Erfahre und erschaffe \u00fcberraschende Musikmomente gemeinsam mit der IQOS Community. F\u00fcr Dich und andere IQOS Nutzer.</p>',
          },
          id: '5177d57c-73bb-405d-ae3f-13372a0d16b4',
        },
        {
          type: 'image',
          value: {
            obj_id: 'uv-raffle-popup',
            image: {
              id: 532,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Head_Picture_23x_FY0skCx.png',
              title: 'Head_Picture 2@3x',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Head_Picture_23x_FY0skCx.scale-50.png',
                width: 1275,
                height: 423,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Head_Picture_23x_FY0skCx.scale-100.png',
                width: 2550,
                height: 846,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Head_Picture_23x_FY0skCx.scale-15.png',
                width: 382,
                height: 126,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Head_Picture_23x_FY0skCx.scale-25.png',
                width: 637,
                height: 211,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Head_Picture_23x_FY0skCx.scale-35.png',
                width: 892,
                height: 296,
              },
            },
            header: '',
            content:
              '<p data-block-key="aozz5">+25 Statuspunkte</p><hr/><p data-block-key="bmegr">Blümchen Special&lt;br&gt;&lt;span class=&quot;text-primary-turquoise&quot;&gt;Signiertes Tour-Plakat&lt;/span&gt;&lt;/br&gt;</p><hr/><p data-block-key="29abt">&lt;br&gt;&lt;span class=&quot;text-primary-turquoise&quot;&gt;Gewinne eines von 10 handsignierten Tour-Plakaten von Blümchen&#x27;s Auftritt mit den IQOS Unexpected Voices.&lt;/span&gt;&lt;/br&gt;</p><hr/><p data-block-key="29su4"></p><hr/><p data-block-key="72tqm"></p><hr/><p data-block-key="6rmfu"></p><hr/><p data-block-key="9h3ca">/teilnahmebedingungen/togetherx-raffle-bluemchen-tourplakat</p><hr/><p data-block-key="d5o4l">Jetzt gewinnen</p><hr/><p data-block-key="9noq6">Vielen Dank für&#x27;s Mitmachen.</p><hr/><p data-block-key="9au1r">25 Statuspunkte wurden Dir gutgeschrieben.</p><hr/><p data-block-key="be670">Schau Dir auch unsere anderen Gewinnspiele im Eventkalender an und sichere Dir Tickets für Deinen Festivalsommer.</p><hr/><p data-block-key="dnsmn">Mehr entdecken</p><hr/><p data-block-key="3ci15">TogetherXMusic_BlümchenPlakat_Gewinnspiel</p>',
          },
          id: 'cacb0f91-57b1-4f2d-acfe-574d1f991307',
        },
        {
          type: 'image',
          value: {
            obj_id: 'uv-raffle-popup-mobile',
            image: {
              id: 335,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/Kaffkiez_-_11.07.png',
              title: 'Kaffkiez - 11.07',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content: '',
          },
          id: 'ae76cda49-1b1d-4c12-bd62-8148ee4187b3',
        },
      ],
    },
  ],
};

export const GetUpcomingEventsModuleContent = {
  status: 200,
  items: [
    {
      id: 115,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/115/',
        html_url: 'http://localhost/togx-documentation-home-page/',
        slug: 'togx-documentation-home-page',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-05-16T17:27:05.468601+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'http://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-module-upcoming-events',
      page_content: [
        {
          type: 'image',
          value: {
            obj_id: 'raffle-popup-4',
            image: {
              id: 339,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/Christian_Steffen_-_13.07._SOLD_OUT.png',
              title: 'Christian Steffen - 13.07. SOLD OUT',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">Gewinnspiel</p><hr/><p data-block-key="34v4n">Sei dabei beim <b>Christian Steiffen Konzert</b> in Hamburg: Gewinne 5 x 2 Tickets.</p><hr/><p data-block-key="2tni4">Schau Dir einfach zus\u00e4tzlich unser Video \u00fcber unsere Coaches an und sichere Dir Deinen Platz im Lostopf. Viel Gl\u00fcck!</p><hr/><p data-block-key="efadp">https://de.iqosclub.com/teilnahmebedingungen/togetherx-raffle-45-hertz-christian-steiffen</p><hr/><p data-block-key="61bek"></p><hr/><p data-block-key="fi161">Ich nehme teil.</p><hr/><p data-block-key="15lh6">Vielen Dank f\u00fcr&#x27;s Mitmachen.</p><hr/><p data-block-key="elod4">Wir dr\u00fccken Dir die Daumen!</p><hr/><p data-block-key="a4c2i">Schau Dir auch unsere anderen Gewinnspiele im Eventkalender an und sichere Dir Tickets f\u00fcr Deinen Festivalsommer.</p>',
          },
          id: '092d4a0e-0467-4503-8af3-1e10ef0f908d',
        },
        {
          type: 'image',
          value: {
            obj_id: 'raffle-popup-3',
            image: {
              id: 335,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/Kaffkiez_-_11.07.png',
              title: 'Kaffkiez - 11.07',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">Gewinnspiel</p><hr/><p data-block-key="e76rk">Sei dabei beim <b>Kaffkiez Konzert</b> in Hamburg: Gewinne 5 x 2 Tickets.</p><hr/><p data-block-key="b4l8v">Schau Dir einfach zus\u00e4tzlich unser Video \u00fcber unsere Coaches an und sichere Dir Deinen Platz im Lostopf. Viel Gl\u00fcck!</p><hr/><p data-block-key="8s7i2">/teilnahmebedingungen/togetherx-raffle-kaffkiez-konzert</p><hr/><p data-block-key="ceqpn"></p><hr/><p data-block-key="hq10">Ich nehme teil.</p><hr/><p data-block-key="m9ev">Vielen Dank f\u00fcr&#x27;s Mitmachen.</p><hr/><p data-block-key="ecst0">Wir dr\u00fccken Dir die Daumen.</p><hr/><p data-block-key="abab5">Schau Dir auch unsere anderen Gewinnspiele im Eventkalender an und sichere Dir Tickets f\u00fcr Deinen Festivalsommer.</p>',
          },
          id: 'ae76ca49-1b1d-4c12-bd62-8148ee4187e3',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-1',
            image: {
              id: 338,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/Speedway_-_05.07_HRerKg6.png',
              title: 'Speedway - 05.07',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">05.07.2024</p><hr/><p data-block-key="69ndt">45 Hertz - Speedway</p><hr/><p data-block-key="b7r70">Sei dabei, beim gr\u00f6\u00dften elektronischen Musik-Festival in Hamburg und Umgebung!</p><hr/><p data-block-key="18dth">Hamburg</p><hr/><p data-block-key="c18gv"><a href="https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/45_Hertz_Speedway.ics\n">https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/45_Hertz_Speedway.ics</a></p><hr/><p data-block-key="b135g">TogetherXMusic_45_Hertz_Speedway_Reminder</p><hr/><p data-block-key="ev1qn">Tickets gewinnen</p><hr/><p data-block-key="8622o">TogetherXMusic_45_Hertz_Speedway_Gewinnspiel</p>',
          },
          id: '5cc0e80f-7784-40b4-b475-cbc59d152c66',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-2',
            image: {
              id: 336,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/ModusxWEIRD_-_06.07.png',
              title: 'ModusxWEIRD - 06.07',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">06.07.2024</p><hr/><p data-block-key="cba0h">45 Hertz - WEIRD x Modus</p><hr/><p data-block-key="3a298">Sei dabei, beim gr\u00f6\u00dften elektronischen Musik-Festival in Hamburg und Umgebung!</p><hr/><p data-block-key="nb8k">Hamburg</p><hr/><p data-block-key="e17pn"><a href="https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/45Hertz_WEIRD_X_Modus.ics">https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/45Hertz_WEIRD_X_Modus.ics</a></p><hr/><p data-block-key="1ktlo">TogetherXMusic_45_Hertz_ WEIRD_X_Modus_Reminder</p><hr/><p data-block-key="doqt9">Tickets gewinnen</p><hr/><p data-block-key="bvaok">TogetherXMusic_45_Hertz_ WEIRD_X_Modus_Gewinnspiel</p>',
          },
          id: '7cad7a48-7c45-48ba-8c0d-de621397675c',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-3',
            image: {
              id: 335,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/Kaffkiez_-_11.07.png',
              title: 'Kaffkiez - 11.07',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Kaffkiez_-_11.07.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">11.07.2024</p><hr/><p data-block-key="barqn">45 Hertz - Konzert Kaffkiez</p><hr/><p data-block-key="e3got">Erlebe jetzt Kaffkiez live: Ziemlich angesagte indie rock sounds und ekstatische Konzerte.</p><hr/><p data-block-key="ob3u">Hamburg</p><hr/><p data-block-key="7nupm"><a href="https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/45Hertz_Kaffkiez.ics">https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/45Hertz_Kaffkiez.ics</a></p><hr/><p data-block-key="28ol2">TogetherXMusic_45_Hertz_ Kaffkiez_Reminder</p><hr/><p data-block-key="a3n9s">Tickets gewinnen</p><hr/><p data-block-key="4ok8f">TogetherXMusic_45_Hertz_ Kaffkiez_Gewinnspiel</p>',
          },
          id: '79902488-f91d-4964-8a1d-0fd3c840e087',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-4',
            image: {
              id: 339,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/Christian_Steffen_-_13.07._SOLD_OUT.png',
              title: 'Christian Steffen - 13.07. SOLD OUT',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Christian_Steffen_-_13.07._SOLD_OUT.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">13.07.2024</p><hr/><p data-block-key="91lto"><b>Christian Steiffen Konzert</b></p><hr/><p data-block-key="c1o5a">Das gro\u00dfe Christian Steiffen Open Air!</p><hr/><p data-block-key="2v1tn">Hamburg</p><hr/><p data-block-key="3onl3"><a href="https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/TogetherXMusic_45Hertz_Christian_Steiffen.ics">https://de-api.iqosclub.com/admin/fileadmin/download/CMS_TogetherXMusic/TogetherXMusic_45Hertz_Christian_Steiffen.ics</a></p><hr/><p data-block-key="7dqfs">TogetherXMusic_45_Hertz_Christian_Steiffen_Reminder</p><hr/><p data-block-key="ctp4j">Tickets gewinnen</p><hr/><p data-block-key="18j8o">TogetherXMusic_45_Hertz_Christian_Steiffen_Gewinnspiel</p>',
          },
          id: '80422106-0590-4dbb-92d0-2bf9297934f1',
        },
        {
          type: 'image',
          value: {
            obj_id: 'raffle-popup-2',
            image: {
              id: 336,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/ModusxWEIRD_-_06.07.png',
              title: 'ModusxWEIRD - 06.07',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/ModusxWEIRD_-_06.07.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">Gewinnspiel</p><hr/><p data-block-key="165bc">Sei dabei beim <b>Modus x WEIRD Festival</b>: Gewinne 5 x 2 Tickets.</p><hr/><p data-block-key="19kf4">Schau Dir einfach zus\u00e4tzlich unser Video \u00fcber unsere Coaches an und sichere Dir Deinen Platz im Lostopf. Viel Gl\u00fcck!</p><hr/><p data-block-key="f21a3">https://de.iqosclub.com/teilnahmebedingungen/togetherx-raffle-modus-x-weird-festival</p><hr/><p data-block-key="1akoo"></p><hr/><p data-block-key="11jc1">Ich nehme teil.</p><hr/><p data-block-key="op78">Vielen Dank f\u00fcr&#x27;s Mitmachen.</p><hr/><p data-block-key="fd1ft">Wir dr\u00fccken Dir die Daumen.</p><hr/><p data-block-key="97ihh">Schau Dir auch unsere anderen Gewinnspiele im Eventkalender an und sichere Dir Tickets f\u00fcr Deinen Festivalsommer.</p>',
          },
          id: '54e40094-68ab-4a05-ac19-9c3db0943c65',
        },
        {
          type: 'image',
          value: {
            obj_id: 'raffle-popup-1',
            image: {
              id: 338,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/Speedway_-_05.07_HRerKg6.png',
              title: 'Speedway - 05.07',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-50.png',
                width: 540,
                height: 540,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-100.png',
                width: 1080,
                height: 1080,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-15.png',
                width: 162,
                height: 162,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-25.png',
                width: 270,
                height: 270,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/Speedway_-_05.07_HRerKg6.scale-35.png',
                width: 378,
                height: 378,
              },
            },
            header: '',
            content:
              '<p data-block-key="md4q5">Gewinnspiel</p><hr/><p data-block-key="9f3d4">Sei dabei beim gr\u00f6\u00dften Elektrofestival in Hamburg und Umgebung: Gewinne 5 x 2 Tickets.</p><hr/><p data-block-key="vg15">Schau Dir einfach zus\u00e4tzlich unser Video \u00fcber unsere Coaches an und sichere Dir Deinen Platz im Lostopf. Viel Gl\u00fcck!</p><hr/><p data-block-key="572r7">https://de.iqosclub.com/teilnahmebedingungen/togetherx-raffle-45-hertz-speedway</p><hr/><p data-block-key="9lhil"></p><hr/><p data-block-key="dpsdt">Ich nehme teil.</p><hr/><p data-block-key="dtdlp">Vielen Dank f\u00fcr&#x27;s Mitmachen.</p><hr/><p data-block-key="27j0t">Wir dr\u00fccken Dir die Daumen.</p><hr/><p data-block-key="2nril">Schau Dir auch unsere anderen Gewinnspiele im Eventkalender an und sichere Dir Tickets f\u00fcr Deinen Festivalsommer.</p>',
          },
          id: '73167750-d76a-47da-bb9c-0637aeccf864',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-events-title',
            content:
              '<p data-block-key="kqum8">Die n\u00e4chsten IQOS</p><p data-block-key="4nij6">TogetherXMusic Events.</p>',
          },
          id: '62612a6a-e532-40f3-9fa2-bfbe83bd1548',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-events-sub-title',
            content:
              '<p data-block-key="kqum8">Entdecke die musikalischen Highlights der TogetherXMusic Tour! Gewinne Tickets zu exklusiven Events und Festivals hier im IQOS CLUB und sei live dabei!</p>',
          },
          id: '4fb23eb5-a9ef-44d2-9ec4-41faa5391924',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-5',
            content:
              '<p data-block-key="nlf26">08.06.2024</p><hr/><p data-block-key="4jf5o">Fly with Me Festival</p><hr/><p data-block-key="7lqpb">Das gr\u00f6\u00dfte elektronische Festival in Leipzig.</p><hr/><p data-block-key="57eom">Leipzig</p><hr/><p data-block-key="2a3f3"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Fly_with_me_Festival.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Fly_with_me_Festival.ics</a></p><hr/><p data-block-key="8f04n">TogetherXMusic_FlyWithMe_Reminder</p>',
          },
          id: '6a0da6d5-ce42-4d41-b794-4d5f7ace2703',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upcoming-event-6',
            content:
              '<p data-block-key="nlf26">21.06. - 23.06.2024</p><hr/><p data-block-key="1c0b">Saluti da Capri</p><hr/><p data-block-key="agr64">Dolce Vita im Olympiapark M\u00fcnchen.</p><hr/><p data-block-key="2ipie">M\u00fcnchen</p><hr/><p data-block-key="32vpb"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Saluti_da_Capri.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Saluti_da_Capri.ics</a></p><hr/><p data-block-key="7sfu">TogetherXMusic_SalutiDaCapri_Reminder</p>',
          },
          id: 'cfad9d23-548a-4a9f-ad3f-b2b7e251ef0a',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-7',
            image: {
              id: 280,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/picture2x.998eb41b_1.png',
              title: 'picture@2x.998eb41b (1)',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.998eb41b_1.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.998eb41b_1.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.998eb41b_1.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.998eb41b_1.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.998eb41b_1.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="r5wvu">11.07. - 13.07.2024</p><hr/><p data-block-key="59s9m">IQOS UNEXPECTED VOICES @ MELT</p><hr/><p data-block-key="dppmt">Erlebe Bl\u00fcmchen und ihren Community Chor live auf dem MELT.</p><hr/><p data-block-key="507th">MELT</p><hr/><p data-block-key="elmiq">https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/ics/Unexpected_Voices_@_MELT.ics</p><hr/><p data-block-key="d0b0t">TogetherXMusic_MELT_Reminder</p>',
          },
          id: '3617a111-6883-4b78-ac40-d11a8e3ea244',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-8',
            image: {
              id: 281,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/picture2x.4d0e25a5_1.png',
              title: 'picture@2x.4d0e25a5 (1)',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5_1.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5_1.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5_1.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5_1.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.4d0e25a5_1.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="r5wvu">09.08. - 10.08.2024</p><hr/><p data-block-key="t51c">IQOS UNEXPECTED VOICES @ HIPHOP OPEN</p><hr/><p data-block-key="68nnq">Schau Dir Kool Savas und seinen Community Chor live auf dem HipHop Open an.</p><hr/><p data-block-key="2e1kv">HipHop Open</p><hr/><p data-block-key="ep0lt"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Unexpected_Voices__HIP-HOP_OPEN.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Unexpected_Voices__HIP-HOP_OPEN.ics</a></p><hr/><p data-block-key="f9cc3">TogetherXMusic_HIPHOP_OPEN_Reminder</p>',
          },
          id: 'a60cfd3c-8890-4897-a56f-26639ba019fd',
        },
        {
          type: 'image',
          value: {
            obj_id: 'upcoming-event-9',
            image: {
              id: 282,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/picture2x.66f6d54f.png',
              title: 'picture@2x.66f6d54f',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.66f6d54f.scale-50.png',
                width: 305,
                height: 202,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.66f6d54f.scale-100.png',
                width: 610,
                height: 404,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.66f6d54f.scale-15.png',
                width: 91,
                height: 60,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.66f6d54f.scale-25.png',
                width: 152,
                height: 101,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/picture2x.66f6d54f.scale-35.png',
                width: 213,
                height: 141,
              },
            },
            header: '',
            content:
              '<p data-block-key="r5wvu">07.09. - 08.09.2024</p><hr/><p data-block-key="4d2if">IQOS UNEXPECTED VOICES @ SUPERBLOOM</p><hr/><p data-block-key="2n31a">Erlebe Michael Schulte und seinen Community Chor live auf dem SUPERBLOOM.</p><hr/><p data-block-key="a6a1e">SUPERBLOOM</p><hr/><p data-block-key="31k8p"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Unexpected_Voices__SUPERBLOOM.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Unexpected_Voices__SUPERBLOOM.ics</a></p><hr/><p data-block-key="7fts4">TogetherXMusic_SUPERBLOOM_Reminder</p>',
          },
          id: '6426051f-a7c0-4d01-baf5-4a807487d309',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'stickybar',
            content:
              '<p data-block-key="kqum8">Entdecke jetzt <br><span class="text-primary-turquoise">alle Gewinnspiele</span></br></p>',
          },
        },
      ],
    },
  ],
};
