import { RouteRecordRaw, Router } from 'vue-router';

export const routeNames = {
  TEASER_HOME: '/teaser',
};

export default initialiseNewsTeaserRouter;

const routes: RouteRecordRaw[] = [
  {
    path: routeNames.TEASER_HOME,
    name: routeNames.TEASER_HOME,
    meta: {
      gtagDomain: 'teaser',
    },
    component: () =>
      import(
        /* webpackChunkName: "Teaser" */ './views/NewsTeaserHome/ConnectedNewsTeaserHome.vue'
      ),
  },
];

function initialiseNewsTeaserRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
