import { RouteRecordRaw, Router } from 'vue-router';

export const routeNames = {
  STORIES_HOME: '/stories',
  STORIES_FALLBACK: '/stories/fallback',
  STORIES_ARCHIVE: '/stories/archive',
  STORIES_DETAIL: '/stories/:slug',
  TEILNAHMEBEDINGUNGEN: 'teilnahmebedingungen',
  TEILNAHMEBEDINGUNGEN2: 'teilnahmebedingungen2',
  TEILNAHMEBEDINGUNGEN3: 'teilnahmebedingungen3',
  TEILNAHMEBEDINGUNGEN_TIME_WARP: 'teilnahmebedingungen/time-warp',
};

export const ROOT_URL = '/stories';

export default initialiseStoriesRouter;

const routes: RouteRecordRaw[] = [
  {
    path: `${routeNames.STORIES_HOME}`,
    name: routeNames.STORIES_HOME,
    meta: {
      gtagDomain: 'stories',
    },
    component: () =>
      import(
        /* webpackChunkName: "Stories" */ './views/StoriesHome/ConnectedStoriesHome.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_DETAIL}`,
    name: 'stories-detail',
    meta: {
      gtagDomain: 'stories',
    },
    component: () =>
      import(
        /* webpackChunkName: "Stories" */ './views/StoriesDetail/ConnectedStoriesDetail.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_FALLBACK}`,
    name: routeNames.STORIES_FALLBACK,
    meta: {
      gtagDomain: 'stories',
    },
    component: () =>
      import(
        /* webpackChunkName: "Stories" */ './views/StoriesFallback/StoriesFallback.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_ARCHIVE}`,
    name: routeNames.STORIES_ARCHIVE,
    meta: {
      gtagDomain: 'stories',
    },
    component: () =>
      import(
        /* webpackChunkName: "StoriesArchive" */ './views/StoriesArchive/ConnectedStoriesArchive.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN}`,
    name: routeNames.TEILNAHMEBEDINGUNGEN,
    props: (route) => ({ story: route.query.story }),
    component: () =>
      import(
        /* webpackChunkName: "stories" */ './views/Teilnahmebedingungen.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN2}`,
    name: routeNames.TEILNAHMEBEDINGUNGEN2,
    props: (route) => ({ story: route.query.story }),
    component: () =>
      import(
        /* webpackChunkName: "stories" */ './views/Teilnahmebedingungen2.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN3}`,
    name: routeNames.TEILNAHMEBEDINGUNGEN3,
    props: (route) => ({ story: route.query.story }),
    component: () =>
      import(
        /* webpackChunkName: "stories" */ './views/Teilnahmebedingungen3.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN_TIME_WARP}`,
    name: routeNames.TEILNAHMEBEDINGUNGEN_TIME_WARP,
    props: (route) => ({ story: route.query.story }),
    component: () =>
      import(
        /* webpackChunkName: "stories" */ './views/TeilnahmebedingungenTimeWarp.vue'
      ),
  },
  {
    path: `${routeNames.STORIES_HOME}${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN}`,
    redirect: `${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN}`,
  },
  {
    path: `${routeNames.STORIES_HOME}${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN2}`,
    redirect: `${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN2}`,
  },
  {
    path: `${routeNames.STORIES_HOME}${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN3}`,
    redirect: `${routeNames.STORIES_HOME}/${routeNames.TEILNAHMEBEDINGUNGEN3}`,
  },
];

function initialiseStoriesRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
