/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { authApiGet, authApiPost } from '@/api/api.js';

const URLS = {
  ACADEMY: 'academy',
  ARCHIVE: 'academy/archive',
  GAME_START: 'academy/game/start',
  DRAFTS: 'drafts/?type=academy.ClassPage&fields=*',
  GET_AMBASSADORS: 'academy2/ambassador',
  PUT_AMBASSADORS: 'academy2/consumer/set_ambassador/:ambassadorId',
  CONSUMER: 'academy2/consumer',
  CAMPAIGNS: 'academy2/campaigns',
  CHALLENGE: 'academy2/challenge/:challengeId',
  CHALLENGE_PROGRESS: 'academy2/challenge/:challengeId/progress',
  GROUP_CHALLENGES: 'academy2/group/:groupId/challenges',
  GROUP: 'academy2/group',
  GROUPS: 'academy2/groups',
  GET_PLAYABLE_START: 'academy2/challenge/:challengeId/start_playable',
  ARCHIVE2: 'academy2/archive',
  ACADEMY_DATA: 'academy2/data',
  GET_HOMEPAGE_CONTENT: 'academy2/startpage',
  REDEEM_ILUMA_REWARD: 'academy2/group/:groupId/redeem',
};

export const startGame = (gameId) =>
  authApiPost(URLS.GAME_START, { class_id: String(gameId) });

const fetchClasses = authApiGet(URLS.ACADEMY);

const fetchDraftClasses = authApiGet(URLS.DRAFTS);

export const fetchAvailableClasses = (headers, fetchDraftsEnabled) => {
  const requests = fetchDraftsEnabled
    ? [fetchClasses(headers), fetchDraftClasses(headers)]
    : [fetchClasses(headers)];
  return Promise.all(requests);
};

export const fetchArchivedClasses = authApiGet(URLS.ARCHIVE);

export const fetchCampaigns = authApiGet(URLS.CAMPAIGNS);

export const fetchConsumer = authApiGet(URLS.CONSUMER);
export const fetchAmbassadors = authApiGet(URLS.GET_AMBASSADORS);
export const postAmbassador = (ambassadorId) =>
  authApiPost(URLS.PUT_AMBASSADORS.replace(':ambassadorId', ambassadorId));

export const fetchGroupChallenges = (groupId) =>
  authApiGet(URLS.GROUP_CHALLENGES.replace(':groupId', groupId));

export const fetchGroups = authApiGet(URLS.GROUPS);

export const fetchArchivedChallenges = authApiPost(URLS.ARCHIVE2, { limit: 100 });

export const fetchAcademyData = authApiGet(URLS.ACADEMY_DATA);

export const postRedeemIlumaReward = (groupId: string, rewardType: string) =>
  authApiPost(URLS.REDEEM_ILUMA_REWARD.replace(':groupId', groupId), { choice: rewardType })

export const postChallengeDone = (challengeId: string) =>
  authApiPost(URLS.CHALLENGE.replace(':challengeId', challengeId))

export const postChallengeStart = (challengeId: number) =>
  authApiGet(URLS.GET_PLAYABLE_START.replace(':challengeId', String(challengeId)))

export const fetchHomepageContent =
  authApiGet(URLS.GET_HOMEPAGE_CONTENT)

export const postListenToMediaFragment = (
  challengeId: string,
  progress: number,
) => authApiPost(
  URLS.CHALLENGE_PROGRESS.replace(':challengeId', challengeId),
  { progress_percent: progress },
)
