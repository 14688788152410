module.exports = {
  1: '1',
  1: '2',
  3: '3',
  5: '5',
  60: '60',
  70: '70',
  80: '80',
  90: '90',
  100: '100',
};
