import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "generic-error" }
const _hoisted_2 = { class: "flex flex-col justify-center items-center" }
const _hoisted_3 = { class: "text-center text-fs-14-lh-20-ls-0.4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        class: "h-12 md:h-16 mb-l md:mb-s xl:mb-m",
        type: "info",
        theme: _ctx.ICON_THEMES.LIGHT_TURQUOISE
      }, null, 8, ["theme"]),
      _createElementVNode("strong", {
        class: _normalizeClass([
          'block',
          'text-fs-20-lh-24-ls-0.2',
          'md:text-fs-24-lh-32-ls-0',
          'xl:text-fs-28-lh-36-ls-0',
          'mb-m lg:mb-l',
        ])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}