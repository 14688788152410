import { RouteRecordRaw, Router } from 'vue-router';

export const routeNames = {
  NEWS_ARTICLE_RAFFLE_HOME: '/teilnahmebedingungen-10-Jahre-IQOS',
};

export default initialiseNewsArticleRaffleRouter;

const routes: RouteRecordRaw[] = [
  {
    path: `${routeNames.NEWS_ARTICLE_RAFFLE_HOME}`,
    name: routeNames.NEWS_ARTICLE_RAFFLE_HOME,
    meta: {
      gtagDomain: 'teilnahmebedingungen-10-Jahre-IQOS',
    },
    component: () =>
      import(
        /* webpackChunkName: "teilnahmebedingungen-10-Jahre-IQOS" */ './views/IQOS10Years.vue'
      ),
  },
];

function initialiseNewsArticleRaffleRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
