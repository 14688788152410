import { RouteRecordRaw, Router } from 'vue-router';

import store from './store/store';

const DEFAULT_GTAG_DOMAIN = 'rewardshop';

export const ROOT_URL = '/rewardshop';

export const routeBreadcrumbsMeta = {
  PRODUCT_LIST: { name: 'Prämienübersicht', link: '/rewardshop' },
};
export const routeNames = {
  PRODUCT_LIST: 'PRODUCT_LIST',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  CHECKOUT: 'CHECKOUT',
  ORDER_CONFIRMATION: 'ORDER_CONFIRMATION',
};

export default initialiseProductDetailRouter;

const getCurrentProductOrRedirect = (): boolean | string => {
  const productToCheckout = store.state.currentProduct;
  // True to continue navigation, otherwise redirect to product list
  return productToCheckout ? true : routeNames.PRODUCT_LIST;
};

export const getProductDetailUrl =
  (productId: string, variantId: string): string =>
    `/rewardshop/product/${productId}/${variantId}`;

const routes: RouteRecordRaw[] = [
  {
    path: '/rewardshop',
    name: routeNames.PRODUCT_LIST,
    component: () =>
      import(
        /* webpackChunkName: "Shop" */
        './views/ProductList/ConnectedProductList.vue'
      ),
    meta: {
      routeBreadcrumbName: 'Prämienübersicht',
      gtagDomain: DEFAULT_GTAG_DOMAIN,
    },
  },
  {
    path: getProductDetailUrl(':id', ':variantId'),
    name: routeNames.PRODUCT_DETAIL,
    component: () =>
      import(
        /* webpackChunkName: "Shop" */
        './views/ProductDetails/ConnectedProductDetails.vue'
      ),
    meta: {
      breadcrumbs: [routeBreadcrumbsMeta.PRODUCT_LIST],
      gtagDomain: DEFAULT_GTAG_DOMAIN,
    },
  },
  {
    path: '/order/address/:productId/:variantId',
    name: routeNames.CHECKOUT,
    component: () =>
      import(
        /* webpackChunkName: "Shop" */
        './views/Checkout/ConnectedCheckout.vue'
      ),
    meta: {
      routeBreadcrumbName: 'Checkout',
      breadcrumbs: [routeBreadcrumbsMeta.PRODUCT_LIST],
      gtagDomain: DEFAULT_GTAG_DOMAIN,
    },
  },
  {
    path: '/order/confirmation',
    name: routeNames.ORDER_CONFIRMATION,
    component: () =>
      import(
        /* webpackChunkName: "Shop" */
        './views/OrderConfirmation/ConnectedOrderConfirmation.vue'
      ),
    beforeEnter: getCurrentProductOrRedirect,
    meta: {
      routeBreadcrumbName: 'Checkout',
      breadcrumbs: [routeBreadcrumbsMeta.PRODUCT_LIST],
      gtagDomain: DEFAULT_GTAG_DOMAIN,
    },
  },
];

function initialiseProductDetailRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
