import {
  fetchAmbassadors,
  fetchArchivedClasses,
  fetchAvailableClasses,
  postAmbassador,
  startGame,
  postChallengeDone,
  postListenToMediaFragment,
} from '../api/api.js';

import { FAKE_USER_ERROR_MESSAGE } from '@/constants/messages';

import normalizeClasses from './normalizers/classes.js';
import normalizeFaq from './normalizers/faq.js';
import { ROOT_URL } from '../router';

function getClasses({ dispatch }) {
  return Promise.all([
    dispatch('getArchivedClasses'),
    dispatch('getAvailableClasses'),
  ]);
}

function getAmbassadors({ dispatch, commit }) {
  return dispatch('authModule/refreshHeaders', null, { root: true })
    .then(fetchAmbassadors)
    .then((response) => {
      commit('SET_AMBASSADOR_LIST', {
        ambassadors: response?.[0]?.ambassadors,
        groupId: response?.[0].id,
      });

      const currentAmbassador = response?.[0]?.ambassadors.find(
        (ambassador) => ambassador.id === response?.[0]?.current_ambassador
      );

      if (currentAmbassador) {
        commit('SET_AMBASSADOR', currentAmbassador);
      }
    });
}

function saveAmbassador({ dispatch, commit }, ambassador) {
  return dispatch('authModule/refreshHeaders', null, { root: true })
    .then(postAmbassador(ambassador.groupId, ambassador.id))
    .then((response) => commit('SET_AMBASSADOR', response?.ambassador));
}

async function listenedToMediaFragment(
  { commit, dispatch },
  { challengeId, progress }
) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  try {
    await postListenToMediaFragment(challengeId, progress)(headers);
  } catch (error) {
    commit('SET_MEDIA_ERROR', error.message);
  }
}

async function markChallengeDone({ dispatch, commit }, challenge) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  try {
    await postChallengeDone(challenge.id)(headers);
    commit('SET_CHALLENGE_DONE', challenge);
  } catch (error) {
    if (error?.response?.status === 402)
      commit(
        'PUSH_ERROR',
        {
          message: FAKE_USER_ERROR_MESSAGE,
          title: 'Hinweis',
          buttonText: 'Zurück',
          buttonLink: ROOT_URL,
        },
        { root: true }
      );
    else commit('SET_CHALLENGE_DONE_ERROR', error.message);
  }
}

async function postGameStart({ dispatch }, classId) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  return startGame(String(classId))(headers);
}

async function getAvailableClasses({ commit, dispatch, state }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  const classesResponses = await fetchAvailableClasses(
    headers,
    state.featureFlags.FLAG_IQA_19
  );
  const reduceClasses = (allClasses, { classes, items }) => {
    // Regular API uses "classes", wagtail uses "items"
    const rawClasses = classes || items;
    return [
      ...allClasses,
      ...normalizeClasses({ rawClasses, fromArchive: false }),
    ];
  };
  commit(
    'SET_NEXT_CLASS_AVAILABILITY_DATE',
    classesResponses[0].next_class_availability
  );
  const classes = classesResponses.reduce(reduceClasses, []);
  commit('SET_CLASSES_DATA', classes);
  return classes;
}

function getArchivedClasses({ commit, dispatch }) {
  const normalizeData = ({ classes: rawClasses }) =>
    normalizeClasses({ rawClasses, fromArchive: true });

  const commitData = (classes) => {
    commit('SET_ARCHIVE_DATA', classes);
    return classes;
  };

  return dispatch('authModule/refreshHeaders', null, { root: true })
    .then(fetchArchivedClasses)
    .then(normalizeData)
    .then(commitData);
}

export default {
  getAmbassadors,
  getArchivedClasses,
  getAvailableClasses,
  getClasses,
  postGameStart,
  saveAmbassador,
  markChallengeDone,
  listenedToMediaFragment,
};
