
import { defineComponent } from 'vue';
import { CLUB_LOYALTY_URL } from '@/modules/auth/constants';

export default defineComponent({
  name: 'Footer',
  setup() {
    return {
      CLUB_LOYALTY_URL,
      iqosBaseURL: 'https://www.iqos.com/de/de',
    };
  },
});
