export default {
  product: {
      product_id: '6087d386686d263a000014c8',
      group: 'voucher',
      name: '20\u20ac Fleurop Gutschein',
      category: '',
      main_category: 'Partnerpr\u00e4mien',
      image: 'main',
      description: 'Ob den \"Strau\u00df der Woche\', Fr\u00fchlingszauber oder die exklusive Valentinskollektion - bei fleurop findet ihr immer den passenden Strau\u00df f\u00fcr jede Gelegenheit. Hier erh\u00e4lt man garantierte Frische und Floristen Qualit\u00e4t. Und wer freut sich nicht \u00fcber Blumen? ',
      sub_headline: 'Hier findet man immer den passenden Strau\u00df - egal f\u00fcr welche Gelegenheit.',
      required_points: 200,
      required_tierlevel: 'silver',
      attributes: [],
      variants: [
          {
              variant_id: '071a225a-c5c0-11ee-aa7a-0242c0a8f005',
              color_code: '#000000',
              out_of_stock: false,
              images: [
                  '65c63119309efee2b3456c53'
              ],
              ean_code: '',
              voucher_url: '',
              voucher_value: '20\u20ac'
          }
      ],
      order_group: 'a',
      limited_edition: false,
      label: 'Neu <del>test</del>',
      label_color_code: '',
  }
}
