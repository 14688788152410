
import { defineComponent, PropType } from 'vue';
import { NavFirstLevel } from '@/components/NavHeader/NavHeader.types';
import IconArrowDown from '@/components/svg/IconArrowDown.vue';

const FIRST_LEVEL_CLICK_EVENT = 'firstLevelClick';

export default defineComponent({
  components: { IconArrowDown },
  props: {
    menu: {
      type: Object as PropType<NavFirstLevel>,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    shouldBeGreyActive: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  emits: [FIRST_LEVEL_CLICK_EVENT],
  setup(props, { emit }) {
    const handleClick = () =>
      props.menu?.handler && emit(FIRST_LEVEL_CLICK_EVENT, props.menu);
    return {
      handleClick,
    };
  },
});
