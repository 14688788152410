
import { defineComponent, computed, ref } from 'vue';

import { navItems } from '@/components/NavHeader/ItemsMenu';
import { useNavState, globalNavState } from '@/components/NavHeader/useNavState';

import IconArrowDown from '@/components/svg/IconArrowDown.vue';
import NavDesktopFirstLevel from './NavDesktopFirstLevel.vue';
import NavFooter from '@/components/NavHeader/components/NavFooter.vue';
import NavLink from '@/components/NavHeader/components/NavLink.vue';
import NavLinkSmall from '@/components/NavHeader/components/NavLinkSmall.vue';
import NavThirdLevel from '@/components/NavHeader/components/NavThirdLevel.vue';
import TSlideInLeft from '@/components/Transitions/TSlideInLeft.vue';

export default defineComponent({
  components: {
    IconArrowDown,
    NavDesktopFirstLevel,
    NavFooter,
    NavLink,
    NavLinkSmall,
    NavThirdLevel,
    TSlideInLeft,
  },
  props: {
    contentClasses: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      closeAllMenus,
      firstLevelClick,
      secondLevelClick,
      transitionDurationCalc,
      getImageUrl,
    } = useNavState()

    const hasSecondLevelMenu = (item) => {
      item.handler ? secondLevelClick(item) : false
    }

    const mouseOverFirstLevelLinkId = ref(null);
    const mouseOverSecondLevelLinkId = ref(null);
    const toggleMouseOverFirstLevelLink = (id) => mouseOverFirstLevelLinkId.value = id
    const toggleMouseOverSecondLevelLink = (id) => mouseOverSecondLevelLinkId.value = id

    const shouldBeGreyHoverFirsLevel = (id) => {
      return mouseOverFirstLevelLinkId.value
        && mouseOverFirstLevelLinkId.value !== id
        && globalNavState.firstLevelActive !== id
    }

    const shouldBeGreyHoverSecondLevel = (id) => {
      return mouseOverSecondLevelLinkId.value
        && mouseOverSecondLevelLinkId.value !== id
        && globalNavState.secondLevelActive !== id;
    }

    const isLinkActiveOrInitialState = (id) =>
      globalNavState.firstLevelActive === id || !globalNavState.firstLevelActive

    const isFirstLinkActive = (id) =>
      globalNavState.firstLevelActive === id

    const isSecondLinkActive = (id) =>
      globalNavState.secondLevelActive === id ||
      !globalNavState.secondLevelActive

    const hasNavFooter = computed(() =>
      globalNavState.isThirdLevelVisible
        ? globalNavState.thirdLevelItems.footer
        : globalNavState.secondLevelItems.footer
    )

    const navItemsDesktop = computed(() => {
      const itemsLeft = navItems.filter(
        (item) => item.desktopPosition === 'left'
      );
      const itemsRight = navItems.filter(
        (item) => item.desktopPosition === 'right'
      );
      return { itemsLeft, itemsRight };
    });

    return {
      closeAllMenus,
      firstLevelClick,
      getImageUrl,
      globalNavState,
      hasNavFooter,
      hasSecondLevelMenu,
      isFirstLinkActive,
      isLinkActiveOrInitialState,
      isSecondLinkActive,
      navItemsDesktop,
      secondLevelClick,
      shouldBeGreyHoverFirsLevel,
      shouldBeGreyHoverSecondLevel,
      toggleMouseOverFirstLevelLink,
      toggleMouseOverSecondLevelLink,
      transitionDurationCalc
    }
  }
});
