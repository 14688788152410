/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import asyncActions from './asyncActions.js';
import actions from './actions.js';
import { normaliseCmsImage } from '@/components/ResponsiveImage/utils';

export const initialState = () => ({
  archive: [],
  faq: {
    header: '',
    text: '',
    questions: [],
  },
  featureFlags: {
    FLAG_IQA_19: window.location.href.includes('preview=true'),
  },
  ilumaJourney: {
    ambassador: null,
    ambassadorList: null,
  },
  mediaError: null,
  nextChallenge: null,
  challengeDone: null,
  challengeDoneError: null,
  isChallengePlayWithFakeUser: null,
  homepageFilter: null,
  homepagePagination: {
    startSlice: null,
    endSlice: null,
  }
});

export const mutationsFunctions = {
  SET_HOMEPAGE_FILTER: (state, payload) => {
    state.homepageFilter = payload;
    console.debug('SET_HOMEPAGE_FILTER', state.homepageFilter);
  },
  SET_HOMEPAGE_PAGINATION: (state, payload) => {
    state.homepagePagination = payload;
    console.debug('SET_HOMEPAGE_PAGINATION', state.homepagePagination);
  },
  SET_FAQ_DATA: (state, payload) => {
    state.faq = payload;
    console.debug('SET_FAQ_DATA', state.faq);
  },
  SET_CLASSES_DATA: (state, payload) => {
    state.classes = payload;
    console.debug('SET_CLASSES_DATA', state.classes);
  },
  SET_ARCHIVE_DATA: (state, payload) => {
    state.archive = payload;
    console.debug('SET_ARCHIVE_DATA', state.archive);
  },
  SET_LESSON_COMPLETION_STATUS: (state, payload) => {
    const { value, classIndex, lessonIndex, isArchived } = payload;
    const collectionToUpdate = isArchived ? 'archive' : 'classes';

    state[collectionToUpdate][classIndex].lessons[lessonIndex].isCompleted =
      value;
    console.debug(
      'SET_LESSON_COMPLETION_STATUS',
      state[collectionToUpdate][classIndex].lessons[lessonIndex].isCompleted
    );
  },
  SET_NEXT_CLASS_AVAILABILITY_DATE: (state, payload) => {
    state.nextClassAvailability = payload && new Date(payload);
    console.debug(
      'SET_NEXT_CLASS_AVAILABILITY_DATE',
      state.nextClassAvailability
    );
  },
  SET_AMBASSADOR_LIST: (state, payload) => {
    const { ambassadors, groupId } = payload
    state.ilumaJourney.ambassadorList = !ambassadors
      ? null
      : ambassadors.map(ambassador => ({
          id: ambassador.id,
          title: ambassador.title,
          name: ambassador.name,
          groupId,
          image: normaliseCmsImage({
            image: ambassador.image,
            alt: ambassador.title,
          }),
        })
      );

    console.debug('SET_AMBASSADOR_LIST', state.ilumaJourney.ambassadorList);
  },
  SET_AMBASSADOR: (state, payload) => {
    state.ilumaJourney.ambassador = payload
    console.debug('SET_AMBASSADOR', state.ilumaJourney.ambassador);
  },
  SET_NEXT_CHALLENGE: (state, payload) => {
    state.nextChallenge = payload;
    console.debug('SET_NEXT_CHALLENGE', state.nextChallenge);
  },
  SET_MEDIA_ERROR: (state, error) => {
    state.mediaError = error;
    console.debug('SET_MEDIA_ERROR', state.mediaError);
  },
  SET_CHALLENGE_DONE: (state, payload) => {
    state.challengeDone = payload;
    console.debug('SET_CHALLENGE_DONE', state.challengeDone);
  },
  SET_CHALLENGE_DONE_ERROR: (state, error) => {
    state.challengeDoneError = error;
    console.debug('SET_CHALLENGE_DONE_ERROR', state.challengeDoneError);
  },
  RESET_CHALLENGE_DONE: (state) => {
    state.challengeDone = null;
    console.debug('SET_CHALLENGE_DONE', state.challengeDone);
  },
  RESET_CHALLENGE_DONE_ERROR: (state) => {
    state.challengeDoneError = null;
    console.debug('SET_CHALLENGE_DONE_ERROR', state.challengeDoneError);
  },
  SET_IS_CHALLENGE_PLAY_WITH_FAKE_USER: (state, payload) => {
    state.isChallengePlayWithFakeUser = payload;
    console.debug('SET_IS_CHALLENGE_PLAY_WITH_FAKE_USER', state.isChallengePlayWithFakeUser);
  },
};

export const mutations: { [key: string]: string } = Object
  .keys(mutationsFunctions)
  .reduce((acc, keyName) => ({ ...acc, [keyName]: keyName }), {});

const academyModule = {
  namespaced: true,
  state: initialState(),
  mutations: mutationsFunctions,
  actions: { ...actions, ...asyncActions },
};

export default academyModule;
