import { authApiPost } from '@/api/api';
import { TrackingAction } from '../types';
import { PostConsumedContentResponse } from '../store/dtos';

type GetPostTrackingUrl = (actionId: TrackingAction) => string;
const getPostTrackingUrl: GetPostTrackingUrl = (actionId) =>
  `action/${actionId}`;

export type PostTrackingActionType = (
  trackingAction: TrackingAction
) => Promise<PostConsumedContentResponse>;
// @ts-expect-error we are not going to type authApiPost
export const postTrackingAction: PostTrackingActionType = (trackingAction) =>
  authApiPost(getPostTrackingUrl(trackingAction));
