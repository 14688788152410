
import { defineComponent } from 'vue';
import Icon from '@/components/Icon/Icon.vue';

const EVENT_CLOSE_MODAL = 'close';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: [EVENT_CLOSE_MODAL],
  setup(props, { emit }) {
    const handleClose = () => {
      emit(EVENT_CLOSE_MODAL);
    };
    return {
      handleClose,
    }
  },
});
