import mutations from './mutations';
import actions from './actions';
import asyncActions from './asyncActions';
import { SpringRaffleState } from '../types';

export const initialState = (): SpringRaffleState => ({
  hasRedeemedPoints: false,
});

export const getters = {};

const storiesModule = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: { ...actions, ...asyncActions },
};

export default storiesModule;
