import { type GetApplicantListDTO } from '../../types';

const GetApplicantsResponse: GetApplicantListDTO = {
  cache: {
    created: '2024-05-03 17:48:46',
  },
  results: [
    {
      name: 'Nazanin Pohlschmidt',
      applicant_id: '6617d057c04a58009e8132d4',
      life_story:
        'Hi, ich bin 44 wohne in Frankfurt und bin Mutter von zwei S\u00f6hnen. Ich arbeite mit Gefl\u00fcchtete und Wohnungslose und m\u00f6chte mich bald umorientieren.',
      artist_team: 'kool_savas',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6617d057c04a58009e8132d4_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6617d057c04a58009e8132d4_vid.mp4',
    },
    {
      name: 'Nina Steiner',
      applicant_id: '6618d4024f68597d2b812232',
      life_story: 'None',
      artist_team: 'bluemchen',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6618d4024f68597d2b812232_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6618d4024f68597d2b812232_vid.mp4',
    },
    {
      name: 'Peter Stahl',
      applicant_id: '661a8d058734f020923b44ec',
      life_story: 'ich bin peter, 47 spiele gern boule und koche gern. ',
      artist_team: 'kool_savas',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661a8d058734f020923b44ec_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661a8d058734f020923b44ec_vid.mp4',
    },
    {
      name: 'Belarmino Pinto Ferreira',
      applicant_id: '661d257881335a7c333b704a',
      life_story:
        'Ich bin Belarmino und bin schon seid l\u00e4ngerem bei iqos und bin damit voll zufrieden und es w\u00e4re ja mega cool \ud83d\ude0e mal mit Bl\u00fcmchen zusammen zu treffen\ud83e\udd70',
      artist_team: 'bluemchen',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661d257881335a7c333b704a_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661d257881335a7c333b704a_vid.mp4',
    },
    {
      name: 'Christoph Knapp',
      applicant_id: '661e8c2481335a7c333b9351',
      life_story:
        'Hey! Chris hier, 27 & gute Laune B\u00e4r, durfte letztes Jahr an der One Million  Kampagne dabei sein & w\u00e4r\u2019s gern wieder! W\u00fcrde mich freuen! #HerzanHerz',
      artist_team: 'bluemchen',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661e8c2481335a7c333b9351_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661e8c2481335a7c333b9351_vid.mp4',
    },
    {
      name: 'Yvonne Vogel-schienbein',
      applicant_id: '661f9aec81335a7c333baed0',
      life_story:
        'Ich bin Yvonne, 39 und kenne Bl\u00fcmchen seit meiner Jugend. Da ich seit 3 Jahren auch ein Fan von IQOS bin, steht es f\u00fcr mich au\u00dfer Frage, beides zu ko!',
      artist_team: 'bluemchen',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661f9aec81335a7c333baed0_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661f9aec81335a7c333baed0_vid.mp4',
    },
    {
      name: 'Dorothee Zurek',
      applicant_id: '661fc8878734f020923bf258',
      life_story: 'None',
      artist_team: 'bluemchen',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fc8878734f020923bf258_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fc8878734f020923bf258_vid.mp4',
    },
    {
      name: 'Daha Mohamud Said',
      applicant_id: '661fdb8f8734f020923c0f0a',
      life_story:
        'Ich bin Daha. Meine Begeisterung f\u00fcr Musik begann schon sehr fr\u00fch und nun ist Singen zu meiner gro\u00dfen Leidenschaft geworden. Mich bewegt die Freiheit.',
      artist_team: 'michael_schulte',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fdb8f8734f020923c0f0a_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fdb8f8734f020923c0f0a_vid.mp4',
    },
    {
      name: 'Heike Kathrin Hahn',
      applicant_id: '661fec2c5ce40a9a043b6b19',
      life_story:
        'Ich bin Heike und 57 Jahre alt. Mir gef\u00e4llt die Musik von Michael Schulte',
      artist_team: 'michael_schulte',
      photo_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fec2c5ce40a9a043b6b19_img.jpg',
      video_url:
        'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/661fec2c5ce40a9a043b6b19_vid.mp4',
    },
    // {
    //   name: 'Holger Liekefett',
    //   applicant_id: '662002578734f020923c6360',
    //   life_story:
    //     'Holger, Papa (3 Kinder), liebe meine Family, das Leben und einfach auch mal verr\u00fcckte Dinge zu machen. Spiele ein paar Instrumente und singe gerne',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662002578734f020923c6360_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662002578734f020923c6360_vid.mp4',
    // },
    // {
    //   name: 'Pascal Goelde',
    //   applicant_id: '662006b58734f020923c6a91',
    //   life_story:
    //     'Hallo mein Name ist Pascal und bin 24 Jahre alt . Ich liebe die Musik von Bl\u00fcmchen und w\u00fcrde mich extrem freuen wenn es ch sie h\u00f6ren k\u00f6nnte . ',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662006b58734f020923c6a91_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662006b58734f020923c6a91_vid.mp4',
    // },
    // {
    //   name: 'Ingo Ganz',
    //   applicant_id: '6620183781335a7c333c307a',
    //   life_story:
    //     'Hallo, ich bin Ingo und mich bewegt die faszinierende Welt von IQOS und die vielen Aktivit\u00e4ten ',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620183781335a7c333c307a_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620183781335a7c333c307a_vid.mp4',
    // },
    // {
    //   name: 'Max Eckardt',
    //   applicant_id: '6620292b81335a7c333c3c30',
    //   life_story:
    //     'Schon seit meinen Schritt ins Erwachsenenleben h\u00f6re ich HipHop und bis jetzt \u00fcbe ich mein Hobby nur unter der Dusche und beim Karaoke aus..',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620292b81335a7c333c3c30_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620292b81335a7c333c3c30_vid.mp4',
    // },
    // {
    //   name: 'Marvin Gra\u00df',
    //   applicant_id: '6620e4ce81335a7c333c7054',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620e4ce81335a7c333c7054_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620e4ce81335a7c333c7054_vid.mp4',
    // },
    // {
    //   name: 'Daniela Kasten',
    //   applicant_id: '6620f3b58734f020923cf70e',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620f3b58734f020923cf70e_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6620f3b58734f020923cf70e_vid.mp4',
    // },
    // {
    //   name: 'Halil Er',
    //   applicant_id: '662116825ce40a9a043ba54e',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662116825ce40a9a043ba54e_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662116825ce40a9a043ba54e_vid.mp4',
    // },
    // {
    //   name: 'Leonard Bendner',
    //   applicant_id: '66211aaf8734f020923d177f',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66211aaf8734f020923d177f_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66211aaf8734f020923d177f_vid.mp4',
    // },
    // {
    //   name: 'Marco Loeffel',
    //   applicant_id: '662156578734f020923d34fc',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662156578734f020923d34fc_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662156578734f020923d34fc_vid.mp4',
    // },
    // {
    //   name: 'Katharina Sieg',
    //   applicant_id: '6621584681335a7c333cb9d5',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6621584681335a7c333cb9d5_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6621584681335a7c333cb9d5_vid.mp4',
    // },
    // {
    //   name: 'Sylke Gandzior',
    //   applicant_id: '66215bc581335a7c333cbb18',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66215bc581335a7c333cbb18_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66215bc581335a7c333cbb18_vid.mp4',
    // },
    // {
    //   name: 'Olaf Matuszewski',
    //   applicant_id: '66216a4a8734f020923d405e',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66216a4a8734f020923d405e_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66216a4a8734f020923d405e_vid.mp4',
    // },
    // {
    //   name: 'Michelle Reinsdorf',
    //   applicant_id: '6621779381335a7c333cc5ac',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6621779381335a7c333cc5ac_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6621779381335a7c333cc5ac_vid.mp4',
    // },
    // {
    //   name: 'Marvin Knaak',
    //   applicant_id: '662181a08734f020923d48a9',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662181a08734f020923d48a9_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662181a08734f020923d48a9_vid.mp4',
    // },
    // {
    //   name: 'Stefanie Deisler',
    //   applicant_id: '662182f781335a7c333cc85a',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662182f781335a7c333cc85a_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662182f781335a7c333cc85a_vid.mp4',
    // },
    // {
    //   name: 'Silke Eppmann',
    //   applicant_id: '662183f781335a7c333cc89a',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662183f781335a7c333cc89a_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662183f781335a7c333cc89a_vid.mp4',
    // },
    // {
    //   name: 'Kai-michael H\u00f6now',
    //   applicant_id: '66228cf68734f020923d84bc',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66228cf68734f020923d84bc_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66228cf68734f020923d84bc_vid.mp4',
    // },
    // {
    //   name: 'Marcel Herz',
    //   applicant_id: '66229f1b317a8af12a3b1bff',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66229f1b317a8af12a3b1bff_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66229f1b317a8af12a3b1bff_vid.mp4',
    // },
    // {
    //   name: 'Baris Savas',
    //   applicant_id: '6622a0e18734f020923d8f6d',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6622a0e18734f020923d8f6d_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6622a0e18734f020923d8f6d_vid.mp4',
    // },
    // {
    //   name: 'Max Brockmeyer K\u00fcppers',
    //   applicant_id: '66239db381335a7c333d2b88',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66239db381335a7c333d2b88_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66239db381335a7c333d2b88_vid.mp4',
    // },
    // {
    //   name: 'Nadege Nana Fofana',
    //   applicant_id: '66239e1981335a7c333d2bbd',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66239e1981335a7c333d2bbd_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66239e1981335a7c333d2bbd_vid.mp4',
    // },
    // {
    //   name: 'Joshua Afsar',
    //   applicant_id: '6623a2075ce40a9a043bdb30',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623a2075ce40a9a043bdb30_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623a2075ce40a9a043bdb30_vid.mp4',
    // },
    // {
    //   name: 'Merih Weber',
    //   applicant_id: '6623a6345ce40a9a043bdb93',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623a6345ce40a9a043bdb93_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623a6345ce40a9a043bdb93_vid.mp4',
    // },
    // {
    //   name: 'Rubina Wilkens',
    //   applicant_id: '6623a86281335a7c333d2ec0',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623a86281335a7c333d2ec0_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623a86281335a7c333d2ec0_vid.mp4',
    // },
    // {
    //   name: 'Bahar Beyza Bali',
    //   applicant_id: '6623b0488734f020923dcfac',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623b0488734f020923dcfac_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623b0488734f020923dcfac_vid.mp4',
    // },
    // {
    //   name: 'Seif Sabra',
    //   applicant_id: '6623bedf8734f020923dd4f4',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623bedf8734f020923dd4f4_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623bedf8734f020923dd4f4_vid.mp4',
    // },
    // {
    //   name: 'sendy brems',
    //   applicant_id: '6623c14481335a7c333d35e7',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623c14481335a7c333d35e7_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623c14481335a7c333d35e7_vid.mp4',
    // },
    // {
    //   name: 'Monika Ziarnik',
    //   applicant_id: '6623d7088734f020923ddc8f',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623d7088734f020923ddc8f_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623d7088734f020923ddc8f_vid.mp4',
    // },
    // {
    //   name: 'Sophia Calis',
    //   applicant_id: '6623e11e81335a7c333d3d19',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623e11e81335a7c333d3d19_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623e11e81335a7c333d3d19_vid.mp4',
    // },
    // {
    //   name: 'Katharina Schiller',
    //   applicant_id: '6623f54381335a7c333d413c',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623f54381335a7c333d413c_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623f54381335a7c333d413c_vid.mp4',
    // },
    // {
    //   name: 'Rene Schiller',
    //   applicant_id: '6623f5a281335a7c333d4152',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623f5a281335a7c333d4152_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623f5a281335a7c333d4152_vid.mp4',
    // },
    // {
    //   name: 'Roberto Hesse',
    //   applicant_id: '6623f6a70a5f22d1263b2037',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623f6a70a5f22d1263b2037_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6623f6a70a5f22d1263b2037_vid.mp4',
    // },
    // {
    //   name: 'Florian Ziegert',
    //   applicant_id: '6624018e8734f020923de72a',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624018e8734f020923de72a_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624018e8734f020923de72a_vid.mp4',
    // },
    // {
    //   name: 'Lier Helen',
    //   applicant_id: '662401d98734f020923de745',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662401d98734f020923de745_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662401d98734f020923de745_vid.mp4',
    // },
    // {
    //   name: 'Jan Rehder',
    //   applicant_id: '662402328734f020923de75d',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662402328734f020923de75d_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662402328734f020923de75d_vid.mp4',
    // },
    // {
    //   name: 'Miguel Pohlandt',
    //   applicant_id: '6624043a8734f020923de7ca',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624043a8734f020923de7ca_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624043a8734f020923de7ca_vid.mp4',
    // },
    // {
    //   name: 'Melanie Britzke',
    //   applicant_id: '66242e9181335a7c333d4cfa',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66242e9181335a7c333d4cfa_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66242e9181335a7c333d4cfa_vid.mp4',
    // },
    // {
    //   name: 'Petra Barrow',
    //   applicant_id: '66242f048734f020923df3b2',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66242f048734f020923df3b2_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66242f048734f020923df3b2_vid.mp4',
    // },
    // {
    //   name: 'Andr\u00e9 Goskowitz',
    //   applicant_id: '662430a95ce40a9a043be756',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662430a95ce40a9a043be756_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662430a95ce40a9a043be756_vid.mp4',
    // },
    // {
    //   name: 'Viktor Konrad',
    //   applicant_id: '6624cce68734f020923e0141',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624cce68734f020923e0141_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624cce68734f020923e0141_vid.mp4',
    // },
    // {
    //   name: 'Dennis Christ',
    //   applicant_id: '6624d12d8734f020923e0295',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624d12d8734f020923e0295_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6624d12d8734f020923e0295_vid.mp4',
    // },
    // {
    //   name: 'Jessica Heinrich',
    //   applicant_id: '66261cdd81335a7c333d80a6',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66261cdd81335a7c333d80a6_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66261cdd81335a7c333d80a6_vid.mp4',
    // },
    // {
    //   name: 'Melina Georgi',
    //   applicant_id: '6627caf081335a7c333db6fb',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627caf081335a7c333db6fb_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627caf081335a7c333db6fb_vid.mp4',
    // },
    // {
    //   name: 'Rosa Steiner',
    //   applicant_id: '6627dc388734f020923e7dc7',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627dc388734f020923e7dc7_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627dc388734f020923e7dc7_vid.mp4',
    // },
    // {
    //   name: 'Enis Pervizaj',
    //   applicant_id: '6627df5e8734f020923e7ec4',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627df5e8734f020923e7ec4_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627df5e8734f020923e7ec4_vid.mp4',
    // },
    // {
    //   name: 'Charleen Dev',
    //   applicant_id: '6627e26081335a7c333dbaa4',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627e26081335a7c333dbaa4_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6627e26081335a7c333dbaa4_vid.mp4',
    // },
    // {
    //   name: 'Lisa Gei\u00dfler',
    //   applicant_id: '6628b8ea8734f020923e9b06',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6628b8ea8734f020923e9b06_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6628b8ea8734f020923e9b06_vid.mp4',
    // },
    // {
    //   name: 'Justus Schr\u00f6pfer',
    //   applicant_id: '66291b5681335a7c333ddfe1',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66291b5681335a7c333ddfe1_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66291b5681335a7c333ddfe1_vid.mp4',
    // },
    // {
    //   name: 'Andreas Sommer',
    //   applicant_id: '66292828b913b947523b9a2d',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66292828b913b947523b9a2d_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66292828b913b947523b9a2d_vid.mp4',
    // },
    // {
    //   name: 'Sina Kriedel',
    //   applicant_id: '6629287c81335a7c333de47c',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6629287c81335a7c333de47c_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6629287c81335a7c333de47c_vid.mp4',
    // },
    // {
    //   name: 'Stella Marie Eichel',
    //   applicant_id: '662929b88734f020923eb5b1',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662929b88734f020923eb5b1_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662929b88734f020923eb5b1_vid.mp4',
    // },
    // {
    //   name: 'Farris K\u00f6tter',
    //   applicant_id: '6629359281335a7c333de7dc',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6629359281335a7c333de7dc_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6629359281335a7c333de7dc_vid.mp4',
    // },
    // {
    //   name: 'Charlotte Herhold',
    //   applicant_id: '662a57ae8734f020923efe97',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662a57ae8734f020923efe97_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662a57ae8734f020923efe97_vid.mp4',
    // },
    // {
    //   name: 'Antonia Elisa Bier\u00e4ugel',
    //   applicant_id: '662a8e882bae9216eb6981a3',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662a8e882bae9216eb6981a3_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662a8e882bae9216eb6981a3_vid.mp4',
    // },
    // {
    //   name: 'Maximilian Steinbach',
    //   applicant_id: '662b7c626fda3316af69914e',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662b7c626fda3316af69914e_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662b7c626fda3316af69914e_vid.mp4',
    // },
    // {
    //   name: 'Maxima M\u00fcller',
    //   applicant_id: '662b944e6fda3316af699595',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662b944e6fda3316af699595_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662b944e6fda3316af699595_vid.mp4',
    // },
    // {
    //   name: 'Giuliano Petronio',
    //   applicant_id: '662b9543d68fb8d6e4697a81',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662b9543d68fb8d6e4697a81_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662b9543d68fb8d6e4697a81_vid.mp4',
    // },
    // {
    //   name: 'Lilly Herta Luise Regel',
    //   applicant_id: '662ba6f32bae9216eb69c4ca',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662ba6f32bae9216eb69c4ca_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662ba6f32bae9216eb69c4ca_vid.mp4',
    // },
    // {
    //   name: 'Valerija Redel',
    //   applicant_id: '662ba87f2bae9216eb69c585',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662ba87f2bae9216eb69c585_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662ba87f2bae9216eb69c585_vid.mp4',
    // },
    // {
    //   name: 'Stefanie Gelpke',
    //   applicant_id: '662bb83c6fda3316af699ba1',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662bb83c6fda3316af699ba1_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662bb83c6fda3316af699ba1_vid.mp4',
    // },
    // {
    //   name: 'Anna Sapozhnyk',
    //   applicant_id: '662bc5fb2bae9216eb69cf70',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662bc5fb2bae9216eb69cf70_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662bc5fb2bae9216eb69cf70_vid.mp4',
    // },
    // {
    //   name: 'Marc Steenfatt',
    //   applicant_id: '662bcfdb2bae9216eb69d371',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662bcfdb2bae9216eb69d371_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662bcfdb2bae9216eb69d371_vid.mp4',
    // },
    // {
    //   name: 'Marc Bohnes',
    //   applicant_id: '662be28a6fda3316af69a30c',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662be28a6fda3316af69a30c_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662be28a6fda3316af69a30c_vid.mp4',
    // },
    // {
    //   name: 'Johanna Kalos',
    //   applicant_id: '662cde782bae9216eb6a0650',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662cde782bae9216eb6a0650_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662cde782bae9216eb6a0650_vid.mp4',
    // },
    // {
    //   name: 'Aurelie Helque',
    //   applicant_id: '662cdf142bae9216eb6a0673',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662cdf142bae9216eb6a0673_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662cdf142bae9216eb6a0673_vid.mp4',
    // },
    // {
    //   name: 'Malte Gerkens',
    //   applicant_id: '662cee486fda3316af69b8da',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662cee486fda3316af69b8da_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662cee486fda3316af69b8da_vid.mp4',
    // },
    // {
    //   name: 'Elisa Schuhmacher',
    //   applicant_id: '662d0a156fda3316af69bbc3',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d0a156fda3316af69bbc3_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d0a156fda3316af69bbc3_vid.mp4',
    // },
    // {
    //   name: 'Judith Sedlak',
    //   applicant_id: '662d27c16fda3316af69be6b',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d27c16fda3316af69be6b_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d27c16fda3316af69be6b_vid.mp4',
    // },
    // {
    //   name: 'Markus Lorenz',
    //   applicant_id: '662d29682bae9216eb6a168d',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d29682bae9216eb6a168d_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d29682bae9216eb6a168d_vid.mp4',
    // },
    // {
    //   name: 'Dirk Benner',
    //   applicant_id: '662d2b9cd68fb8d6e4697f53',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d2b9cd68fb8d6e4697f53_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d2b9cd68fb8d6e4697f53_vid.mp4',
    // },
    // {
    //   name: 'Dejan Grimm',
    //   applicant_id: '662d2c5b2bae9216eb6a177d',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d2c5b2bae9216eb6a177d_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d2c5b2bae9216eb6a177d_vid.mp4',
    // },
    // {
    //   name: 'Jannis Wettlaufer',
    //   applicant_id: '662d39ab6fda3316af69c0ad',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d39ab6fda3316af69c0ad_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d39ab6fda3316af69c0ad_vid.mp4',
    // },
    // {
    //   name: 'Nicole Nitschke',
    //   applicant_id: '662d48296fda3316af69c2fa',
    //   life_story: 'None',
    //   artist_team: 'michael_schulte',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d48296fda3316af69c2fa_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d48296fda3316af69c2fa_vid.mp4',
    // },
    // {
    //   name: 'Sebastian Khaukha',
    //   applicant_id: '662d482d2bae9216eb6a1ff3',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d482d2bae9216eb6a1ff3_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d482d2bae9216eb6a1ff3_vid.mp4',
    // },
    // {
    //   name: 'Tobias Funke',
    //   applicant_id: '662d616b6fda3316af69c4ab',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d616b6fda3316af69c4ab_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662d616b6fda3316af69c4ab_vid.mp4',
    // },
    // {
    //   name: 'zeynep kuru',
    //   applicant_id: '662f4d412bae9216eb6a7269',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662f4d412bae9216eb6a7269_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662f4d412bae9216eb6a7269_vid.mp4',
    // },
    // {
    //   name: 'Kimberly Preteni',
    //   applicant_id: '662f9f8e2bae9216eb6a881d',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662f9f8e2bae9216eb6a881d_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662f9f8e2bae9216eb6a881d_vid.mp4',
    // },
    // {
    //   name: 'Pia Faasch',
    //   applicant_id: '662f9f956fda3316af69f153',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662f9f956fda3316af69f153_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662f9f956fda3316af69f153_vid.mp4',
    // },
    // {
    //   name: 'Elias Fabry',
    //   applicant_id: '662fa1286fda3316af69f17a',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fa1286fda3316af69f17a_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fa1286fda3316af69f17a_vid.mp4',
    // },
    // {
    //   name: 'Timohee Germes',
    //   applicant_id: '662fa9e52bae9216eb6a8ba4',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fa9e52bae9216eb6a8ba4_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fa9e52bae9216eb6a8ba4_vid.mp4',
    // },
    // {
    //   name: 'Battal Yali',
    //   applicant_id: '662fba102bae9216eb6a9052',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fba102bae9216eb6a9052_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fba102bae9216eb6a9052_vid.mp4',
    // },
    // {
    //   name: 'Alen Mehic',
    //   applicant_id: '662fbf8cdf35c0d388698bfd',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fbf8cdf35c0d388698bfd_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/662fbf8cdf35c0d388698bfd_vid.mp4',
    // },
    // {
    //   name: 'Marsel Saliovski',
    //   applicant_id: '6630d801f1aa70f0edb784ed',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6630d801f1aa70f0edb784ed_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6630d801f1aa70f0edb784ed_vid.mp4',
    // },
    // {
    //   name: 'Lucas Lomnicki',
    //   applicant_id: '66339fc1bff953a86953c27e',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66339fc1bff953a86953c27e_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/66339fc1bff953a86953c27e_vid.mp4',
    // },
    // {
    //   name: 'Tatjana Kusig',
    //   applicant_id: '6633bd6267f89d45689f5cfb',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6633bd6267f89d45689f5cfb_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6633bd6267f89d45689f5cfb_vid.mp4',
    // },
    // {
    //   name: 'Lutz Feldmann',
    //   applicant_id: '6633e06a67f89d45689f663f',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6633e06a67f89d45689f663f_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6633e06a67f89d45689f663f_vid.mp4',
    // },
    // {
    //   name: 'John Carlsson',
    //   applicant_id: '6634bfbf3dc382d40e9f826a',
    //   life_story: 'None',
    //   artist_team: 'kool_savas',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6634bfbf3dc382d40e9f826a_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6634bfbf3dc382d40e9f826a_vid.mp4',
    // },
    // {
    //   name: 'Yves Gattung',
    //   applicant_id: '6634d99d67f89d45689f84f8',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6634d99d67f89d45689f84f8_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6634d99d67f89d45689f84f8_vid.mp4',
    // },
    // {
    //   name: 'Sofia Belostotskaja',
    //   applicant_id: '6634f18167f89d45689f88cd',
    //   life_story: 'None',
    //   artist_team: 'bluemchen',
    //   photo_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6634f18167f89d45689f88cd_img.jpg',
    //   video_url:
    //     'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/togetherx/6634f18167f89d45689f88cd_vid.mp4',
    // },
  ],
  status: 200,
};

export default GetApplicantsResponse;
