import { Router } from 'vue-router';
import { Store } from 'vuex';

import initialiseAcademyRouter from './router';
import academyStore from './store/store';

function academyModule({
    store,
    router,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store: Store<any>;
    router: Router;
}): void {
    store.registerModule('academyModule', academyStore);
    initialiseAcademyRouter(router);
}

export default academyModule;
