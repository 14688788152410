
import throttle from 'lodash/throttle';
import { defineComponent, Ref, ref, computed, type PropType } from 'vue';
import { useNavState, globalNavState } from './useNavState';

import BurgerMenuIcon from '@/components/NavHeader/components/BurgerMenuIcon.vue';
import NavDesktop from '@/components/NavHeader/components/NavDesktop/NavDesktop.vue';
import NavMobile from '@/components/NavHeader/components/NavMobile/NavMobile.vue';
import Icon from '@/components/Icon/Icon.vue';

const themeClasses = {
  dark: {
    bgColor: 'bg-primary-soft-white',
    textColor: 'text-primary-slate',
    borderColor: 'lg:border-grey-400',
  },
  light: {
    bgColor: 'bg-primary-slate',
    textColor: 'text-primary-soft-white',
    borderColor: 'lg:border-primary-slate',
  },
};

export default defineComponent({
  components: {
    NavDesktop,
    NavMobile,
    BurgerMenuIcon,
    Icon,
  },
  props: {
    logoutAction: {
      type: Function,
      required: true,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'light',
    },
  },
  setup(props) {
    const { toggleMobileNav } = useNavState();

    const isHeaderShown = ref(true);
    const oldScroll: Ref<number | null> = ref(window.pageYOffset);
    const newScroll: Ref<number | null> = ref(null);

    const areSubmenusOpened = computed(
      () =>
        globalNavState.isFirstLevelVisible ||
        globalNavState.isSecondLevelVisible
    );

    const handleAnimation = throttle((): void => {
      const INITIAL_OFFSET = 70;
      oldScroll.value = newScroll.value;
      newScroll.value = window.pageYOffset;

      const isScrollSmallerThanOffset = newScroll.value < INITIAL_OFFSET;
      const isScrollingUp = newScroll.value - oldScroll.value <= 0;

      isHeaderShown.value =
        areSubmenusOpened.value || isScrollingUp || isScrollSmallerThanOffset;
    }, 150);

    document.removeEventListener('scroll', handleAnimation);
    document.addEventListener('scroll', handleAnimation);

    const navLinks = {
      iqosHome: 'https://www.iqos.com/de/de/home.html',
      findStore: 'https://www.iqos.com/de/de/store-finder.html',
      shoppingBag: 'https://www.iqos.com/de/de/shopping-bag.html',
      account: 'https://www.iqos.com/de/de/mein-profil.html',
      wichtigeInfo:
        'https://www.iqos.com/de/de/tabakerhitzer-entdecken/wichtige-informationen.html',
    };

    const currentTheme = computed(() => themeClasses[props.theme]);

    return {
      areSubmenusOpened,
      currentTheme,
      globalNavState,
      isHeaderShown,
      navLinks,
      toggleMobileNav,
      newScroll,
    };
  },
});
