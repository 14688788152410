const jsonResponse = {
  status: '200',
  next_class_availability: '2021-08-02T15:30:00.061000+02:00',
  classes: [
    {
      id: 6,
      meta: {
        type: 'academy.ClassPage',
        detail_url: 'https:///api/v2/drafts/6/',
        html_url: 'http://localhost/academy/keine-asche/',
        slug: 'keine-asche',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2021-07-28T14:54:26.767022+02:00',
        locale: 'en',
      },
      title: 'Keine Asche',
      class_id: 2,
      category: 'Challenge 2',
      lessons_list: [
        {
          type: 'lessons',
          value: {
            name: 'Keine Asche',
            summary:
              'Wo nichts verbrannt wird, entsteht auch keine lästige Asche. Wo nichts verbrannt wird, entsteht auch keine lästige Asche.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/dce-qa-copy-from-challenge-2',
            leadfamly_game_id: '63776',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '',
            completed_in: '2021-07-07T06:19:42.074000+02:00',
          },
          id: 'dafb7e10-f59f-465d-b1b8-bc96bdff098c',
        },
        {
          type: 'lessons',
          value: {
            name: 'Eine Alternative zur Zigarette.',
            summary:
              'Wo nichts verbrannt wird, entsteht auch keine lästige Asche. Wo nichts verbrannt wird, entsteht auch keine lästige Asche.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/dce-qa-copy-from-challenge-2',
            leadfamly_game_id: '63776',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '',
            completed_in: null,
          },
          id: '2946f7dc-d713-4604-a810-f6316379cca9',
        },
        {
          type: 'lessons',
          value: {
            name: 'Keine Asche',
            summary:
              'Wo nichts verbrannt wird, entsteht auch keine lästige Asche.Wo nichts verbrannt wird, entsteht auch keine lästige Asche.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/dce-qa-copy-from-challenge-2',
            leadfamly_game_id: '63776',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '',
            completed_in: null,
          },
          id: '40da9086-a613-4a5c-9b05-4c33014a125b',
        },
      ],
      main_image: {
        id: 5,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Challenge4.original.png',
        title: 'Challenge4.original.png',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original.2e16d0ba.fill-1440x772.png',
          width: 1427,
          height: 766,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original.2e16d0ba.fill-2880x1544.png',
          width: 1427,
          height: 766,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original.2e16d0ba.fill-4320x2316.png',
          width: 1427,
          height: 766,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original.2e16d0ba.fill-1245x1125.png',
          width: 1057,
          height: 954,
        },
      },
      description: 'Erlebe was IQOS besser macht.',
      points_awarded: '30',
      waiting_time: '0',
      go_live_at: null,
      expire_at: null,
      completed_in: null,
    },
    {
      id: 7,
      meta: {
        type: 'academy.ClassPage',
        detail_url: 'https:///api/v2/drafts/7/',
        html_url: 'http://localhost/academy/eine-frage-des-guten-geschmacks/',
        slug: 'eine-frage-des-guten-geschmacks',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2021-07-28T14:55:34.867102+02:00',
        locale: 'en',
      },
      title: 'Eine Frage des guten Geschmacks.',
      class_id: 3,
      category: 'Challenge 3',
      lessons_list: [
        {
          type: 'lessons',
          value: {
            name: 'Eine Frage des guten Geschmacks.',
            summary: 'Entdecke Tabak in seiner vollen Vielfalt.',
            duration_in_minutes: '20',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/testing-game-copy-from-challenge-3',
            leadfamly_game_id: '63343',
            key_message: '',
            key_message_image: null,
            failed_lesson_message:
              '<p>Das war leider nicht ganz richtig. Entdecke <a href="https://de.iqos.com/de/tabakerhitzer/heets-tabaksticks">hier</a> unsere verschiedenen Heets Sorten und versuche es gleich noch einmal.</p>',
            completed_in: null,
          },
          id: '351ab85f-83c6-48c1-b4a9-6e4bb8356330',
        },
      ],
      main_image: {
        id: 1,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Challenge1.png',
        title: 'Challenge1.png',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge1.2e16d0ba.fill-1440x772.png',
          width: 900,
          height: 483,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge1.2e16d0ba.fill-2880x1544.png',
          width: 900,
          height: 483,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge1.2e16d0ba.fill-4320x2316.png',
          width: 900,
          height: 483,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge1.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge1.2e16d0ba.fill-830x750.png',
          width: 788,
          height: 711,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge1.2e16d0ba.fill-1245x1125.png',
          width: 788,
          height: 711,
        },
      },
      description: 'Erlebe was IQOS besser macht.',
      points_awarded: '30',
      waiting_time: '0',
      go_live_at: null,
      expire_at: null,
      completed_in: null,
    },
    {
      id: 8,
      meta: {
        type: 'academy.ClassPage',
        detail_url: 'https:///api/v2/drafts/8/',
        html_url: 'http://localhost/academy/temperatur-macht-den-unterschied/',
        slug: 'temperatur-macht-den-unterschied',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2021-07-28T14:56:53.900492+02:00',
        locale: 'en',
      },
      title: 'Temperatur macht den Unterschied.',
      class_id: 4,
      category: 'Challenge 4',
      lessons_list: [
        {
          type: 'lessons',
          value: {
            name: 'Temperatur macht den Unterschied.',
            summary: 'IQOS erhitzt den Tabak konstant auf 350 Grad.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/dce-qa-copy-from-challenge-4',
            leadfamly_game_id: '63778',
            key_message: '',
            key_message_image: null,
            failed_lesson_message: '',
            completed_in: null,
          },
          id: '541604fb-53ed-4658-aad3-948dd53de1d0',
        },
      ],
      main_image: {
        id: 6,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Challenge2.original.png',
        title: 'Challenge2.original.png',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge2.original.2e16d0ba.fill-1440x772.png',
          width: 1000,
          height: 537,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge2.original.2e16d0ba.fill-2880x1544.png',
          width: 1000,
          height: 537,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge2.original.2e16d0ba.fill-4320x2316.png',
          width: 1000,
          height: 537,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge2.original.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge2.original.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge2.original.2e16d0ba.fill-1245x1125.png',
          width: 878,
          height: 793,
        },
      },
      description: 'Erlebe was IQOS besser macht.',
      points_awarded: '10',
      waiting_time: '0',
      go_live_at: null,
      expire_at: null,
      completed_in: null,
    },
    {
      id: 9,
      meta: {
        type: 'academy.ClassPage',
        detail_url: 'https:///api/v2/drafts/9/',
        html_url: 'http://localhost/academy/this-is-a-special-class/',
        slug: 'special-quality-boost',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2021-07-28T14:59:39.884433+02:00',
        locale: 'en',
      },
      title: 'This is a special class: Firmware update',
      class_id: 5,
      category: 'Challenge 5',
      lessons_list: [
        {
          type: 'lessons',
          value: {
            name: 'Eine Frage des guten Geschmacks.',
            summary: 'Entdecke Tabak in seiner vollen Vielfalt.',
            duration_in_minutes: '5',
            leadfamly_game_url:
              'https://pmgacademy.leadfamly.com/testing-game-copy-from-challenge-3',
            leadfamly_game_id: '63343',
            key_message: '',
            key_message_image: null,
            failed_lesson_message:
              '<p>Das war leider nicht ganz richtig. Entdecke <a href="https://de.iqos.com/de/tabakerhitzer/heets-tabaksticks">hier</a> unsere verschiedenen Heets Sorten und versuche es gleich noch einmal.</p>',
            completed_in: null,
          },
          id: 'b2aea785-590a-470e-83ac-525931920fb8',
        },
      ],
      main_image: {
        id: 7,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/rl-heets-header-desktop.png',
        title: 'rl-heets-header-desktop.png',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rl-heets-header-desktop.2e16d0ba.fill-1440x772.png',
          width: 1440,
          height: 772,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rl-heets-header-desktop.2e16d0ba.fill-2880x1544.png',
          width: 1574,
          height: 843,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rl-heets-header-desktop.2e16d0ba.fill-4320x2316.png',
          width: 1574,
          height: 843,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rl-heets-header-desktop.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rl-heets-header-desktop.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rl-heets-header-desktop.2e16d0ba.fill-1245x1125.png',
          width: 934,
          height: 843,
        },
      },
      description: 'Erlebe was IQOS besser macht.',
      points_awarded: '30',
      waiting_time: '0',
      go_live_at: '2021-07-27T14:59:00+02:00',
      expire_at: '2021-10-07T14:59:00+02:00',
      completed_in: null,
    },
  ],
};
export default jsonResponse;
