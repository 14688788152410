
import { defineComponent, PropType, ref } from 'vue';
import { NavBase } from '../NavHeader.types'
import { useNavState } from '../useNavState'

import TSlideInBottom from '@/components/Transitions/TSlideInBottom.vue';
import NavLink from './NavLink.vue';
import NavLinkSmall from './NavLinkSmall.vue';

export default defineComponent({
  components: { TSlideInBottom, NavLink, NavLinkSmall },
  props: {
    menu: {
      type: Object as PropType<NavBase>,
      default: null
    },
  },
  setup() {
    const { getImageUrl, transitionDurationCalc } = useNavState()

    const mouseOverLinkId = ref(null);
    const toggleMouseOver = (id) => mouseOverLinkId.value = id
    const shouldBeGreyHover = (id) => {
      return mouseOverLinkId.value
        && mouseOverLinkId.value !== id
    }
    return {
      getImageUrl,
      transitionDurationCalc,
      toggleMouseOver,
      shouldBeGreyHover
    }
  }
});
