const jsonResponse = `
{
  "status": 200,
  "categories": [
    {
      "name": "Living Delight",
      "description": "„Hier wohnt das Glück! \/n Entdecke must-haves für dein Zuhause“"
    },
    {
      "name": "Trending Design",
      "description": "Description for the Trending Design category"
    },
    {
      "name": "Exploring World",
      "description": "Description for the World category"
    }
  ]
}
`;

export default JSON.parse(jsonResponse);