const { VUE_APP_DCE_LOGIN_URL, VUE_APP_CLUB_LOYALTY_URL } = process.env;

export const DCE_LOGIN_URL = VUE_APP_DCE_LOGIN_URL;
if (!DCE_LOGIN_URL)
  throw new Error(
    'The VUE_APP_DCE_LOGIN_URL constant is not defined in the process.env'
  );

export const CLUB_LOYALTY_URL = VUE_APP_CLUB_LOYALTY_URL;
if (!CLUB_LOYALTY_URL)
  throw new Error(
    'The CLUB_LOYALTY_URL constant is not defined in the process.env'
  );
