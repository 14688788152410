<template>
  <transition
    name="slide-in-bottom"
    appear
  >
    <slot />
  </transition>
</template>
<script>
export default {
  name: 'TSlideInBottom',
};
</script>

<style lang="scss">
.slide-in-bottom {
  &-enter-active,
  &-leave-active {
    transition: all var(--enter-timing) ease-out var(--enter-delay);
  }
  &-leave-active {
    transition: all var(--leave-timing) ease-out var(--leave-delay);
  }
  &-enter-from,
  &-leave-to {
    opacity: var(--opacity);
    transform: translateY(var(--translate));
  }
}
</style>