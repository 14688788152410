
import { defineComponent, PropType } from 'vue';
import { NavFooter } from '../NavHeader.types'
import { useNavState } from '../useNavState'


export default defineComponent({
  props: {
    footer: {
      type: Object as PropType<NavFooter>,
      default: null
    },
  },
  setup() {
    const { getImageUrl } = useNavState()
    return { getImageUrl }
  }
});
