import { Router } from 'vue-router';

export const routeNames = {
  MTN_FINALE: 'mtnfinale',
};

const routes = [
  {
    path: `/${routeNames.MTN_FINALE}`,
    name: routeNames.MTN_FINALE,
    meta: {
      gtagDomain: 'mtnfinal21',
    },
    component: () =>
      import(/* webpackChunkName: "MTN_2" */ './views/MtnFinale.vue'),
  },
];

function initialiseMtnFinaleRouter(router: Router): void {
  routes.forEach(router.addRoute);
}

export default initialiseMtnFinaleRouter;
