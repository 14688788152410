import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';

import { VoucherState, VoucherValidResponse } from './types';
import { redeemVoucher } from '../api/api';
import { FAKE_USER_ERROR_MESSAGE } from '@/constants/messages';
import { ROOT_URL } from '../router';

export const initialState = (): VoucherState => ({
  error: null,
  showForm: true,
  isWorking: false,
  pointsGranted: 0,
  isOnlyStatusPoint: false,
});

const SET_ERROR = 'SET_ERROR';
const SET_SHOW_FORM = 'SET_SHOW_FORM';
const SET_IS_WORKING = 'SET_IS_WORKING';
const SET_POINTS_GRANTED = 'SET_POINTS_GRANTED';
const SET_IS_ONLY_STATUS_POINT = 'SET_IS_ONLY_STATUS_POINT';

const mutations = {
  [SET_SHOW_FORM]: (state: VoucherState, payload: boolean): void => {
    state.showForm = payload;
  },
  [SET_ERROR]: (state: VoucherState, payload: string): void => {
    state.error = payload;
  },
  [SET_IS_WORKING]: (state: VoucherState, payload: boolean): void => {
    state.isWorking = payload;
  },
  [SET_POINTS_GRANTED]: (state: VoucherState, payload: number): void => {
    state.pointsGranted = payload;
  },
  [SET_IS_ONLY_STATUS_POINT]: (state: VoucherState, payload: boolean): void => {
    state.isOnlyStatusPoint = payload;
  },
};

const actions: ActionTree<VoucherState, RootState> = {
  redeemVoucher: ({ dispatch, commit }, { voucher, fallbackError = '' }): Promise<void> => {
    commit(SET_POINTS_GRANTED, 0);
    commit(SET_IS_ONLY_STATUS_POINT, false);
    commit(SET_IS_WORKING, true);
    commit(SET_ERROR, null);
    return dispatch('authModule/refreshHeaders', null, { root: true })
    .then(async (headers) => {
      const { given_points, only_statuspoints } = await redeemVoucher(voucher)(headers) as VoucherValidResponse;
      await dispatch('consumerModule/getConsumer', null, { root: true });
      commit(SET_POINTS_GRANTED, given_points);
      commit(SET_IS_ONLY_STATUS_POINT, only_statuspoints);
      commit(SET_SHOW_FORM, false);
    })
    .catch((error) => {
      console.debug(error.response.data);
      commit(
        SET_ERROR,
        error?.response?.status === 402
          ? FAKE_USER_ERROR_MESSAGE
          : error?.response?.data?.user_error || fallbackError
      );
      if(error?.response?.status === 402)
        commit(
          'PUSH_ERROR',
          {
            message: FAKE_USER_ERROR_MESSAGE,
            title: 'Hinweis',
            buttonText: 'Zurück',
            buttonLink: ROOT_URL,
          },
          { root: true }
        );
      return error.response?.data;
    })
    .finally(() => commit(SET_IS_WORKING, false));
  },
};

export const getters = {
  formError: ({ error }: VoucherState): string => error,
  isLoading: ({ isWorking }: VoucherState): boolean => isWorking,
  shouldShowForm: ({ showForm }: VoucherState): boolean => showForm,
  pointsAwarded: ({ pointsGranted }: VoucherState): number => pointsGranted,
  isOnlyStatusPoint: ({ isOnlyStatusPoint }: VoucherState): boolean => isOnlyStatusPoint,
};

const voucherModule = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

export default voucherModule;
