import { RouteRecordRaw, Router } from 'vue-router';

const DEFAULT_GTAG_DOMAIN = 'advent2023';

export const routeAdventBreadcrumbsMeta = {
  ADVENT_HOME: { name: 'Advent', link: '/adventcalendar2023' },
};
export const routeNames = {
  ADVENT_HOME: 'advent2023-home',
  ADVENT_CLOSING: 'advent2023-closing',
  ADVENT_COUNTDOWN: 'advent2023-countdown',
  ADVENT_MAINTENANCE: 'advent2023-maintenance',
  ADVENT_TERMS: 'advent2023-terms',
};

const checkDateIsBeforeXmas = (): boolean | string => {

  const currentDay = new Date().getTime();
  const startDay = new Date(2023, 11, 1).getTime();
  const endDay = new Date(2023, 11, 25).getTime();

  return currentDay < startDay
    ? '/adventcalendar2023/countdown'
    : endDay > currentDay
    ? true // pass through
    : '/adventcalendar2023/closing';
};

export default initialiseAdventRouter;

const routes: RouteRecordRaw[] = [
  {
    path: '/adventcalendar2023',
    name: routeNames.ADVENT_HOME,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/AdventHome.vue'),
    beforeEnter: [checkDateIsBeforeXmas],
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Advent',
    },
  },
  {
    path: '/adventcalendar2023/closing',
    name: routeNames.ADVENT_CLOSING,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/AdventClosing.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventClosing',
    },
  },
  {
    path: '/adventcalendar2023/maintenance',
    name: routeNames.ADVENT_MAINTENANCE,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/Maintenance.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventMaintenance',
    },
  },
  {
    path: '/adventcalendar2023/countdown',
    name: routeNames.ADVENT_COUNTDOWN,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/Countdown.vue'),
      meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventCountdown',
    },
  },
  {
    path: '/adventcalendar2023/teilnahmebedingungen',
    name: routeNames.ADVENT_TERMS,
    component: () =>
      import(/* webpackChunkName: "Advent" */ './views/Terms.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'AdventTerms',
    },
  },
];

function initialiseAdventRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
