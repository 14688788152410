// mutation types
import { POINTS_REDEEMED } from './mutation-types';

import { redeemPoints } from '../api/api.js';

async function triggerRedeemPoints({ commit, dispatch }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  try {
    const response = await redeemPoints('SPRINGRAFFLE')(headers);
    commit(POINTS_REDEEMED, response);
  } catch {
    commit(
      'PUSH_ERROR',
      {
        message: 'Die 50 Punkte wurden dir schon gut geschrieben.',
        buttonText: 'Okay',
        buttonLink: 'spring-raffle',
      },
      { root: true }
    );
  }
}

export default {
  triggerRedeemPoints,
};
