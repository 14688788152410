import { podcastPostResponse, podcastGetResponse } from './podcast-post-response';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  // mock.onPost('podcasts/').reply(makeResponse('podcasts', podcastPostResponse));
  // mock.onGet(/action\/progress\/PODCAST\d/).reply(makeResponse('podcasts', podcastGetResponse));
  // mock.onPost(/action\/progress\/PODCAST\d/).reply(makeResponse('podcasts', {}));
}
