import { Variant } from '../../types';
import { VariantDTO } from '../dtos';
import ImageNormalizer from './image';

import { getProductDetailUrl } from '@/modules/shop/router';

export default (
  productId: string,
  variant: VariantDTO,
  index: number
): Variant => ({
  color: variant.color_code,
  images: variant.images.map((imageId) => ImageNormalizer(productId, imageId)),
  name: variant.color_name,
  outOfStock: variant.out_of_stock,
  unitsLeft: variant.units_left,
  variantId: variant.variant_id,
  voucherURL: variant.voucher_url,
  voucherValue: variant.voucher_value,
  index,
  variantURL: getProductDetailUrl(productId, variant.variant_id),
});
