import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal"
}
const _hoisted_2 = { class: "modal__dialog relative z-20 flex flex-col justify-between p-xxl-5 md:rounded bg-white w-full h-full md:w-auto md:h-auto max-w-screen max-h-screen" }
const _hoisted_3 = { class: "modal__header" }
const _hoisted_4 = { class: "modal__body" }
const _hoisted_5 = { class: "modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "modal__backdrop fixed inset-0 bg-black bg-opacity-50 z-10",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header"),
                _createElementVNode("button", {
                  id: "DCE_close_modal",
                  class: "absolute right-l top-l",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
                }, [
                  _createVNode(_component_Icon, {
                    type: "close",
                    theme: "dark",
                    class: "h-6 pointer-events-none"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "footer")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}