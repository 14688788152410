export const GetTokyoModuleContent = {
  status: 200,
  items: [
    {
      id: 187,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/187/',
        html_url: 'https://localhost/togx-tokyo-ugc-module/',
        slug: 'togx-tokyo-ugc-module',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-06-14T13:18:39.479200+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'https://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: 'togx-tokyo-ugc-module',
      page_content: [
        {
          type: 'strings',
          value: {
            obj_id: 'heading-text',
            content:
              '<p data-block-key="99qfj">Dein Musik-Moment.<br/>Dein Ticket nach Tokyo.</p>',
          },
          id: '7afeff65-08da-4b7c-a4d8-2d4d4df38551',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'points-given',
            content: '<p data-block-key="uevdi">+ 200 Statuspunkte</p>',
          },
          id: 'b0203e19-28a4-4d5f-ae9d-409ac14ba98b',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-bubble-heading',
            content:
              '<p data-block-key="xdgzj">JETZT<br/>T\u00c4GLICH<br/>MUSIK-MOMENT<br/>TEILEN.</p>',
          },
          id: 'd55d34ca-924d-456b-98e3-f749e92e7abb',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-button-text',
            content: '<p data-block-key="xdgzj">Bild/Video ausw\u00e4hlen</p>',
          },
          id: '4baaf8c1-fdbc-47ce-80e2-4c815767f7bb',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-bubble-text',
            content:
              '<p data-block-key="z1o8s">Je mehr Momente Du teilst, desto h\u00f6her Deine Chance die Reise nach Tokyo zu gewinnen.</p>',
          },
          id: '818d1976-16dc-4d09-af30-68001d7f143d',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-finished-bubble-heading',
            content:
              '<p data-block-key="z1o8s">VIELEN DANK</p><p data-block-key="36rro">F\u00dcR DEINEN</p><p data-block-key="3d1b3">MUSIK-MOMENT.</p>',
          },
          id: 'fc413dc5-6b98-4a9b-9945-16e0e66dbf73',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-finished-bubble-text',
            content:
              '<p data-block-key="z1o8s">Du willst IQOS TogetherXMusic live erleben? Dann komm doch vorbei und erlebe noch mehr Musik-Momente.</p>',
          },
          id: 'c8c308ff-2ecf-4463-85b1-01edcc397a09',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-finished-calendar-link',
            content:
              '<p data-block-key="z1o8s">Zum Event Kalender</p><p data-block-key="6ajto">\u2193</p>',
          },
          id: 'c6f6ab98-601a-4137-8fa0-68ca8cb7d7b0',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-modal-thank-you-heading',
            content:
              '<p data-block-key="z1o8s">Vielen Dank!</p><p data-block-key="3c4ba">Dein Musik-Moment</p><p data-block-key="51fis">ist hochgeladen.</p>',
          },
          id: '412e42ff-6194-4d1d-8f05-83d3b6591cb3',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-modal-datenschutz',
            content:
              '<p data-block-key="z1o8s">Ver\u00f6ffentlichungsfreigabe</p><hr/><p data-block-key="aubvc">https://de.pp.iqosclub.com/document/veroeffentlichungsfreigabe-togetherx-raffle-tokyo</p>',
          },
          id: '950cf4d7-cea1-475b-9ff5-dafbb42e145f',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-modal-teilnahmebedingungen',
            content:
              '<p data-block-key="z1o8s">Teilnahmebedingungen</p><hr/><p data-block-key="bc3i8">https://de.pp.iqosclub.com/teilnahmebedingungen/togetherx-raffle-tokyo</p>',
          },
          id: '118657c3-d4b7-4770-905d-532bbdf227f5',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-modal-button',
            content: '<p data-block-key="z1o8s">Jetzt einreichen</p>',
          },
          id: 'b560ace2-ee51-40e8-bc5b-0c8ec49ad90b',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-modal-choose-another',
            content:
              '<p data-block-key="z1o8s">\u2190 Anderes Bild/Video hochladen</p>',
          },
          id: 'eea9e6a8-c394-4b58-9108-fbca86022fa1',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'upload-modal-sub-note',
            content:
              '<p data-block-key="z1o8s">* Bitte beachte dass es bis zu 72 Stunden dauern kann, ehe Dein Bild freigegeben wird. Bis dahin lade morgen doch gleich noch einen weiteren Musik-Moment hoch.</p>',
          },
          id: '0f3d6aec-1be4-4694-9e0f-be5be575ba2a',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'error-dialog-heading',
            content: '<p data-block-key="z1o8s">UUUUUPS,</p>',
          },
          id: '7ac5771d-1af3-46bf-abfc-c14f0c8c6a17',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'error-dialog-text',
            content:
              '<p data-block-key="z1o8s">da ist leider etwas schief gelaufen. Versuche vielleicht ein kleineres Bild oder Video hochzuladen.</p>',
          },
          id: 'b338cb7c-34f8-4147-b6ef-d7aaa538a6d9',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'error-dialog-button-text',
            content: '<p data-block-key="z1o8s">Bild/Video ausw\u00e4hlen</p>',
          },
          id: 'c3696e15-1cb3-4815-98ed-ac88285bf492',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'features-bubbles',
            content:
              '<p data-block-key="bucff">10 Jahre IQOS Event</p><hr/><p data-block-key="co20u">10 exklusive Tickets</p><hr/><p data-block-key="eu98v">Flug &amp; Hotel inklusive</p><hr/><p data-block-key="7j31o">Tokyo erleben</p>',
          },
          id: 'beaf095f-2d1e-4c58-93e2-fa84beb6e576',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'text-below',
            content:
              '<p data-block-key="2lqoo">Jetzt bis zum 11. August t\u00e4glich Musik-Moment teilen.</p>',
          },
          id: 'e1f96325-7f8f-43ee-ab44-9d29abdd23e1',
        },
      ],
    },
  ],
};
