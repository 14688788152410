import StoriesListResponse from './stories-list';
import { archivedStoriesResponse } from './archive';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  // mock
  //   .onGet(
  //     'https://de-cms.pp.iqosclub.com/api/v2/pages/?type=editorial.ArticlePage&fields=page_content,page_teaser,iqos_points_only,status_points_only,iqos_points,category,category_image,page_cta,publication_date,page_footnote'
  //   )
  //   .reply(
  //     makeResponse(
  //       'pages/?type=editorial.ArticlePage&fields=page_content,page_teaser,iqos_points,category,category_image,page_cta',
  //       StoriesListResponse
  //     )
  //   );
  // mock
  //   .onGet('editorial/pages')
  //   .reply(makeResponse('editorial/pages', archivedStoriesResponse));
  // mock.onPost(/\/editorial\/\d+/).reply(makeResponse('editorial/pages', StoriesListResponse));
  // mock.onPost(/\/editorial\/\d+/).reply(makeResponse('editorial/pages', {
  //   show_to_user: false,
  //   error_type: 'blocked',
  //   status: 402,
  //   error_message: 'lorem ipsum',
  // }, 402));
}
