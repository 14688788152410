<template>
  <transition
    name="slide-in-left"
    appear
  >
    <slot />
  </transition>
</template>
<script>
export default {
  name: 'TSlideInLeft',
};
</script>

<style lang="scss">
.slide-in-left {
  &-enter-active {
    transition: all var(--enter-timing) ease-out var(--enter-delay);
    transition-duration: 0.4s
  }
  &-leave-active {
    transition: none
  }
  &-enter-from,
  &-leave-to {
    opacity: var(--opacity);
    transform: translateX(var(--translate));
  }
}
</style>