const jsonResponse = {
  '1': {
      'door_content': {
          'type': 'leadgame',
          'header': '',
          'body': '',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.campaign.playable.com/01-12-wof-prime-wrap',
          'game_id': 125183
      },
      'state': 'currently_open'
  },
  '2': {
      'door_content': {
          'type': 'leadgame',
          'header': '',
          'body': '',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.campaign.playable.com/02-12-slot-machine-iqos-iluma-prime',
          'game_id': 125722
      },
      'state': 'opened_in_past'
  },
  '3': {
      'door_content': {
          'type': 'leadgame',
          'header': '',
          'body': '',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.campaign.playable.com/03-12-23-rock-paper-scissors-trip-to-japan',
          'game_id': 125833
      },
      'state': 'opened_in_past'
  },
  '4': {
      'door_content': {
          'type': 'leadgame',
          'header': '',
          'body': '',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.campaign.playable.com/01-12-wof-prime-wrap',
          'game_id': 125183
      },
      'state': 'opened_in_past'
  },
  '5': {
      'door_content': {
          'type': 'user_mgn',
          'header': 'Mit Deiner Empfehlung wird\'s ein Fest.',
          'body': 'Sichere Dir 200 IQOS Points und 20\u20ac off f\u00fcr Deine Freund*Innen. ',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.leadfamly.com/iqos-christmas-2022-slot-machine-game-01',
          'game_id': 97385,
          'mgm': 'MGM-tN0SVf-A295UP'
      },
      'state': 'opened_in_past'
  },
  '6': {
      'door_content': {
          'type': 'leadgame',
          'header': '',
          'body': '',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.leadfamly.com/iqos-christmas-2022-wof-december-6',
          'game_id': 99149
      },
      'state': 'opened_in_past'
  },
  '7': {
      'state': 'currently_open'
  },
  '8': {
      'state': 'currently_open'
  },
  '9': {
      'state': 'currently_open'
  },
  '10': {
      'door_content': {
          'type': 'leadgame',
          'header': '',
          'body': '',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.leadfamly.com/iqos-christmas-2022-scratchcard-dec-10',
          'game_id': 99175
      },
      'state': 'opened_in_past'
  },
  '11': {
      'state': 'currently_open'
  },
  '12': {
      'state': 'currently_open'
  },
  '13': {
      'state': 'currently_open'
  },
  '14': {
      'state': 'currently_open'
  },
  '15': {
      'state': 'currently_open'
  },
  '16': {
      'door_content': {
          'type': 'leadgame',
          'header': '',
          'body': '',
          'button': 'none',
          'game_url': 'https://philip-morris-germany.leadfamly.com/iqos-christmas-2022-scratchcard-game-01',
          'game_id': 97377
      },
      'state': 'opened_in_past'
  },
  '17': {
      'state': 'currently_open'
  },
  '18': {
      'door_content': {
          'type': 'wyng',
          'header': 'Teile Deinen XMAS Moment mit uns!',
          'body': 'Und sichere dir 200 Statuspunkte.',
          'button': 'none',
          'url': 'https://de.iqosclub.com/iqos-community',
          'link_name': 'Los geht\'s'
      },
      'state': 'opened_in_past'
  },
  '19': {
      'state': 'currently_open'
  },
  '20': {
      'state': 'currently_open'
  },
  '21': {
      'state': 'currently_open'
  },
  '22': {
      'state': 'currently_open'
  },
  '23': {
      'state': 'currently_open'
  },
  '24': {
      'state': 'currently_open'
  }
};
export default jsonResponse;
