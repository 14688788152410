import { RouteRecordRaw, Router } from 'vue-router';

export const routeNames = {
  SPRING_RAFFLE_HOME: '/spring-raffle',
  TEILNAHMEBEDINGUNGEN: '/teilnahmebedingungen',
};

export default initialiseSpringRaffleRouter;

const routes: RouteRecordRaw[] = [
  {
    path: `${routeNames.SPRING_RAFFLE_HOME}`,
    name: routeNames.SPRING_RAFFLE_HOME,
    meta: {
      gtagDomain: 'spring-raffle',
    },
    component: () =>
      import(
        /* webpackChunkName: "spring-raffle" */ './views/SpringRaffleHome/ConnectedSpringRaffleHome.vue'
      ),
  },
  {
    path: `${routeNames.SPRING_RAFFLE_HOME}${routeNames.TEILNAHMEBEDINGUNGEN}`,
    name: routeNames.TEILNAHMEBEDINGUNGEN,
    component: () =>
      import(
        /* webpackChunkName: "spring-raffle" */ './views/Teilnahmebedingungen.vue'
      ),
  },
];

function initialiseSpringRaffleRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
