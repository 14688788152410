import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  // mock
  //   .onGet('/downtime/clubsubpages')
  //   .reply(makeResponse('downtime', { in_downtime: false }));
  // mock
  //   .onGet('/downtime/academy')
  //   .reply(makeResponse('downtime', { in_downtime: true }));
  // mock
  //   .onGet('/downtime/xmas')
  //   .reply(makeResponse('downtime', { in_downtime: true }));
  mock
    .onGet(/downtime\/.*/)
    .reply(makeResponse('downtime', { in_downtime: false }));

}
