export const getReponse = {
  'selector_data':{
     'image':{
        'id':81,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/image3x.webp',
        'title':'image@3x',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x.scale-50.png',
           'width':562,
           'height':1140
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x.scale-100.png',
           'width':1125,
           'height':2280
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x.2e16d0ba.fill-4320x2316.png',
           'width':1125,
           'height':604
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x.scale-15.png',
           'width':168,
           'height':342
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x.scale-25.png',
           'width':281,
           'height':570
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x.scale-35.png',
           'width':393,
           'height':798
        }
     },
     'headline':'W\u00e4hle jetzt deinen IQOS Ambassador.',
     'text':'<p data-block-key="dgrs1">Dein pers\u00f6nlicher IQOS Ambassador begleitet Dich ab jetzt in der IQOS Academy.</p>'
  },
  'ambassadors':[
     {
        'id':36,
        'meta':{
           'type':'academy2.AmbassadorPage',
           'detail_url':'https:///api/v2/drafts/36/',
           'html_url':'https://localhost/academy2/ambassadors/ambassador-for-iluma-group/',
           'slug':'ambassador-for-iluma-group',
           'show_in_menus':false,
           'seo_title':'',
           'search_description':'',
           'first_published_at':'2023-04-12T10:04:39.156287+02:00',
           'alias_of':null,
           'locale':'en'
        },
        'title':'Ambassador Ines',
        'name':'Ines',
        'headline_success_screen_archive':'Yeah, Maximilian! Rematch gemeistert.',
        'copy_success_screen_archive':'Spiele noch weitere spannende Challenges aus Deinem Archiv oder entdecke die neusten Infos rund um IQOS. Let\'s go.',
        'headline_welcome':'Toll Dich zu sehen, Maximilian.',
        'headline_archive_home':'Jederzeit bereit f\u00fcr ein Rematch, Maximilian.',
        'copy_archive_home':'<p data-block-key="m8cvu">Willkommen in Deinem Archiv. St\u00f6bere durch Deine bereits gespielten Inhalte und frische Dein Wissen auf oder sammle weitere IQOS Points beim Spielen von neuen Inhalten.</p>',
        'image':{
           'id':65,
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/230419_iqos_iluma01.jpg',
           'title':'230419_iqos_iluma01',
           'image_desktop_1x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230419_iqos_iluma01.scale-50.jpg',
              'width':2523,
              'height':1682
           },
           'image_desktop_2x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230419_iqos_iluma01.scale-100.jpg',
              'width':5046,
              'height':3364
           },
           'image_desktop_3x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230419_iqos_iluma01.2e16d0ba.fill-4320x2316.jpg',
              'width':4320,
              'height':2316
           },
           'image_mobile_1x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230419_iqos_iluma01.scale-15.jpg',
              'width':756,
              'height':504
           },
           'image_mobile_2x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230419_iqos_iluma01.scale-25.jpg',
              'width':1261,
              'height':841
           },
           'image_mobile_3x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230419_iqos_iluma01.scale-35.jpg',
              'width':1766,
              'height':1177
           }
        }
     },
     {
        'id':35,
        'meta':{
           'type':'academy2.AmbassadorPage',
           'detail_url':'https:///api/v2/drafts/35/',
           'html_url':'https://localhost/academy2/ambassadors/ambassador-fur-group-1/',
           'slug':'ambassador-fur-group-1',
           'show_in_menus':false,
           'seo_title':'',
           'search_description':'',
           'first_published_at':'2023-04-08T15:03:57.697174+02:00',
           'alias_of':null,
           'locale':'en'
        },
        'title':'Ambassador Nicky',
        'name':'Nicky',
        'headline_success_screen_archive':'Super, {{USERNAME}}, Challenge erneut gemeistert.',
        'copy_success_screen_archive':'Bestehe weitere Challenges aus Deinem Archiv oder entdecke neue Inhalte und Infos rund um IQOS. Los geht\'s.',
        'headline_welcome':'Sch\u00f6n Dich zu sehen, {{USERNAME}}.',
        'headline_archive_home':'Willkommen im Archiv, {{USERNAME}}.',
        'copy_archive_home':'<p data-block-key="y2xft">Hier findest Du bereits gespielte Challenges und gesehene Videos. Du kannst alle Challenges und Stories noch einmal durcharbeiten, IQOS Points bekommst Du allerdings nur bei neuen Inhalten. Viel Spa\u00df.</p>',
        'image':{
           'id':66,
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/230420_iqos_iluma27.jpg',
           'title':'230420_iqos_iluma27',
           'image_desktop_1x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230420_iqos_iluma27.scale-50.jpg',
              'width':2534,
              'height':1689
           },
           'image_desktop_2x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230420_iqos_iluma27.scale-100.jpg',
              'width':5068,
              'height':3379
           },
           'image_desktop_3x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230420_iqos_iluma27.2e16d0ba.fill-4320x2316.jpg',
              'width':4320,
              'height':2316
           },
           'image_mobile_1x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230420_iqos_iluma27.scale-15.jpg',
              'width':760,
              'height':506
           },
           'image_mobile_2x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230420_iqos_iluma27.scale-25.jpg',
              'width':1267,
              'height':844
           },
           'image_mobile_3x':{
              'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/230420_iqos_iluma27.scale-35.jpg',
              'width':1773,
              'height':1182
           }
        }
     }
  ]
};

export const postReponse = {
  status: '200',
  ambassador: 'Alessandro',
};
