
import Icon from '@/components/Icon/Icon.vue';
import { defineComponent } from 'vue';

const EVENTS = {
  CLOSE_ALL_MENUS: 'closeAllMenus',
  ARROW_BACK_CLICK: 'arrowBackClick',
};

export default defineComponent({
  components: { Icon },
  props: {
    label: {
      type: String,
      default: null,
    },
    isLabelVisible: {
      type: Boolean,
      default: false,
    },
    isIconBackVisible: {
      type: Boolean,
      default: false,
    },
    iconCloseColor: {
      type: String,
      default: null,
    },
  },
  emits: [EVENTS.CLOSE_ALL_MENUS, EVENTS.ARROW_BACK_CLICK],
  setup(_, { emit }) {
    return {
      onCloseNavMobileClick: () => emit(EVENTS.CLOSE_ALL_MENUS),
      onBackClick: () => emit(EVENTS.ARROW_BACK_CLICK),
    };
  },
});
