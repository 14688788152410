import { ResponsiveImageFactory } from '@/utils/image'

import oliverAndLucaMobile from '@/assets/mtn/warumduscher-mobil.jpg';
import olverAndLucaDesktop from '@/assets/mtn/warumduscher-desktop.jpg';

import davidMobile from '@/assets/mtn/wasser-mobil.jpg';
import davidDesktop from '@/assets/mtn/wasser-desktop.jpg';

import maluMobile from '@/assets/mtn/alge-mobil.jpg';
import maluDesktop from '@/assets/mtn/alge-desktop.jpg';

import { MtnState } from '../types';

export const STATIC_SERVER = 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com';

export const initialState = (): MtnState => ({
  videos: [
    {
      title: 'Der Warmduscher',
      category: 'Finalist 1',
      description:
        'Oliver und Luca haben ein Produkt erfunden, das beim Duschen Energie spart und dadurch die Umwelt schont.',
      url:
        `${STATIC_SERVER}/MTN%20Videos/IQOS_MTN_SemiFinals__Pitch_02__Warmduscher__MASTER_16x9__SMALL.mp4`,
      image: ResponsiveImageFactory(oliverAndLucaMobile, olverAndLucaDesktop, 'Person holding IQOS device'),
    },
    {
      title: 'Wasser für die Dritte Welt',
      category: 'Finalist 2',
      description:
        'David hat ein Konzept für eine faire und damit bessere Wasserverteilung in Entwicklungsländern entwickelt.',
      url:
        `${STATIC_SERVER}/MTN%20Videos/IQOS_MTN_SemiFinals__Pitch_03__Wasser__MASTER_16x9__SMALL.mp4`,
      image: ResponsiveImageFactory(davidMobile, davidDesktop, 'Person holding IQOS device'),
    },
    {
      title: 'Verpackung aus Algen',
      category: 'Finalist 3',
      description:
        'Malu, Juni und Annekathrin arbeiten an einem Verpackungsmaterial aus Algen – plastikfrei, recyclebar und nachhaltig.',
      url:
        `${STATIC_SERVER}/MTN%20Videos/IQOS_MTN_SemiFinals__Pitch_05__Algen__MASTER_16x9__SMALL.mp4`,
      image: ResponsiveImageFactory(maluMobile, maluDesktop, 'Person holding IQOS device'),
    },
  ],
});

const mtnModule = {
  namespaced: true,
  state: initialState(),
};

export default mtnModule;
