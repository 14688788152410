import { NEWS_ARTICLE_RAFFLE_REGISTERED } from './mutation-types';

import {
  getNewsArticleRaffleRegister,
  newsArticleRaffleRegister,
} from '../api/api.js';

import normalizeNewsArticleRaffleRegister from './normalizers/newsArticleRaffleRegister';

async function checkNewsArticleRaffleRegister({ commit, dispatch }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });

  const response = await getNewsArticleRaffleRegister(
    '10_Jahre_Ticketverlosung'
  )(headers);
  const normalizeResponse = normalizeNewsArticleRaffleRegister(response);

  commit(NEWS_ARTICLE_RAFFLE_REGISTERED, normalizeResponse);
}

async function triggerNewsArticleRaffleRegister({ commit, dispatch }) {
  const headers = await dispatch('authModule/refreshHeaders', null, {
    root: true,
  });
  try {
    const response = await newsArticleRaffleRegister(
      '10_Jahre_Ticketverlosung'
    )(headers);
    const normalizeResponse = normalizeNewsArticleRaffleRegister(response);

    commit(NEWS_ARTICLE_RAFFLE_REGISTERED, normalizeResponse);
  } catch {}
}

export default {
  checkNewsArticleRaffleRegister,
  triggerNewsArticleRaffleRegister,
};
