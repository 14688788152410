import { authApiGet, authApiPost } from '@/api/api.js';

const adventApiRoot = 'advent';

const URLS = {
  CALENDAR: `${adventApiRoot}/`,
  DOOR: (day) => `${adventApiRoot}/door/${day}`,
  ACTION: (actionId) => `action/${actionId}`,
};

export const getCalendar = () => authApiGet(URLS.CALENDAR);
export const openDoor = (day) => authApiGet(URLS.DOOR(day));

export const postTrackingAction = (trackingAction) =>
  authApiPost(URLS.ACTION(trackingAction), {
    action_id: String(trackingAction),
  });
