import { Router } from 'vue-router';
import { Store } from 'vuex';
import initialiseSpringRaffleRouter from './router';
import storiesStore from './store/store';
import { RootState } from '@/store/types';

function storiesModule({
  store,
  router,
}: {
  store: Store<RootState>;
  router: Router;
}): void {
  store.registerModule('springRaffleModule', storiesStore);
  initialiseSpringRaffleRouter(router);
}

export default storiesModule;
