import { Router } from 'vue-router';
import { Store } from 'vuex';

import mtnFinaleStore from './store/store';
import initialiseMtnFinaleRouter from './router';

function mtnFinaleModule({
  store,
  router,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: Store<any>;
  router: Router;
}): void {
  store.registerModule('mtnFinaleModule', mtnFinaleStore);
  initialiseMtnFinaleRouter(router);
}

export default mtnFinaleModule;
