export default {
  toggles: [
    {
      name: 'IQOS-1171', // TogetherX Main Toggle (related to all routes)
      enabled: true,
    },
    {
      name: 'IQOS-1394-documentation-phase',
      enabled: true,
    },
    {
      name: 'IQOS-1408-artist-rollouts',
      enabled: true,
    },
    {
      name: 'IQOS-1484-new-artist-rollouts-17-06-2024',
      enabled: true,
    },
    {
      name: 'IQOS-1504-10-years-news-article',
      enabled: true,
    },
    {
      name: 'IQOS-1503-levia-news-article',
      enabled: true,
    },
    {
      name: 'IQOS-1535-togx-tokyo-ugc-module',
      enabled: true,
    },
    {
      name: 'IQOS-1609-product-notes',
      enabled: true,
    },
    {
      name: 'IQOS-1586-raffle-popup',
      enabled: true,
    },
    {
      name: 'IQOS-1637-earning-overview-module',
      enabled: true,
    },
    {
      name: 'IQOS-1668-10-years-history',
      enabled: true,
    },
    {
      name: 'IQOS-1677-wave-2b-article',
      enabled: true,
    },
    {
      name: 'IQOS-1708-remove-community-wall',
      enabled: true,
    },
  ],
};
