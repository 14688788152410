import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "block h-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicUnderline = _resolveComponent("DynamicUnderline")!

  return (_openBlock(), _createElementBlock("a", {
    class: "nav-link-small relative block lg:py-1",
    href: _ctx.menu.href
  }, [
    _createVNode(_component_DynamicUnderline, { hideUnderline: _ctx.hideUnderline }, {
      underlined: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.menu.text), 1)
      ]),
      _: 1
    }, 8, ["hideUnderline"])
  ], 8, _hoisted_1))
}