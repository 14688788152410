import { Router } from 'vue-router';
import { Store } from 'vuex';
import initialiseStoriesRouter from './router';
import storiesStore from './store/store';
import { RootState } from '@/store/types';

function storiesModule({
  store,
  router,
}: {
  store: Store<RootState>;
  router: Router;
}): void {
  store.registerModule('storiesModule', storiesStore);
  initialiseStoriesRouter(router);
}

export default storiesModule;
