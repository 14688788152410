import store from '@/store/index';
import { NavigationGuard } from 'vue-router';
import { isEqual, isEmpty } from 'lodash';
import { useGtm } from '@gtm-support/vue-gtm';

import { Consumer } from '@/modules/consumer/types';
import { UserGTMData } from './types';

const userDataLayerPush = (userData: UserGTMData) => window.dataLayer.push({
  event: 'userdetails',
  user: {
    affiliation: 'Registered',
    birthYear: new Date(userData.user.dateOfBirth).getFullYear(),
    cookieSyncID: '',
    gender: userData.user.gender,
    hasAlreadyOrdered: '',
    hasDeviceRegistered: 'true',
    loginStatus: 'logged-in',
    originChannel: '',
    segment: '',
    userID: userData.userId,
    pointsAvailable: userData.loyaltyPoints,
  }
})

const fetchStoreUserData = (): UserGTMData => {
  const user: Consumer = store.getters['consumerModule/consumer']
  const loyaltyPoints: number | null = store.getters['consumerModule/loyaltyPoints']
  const userId: string | null = store.getters['authModule/userId']
  return {
    user,
    loyaltyPoints,
    userId
  }
};

const isAllFetched = (userData: UserGTMData): boolean => {

  const { user, userId, loyaltyPoints } = userData
  return !isEmpty(user) && Boolean(userId) && loyaltyPoints !== null
}

// We push the dataLayer every time the route change
// but on first load of our app we need to wait all data are fetched
export const userGTMDatalayerGuard: NavigationGuard =
  (to, from, next): void => {

    const pushDataLayerIfFetched = (nextValue, prevValue) => {

      if (isAllFetched(nextValue) && !isEqual(nextValue, prevValue)) {
        userDataLayerPush(fetchStoreUserData());
        // Enable GTM script after fetch user so the datalayer of the user will be pushed before gtm.js event.
        // The reason for having datalayer user pushed before gtm script remains a mystery.
        const gtm = useGtm()
        !gtm.enabled() && gtm.enable(true)
      }
    }

    isAllFetched(fetchStoreUserData())
      ? userDataLayerPush(fetchStoreUserData())
      : store.watch(fetchStoreUserData, pushDataLayerIfFetched)

    next()
  };