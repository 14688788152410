import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  mock.onGet('action/SPRINGRAFFLE').reply(makeResponse('get spring raffle action', { '50': false }));

  // mock.onPost('action/SPRINGRAFFLE').networkError();
  mock.onPost('action/SPRINGRAFFLE').reply(makeResponse('post spring raffle action', {}));
}
