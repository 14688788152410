module.exports = {
  px: '1px',
  13: '3.25rem',
  18: '4.5rem',
  19: '4.75rem',
  26: '6.5rem',
  38: '9.5rem',
  52: '13rem',
  75: '18.75rem',
  76: '19rem',
  102: '30rem', // THIS SHOULD BE 120, fix in separate PR
  104: '26rem',
  112: '33rem',
  144: '36rem',
  160: '40rem',
  192: '48rem',
  // Zeplin spacing
  'xxs': '0.25rem',
  'xs': '0.5rem',
  's': '0.75rem',
  'm': '1rem',
  'l': '1.25rem',
  'xl': '1.5rem',
  'xxl': '1.75rem',
  'xxl-2': '2rem',
  'xxl-3': '2.25rem',
  'xxl-4': '2.5rem',
  'xxl-5': '3.75rem',
  'xxl-6': '5rem',
  'xxl-7': '6.25rem',
  'xxl-8': '12.5rem',
  // Percent
  '1/6': '16.66%',
  '5/6': '83.33%',
  '1/12': '8.33%',
  '11/12': '91.66%',
  '1/10': '10%',
  '2/10': '20%',
  '3/10': '30%',
  '4/10': '40%',
  '5/10': '50%',
  '6/10': '60%',
  '7/10': '70%',
  '8/10': '80%',
  '9/10': '90%',
};
