// CATEGORIES
export const SET_CATEGORIES = 'SET_CATEGORIES';

// HEROSLIDERS
export const SET_HERO_SLIDERS = 'SET_HERO_SLIDERS';

// PRODUCTS
// Committed when we have the list of products data
export const SET_PRODUCTS = 'SET_PRODUCTS';
// Committed when there is an error while fetching the list of products
export const SET_PRODUCTS_FETCHING_ERROR = 'SET_PRODUCTS_FETCHING_ERROR';

// ADDITIONAL PRODUCT
export const SET_ADDITIONAL_PRODUCT = 'SET_ADDITIONAL_PRODUCT';

// PRODUCT DETAIL
// Committed when we have to clean the current product
export const CLEAN_CURRENT_PRODUCT = 'CLEAN_CURRENT_PRODUCT';
// Committed when selecting a detailed product
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
// Committed when there is an error while fetching a product
export const SET_PRODUCT_FETCHING_ERROR = 'SET_PRODUCT_FETCHING_ERROR';
// Committed when selecting a specific variant of a product
export const SET_CURRENT_VARIANT = 'SET_CURRENT_VARIANT';

// ORDERING A PRODUCT
// Committed when we have a product order data
export const SET_PRODUCT_ORDER = 'SET_PRODUCT_ORDER';
// To be commited alongside SET_PRODUCT_ORDER to store customer info like shipping
export const SET_PRODUCT_ORDER_CUSTOMER_INFO = 'SET_PRODUCT_ORDER_CUSTOMER_INFO';
// Committed when there is an error while ordering a product
export const SET_PRODUCT_ORDERING_ERROR = 'SET_PRODUCT_ORDERING_ERROR';

// NOTIFICATIONS
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

// CONFIG
// Committed when we have the config data
export const SET_CONFIG = 'SET_CONFIG';
// Committed when there is an error while fetching the config data
export const SET_CONFIG_FETCHING_ERROR = 'SET_CONFIG_FETCHING_ERROR';
export const SET_USER_INTERACTION = 'SET_USER_INTERACTION';
