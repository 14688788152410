import {
  CLEAN_CURRENT_PRODUCT,
  SET_CONFIG,
  SET_CONFIG_FETCHING_ERROR,
  SET_CURRENT_PRODUCT,
  SET_ADDITIONAL_PRODUCT,
  SET_CURRENT_VARIANT,
  SET_PRODUCTS,
  SET_PRODUCTS_FETCHING_ERROR,
  SET_PRODUCT_FETCHING_ERROR,
  SET_PRODUCT_ORDER,
  SET_PRODUCT_ORDERING_ERROR,
  SET_PRODUCT_ORDER_CUSTOMER_INFO,
  SET_USER_INTERACTION,
  SET_CATEGORIES,
  SET_HERO_SLIDERS,
  SET_NOTIFICATIONS,
} from './mutation-types';
import {
  Config,
  CustomerInfo,
  Order,
  Product,
  ShopState,
  Variant,
  Category,
  Notification,
  HeroSliderItem,
} from '../types';

const mutations = {
  [SET_CATEGORIES]: (state: ShopState, categories: Category[]): void => {
    state.categories = categories;
    console.debug(SET_CATEGORIES, state.categories);
  },
  [SET_HERO_SLIDERS]: (state: ShopState, heroSliders: HeroSliderItem[]): void => {
    state.heroSliders = heroSliders;
    console.debug(SET_HERO_SLIDERS, state.heroSliders);
  },
  [SET_PRODUCTS]: (state: ShopState, products: Product[]): void => {
    state.products = products;
    console.debug(SET_PRODUCTS, state.products);
  },
  [SET_CURRENT_VARIANT]: (state: ShopState, variant: Variant): void => {
    state.currentVariant = variant;
    console.debug(SET_CURRENT_VARIANT, state.currentVariant);
  },
  [SET_PRODUCT_FETCHING_ERROR]: (state: ShopState, error: string): void => {
    state.productsFetchError = error;
    console.debug(SET_PRODUCT_FETCHING_ERROR, state.productsFetchError);
  },
  [SET_CURRENT_PRODUCT]: (state: ShopState, product: Product): void => {
    state.currentProduct = product;
    console.debug(SET_CURRENT_PRODUCT, state.currentProduct);
  },
  [SET_ADDITIONAL_PRODUCT]: (state: ShopState, product: Product): void => {
    state.additionalProduct = product;
    console.debug(SET_ADDITIONAL_PRODUCT, state.additionalProduct);
  },
  [SET_PRODUCTS_FETCHING_ERROR]: (state: ShopState, error: string): void => {
    state.productsFetchError = error;
    console.debug(SET_PRODUCTS_FETCHING_ERROR, state.productsFetchError);
  },
  [CLEAN_CURRENT_PRODUCT]: (state: ShopState): void => {
    state.currentProduct = null;
    state.additionalProduct = null;
    console.debug(CLEAN_CURRENT_PRODUCT, state.currentProduct);
  },
  [SET_PRODUCT_ORDER_CUSTOMER_INFO]: (
    state: ShopState,
    customerInfo: CustomerInfo
  ): void => {
    state.customerInfo = customerInfo;
    console.debug(SET_PRODUCT_ORDER_CUSTOMER_INFO, state.customerInfo);
  },
  [SET_PRODUCT_ORDER]: (state: ShopState, order: Order): void => {
    state.order = order;
    console.debug(SET_PRODUCT_ORDER, state.order);
  },
  [SET_PRODUCT_ORDERING_ERROR]: (state: ShopState, error: string): void => {
    state.orderProductError = error;
    console.debug(SET_PRODUCT_ORDERING_ERROR, state.orderProductError);
  },
  [SET_NOTIFICATIONS]: (state: ShopState, notifications: Notification[]): void => {
    state.notifications = notifications;
    console.debug(SET_NOTIFICATIONS, state.notifications);
  },
  [SET_CONFIG]: (state: ShopState, config: Config): void => {
    state.config = config;
    console.debug(SET_CONFIG, state.config);
  },
  [SET_CONFIG_FETCHING_ERROR]: (state: ShopState, error: string): void => {
    state.configFetchError = error;
    console.debug(SET_CONFIG_FETCHING_ERROR, state.configFetchError);
  },
  [SET_USER_INTERACTION]: (state: ShopState, payload: ShopState['userInteractionToProductDetail']): void => {
    state.userInteractionToProductDetail = payload;
  },
};
export default mutations;
