import { Router } from 'vue-router';
import { Store } from 'vuex';
import initialiseProductDetailRouter from './router';
import shopStore from './store/store';
import { RootState } from '@/store/types';

function shopModule({
  store,
  router,
}: {
  store: Store<RootState>;
  router: Router;
}): void {
  store.registerModule('shopModule', shopStore);
  initialiseProductDetailRouter(router);
}

export default shopModule;
