import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45909397"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dynamic-underline" }
const _hoisted_2 = { class: "dynamic-underline__lined-text" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "underlined", { class: "inline" }, undefined, true),
      _createVNode(_Transition, { name: "dynamic-underline__transition" }, {
        default: _withCtx(() => [
          (_ctx.shouldDisplayLine)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["dynamic-underline__line", { hidden: !_ctx.alwaysShowOnActive && _ctx.hideUnderline }])
              }, null, 2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("button", {
      id: `DCE_nav_${_ctx.gtmId}`,
      class: "absolute w-full h-full top-0 left-0 z-30s",
      onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.triggerEnterHover && _ctx.triggerEnterHover(...args))),
      onMouseout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.triggerLeaveHover && _ctx.triggerLeaveHover(...args)))
    }, null, 40, _hoisted_3)
  ]))
}