export default {
  status: 200,
  config: {
    txt_cta_einlösen: '',
    txt_cta_missing_points: '',
    txt_cta_order: '',
    txt_checkbox_address: '',
    txt_thankyou_1: '',
    txt_thankyou_2: '',
    txt_thankyou_3: '',
    txt_thankyou_voucher:
      'Kopiere deinen persönlichen Vouchercode oder mache einen Screenshot. Dein Code wird dir zusätzlich per Mail zugesandt. ',
    default_attributes: [
      {
        product_type: 'voucher',
        attributes: [
          'Kopiere deinen persönlichen Vouchercode oder mache einen Screenshot. Dein Code wird dir zusätzlich per Mail zugesandt. ',
          '',
        ],
      },
      {
        product_type: 'itg_product',
        attributes: ['Kein Widerrufsrecht', 'Schneller Versand'],
      },
    ],
    custom_texts: [
      {
        text_id: 'mgm_warning',
        lines: [
          `* Nur für erwachsene Raucher. Die Nutzung des Freundschaftscodes für kommerzielle Zwecke oder eine Verbreitung
          auf eBay ist untersagt und auf 50 erfolgreiche Empfehlungen limitiert. Nach abgeschlossenem Kauf mit Nutzung des Freundschaftscodes
          sowie erfolgreicher Registrierung Deiner Freunde erhältst Du 300 IQOS Points, die Du für Prämien im IQOS CLUB eintauschen kannst.`,
        ],
      },
    ],
  },
};
