// Vuex types
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
// import {
//   SELECT_ARCHIVED_STORY,
//   UNSELECT_ARCHIVED_STORY,
// } from './mutation-types';
import { StoriesState } from '@/modules/stories/types';

const actions: ActionTree<StoriesState, RootState> = {
  // selectArchivedStory({ commit }, payload) {
  //   commit(SELECT_ARCHIVED_STORY, payload)
  // },
  // unselectArchivedStory({ commit }) {
  //   commit(UNSELECT_ARCHIVED_STORY)
  // }
};

export default actions;
