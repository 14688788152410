const jsonResponse = `
{
  "status": 404,
  "error_message": "not_found"
}
`;
// "error_message": "not_available"
// "error_message": "missing_points"
// "error_message": "out_of_stock"
export default JSON.parse(jsonResponse);
