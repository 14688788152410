const jsonResponse = `
{
  "status": 200,
  "products": [
    {
      "product_id": "6086f24a896da8d2947a7191",
      "group": "itg_product",
      "name": "Bose Lautsprecher",
      "category": "Elektronik",
      "main_category": "Living Delight",
      "image": "main",
      "description": "Der XSound Surf ist eine Kombination aus Eleganz und Leistung. Tribit hat die Leistung speziell entwickelter Treiber mit der Eleganz des Designs kombiniert. <br><br>Die speziell entwickelten Treiber schaffen einen langlebigen Lautsprecher, der genauso gut klingt, wie er aussieht. Der XSound Surf Bluetooth-Funklautsprecher ist für zu Hause oder unterwegs gleichermaßen geeignet. <br><br>Übertragen Sie Musik kabellos per Bluetooth für bis zu 10 Stunden. Mit seinem neuen wasserdichten IPX7-Design gibt der XSound Surf Musikliebhabern die Möglichkeit, ihre Lautsprecher an den Pool oder an den Strand mitzunehmen und sorgt somit für noch mehr Spaß.",
      "sub_headline": "Tribit Bluetooth-Lautsprecher",
      "required_points": 17,
      "attributes": [
        "Lieferung 1-3 Werktage",
        "Kostenloser Versand",
        "1 Jahr Garantie"
      ],
      "variants": [
        {
          "variant_id": "f82265de-ada1-11eb-bc68-0242ac110005",
          "color_name": "Schwarz",
          "color_code": "#000000",
          "out_of_stock": true,
          "images": [
            "6087cd6df7a95b5ecb356f7e",
            "6087cd6df7a95b5ecb356f80",
            "6098fcdde7f155ce99ef12b8",
            "6098fcdde7f155ce99ef12ba"
          ]
        },
        {
          "variant_id": "f8226fac-ada1-11eb-bc68-0242ac110005",
          "color_name": "Rot",
          "color_code": "#FF0000",
          "out_of_stock": true,
          "images": [
            "6087cd54f7a95b5ecb356f7c",
            "6098fcdee7f155ce99ef12bc",
            "6098fcdee7f155ce99ef12bf"
          ]
        }
      ],
      "order_group": "z",
      "label": "Limited Edition",
      "label_color_code": ""
    },
    {
      "product_id": "60a2347564b6824e0d84e498",
      "group": "itg_product",
      "name": "JBL Kopfhörer",
      "category": "Elektronik",
      "main_category": "Living Delight",
      "image": "main",
      "description": "Der JBL TUNE500 liefert kraftvolle Soundqualität, die dich täglich neu begeistert. Dieser Kopfhörer bereitet dir mit tollem Sound jederzeit Vergnügen, denn er verfügt über JBL Pure Bass Sound, JBL 32-mm-Treiber und hat eine universelle Ein-Tasten-Fernbedienung, die mit den meisten Smartphones kompatibel ist. Der JBL TUNE500 Kopfhörer ist leicht und bequem dank der weichen Ohrpolster und des gepolsterten Kopfbügels.Außerdem erlaubt er die Verbindung zu Siri oder Google Now, ohne dass du dafür dein Mobilgerät verwenden musst. Den JBL TUNE500 Kopfhörer gibt es in vier frischen Farben, er hat ein verwicklungsfreies Flachkabel und er lässt sich für unterwegs einfach zusammenfalten. Er bietet damit eine Plug 'n' Go-Lösung, mit der du jeden Moment deines Lebens mit Musik bereichern kannst.JBL Pure Bass SoundDer Kopfhörer bietet den weltbekannten JBL Pure Bass Sound, der an fast jedem berühmten Veranstaltungsort rund um die Welt zu fi nden ist.Ein-Tasten-Fernbedienung mit MikrofonMusik und Anrufe kannst du über Zugriff auf Siri oder Google Now mit einem einzigen Knopfdruck leicht steuern.Leichtes und faltbares DesignDurch die leichten Materialien und das faltbare Design kann der Kopfhörer bequem überallhin mitgenommen werden, um Musik zu hören, wann immer du willst.",
      "sub_headline": "TUNE500",
      "required_points": 100,
      "attributes": [
        "Lieferung 1-3 Werktage",
        "Kostenloser Versand",
        "3 Jahre Garantie"
      ],
      "variants": [
        {
          "variant_id": "955782b0-b6f0-11eb-a7db-0242ac110005",
          "color_name": "",
          "color_code": "#000000",
          "out_of_stock": false,
          "images": [
            "60a2347564b6824e0d84e48e",
            "60a2347564b6824e0d84e490",
            "60a2347564b6824e0d84e492",
            "60a2347564b6824e0d84e494",
            "60a2347564b6824e0d84e496"
          ]
        },
        {
          "variant_id": "23da0db4-b6f1-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#ffffff",
          "out_of_stock": false,
          "images": [
            "60a235647a2e905a5984e499",
            "60a235647a2e905a5984e49b",
            "60a235647a2e905a5984e49d",
            "60a235647a2e905a5984e49f",
            "60a235647a2e905a5984e4a1"
          ]
        },
        {
          "variant_id": "23da1458-b6f1-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#F0F8FF",
          "out_of_stock": true,
          "images": [
            "60a235647a2e905a5984e4a3",
            "60a235647a2e905a5984e4a5",
            "60a235647a2e905a5984e4a7",
            "60a235647a2e905a5984e4a9",
            "60a235647a2e905a5984e4ab"
          ]
        }
      ],
      "order_group": "z",
      "label": "Supercool !",
      "required_points_before": "800",
      "label_color_code": "#FF0000"
    },
    {
      "product_id": "60a239257a2e905a5984e4b6",
      "group": "itg_product",
      "name": "MIKASA Volleyball",
      "category": "Freizeit",
      "main_category": "Living Delight",
      "image": "main",
      "description": "Mit diesem Volleyball lässt sich die Sportart Volleyball angstfrei lernen, denn der Kontakt mit dem Allroundball ist dank des äusserst weichen EVA-Oberflächenmaterials angenehm weich und schmerzfrei. Ein idealer Ball für den täglichen Einsatz im Hallenvolleyballtraining bei Kinder- und Jugendlichen.",
      "sub_headline": "VS123W",
      "required_points": 300,
      "attributes": [
        "Lieferung 3-5 Werktage",
        "1 Jahr Garantie"
      ],
      "variants": [
        {
          "variant_id": "60909884-b6f3-11eb-9867-0242ac110005",
          "color_name": "",
          "color_code": "#F3E531",
          "out_of_stock": false,
          "images": [
            "60a239257a2e905a5984e4b4"
          ]
        }
      ],
      "order_group": "z"
    }
  ],
  "categories_of_products": {
    "Living Delight": "Blablabla"
  }
}
`;

export default JSON.parse(jsonResponse);
