import { Router } from 'vue-router';

import { Store } from 'vuex';

import podcastsStore from './store/store';

import initialisePodcastsRouter from './router';
import { RootState } from '@/store/types';

function podcastsModule({ store, router }: { store: Store<RootState>, router: Router }): void {
  store.registerModule('podcastsModule', podcastsStore);
  initialisePodcastsRouter(router)
}

export default podcastsModule;
