import { authApiPost, authApiGet } from '@/api/api';
import { Podcast } from '@/types';
import { PostListenToPodcastResponse } from '../store/dtos';

type GetPostPodcastUrl = (actionId: Podcast['trackingAction']) => string;
const getPostPodcastUrl: GetPostPodcastUrl = (actionId) =>
  `action/${actionId}`;

type PostListenToPodcast = (
  trackingAction: Podcast['trackingAction']
) => Promise<PostListenToPodcastResponse>;
// @ts-expect-error we are not going to type authApiPost
export const postListenToPodcast: PostListenToPodcast = (trackingAction) =>
  authApiPost(getPostPodcastUrl(trackingAction));

export const postListenToAudioFragment = (trackingAction: string, progress: number): unknown =>
  authApiPost(`action/progress/${trackingAction}`, { progress_percent: progress });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchAudioFragments = (trackingAction: string) => authApiGet(`action/progress/${trackingAction}`);
