import { NavigationGuard } from 'vue-router';
import { fetchDowntime } from './api/api';
import { routeNames } from './router';

type MaintenanceGuardFnType = (isInMaintenance: boolean, maintenanceUrl?: string) => NavigationGuard;

const isAcademyUrl = (url: string) => url?.startsWith('academy') && !url?.startsWith('academytesting2023')

export const maintenanceGuardFn: MaintenanceGuardFnType =
  (isInMaintenance, maintenanceUrl) => (to, _, next) => {
    const isGoingToProtectedRoute = ![
      routeNames.MAINTENANCE,
      'root',
    ].includes(String(to.name));

    const shouldRedirect = isInMaintenance && isGoingToProtectedRoute;
    const nextRoute = shouldRedirect
      ? `/${maintenanceUrl || routeNames.MAINTENANCE}`
      : undefined;
    next(nextRoute);
  };

export const maintenanceGuard: NavigationGuard = async (...args) => {
  try {
    const toPage = args[0]?.fullPath?.[0] === '/'
      ? args[0]?.fullPath.substring(1)
      : args[0]?.fullPath;

    const customMaintenanceUrls = {
      default: routeNames.MAINTENANCE,
      academy: routeNames.MAINTENANCE, // Same as default
      xmas: 'adventcalendar2023/maintenance',
    };

    const maintenancePagesToAvoidInfiniteRedirection = [
      ...Object.values(customMaintenanceUrls),
    ];

    const downtimeKey = maintenancePagesToAvoidInfiniteRedirection.includes(toPage)
      ? 'default'
      : isAcademyUrl(toPage) || toPage.startsWith('podcasts')
      ? 'academy'
      : toPage.startsWith('adventcalendar')
      ? 'xmas'
      : 'default';

    const { in_downtime: isInMaintenance } = await fetchDowntime(downtimeKey);
    maintenanceGuardFn(isInMaintenance, customMaintenanceUrls[downtimeKey])(...args);
  } catch (error) {
    // @TODO: update to true
    maintenanceGuardFn(false)(...args);
    throw error;
  }
};
