import { ActionTree, Commit } from 'vuex';
import { RootState } from '@/store/types';

import { ActionFactory } from '@/store/actions';

import consumerNormalizer from './normalizers/consumer';
import consumerLoyaltyNormalizer from './normalizers/consumer-loyalty';
import { fetchConsumer, fetchConsumerLoyalty } from '../api/api';
import {
  Consumer,
  ConsumerLoyalty,
  ConsumerState,
  ErrorMessage,
  Loyalty,
  LoyaltyErrorResponse,
} from '../types';

export const initialState = (): ConsumerState => ({
  consumer: null,
  loyalty: null,
  consumerFetchError: null,
  consumerLoyalty: null,
  consumerLoyaltyFetchError: null,
  clubError: null,
  isClubUser: null,
  loggedOut: false,
});

export const getters = {
  loyaltyPoints: ({ loyalty }: ConsumerState): number => loyalty?.points,
  statusPoints: ({ loyalty }: ConsumerState): number => loyalty?.statusPoints,
  statusLevel: ({ loyalty }: ConsumerState): string => loyalty?.statusLevel,
  clubError: ({ clubError }: ConsumerState): string | null => clubError,
  isClubUser: ({ isClubUser }: ConsumerState): boolean | null => isClubUser,
  consumer: ({ consumer }: ConsumerState): Consumer => consumer,
};

export const mutations = {
  LOGOUT: (state: ConsumerState): void => {
    state.consumer = null;
    state.loyalty = null;
    state.isClubUser = null;
    state.loggedOut = true;
    console.debug('LOGOUT');
  },
  SET_CONSUMER: (state: ConsumerState, payload: Consumer): void => {
    state.consumer = payload;
    console.debug('SET_CONSUMER', state.consumer);
  },
  SET_CONSUMER_ERROR: (state: ConsumerState, error: ErrorMessage | string): void => {
    state.consumerFetchError = typeof error === 'string'
      ? error
      : error.errorMessage;
    console.debug('SET_CONSUMER_ERROR', state.consumerFetchError);
  },
  SET_LOYALTY: (state: ConsumerState, payload: Loyalty): void => {
    state.loyalty = payload;
    console.debug('SET_LOYALTY', state.loyalty);
  },
  SET_LOYALTY_POINTS: (state: ConsumerState, payload: number): void => {
    state.loyalty.points = payload;
    console.debug('SET_LOYALTY_POINTS', state.loyalty.points);
  },
  SET_LOYALTY_ERROR: (state: ConsumerState, error: ErrorMessage | string): void => {
    state.consumerLoyaltyFetchError = typeof error === 'string'
      ? error
      : error.errorMessage;
    console.debug('SET_LOYALTY_ERROR', state.consumerLoyaltyFetchError);
  },
  SET_IS_CLUB_USER: (state: ConsumerState, payload: boolean): void => {
    state.isClubUser = payload;
    console.debug('SET_IS_CLUB_USER', state.isClubUser);
  },
  SET_CLUB_ERROR: (state: ConsumerState, error: ErrorMessage): void => {
    state.clubError = error.errorMessage;
    console.debug('SET_CLUB_ERROR', state.clubError);
  },
};

// store now has committers, allows possible reusability and composability
const committers = {
  consumer: (commit: Commit) => (
    response: ConsumerLoyalty | LoyaltyErrorResponse
  ) => {
    const mutationHandler = !('errorMessage' in response)
      ? () => {
        commit('SET_CONSUMER', response.consumer);
        commit('SET_LOYALTY', response.loyalty);
      }
      : !response.isClubUser
        ? () => commit('SET_CLUB_ERROR', response)
        : () => commit('SET_CONSUMER_ERROR', response);
    mutationHandler();
    commit('SET_IS_CLUB_USER', response.isClubUser);
    return response;
  },
  loyalty: (commit: Commit) => (response: Loyalty | ErrorMessage) => {
    const curryCommit = (mutation) => (payload) => commit(mutation, payload);

    const mutationHandler =
      'errorMessage' in response
        ? curryCommit('SET_LOYALTY_ERROR')
        : curryCommit('SET_LOYALTY');
    mutationHandler(response);
    return response;
  },
};

const actionsSetup = [
  {
    name: 'getConsumer',
    fetchFn: fetchConsumer,
    normalizeFn: consumerNormalizer,
    committerFn: committers.consumer,
    catchMutation: 'SET_CONSUMER_ERROR',
  },
  {
    name: 'getConsumerLoyalty',
    fetchFn: fetchConsumerLoyalty,
    normalizeFn: consumerLoyaltyNormalizer,
    committerFn: committers.loyalty,
    catchMutation: 'SET_LOYALTY_ERROR',
  },
];

const getActionsfromActionsSetup = () => Object.values(actionsSetup).reduce(
  (actionsBundle, action) => ({
    ...actionsBundle,
    [action.name]: (context) => ActionFactory({ context, ...action }),
  }),
  {}
);

const actions: ActionTree<ConsumerState, RootState> = {
  ...getActionsfromActionsSetup(),
  addLoyaltyPoints: ({ getters, commit }, payload: number): void => {
    const { loyaltyPoints } = getters;
    commit('SET_LOYALTY_POINTS', loyaltyPoints + payload);
  }
};

const consumerModule = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

export default consumerModule;
