import { Router, RouteRecordRaw } from 'vue-router';

const routeNames = {
  OFFLINE_REDEMPTION: 'offline-redemption',
}

export const ROOT_URL = '/vorortpunkten'

export default initialiseVoucherRouter;

const routes: RouteRecordRaw[] = [
  {
    path: '/vorortpunkten',
    name: routeNames.OFFLINE_REDEMPTION,
    component: () =>
      import(
        /* webpackChunkName: "Voucher" */
        './views/OfflineRedemption.vue'
      ),
    meta: {
      gtagDomain: 'vorortpunkten',
      routeBreadcrumbName: 'Vorortpunkten',
    },
  },
];

function initialiseVoucherRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
