import { RouteRecordRaw, Router } from 'vue-router';

const DEFAULT_GTAG_DOMAIN = 'academy';

export const ROOT_URL = '/academy';

export const routeAcademyBreadcrumbsMeta = {
  ACADEMY_HOME: { name: 'Academy', link: ROOT_URL },
  ACADEMY_ARCHIVE: { name: 'Archiv', link: '/archive' },
  ACADEMY_FAQ: { name: 'FAQ', link: '/faq' },
};
export const routeNames = {
  ACADEMY_HOME: 'academy-home',
  ACADEMY_FAQ: 'academy-faq',
  ACADEMY_PAGE_TC: 'academy-tc',
  ACADEMY_ARCHIVE: 'academy-archive',
  ACADEMY_ARCHIVE_CHALLENGE_SUCCESS: 'academy-archive-success',
  ACADEMY_CLASS: 'academy-class',
  ACADEMY_LESSON: 'academy-lesson',
  ACADEMY_CLASS_COMPLETED: 'academy-class-completed',
  ACADEMY_LESSON_SUCCESS: 'academy-lesson-success',
  ACADEMY_LESSON_FAILED: 'academy-lesson-failed',
  ACADEMY_AMBASSADOR: 'rework-academy-ambassador',
  ACADEMY_CHALLENGE: 'rework-academy-challenge',
  ACADEMY_CHALLENGE_SUCCESS: 'rework-academy-challenge-success',
  ACADEMY_CHALLENGE_FAILURE: 'rework-academy-challenge-failure',
  ACADEMY_CHALLENGE_PLAYABLE_PLAY: 'rework-academy-playable-play',
  ACADEMY_CHALLENGE_PODCAST_PLAY: 'rework-academy-podcast-play',
  ACADEMY_CHALLENGE_VIDEO_PLAY: 'rework-academy-video-play',
  ACADEMY_ILUMA_GROUP_GIFT: 'iluma-group-gift',
  ILUMA_MAINTENANCE: 'iluma-maintenance',
};

export default initialiseAcademyRouter;

const routes: RouteRecordRaw[] = [
  {
    path: ROOT_URL,
    name: routeNames.ACADEMY_HOME,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyHome/AcademyHome.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Academy',
    },
  },
  {
    path: `${ROOT_URL}/select-ambassador`,
    name: routeNames.ACADEMY_AMBASSADOR,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/SelectAmbassador/SelectAmbassador.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Academy',
    },
  },
  {
    // Fix broken link on PMG side
    path: `${ROOT_URL}/challenge/challenge-13-was-tun-%20wenn`,
    redirect: `${ROOT_URL}/challenge/challenge-13-was-tun-wenn`,
  },
  {
    path: `${ROOT_URL}/challenge/:challenge`,
    name: routeNames.ACADEMY_CHALLENGE,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyChallenge/AcademyChallenge.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/challenge/success/:challenge`,
    name: routeNames.ACADEMY_CHALLENGE_SUCCESS,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyChallengeSuccess/AcademyChallengeSuccess.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/challenge/failure/:challenge`,
    name: routeNames.ACADEMY_CHALLENGE_FAILURE,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyChallengeFailure/AcademyChallengeFailure.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/challenge/playable/:challenge/play`,
    name: routeNames.ACADEMY_CHALLENGE_PLAYABLE_PLAY,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyChallengePlay/AcademyChallengePlay.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/challenge/podcast/:challenge/play`,
    name: routeNames.ACADEMY_CHALLENGE_PODCAST_PLAY,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyMedia/AcademyMedia.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/challenge/video/:challenge/play`,
    name: routeNames.ACADEMY_CHALLENGE_VIDEO_PLAY,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyMedia/AcademyMedia.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/archive`,
    name: routeNames.ACADEMY_ARCHIVE,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyArchive/AcademyArchive.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Archiv',
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/archive/challenge/:challenge`,
    name: routeNames.ACADEMY_ARCHIVE_CHALLENGE_SUCCESS,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyChallengeSuccess/AcademyChallengeSuccess.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Archiv',
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
  {
    path: `${ROOT_URL}/faqs`,
    name: routeNames.ACADEMY_FAQ,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyFaq/AcademyFaq.vue'
      ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_FAQ],
    },
  },
  {
    path: `${ROOT_URL}/special-quality-boost/teilnahmebedingung`,
    name: routeNames.ACADEMY_PAGE_TC,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/TermsConditionsAcademy.vue'
      ),
  },
  {
    path: `${ROOT_URL}/iluma-group-gift`,
    name: routeNames.ACADEMY_ILUMA_GROUP_GIFT,
    component: () =>
      import(
        /* webpackChunkName: "Academy" */ './views/AcademyIlumaGroupGift/AcademyIlumaGroupGift.vue'
        ),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      breadcrumbs: [routeAcademyBreadcrumbsMeta.ACADEMY_HOME],
    },
  },
];

function initialiseAcademyRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
