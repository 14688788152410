import { RouteRecordRaw, Router } from 'vue-router';

export default initialiseAuthRouter;

const routes: RouteRecordRaw[] = [
  {
    path: '/gigya',
    name: 'gigya',
    component: () =>
      import(/* webpackChunkName: "Gigya" */ './views/Gigya.vue'),
  },
];

function initialiseAuthRouter(router: Router): void {
  routes.forEach(router.addRoute);
}
