
import { defineComponent } from 'vue';
import { NavFirstLevel, NavSecondLevel, NavThirdLevel } from '../NavHeader.types'

import DynamicUnderline from '@/components/DynamicUnderline/DynamicUnderline.vue';
import IconArrowDown from '@/components/svg/IconArrowDown.vue';

export default defineComponent({
  components: { DynamicUnderline, IconArrowDown },
  props: {
    menu: {
      type: Object as () => NavFirstLevel | NavSecondLevel | NavThirdLevel,
    },
    transitionDelay: { type: String, default: '0' },
    transitionName: { type: String, default: null },
    isActive: { type: Boolean, default: true },
    isItemSelected: { type: Boolean, default: false },
    hideUnderline: { type: Boolean, default: false },
    alwaysShowOnActive: { type: Boolean, default: false },
  },
});
