import { ConfigDTO, DefaultAttributesDTO } from '../dtos';
import { Config, ErrorMessage, PRODUCT_TYPES } from '../../types';

const findByType = (type: string) => (attr: DefaultAttributesDTO) =>
  attr.product_type === type;

const configNormalizer = ({ config }: ConfigDTO): Config => ({
  attributes: {
    [PRODUCT_TYPES.VOUCHER]:
      config.default_attributes.find(findByType(PRODUCT_TYPES.VOUCHER))
        .attributes || [],
    [PRODUCT_TYPES.ITG]:
      config.default_attributes.find(findByType(PRODUCT_TYPES.ITG))
        .attributes || [],
  },
  textCheckboxAddress: config.txt_checkbox_address,
  textCtaMissingPoints: config.txt_cta_missing_points,
  textCtaOrder: config.txt_cta_order,
  textCtaRedeem: config.txt_cta_einlösen,
  textThankyou1: config.txt_thankyou_1,
  textThankyou2: config.txt_thankyou_2,
  textThankyou3: config.txt_thankyou_3,
  textThankyouVoucher: config.txt_thankyou_voucher,
  customTexts: (config.custom_texts || []).reduce(
    (acc, item) => ({ ...acc, [item.text_id]: item.lines }),
    {}
  ),
});

export default (config: ConfigDTO): Config | ErrorMessage =>
  'error_message' in config
    ? { errorMessage: config.error_message }
    : configNormalizer(config);
