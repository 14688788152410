module.exports = {
  // Zeplin color Palette
  'primary-slate': '#34303d',
  'primary-soft-white': '#fffdfb',
  'primary-turquoise': '#00d1d2',
  'background-hard-white': '#f6f4f0',
  'background-blue': '#002036',
  'background-black-halo': '#1e1b23',
  'background-black-atomic': ' #0f0f0f',
  'background-dark-gunmetal': '#17212a',
  'background-infinity': '#1f2931',
  'secondary-yellow': '#ffd040',
  'secondary-amber': '#e27d34',
  'secondary-deep-red': '#a22f26',
  'secondary-red': '#db3826',
  'secondary-blue': '#2980b5',
  'secondary-platinum': '#31333f',
  'tints-slate-85': '#524f5a',
  'tints-slate-65': '#7b7881',
  'tints-slate-50': '#99979e',
  'tints-slate-30': '#b8b7bb',
  'tints-slate-15': '#e1e0e2',
  'tints-slate-5': '#f7f7f7',
  'alerts-success-green': '#57760c',
  'alerts-amber': '#e5b052',
  'alerts-error-red': '#c73636',
  'advent-yellow': '#ecdca1',
  'advent-blue': '#001f36',
  /**
   * Used this app for better naming colors:
   * https://chir.ag/projects/name-that-color/
   * Also, find comments below with equivalent colors names in zeplin:
   * https://app.zeplin.io/project/5d77bd0be83ac45f04446a8b/screen/5f91f509ebc2e62fe52e643f
   */
  dove: {
    50: '#f9f9f9',
    100: '#e2e2e2',
    200: '#c5c5c5',
    300: '#9A9A9A', // Main Brand dark variation 2
    400: '#8b8b8b',
    500: '#6e6e6e', // Main Brand dark variation 1
    600: '#585858',
    700: '#404040', // Main brand dark
    800: '#2c2c2c',
    900: '#161616',
    950: '#111111',
  },
  paarl: {
    300: '#D6A76F', // Main brand variation 1
    500: '#9d6b25', // Main brand
  },
  teal: {
    500: '#01d2d3',
    700: '#017e7f',
  },
  indigo: {
    100: '#dde3e5',
    200: '#e0f3f4',
    300: '#98acb2',
    400: '#769199',
    500: '#54757f',
    600: '#435e66',
    700: '#32464c',
    800: '#222f33',
    900: '#111719',
  },
  grey: {
    100: '#fbfbfb',
    200: '#f7f7f7',
    300: '#f4f2f3',
    400: '#f5f5f3', // Light grey
    500: '#eceaeb', // Medium grey
    600: '#dfe0e2', // Base Grey
    650: '#d3d3d3',
    700: '#8e8c8d',
    800: '#5e5e5e',
    900: '#2f2f2f',
  },
};
