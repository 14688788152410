<template>
  <img :src="svgSrc" :alt="type" class="icon" />
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';

export const ICON_THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  LIGHT_TURQUOISE: 'light-turquoise',
  ADVENT_YELLOW: 'advent-yellow',
};

export default defineComponent({
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: (value) => Object.values(ICON_THEMES).includes(value),
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { theme, type } = toRefs(props);
    const svgSrc = computed(() =>
      require(`./assets/${theme.value}/${type.value}.svg`)
    );
    return {
      svgSrc,
    };
  },
});
</script>

<style lang="scss">
.icon {
  @apply inline-block
         not-italic
         h-4;
}
</style>
