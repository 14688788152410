<template>
  <component
    :is="componentType"
    v-bind="{ ...linkAttr, ...isDisabled }"
    class="button"
    :class="[
      `button--${theme}`,
      {
        'button--outline': outline,
        'button--dashed': dashed,
        'button--pill': pill,
        'button--alternate-hover': alternateHover,
        'button--alternate-hover-white': alternateHoverWhite,
        'button--alternate-hover-amber': alternateHoverAmber,
        'button--alternate-hover-deep-red': alternateHoverDeepRed,
      },
    ]"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<script>
import { defineComponent, computed } from 'vue';

export const BUTTON_THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  CUSTOM: 'custom',
  DARK_INVERTED: 'dark-inverted',
};

const LINK_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

const attrToLinkTypeMap = {
  [LINK_TYPES.INTERNAL]: { component: 'RouterLink', attr: 'to' },
  [LINK_TYPES.EXTERNAL]: { component: 'a', attr: 'href' },
};

const CLICK_EVENT = 'click';

export default defineComponent({
  props: {
    theme: {
      type: String,
      // TODO: Change to BUTTON_THEMES.DARK but apparently it doesnt work
      default: 'dark',
      validator: (value) => Object.values(BUTTON_THEMES).includes(value),
    },
    pill: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alternateHover: {
      type: Boolean,
      default: false,
    },
    alternateHoverWhite: {
      type: Boolean,
      default: false,
    },
    alternateHoverAmber: {
      type: Boolean,
      default: false,
    },
    alternateHoverDeepRed: {
      type: Boolean,
      default: false,
    },
  },
  emits: [CLICK_EVENT],
  setup(props, { emit }) {
    const linkType = computed(() =>
      props.href?.[0] === '/' ? LINK_TYPES.INTERNAL : LINK_TYPES.EXTERNAL
    );

    const componentType = computed(() => {
      const componentType = props.href
        ? attrToLinkTypeMap[linkType.value].component
        : 'button';
      return componentType;
    });

    const isDisabled = computed(() =>
      props.disabled ? { disabled: true } : {}
    );

    const linkAttr = computed(() => {
      const attrType = attrToLinkTypeMap[linkType.value].attr;
      return {
        [attrType]: props.href,
      };
    });

    const onClick = () => emit(CLICK_EVENT);

    return {
      componentType,
      linkAttr,
      onClick,
      isDisabled,
    };
  },
});
</script>
<style lang="scss">
@mixin buttonBackgroundGradient($color) {
  background-image: linear-gradient(
    136deg,
    #{$color} 50%,
    rgba(0, 0, 0, 0) 50%
  );
}
.button {
  @apply inline-block w-auto
         text-center
         py-s px-xxl-2
         rounded-full border-transparent
         transition-all duration-500 ease-out
         bg-right;

  background-size: 400% 400%;

  @include buttonBackgroundGradient(theme('colors.primary-turquoise'));

  &--hover, /* This one is for testing purposes only */
  &:hover,
  &:focus,
  &:active {
    @apply text-primary-slate
           border-primary-turquoise
            bg-left;
  }

  &--alternate-hover {
    @include buttonBackgroundGradient(theme('colors.primary-slate'));
    &.button--hover, /* This one is for testing purposes only */
    &:hover,
    &:focus,
    &:active {
      @apply text-primary-soft-white
             border-primary-soft-white;
    }
  }

  &--alternate-hover-white {
    @include buttonBackgroundGradient(theme('colors.primary-soft-white'));
    &.button--hover, /* This one is for testing purposes only */
    &:hover,
    &:focus,
    &:active {
      @apply text-primary-slate
             border-primary-slate;
    }
  }

  &:disabled,
  &[disabled],
  &[disabled='disabled'] {
    cursor: not-allowed;
    pointer-events: none;
    border-width: 1.5px;
    @apply text-tints-slate-50 border-tints-slate-50;
    &:hover {
      @apply bg-primary-soft-white;
    }
  }

  &--dark {
    @apply text-primary-slate  border-primary-slate bg-primary-soft-white;
  }

  &--light {
    @apply text-primary-soft-white border-primary-soft-white bg-primary-slate;
  }

  &--pill {
    @apply py-0 px-0
           h-12 w-12;
  }

  &--outline {
    @apply border border-solid;
  }

  &--dashed {
    @apply border border-dashed;
  }

  &--alternate-hover-amber {
    @include buttonBackgroundGradient(theme('colors.secondary-amber'));
    @apply text-secondary-amber
           border-secondary-amber;
    &.button--hover, /* This one is for testing purposes only */
    &:hover,
    &:focus,
    &:active {
      @apply text-primary-soft-white
             border-secondary-amber;
    }
  }
  &--alternate-hover-deep-red {
    @include buttonBackgroundGradient(theme('colors.secondary-deep-red'));
    @apply text-secondary-deep-red
           border-secondary-deep-red;
    &.button--hover, /* This one is for testing purposes only */
    &:hover,
    &:focus,
    &:active {
      @apply text-primary-soft-white
             border-secondary-deep-red;
    }
  }
}
</style>
