import CalendarResponse from './calendar';
import DoorResponse from './door';
import ActionResponse from './action';

//import ErrorResponse from './error-response';
import { makeResponse } from '@/api/adapters';

export default {
  setup: setupMocks,
};

function setupMocks(mock) {
  mock
    .onGet('advent/')
    .reply(makeResponse('calendar', CalendarResponse));
  mock
    .onGet(/advent\/door\/\d*/)
    .reply(makeResponse('door', DoorResponse));

  // This one interferes with other modules action requests
  // mock.onPost(/action\/[A-Z\d]*/).reply(makeResponse('action', ActionResponse));
}
