import { LoyaltyDTO, ConsumerLoyaltyDTO } from '../dtos';
import { ErrorMessage, Loyalty } from '../../types';

export const loyaltyNormalizer = (loyalty: LoyaltyDTO): Loyalty => ({
  points: loyalty.points,
  statusPoints: loyalty.status_points,
  statusLevel: loyalty.status_level,
});

export default (loyaltyResponse: ConsumerLoyaltyDTO): Loyalty | ErrorMessage =>
  'error_message' in loyaltyResponse
    ? { errorMessage: loyaltyResponse.error_message }
    : loyaltyNormalizer(loyaltyResponse.loyalty);
