import { authApiGet, authApiPost } from '@/api/api';

export const URLS = {
  PRODUCTS: 'products/', // * K-TODO: DELETE THIS
  PRODUCT_DETAIL: (id) => `products/${id}`,
  CONFIG: 'config/',
  CATEGORIES: 'products/categories',
  HERO_SLIDERS: 'cms/heroslider',
  CATEGORY_PRODUCTS: (category) => `products/filter/${encodeURIComponent(category)}`,
  NOTIFICATIONS: 'notification/',
  NOTIFICATIONS_ACTIVATE: (productId, variantId) => `notification/product/${productId}/${variantId}`,
};

/*
 * Usage: fetchProducts(headers)
 * K-TODO: DELETE THIS
 * */
export const fetchProducts = authApiGet(URLS.PRODUCTS);

/*
 * Usage: fetchProduct(productId)(headers)
 * */
export const fetchProduct = (productId) =>
  authApiGet(URLS.PRODUCT_DETAIL(productId));

/**
 * Usage: fetchCategories(headers)
 * */
export const fetchCategories = authApiGet(URLS.CATEGORIES);

/**
 * Usage: fetchHeroSliders(headers)
 * */
export const fetchHeroSliders = authApiGet(URLS.HERO_SLIDERS);

/**
 * Usage: fetchProductByCategory(category)(headers)
 */
export const fetchProductsByCategory = (category) =>
  {
    return authApiGet(URLS.CATEGORY_PRODUCTS(category));
  }

/*
 * Usage: orderProduct(productId, data)(headers)
 * */
export const orderProduct = (productId, data) =>
  authApiPost(URLS.PRODUCT_DETAIL(productId), data);

/*
 * Usage: fetchConfig(headers)
 * */
export const fetchConfig = authApiGet(URLS.CONFIG);

/*
 * Usage: fetchNotifications(headers)
 * */
export const fetchNotifications = authApiGet(URLS.NOTIFICATIONS);

/*
 * Usage: activateNotifications(productId, variant_id)(headers)
 * */
export const activateNotifications = (productId, variantId) =>
  authApiPost(URLS.NOTIFICATIONS_ACTIVATE(productId, variantId));