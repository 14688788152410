// STORIES
export const SET_STORIES = 'SET_STORIES';
export const SET_READ_STORIES_IDS = 'SET_READ_STORIES_IDS';
export const SELECT_ARCHIVED_STORY = 'SELECT_ARCHIVED_STORY';
export const UNSELECT_ARCHIVED_STORY = 'UNSELECT_ARCHIVED_STORY';
export const SET_PROVISIONAL_READ_STORIES_IDS =
  'SET_PROVISIONAL_READ_STORIES_IDS';
export const SELECT_STORY_DROPDOWN_OPTION = 'SELECT_STORY_DROPDOWN_OPTION';
export const UNSELECT_STORY_DROPDOWN_OPTION = 'UNSELECT_STORY_DROPDOWN_OPTION';
export const SET_STORY_DROPDOWN_ERROR = 'SET_STORY_DROPDOWN_ERROR';
export const UNSET_STORY_DROPDOWN_ERROR = 'UNSET_STORY_DROPDOWN_ERROR';
export const READ_STORY = 'READ_STORY';
export const REMOVE_READ_STORY = 'REMOVE_READ_STORY';
