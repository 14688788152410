import { makeResponse } from '@/api/adapters';
import MockAdapter from 'axios-mock-adapter/types';

import mockedOngoingFeatures from './features-ongoing';
import mockedArchivedFeatures from './features-archived';

export default {
  setup: setupMocks,
};

// Setup the mocks defined with axios-mock-adapter
// Bear in mind that the guards are lazy so:
// if we have 2 urls matching, the first one will be the one returned.
function setupMocks(mock: MockAdapter): void {
  // https://config.iqosplus.de/api/frontend
  mock.onGet(/https\:\/\/config\.iqosplus\.de\/api\/frontend\/.*/).reply(
    makeResponse('Feature flags', {
      toggles: [
        ...(mockedArchivedFeatures?.toggles || []),
        ...(mockedOngoingFeatures?.toggles || []),
      ],
    })
  );
}
