export const svg = params => {
  const { name, width, height, color } = params;
  const ratio = width / height;

  return {
    name,
    props: {
      width: { type: [Number, String], default: width },
      height: { type: [Number, String], default: height },
      color: { type: String, default: color },
    },
    data() {
      return {
        viewBox: `0 0 ${width} ${height}`,
      };
    },
    methods: {
      // Size should be calculated if either width or height are different from their default values
      shouldCalculateSize() {
        return Number(this.width) !== width || Number(this.height) !== height;
      },
      // Calculate attribute actual size
      // @param attr The attribute (width or height) to calculate
      // @param reference Quick reference to the attribute to use to calculated the other one
      // @param attrRatio The ratio relative to the attr to calculate
      // @return the newly calculated attribute
      calculateSize(attr, reference, attrRatio) {
        return this.shouldCalculateSize() && Number(this[attr]) === params[attr]
          ? this[reference] * attrRatio
          : this[attr];
      },
    },
    computed: {
      calculatedWidth() {
        return this.calculateSize('width', 'height', ratio);
      },
      calculatedHeight() {
        return this.calculateSize('height', 'width', 1 / ratio);
      },
    },
  };
};
