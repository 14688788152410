const hompepageGrid = (mockId = 0) => [
//   {
//      'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
//      'meta':{
//         'type':'academy2.AcademyChallengePage',
//         'detail_url':'https:///api/v2/drafts/34/',
//         'html_url':'https://localhost/academy2/challenges/iluma-journey-challenge-1/',
//         'slug':'iluma-journey-challenge-1',
//         'show_in_menus':false,
//         'seo_title':'',
//         'search_description':'',
//         'first_published_at':'2023-04-06T14:25:44.920121+02:00',
//         'alias_of':null,
//         'parent':{
//            'id':50,
//            'meta':{
//               'type':'academy2.ChallengesPage',
//               'detail_url':'https:///api/v2/drafts/50/',
//               'html_url':'https://localhost/academy2/challenges/'
//            },
//            'title':'Challenges'
//         },
//         'locale':'en'
//      },
//      'title':'Iluma Quiz 1',
//      'challenge_title':'IQOS Academy Illuma Challenges',
//      'headline':'0. Deine Vorteile mit IQOS ILUMA.',
//      'content':'<p data-block-key="0kh8w">In dieser Challenge stellen wir Dir die neuen IQOS ILUMA Ger\u00e4te vor. Bleib gespannt, am Ende wartet ein kurzes Quiz auf Dich.</p>',
//      'label':'Special',
//      'label_color':'#ffd040',
//      'points':'10',
//      'points_reason':'Academy - Challenge',
//      'points_type':'iqos_points',
//      'main_image':{
//         'id':91,
//         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-01-sizzle3x_W9PKIGG.webp',
//         'title':'academy-teaser-image-01-sizzle@3x',
//         'image_desktop_1x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_W9PKIGG.scale-50.png',
//            'width':1755,
//            'height':1755
//         },
//         'image_desktop_2x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_W9PKIGG.scale-100.png',
//            'width':3510,
//            'height':3510
//         },
//         'image_desktop_3x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x.2e16d0ba.fill-4320x2316_iRhM5C4.png',
//            'width':3510,
//            'height':1882
//         },
//         'image_mobile_1x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_W9PKIGG.scale-15.png',
//            'width':526,
//            'height':526
//         },
//         'image_mobile_2x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_W9PKIGG.scale-25_ZwT7heN.png',
//            'width':877,
//            'height':877
//         },
//         'image_mobile_3x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_W9PKIGG.scale-35.png',
//            'width':1228,
//            'height':1228
//         }
//      },
//      'challenge_content':{
//         'type':'playable',
//         'value':{
//            'playable_game_id':'112539',
//            'playable_game_url':'https://pmgacademy.leadfamly.com/quiz-1-iqos-iluma-tests',
//            'welcome_headline':'Gl\u00fcckwunsch',
//            'welcome_message':'<p data-block-key="je3un">Du m\u00f6chtest mehr \u00fcber die neuen IQOS ILUMA Produkte erfahren? In diesem Video stellen wir sie Dir vor und geben Dir einen \u00dcberblick \u00fcber ihre Unterschiede.</p>',
//            'wrong_answer_headline':'Ups! Das war leider nicht richtig',
//            'wrong_answer_message':'<p data-block-key="zer7f">Versuch es gleich nochmal.</p>',
//            'cta_name':'Challenge starten',
//            'duration':'3',
//            'no_ambassador':false,
//            'ambassador':{
//               'id':36,
//               'name':'Ambassador Ines'
//            }
//         },
//         'id': 'ba948f7c-ec49-4a14-9834-f4440b7854ed'
//      },
//      'group':{
//         'id':33,
//         'meta':{
//            'type':'academy2.AcademyGroupPage',
//            'detail_url':'https:///api/v2/drafts/33/'
//         },
//         'title':'Iluma Journey'
//      },
//      'challenge_done':false
//   },
//   {
//      'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
//      'meta':{
//         'type':'academy2.AcademyChallengePage',
//         'detail_url':'https:///api/v2/drafts/65/',
//         'html_url':'https://localhost/academy2/challenges/iluma-journey-challenge-2/',
//         'slug':'iluma-journey-challenge-2',
//         'show_in_menus':false,
//         'seo_title':'',
//         'search_description':'',
//         'first_published_at':'2023-04-27T10:20:27.145486+02:00',
//         'alias_of':null,
//         'parent':{
//            'id':50,
//            'meta':{
//               'type':'academy2.ChallengesPage',
//               'detail_url':'https:///api/v2/drafts/50/',
//               'html_url':'https://localhost/academy2/challenges/'
//            },
//            'title':'Challenges'
//         },
//         'locale':'en'
//      },
//      'title':'Iluma Quiz 2',
//      'challenge_title':'IQOS Academy Illuma Challenges',
//      'headline':'1. Die revolution\u00e4re ILUMA Technologie.',
//      'content':'<p data-block-key="0kh8w">Was ist das revolution\u00e4re SMARTCORE INDUCTION SYSTEM\u2122, welches in allen IQOS ILUMA Ger\u00e4ten verbaut ist? Wie funktionieren die speziell entwickelten TEREA Tabaksticks? Diese Fragen beantworten wir Dir in diesem Video.</p>',
//      'label':'Special',
//      'label_color':'#ffd040',
//      'points':'10',
//      'points_reason':'Academy - Challenge',
//      'points_type':'iqos_points',
//      'main_image':{
//         'id':88,
//         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-01-sizzle3x_02w8jU3.webp',
//         'title':'academy-teaser-image-01-sizzle@3x',
//         'image_desktop_1x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_02w8jU3.scale-50.png',
//            'width':1755,
//            'height':1755
//         },
//         'image_desktop_2x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_02w8jU3.scale-100.png',
//            'width':3510,
//            'height':3510
//         },
//         'image_desktop_3x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x.2e16d0ba.fill-4320x2316_47gFk8w.png',
//            'width':3510,
//            'height':1882
//         },
//         'image_mobile_1x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_02w8jU3.scale-15.png',
//            'width':526,
//            'height':526
//         },
//         'image_mobile_2x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_02w8jU3.scale-25.png',
//            'width':877,
//            'height':877
//         },
//         'image_mobile_3x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_02w8jU3.scale-35.png',
//            'width':1228,
//            'height':1228
//         }
//      },
//      'challenge_content':{
//         'type':'playable',
//         'value':{
//            'playable_game_id':'112579',
//            'playable_game_url':'https://pmgacademy.leadfamly.com/quiz-2-iqos-iluma-tests',
//            'welcome_headline':'Gl\u00fcckwunsch',
//            'welcome_message':'<p data-block-key="je3un">Starte doch gleich die folgende Challenge: Schau das Video \u00fcber die revolution\u00e4re Technologie hinter IQOS ILUMA an. Beantworte das Quiz am Ende und sichere Dir wertvolle Punkte.</p>',
//            'wrong_answer_headline':'Ups! Das war leider nicht richtig',
//            'wrong_answer_message':'<p data-block-key="zer7f">Versuch es gleich nochmal.</p>',
//            'cta_name':'Challenge starten',
//            'duration':'12',
//            'no_ambassador':false,
//            'ambassador':{
//               'id':36,
//               'name':'Ambassador Ines'
//            }
//         },
//         'id': 'ba948f7c-ec49-4a14-9834-f4440b7854ed'
//      },
//      'group':{
//         'id':33,
//         'meta':{
//            'type':'academy2.AcademyGroupPage',
//            'detail_url':'https:///api/v2/drafts/33/'
//         },
//         'title':'Iluma Journey'
//      },
//      'challenge_done':false
//   },
//   {
//      'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
//      'meta':{
//         'type':'academy2.AcademyChallengePage',
//         'detail_url':'https:///api/v2/drafts/67/',
//         'html_url':'https://localhost/academy2/challenges/iluma-journey-challenge-4/',
//         'slug':'iluma-journey-challenge-4',
//         'show_in_menus':false,
//         'seo_title':'',
//         'search_description':'',
//         'first_published_at':'2023-04-27T10:25:17.557943+02:00',
//         'alias_of':null,
//         'parent':{
//            'id':50,
//            'meta':{
//               'type':'academy2.ChallengesPage',
//               'detail_url':'https:///api/v2/drafts/50/',
//               'html_url':'https://localhost/academy2/challenges/'
//            },
//            'title':'Challenges'
//         },
//         'locale':'en'
//      },
//      'title':'Iluma Quiz 4',
//      'challenge_title':'IQOS Academy Illuma Challenges',
//      'headline':'2. Unterst\u00fctzung f\u00fcr Dich, jederzeit.',
//      'content':'<p data-block-key="0kh8w">Trotz super leichter Benutzung von IQOS ILUMA bieten wir Dir ein umfangreiches Service-Angebot. Welche M\u00f6glichkeiten es gibt, erf\u00e4hrst Du in diesem Video.</p>',
//      'label':'Special',
//      'label_color':'#ffd040',
//      'points':'10',
//      'points_reason':'Academy - Challenge',
//      'points_type':'iqos_points',
//      'main_image':{
//         'id':90,
//         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-01-sizzle3x_miPlxBt.webp',
//         'title':'academy-teaser-image-01-sizzle@3x',
//         'image_desktop_1x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-50.png',
//            'width':1755,
//            'height':1755
//         },
//         'image_desktop_2x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-100.png',
//            'width':3510,
//            'height':3510
//         },
//         'image_desktop_3x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x.2e16d0ba.fill-4320x2316_84Mavxg.png',
//            'width':3510,
//            'height':1882
//         },
//         'image_mobile_1x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-15.png',
//            'width':526,
//            'height':526
//         },
//         'image_mobile_2x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-25.png',
//            'width':877,
//            'height':877
//         },
//         'image_mobile_3x':{
//            'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-35.png',
//            'width':1228,
//            'height':1228
//         }
//      },
//      'challenge_content':{
//         'type':'playable',
//         'value':{
//            'playable_game_id':'112620',
//            'playable_game_url':'https://pmgacademy.leadfamly.com/quiz-4-iqos-iluma-tests',
//            'welcome_headline':'Gl\u00fcckwunsch',
//            'welcome_message':'<p data-block-key="je3un">Challenge gef\u00e4llig? Wir zeigen Dir unser umfangreiches Service-Angebot, das Dir offline und online zur Verf\u00fcgung steht. Am Ende der Challenge hast Du die Chance, 10 IQOS Points zu gewinnen.</p>',
//            'wrong_answer_headline':'Ups! Das war leider nicht richtig',
//            'wrong_answer_message':'<p data-block-key="zer7f">Versuch es gleich nochmal.</p>',
//            'cta_name':'Challenge starten',
//            'duration':'12',
//            'no_ambassador':false,
//            'ambassador':{
//               'id':36,
//               'name':'Ambassador Ines'
//            }
//         },
//         'id': 'ba948f7c-ec49-4a14-9834-f4440b7854ed'
//      },
//      'group':{
//         'id':33,
//         'meta':{
//            'type':'academy2.AcademyGroupPage',
//            'detail_url':'https:///api/v2/drafts/33/'
//         },
//         'title':'Iluma Journey'
//      },
//      'challenge_done':false
//   },
//   {
//    'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
//    'meta':{
//       'type':'academy2.AcademyChallengePage',
//       'detail_url':'https:///api/v2/drafts/67/',
//       'html_url':'https://localhost/academy2/challenges/iluma-journey-challenge-4/',
//       'slug':'iluma-journey-challenge-4',
//       'show_in_menus':false,
//       'seo_title':'',
//       'search_description':'',
//       'first_published_at':'2023-04-27T10:25:17.557943+02:00',
//       'alias_of':null,
//       'parent':{
//          'id':50,
//          'meta':{
//             'type':'academy2.ChallengesPage',
//             'detail_url':'https:///api/v2/drafts/50/',
//             'html_url':'https://localhost/academy2/challenges/'
//          },
//          'title':'Challenges'
//       },
//       'locale':'en'
//    },
//    'title':'Iluma Quiz 4',
//    'challenge_title':'IQOS Academy Illuma Challenges',
//    'headline':'3. Unterst\u00fctzung f\u00fcr Dich, jederzeit.',
//    'content':'<p data-block-key="0kh8w">Trotz super leichter Benutzung von IQOS ILUMA bieten wir Dir ein umfangreiches Service-Angebot. Welche M\u00f6glichkeiten es gibt, erf\u00e4hrst Du in diesem Video.</p>',
//    'label':'Special',
//    'label_color':'#ffd040',
//    'points':'10',
//    'points_reason':'Academy - Challenge',
//    'points_type':'iqos_points',
//    'main_image':{
//       'id':90,
//       'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-01-sizzle3x_miPlxBt.webp',
//       'title':'academy-teaser-image-01-sizzle@3x',
//       'image_desktop_1x':{
//          'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-50.png',
//          'width':1755,
//          'height':1755
//       },
//       'image_desktop_2x':{
//          'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-100.png',
//          'width':3510,
//          'height':3510
//       },
//       'image_desktop_3x':{
//          'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x.2e16d0ba.fill-4320x2316_84Mavxg.png',
//          'width':3510,
//          'height':1882
//       },
//       'image_mobile_1x':{
//          'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-15.png',
//          'width':526,
//          'height':526
//       },
//       'image_mobile_2x':{
//          'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-25.png',
//          'width':877,
//          'height':877
//       },
//       'image_mobile_3x':{
//          'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-01-sizzle3x_miPlxBt.scale-35.png',
//          'width':1228,
//          'height':1228
//       }
//    },
//    'challenge_content':{
//       'type':'playable',
//       'value':{
//          'playable_game_id':'112620',
//          'playable_game_url':'https://pmgacademy.leadfamly.com/quiz-4-iqos-iluma-tests',
//          'welcome_headline':'Gl\u00fcckwunsch',
//          'welcome_message':'<p data-block-key="je3un">Challenge gef\u00e4llig? Wir zeigen Dir unser umfangreiches Service-Angebot, das Dir offline und online zur Verf\u00fcgung steht. Am Ende der Challenge hast Du die Chance, 10 IQOS Points zu gewinnen.</p>',
//          'wrong_answer_headline':'Ups! Das war leider nicht richtig',
//          'wrong_answer_message':'<p data-block-key="zer7f">Versuch es gleich nochmal.</p>',
//          'cta_name':'Challenge starten',
//          'duration':'12',
//          'no_ambassador':false,
//          'ambassador':{
//             'id':36,
//             'name':'Ambassador Ines'
//          }
//       },
//       'id': 'ba948f7c-ec49-4a14-9834-f4440b7854ed'
//    },
//    'group':{
//       'id':33,
//       'meta':{
//          'type':'academy2.AcademyGroupPage',
//          'detail_url':'https:///api/v2/drafts/33/'
//       },
//       'title':'Iluma Journey'
//    },
//    'challenge_done':false
//   },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.CampaignPage',
        'detail_url':'https:///api/v2/drafts/54/',
        'html_url':'https://localhost/academy2/campaigns/rewardshop-modul/',
        'slug':'rewardshop-modul',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-24T12:17:46.684596+02:00',
        'alias_of':null,
        'parent':{
           'id':53,
           'meta':{
              'type':'academy2.CampaignsPage',
              'detail_url':'https:///api/v2/drafts/53/',
              'html_url':'https://localhost/academy2/campaigns/'
           },
           'title':'Campaigns'
        },
        'locale':'en'
     },
     'title':'Iluma',
     'image':{
        'id':79,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/image3x_iuYZn2x.jpg',
        'title':'Podcast2_1',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-50.jpg',
           'width':897,
           'height':897
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-100.jpg',
           'width':1794,
           'height':1794
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.2e16d0ba.fill-4320x2316.jpg',
           'width':1794,
           'height':962
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-15.jpg',
           'width':269,
           'height':269
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-25.jpg',
           'width':448,
           'height':448
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-35.jpg',
           'width':627,
           'height':627
        }
     },
     'small_headline':'X',
     'font_style':'dark',
     'headline':'X',
     'text':'<p data-block-key="0tgvk">X</p>',
     'cta_name':'X',
     'cta_link':'x',
     'label':'',
     'label_color':'#b71f1f'
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/48/',
        'html_url':'https://localhost/academy2/challenges/old-challenge-6/',
        'slug':'old-challenge-6',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T11:57:00.468345+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Old Challenge 6',
     'challenge_title':'IQOS Academy Illuma Challenges',
     'headline':'Wir machen alles ohne Asche.',
     'content':'<p data-block-key="kz2qc">Wir bei IQOS haben die Asche aus der Gleichung genommen. Tu es uns gleich und befreie Dich von l\u00e4stiger Asche. F\u00fcr ein sauberes IQOS Erlebnis.</p>',
     'label':'',
     'label_color':'',
     'points':'10',
     'points_reason':'Academy - Challenge',
     'points_type':'iqos_points',
     'main_image':{
        'id':73,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_ILUMA_054_AP_201003_0171_H_RGB.jpg',
        'title':'IQOS_ILUMA_054_AP_201003_0171_H_RGB',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_054_AP_201003_0171_H_RGB.scale-50.jpg',
           'width':1042,
           'height':695
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_054_AP_201003_0171_H_RGB.scale-100.jpg',
           'width':2085,
           'height':1390
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_054_AP_201003_0171_H_.2e16d0ba.fill-4320x2316.jpg',
           'width':2085,
           'height':1118
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_054_AP_201003_0171_H_RGB.scale-15.jpg',
           'width':312,
           'height':208
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_054_AP_201003_0171_H_RGB.scale-25.jpg',
           'width':521,
           'height':347
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_054_AP_201003_0171_H_RGB.scale-35.jpg',
           'width':729,
           'height':486
        }
     },
     'challenge_content':{
        'type':'playable',
        'value':{
           'playable_game_id':'111587',
           'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-6-iqos-iluma-version',
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="k5689">Klicke Dich direkt durch die n\u00e4chste Challenge, um Punkte f\u00fcr den IQOS Club zu sammeln und Dich auf Dein n\u00e4chstes Statuslevel zu heben.</p>',
           'wrong_answer_headline':'Ups! Das war leider nicht richtig',
           'wrong_answer_message':'<p data-block-key="743gj">Versuch es nochmal.</p>',
           'cta_name':'Challenge starten',
           'duration':'12',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': 'caec726a-27a8-4f56-8d96-cfa465f0bd77'
     },
     'group':{
        'id':71,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/71/'
        },
        'title':'Challenges'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.CampaignPage',
        'detail_url':'https:///api/v2/drafts/72/',
        'html_url':'https://localhost/academy2/campaigns/stories-modul/',
        'slug':'stories-modul',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-05-04T10:58:36.566979+02:00',
        'alias_of':null,
        'parent':{
           'id':53,
           'meta':{
              'type':'academy2.CampaignsPage',
              'detail_url':'https:///api/v2/drafts/53/',
              'html_url':'https://localhost/academy2/campaigns/'
           },
           'title':'Campaigns'
        },
        'locale':'en'
     },
     'title':'Stories Modul',
     'image':{
        'id':62,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.jpg',
        'title':'IQOS BERLIN LAUNCH EVENT-DJ 464x464',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-50.jpg',
           'width':232,
           'height':232
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-100.jpg',
           'width':464,
           'height':464
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x.2e16d0ba.fill-4320x2316.jpg',
           'width':464,
           'height':250
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-15.jpg',
           'width':69,
           'height':69
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-25.jpg',
           'width':116,
           'height':116
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-35.jpg',
           'width':162,
           'height':162
        }
     },
     'small_headline':'Small headline',
     'font_style':'white',
     'headline':'Big headline',
     'text':'<p data-block-key="0tgvk">Copy text</p>',
     'cta_name':'CTA name',
     'cta_link':'Link to Stories',
     'label':'',
     'label_color':'#b71f1f'
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/44/',
        'html_url':'https://localhost/academy2/challenges/old-challenge-2/',
        'slug':'old-challenge-2',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T11:53:19.061008+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Old Challenge 2',
     'challenge_title':'IQOS Academy Illuma Challenges',
     'headline':'Eine Frage des guten Geschmacks.',
     'content':'<p data-block-key="mn5hf">Wie gew\u00f6hnt man sich an den Geschmack von IQOS? Starte unser IQOS Geschmacks-Quiz und sammle 10 IQOS Points sowie 10 Statuspunkte.</p>',
     'label':'',
     'label_color':'',
     'points':'10',
     'points_reason':'Academy - Challenge',
     'points_type':'iqos_points',
     'main_image':{
        'id':74,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Retouch.jpg',
        'title':'Retouch',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-50.jpg',
           'width':527,
           'height':704
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-100.jpg',
           'width':1054,
           'height':1408
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.2e16d0ba.fill-4320x2316.jpg',
           'width':1054,
           'height':566
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-15.jpg',
           'width':158,
           'height':211
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-25.jpg',
           'width':263,
           'height':352
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-35.jpg',
           'width':368,
           'height':492
        }
     },
     'challenge_content':{
        'type':'playable',
        'value':{
           'playable_game_id':'111519',
           'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-2-iqos-iluma-version-22',
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="1usnt">Du kannst Dir wieder Punkte f\u00fcr den IQOS Club sichern. Starte dazu direkt mit der n\u00e4chsten Challenge und teste Deinen Geschmack.</p>',
           'wrong_answer_headline':'Ups! Das war leider nicht richtig',
           'wrong_answer_message':'<p data-block-key="ethoj">Versuch es nochmal.</p>',
           'cta_name':'Challenge starten',
           'duration':'12',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': 'c053cc42-f5a0-4f84-be52-f327f13f4ad5'
     },
     'group':{
        'id':71,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/71/'
        },
        'title':'Challenges'
     },
     'challenge_done':false
  },
  {
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/44/',
      'html_url':'https://localhost/academy2/challenges/old-challenge-2/',
      'slug':'old-challenge-2',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-26T11:53:19.061008+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Old Challenge 2',
   'challenge_title':'IQOS Academy Illuma Challenges',
   'headline':'Eine Frage des guten Geschmacks.',
   'content':'<p data-block-key="mn5hf">Wie gew\u00f6hnt man sich an den Geschmack von IQOS? Starte unser IQOS Geschmacks-Quiz und sammle 10 IQOS Points sowie 10 Statuspunkte.</p>',
   'label':'',
   'label_color':'',
   'points':'10',
   'points_reason':'Academy - Challenge',
   'points_type':'iqos_points',
   'main_image':{
      'id':74,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Retouch.jpg',
      'title':'Retouch',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-50.jpg',
         'width':527,
         'height':704
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-100.jpg',
         'width':1054,
         'height':1408
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.2e16d0ba.fill-4320x2316.jpg',
         'width':1054,
         'height':566
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-15.jpg',
         'width':158,
         'height':211
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-25.jpg',
         'width':263,
         'height':352
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Retouch.scale-35.jpg',
         'width':368,
         'height':492
      }
   },
   'challenge_content':{
      'type':'playable',
      'value':{
         'playable_game_id':'111519',
         'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-2-iqos-iluma-version-22',
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="1usnt">Du kannst Dir wieder Punkte f\u00fcr den IQOS Club sichern. Starte dazu direkt mit der n\u00e4chsten Challenge und teste Deinen Geschmack.</p>',
         'wrong_answer_headline':'Ups! Das war leider nicht richtig',
         'wrong_answer_message':'<p data-block-key="ethoj">Versuch es nochmal.</p>',
         'cta_name':'Challenge starten',
         'duration':'12',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': 'c053cc42-f5a0-4f84-be52-f327f13f4ad5'
   },
   'group':{
      'id':71,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/71/'
      },
      'title':'Challenges'
   },
   'challenge_done':false
},
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/42/',
        'html_url':'https://localhost/academy2/challenges/old-challenge-1/',
        'slug':'old-challenge-1',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-17T08:32:41.460111+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Old Challenge 1',
     'challenge_title':'IQOS Academy Illuma Challenges',
     'headline':'Der Geruch entscheidet.',
     'content':'<p data-block-key="b3jv8">Warum riecht IQOS anders? Teste Dein Wissen in dieser Challenge und sammle 10 IQOS Points, sowie 10 Statuspunkte.</p>',
     'label':'',
     'label_color':'',
     'points':'10',
     'points_reason':'Academy - Challenge',
     'points_type':'iqos_points',
     'main_image':{
        'id':72,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.jpg',
        'title':'IQOS_ILUMA_027_GA_200202_0062_H_RGB',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-50.jpg',
           'width':1042,
           'height':695
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-100.jpg',
           'width':2085,
           'height':1390
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_.2e16d0ba.fill-4320x2316.jpg',
           'width':2085,
           'height':1118
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-15.jpg',
           'width':312,
           'height':208
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-25.jpg',
           'width':521,
           'height':347
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-35.jpg',
           'width':729,
           'height':486
        }
     },
     'challenge_content':{
        'type':'playable',
        'value':{
           'playable_game_id':'111415',
           'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-1-iqos-iluma-version',
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="d9xwq">Hier geht es direkt zur n\u00e4chsten Challenge. Schaffst Du es, diese zu meistern und Dir daf\u00fcr 10 IQOS Points, sowie 10 Statuspunkte zu sichern? Viel Erfolg.</p>',
           'wrong_answer_headline':'Ups! Das war leider nicht richtig',
           'wrong_answer_message':'<p data-block-key="1k53o">Versuch es nochmal.</p>',
           'cta_name':'Challenge starten',
           'duration':'12',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': 'ed8d3e0b-b970-4910-bb42-e07cd6472879'
     },
     'group':{
        'id':71,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/71/'
        },
        'title':'Challenges'
     },
     'challenge_done':false
  },
  {
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/42/',
      'html_url':'https://localhost/academy2/challenges/old-challenge-1/',
      'slug':'old-challenge-1',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-17T08:32:41.460111+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Old Challenge 1',
   'challenge_title':'IQOS Academy Illuma Challenges',
   'headline':'Der Geruch entscheidet.',
   'content':'<p data-block-key="b3jv8">Warum riecht IQOS anders? Teste Dein Wissen in dieser Challenge und sammle 10 IQOS Points, sowie 10 Statuspunkte.</p>',
   'label':'',
   'label_color':'',
   'points':'10',
   'points_reason':'Academy - Challenge',
   'points_type':'iqos_points',
   'main_image':{
      'id':72,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.jpg',
      'title':'IQOS_ILUMA_027_GA_200202_0062_H_RGB',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-50.jpg',
         'width':1042,
         'height':695
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-100.jpg',
         'width':2085,
         'height':1390
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_.2e16d0ba.fill-4320x2316.jpg',
         'width':2085,
         'height':1118
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-15.jpg',
         'width':312,
         'height':208
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-25.jpg',
         'width':521,
         'height':347
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-35.jpg',
         'width':729,
         'height':486
      }
   },
   'challenge_content':{
      'type':'playable',
      'value':{
         'playable_game_id':'111415',
         'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-1-iqos-iluma-version',
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="d9xwq">Hier geht es direkt zur n\u00e4chsten Challenge. Schaffst Du es, diese zu meistern und Dir daf\u00fcr 10 IQOS Points, sowie 10 Statuspunkte zu sichern? Viel Erfolg.</p>',
         'wrong_answer_headline':'Ups! Das war leider nicht richtig',
         'wrong_answer_message':'<p data-block-key="1k53o">Versuch es nochmal.</p>',
         'cta_name':'Challenge starten',
         'duration':'12',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': 'ed8d3e0b-b970-4910-bb42-e07cd6472879'
   },
   'group':{
      'id':71,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/71/'
      },
      'title':'Challenges'
   },
   'challenge_done':false
},
{
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/42/',
      'html_url':'https://localhost/academy2/challenges/old-challenge-1/',
      'slug':'old-challenge-1',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-17T08:32:41.460111+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Old Challenge 1',
   'challenge_title':'IQOS Academy Illuma Challenges',
   'headline':'Der Geruch entscheidet.',
   'content':'<p data-block-key="b3jv8">Warum riecht IQOS anders? Teste Dein Wissen in dieser Challenge und sammle 10 IQOS Points, sowie 10 Statuspunkte.</p>',
   'label':'',
   'label_color':'',
   'points':'10',
   'points_reason':'Academy - Challenge',
   'points_type':'iqos_points',
   'main_image':{
      'id':72,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.jpg',
      'title':'IQOS_ILUMA_027_GA_200202_0062_H_RGB',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-50.jpg',
         'width':1042,
         'height':695
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-100.jpg',
         'width':2085,
         'height':1390
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_.2e16d0ba.fill-4320x2316.jpg',
         'width':2085,
         'height':1118
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-15.jpg',
         'width':312,
         'height':208
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-25.jpg',
         'width':521,
         'height':347
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-35.jpg',
         'width':729,
         'height':486
      }
   },
   'challenge_content':{
      'type':'playable',
      'value':{
         'playable_game_id':'111415',
         'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-1-iqos-iluma-version',
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="d9xwq">Hier geht es direkt zur n\u00e4chsten Challenge. Schaffst Du es, diese zu meistern und Dir daf\u00fcr 10 IQOS Points, sowie 10 Statuspunkte zu sichern? Viel Erfolg.</p>',
         'wrong_answer_headline':'Ups! Das war leider nicht richtig',
         'wrong_answer_message':'<p data-block-key="1k53o">Versuch es nochmal.</p>',
         'cta_name':'Challenge starten',
         'duration':'12',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': 'ed8d3e0b-b970-4910-bb42-e07cd6472879'
   },
   'group':{
      'id':71,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/71/'
      },
      'title':'Challenges'
   },
   'challenge_done':false
},
{
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/42/',
      'html_url':'https://localhost/academy2/challenges/old-challenge-1/',
      'slug':'old-challenge-1',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-17T08:32:41.460111+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Old Challenge 1',
   'challenge_title':'IQOS Academy Illuma Challenges',
   'headline':'Der Geruch entscheidet.',
   'content':'<p data-block-key="b3jv8">Warum riecht IQOS anders? Teste Dein Wissen in dieser Challenge und sammle 10 IQOS Points, sowie 10 Statuspunkte.</p>',
   'label':'',
   'label_color':'',
   'points':'10',
   'points_reason':'Academy - Challenge',
   'points_type':'iqos_points',
   'main_image':{
      'id':72,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.jpg',
      'title':'IQOS_ILUMA_027_GA_200202_0062_H_RGB',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-50.jpg',
         'width':1042,
         'height':695
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-100.jpg',
         'width':2085,
         'height':1390
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_.2e16d0ba.fill-4320x2316.jpg',
         'width':2085,
         'height':1118
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-15.jpg',
         'width':312,
         'height':208
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-25.jpg',
         'width':521,
         'height':347
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-35.jpg',
         'width':729,
         'height':486
      }
   },
   'challenge_content':{
      'type':'playable',
      'value':{
         'playable_game_id':'111415',
         'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-1-iqos-iluma-version',
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="d9xwq">Hier geht es direkt zur n\u00e4chsten Challenge. Schaffst Du es, diese zu meistern und Dir daf\u00fcr 10 IQOS Points, sowie 10 Statuspunkte zu sichern? Viel Erfolg.</p>',
         'wrong_answer_headline':'Ups! Das war leider nicht richtig',
         'wrong_answer_message':'<p data-block-key="1k53o">Versuch es nochmal.</p>',
         'cta_name':'Challenge starten',
         'duration':'12',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': 'ed8d3e0b-b970-4910-bb42-e07cd6472879'
   },
   'group':{
      'id':71,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/71/'
      },
      'title':'Challenges'
   },
   'challenge_done':false
},
{
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/42/',
      'html_url':'https://localhost/academy2/challenges/old-challenge-1/',
      'slug':'old-challenge-1',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-17T08:32:41.460111+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Old Challenge 1',
   'challenge_title':'IQOS Academy Illuma Challenges',
   'headline':'Der Geruch entscheidet.',
   'content':'<p data-block-key="b3jv8">Warum riecht IQOS anders? Teste Dein Wissen in dieser Challenge und sammle 10 IQOS Points, sowie 10 Statuspunkte.</p>',
   'label':'',
   'label_color':'',
   'points':'10',
   'points_reason':'Academy - Challenge',
   'points_type':'iqos_points',
   'main_image':{
      'id':72,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.jpg',
      'title':'IQOS_ILUMA_027_GA_200202_0062_H_RGB',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-50.jpg',
         'width':1042,
         'height':695
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-100.jpg',
         'width':2085,
         'height':1390
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_.2e16d0ba.fill-4320x2316.jpg',
         'width':2085,
         'height':1118
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-15.jpg',
         'width':312,
         'height':208
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-25.jpg',
         'width':521,
         'height':347
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_ILUMA_027_GA_200202_0062_H_RGB.scale-35.jpg',
         'width':729,
         'height':486
      }
   },
   'challenge_content':{
      'type':'playable',
      'value':{
         'playable_game_id':'111415',
         'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-1-iqos-iluma-version',
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="d9xwq">Hier geht es direkt zur n\u00e4chsten Challenge. Schaffst Du es, diese zu meistern und Dir daf\u00fcr 10 IQOS Points, sowie 10 Statuspunkte zu sichern? Viel Erfolg.</p>',
         'wrong_answer_headline':'Ups! Das war leider nicht richtig',
         'wrong_answer_message':'<p data-block-key="1k53o">Versuch es nochmal.</p>',
         'cta_name':'Challenge starten',
         'duration':'12',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': 'ed8d3e0b-b970-4910-bb42-e07cd6472879'
   },
   'group':{
      'id':71,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/71/'
      },
      'title':'Challenges'
   },
   'challenge_done':false
},
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/45/',
        'html_url':'https://localhost/academy2/challenges/old-challenge-3/',
        'slug':'old-challenge-3',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T11:54:08.707064+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Old Challenge 3',
     'challenge_title':'IQOS Academy Illuma Challenges',
     'headline':'Was steckt in TEREA Tabaksticks?',
     'content':'<p data-block-key="ovxhu">Zeig uns Dein Tabak-Wissen und finde heraus, was in den neuen TEREA Tabaksticks steckt. \u00dcberraschend anders.</p>',
     'label':'',
     'label_color':'',
     'points':'10',
     'points_reason':'Academy - Challenge',
     'points_type':'iqos_points',
     'main_image':{
        'id':69,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/2.-Echter-Tabak-B_72dpi.jpg',
        'title':'2.-Echter-Tabak-B_72dpi',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/2.-Echter-Tabak-B_72dpi.scale-50.jpg',
           'width':963,
           'height':643
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/2.-Echter-Tabak-B_72dpi.scale-100.jpg',
           'width':1927,
           'height':1286
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/2.-Echter-Tabak-B_72dpi.2e16d0ba.fill-4320x2316.jpg',
           'width':1927,
           'height':1034
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/2.-Echter-Tabak-B_72dpi.scale-15.jpg',
           'width':289,
           'height':192
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/2.-Echter-Tabak-B_72dpi.scale-25.jpg',
           'width':481,
           'height':321
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/2.-Echter-Tabak-B_72dpi.scale-35.jpg',
           'width':674,
           'height':450
        }
     },
     'challenge_content':{
        'type':'playable',
        'value':{
           'playable_game_id':'111522',
           'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-3-iqos-iluma-version',
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="e77ts">Ich habe wieder neue Entdeckungen f\u00fcr Dich im Gep\u00e4ck. Die n\u00e4chste Challenge hat Facts \u00fcber die TEREA Tabaksticks parat, die Du vielleicht noch nicht kanntest. Starte gleich jetzt.</p>',
           'wrong_answer_headline':'Ups! Das war leider nicht richtig',
           'wrong_answer_message':'<p data-block-key="1qqj7">Versuch es nochmal.</p>',
           'cta_name':'Neuer Versuch',
           'duration':'12',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '2b89af54-ea4b-45de-9748-9d78443c4c2d'
     },
     'group':{
        'id':71,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/71/'
        },
        'title':'Challenges'
     },
     'challenge_done':false
  },
  {
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.CampaignPage',
      'detail_url':'https:///api/v2/drafts/72/',
      'html_url':'https://localhost/academy2/campaigns/stories-modul/',
      'slug':'stories-modul',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-05-04T10:58:36.566979+02:00',
      'alias_of':null,
      'parent':{
         'id':53,
         'meta':{
            'type':'academy2.CampaignsPage',
            'detail_url':'https:///api/v2/drafts/53/',
            'html_url':'https://localhost/academy2/campaigns/'
         },
         'title':'Campaigns'
      },
      'locale':'en'
   },
   'title':'Stories Modul',
   'image':{
      'id':62,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.jpg',
      'title':'IQOS BERLIN LAUNCH EVENT-DJ 464x464',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-50.jpg',
         'width':232,
         'height':232
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-100.jpg',
         'width':464,
         'height':464
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x.2e16d0ba.fill-4320x2316.jpg',
         'width':464,
         'height':250
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-15.jpg',
         'width':69,
         'height':69
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-25.jpg',
         'width':116,
         'height':116
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_BERLIN_LAUNCH_EVENT-DJ_464x464.scale-35.jpg',
         'width':162,
         'height':162
      }
   },
   'small_headline':'Small headline',
   'font_style':'white',
   'headline':'Big headline',
   'text':'<p data-block-key="0tgvk">Copy text</p>',
   'cta_name':'CTA name',
   'cta_link':'Link to Stories',
   'label':'',
   'label_color':'#b71f1f'
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/46/',
        'html_url':'https://localhost/academy2/challenges/old-challenge-4/',
        'slug':'old-challenge-4',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T11:55:01.276282+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Old Challenge 4',
     'challenge_title':'IQOS Academy Illuma Challenges',
     'headline':'Temperatur macht den Unterschied.',
     'content':'<p data-block-key="0npkz">Wie wird der Tabak bei IQOS ILUMA erhitzt? Und wie hei\u00df wird es f\u00fcr den Tabak? Spiele die Challenge und teste Dein Wissen.</p>',
     'label':'',
     'label_color':'',
     'points':'10',
     'points_reason':'Academy - Challenge',
     'points_type':'iqos_points',
     'main_image':{
        'id':58,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Challenge4.original_le1O5Eq.png',
        'title':'Challenge4.original',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original_le1O5Eq.scale-50.png',
           'width':713,
           'height':477
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original_le1O5Eq.scale-100.png',
           'width':1427,
           'height':954
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original_le1O5Eq.2e16d0ba.fill-4320x2316.png',
           'width':1427,
           'height':766
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original_le1O5Eq.scale-15.png',
           'width':214,
           'height':143
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original_le1O5Eq.scale-25.png',
           'width':356,
           'height':238
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Challenge4.original_le1O5Eq.scale-35.png',
           'width':499,
           'height':333
        }
     },
     'challenge_content':{
        'type':'playable',
        'value':{
           'playable_game_id':'111543',
           'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-4-iqos-iluma-version',
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="wpj9f">Starte direkt mit der n\u00e4chsten Challenge und finde heraus, wie IQOS es schafft, beim Erhitzen weniger Schadstoffe entstehen zu lassen.</p>',
           'wrong_answer_headline':'Ups! Das war leider nicht richtig',
           'wrong_answer_message':'<p data-block-key="g4gt8">Versuch es nochmal.</p>',
           'cta_name':'Neuer Versuch',
           'duration':'12',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '73677317-11d9-4307-9424-999268979c36'
     },
     'group':{
        'id':71,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/71/'
        },
        'title':'Challenges'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/47/',
        'html_url':'https://localhost/academy2/challenges/old-challenge-5/',
        'slug':'old-challenge-5',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-28T15:52:58.191428+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Old Challenge 5',
     'challenge_title':'IQOS Academy Illuma Challenges',
     'headline':'Frei von Zigarettenrauch-Geruch.',
     'content':'<p data-block-key="doo5z">L\u00f6se die Challenge und finde die richtigen Aussagen \u00fcber den Geruch von IQOS.</p>',
     'label':'',
     'label_color':'',
     'points':'10',
     'points_reason':'Academy - Challenge',
     'points_type':'iqos_points',
     'main_image':{
        'id':70,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Additional-Hygiene-Benefits_05.jpg',
        'title':'Additional-Hygiene-Benefits_05',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Additional-Hygiene-Benefits_05.scale-50.jpg',
           'width':1042,
           'height':695
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Additional-Hygiene-Benefits_05.scale-100.jpg',
           'width':2085,
           'height':1390
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Additional-Hygiene-Benefits_05.2e16d0ba.fill-4320x2316.jpg',
           'width':2085,
           'height':1118
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Additional-Hygiene-Benefits_05.scale-15.jpg',
           'width':312,
           'height':208
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Additional-Hygiene-Benefits_05.scale-25.jpg',
           'width':521,
           'height':347
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Additional-Hygiene-Benefits_05.scale-35.jpg',
           'width':729,
           'height':486
        }
     },
     'challenge_content':{
        'type':'playable',
        'value':{
           'playable_game_id':'111555',
           'playable_game_url':'https://pmgacademy.leadfamly.com/challenge-5-iqos-iluma-version',
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="5vrxc">Teste Dein Wissen sichere Dir 10 IQOS Points und 10 Statuspunkte. Starte gleich die n\u00e4chste Challenge und fordere Dich heraus.</p>',
           'wrong_answer_headline':'Ups! Das war leider nicht richtig',
           'wrong_answer_message':'<p data-block-key="4jx41">Versuch es nochmal.</p>',
           'cta_name':'Challenge starten',
           'duration':'12',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '7bd3e67a-48f2-4759-8b90-cde0f10e2a84'
     },
     'group':{
        'id':71,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/71/'
        },
        'title':'Challenges'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/63/',
        'html_url':'https://localhost/academy2/challenges/video-story/',
        'slug':'video-story',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T15:51:41.520131+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Video Challenge Verpackung',
     'challenge_title':'Behind the scenes',
     'headline':'Die besonders gefaltete Verpackung.',
     'content':'<p data-block-key="sh63k">Die Verpackung ist ein wichtiger Teil Deines besonderen IQOS Erlebnisses. Warum sie so revolution\u00e4r ist und welche neuen Ma\u00dfst\u00e4be bei der Entwicklung gesetzt wurden, erf\u00e4hrst Du hier.</p>',
     'label':'Club Exclusives',
     'label_color':'#00d1d2',
     'points':'30',
     'points_reason':'Academy - Video',
     'points_type':'statuspoints',
     'main_image':{
        'id':96,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-03-thestoryunfolds-43x.webp',
        'title':'academy-teaser-image-03-thestoryunfolds-4@3x',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-03-thestoryunfolds-43x.scale-50.png',
           'width':1755,
           'height':1755
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-03-thestoryunfolds-43x.scale-100.png',
           'width':3510,
           'height':3510
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-03-thestory.2e16d0ba.fill-4320x2316_Ua43Azt.png',
           'width':3510,
           'height':1882
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-03-thestoryunfolds-43x.scale-15.png',
           'width':526,
           'height':526
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-03-thestoryunfolds-43x.scale-25.png',
           'width':877,
           'height':877
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-03-thestoryunfolds-43x.scale-35.png',
           'width':1228,
           'height':1228
        }
     },
     'challenge_content':{
        'type':'video',
        'value':{
           'video':{
              'id':17,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/B390825_IQOS4_ILUMA_Prime_Thestoryunfoldslong_video002_A_1080x1920_de_DE_99_PP004_S.mp4'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="kbp32">Wir haben noch mehr spannende Inhalte f\u00fcr Dich. Schau Dir doch diesen Clip hier an: Warum die Verpackung unseres IQOS ILUMA so revolution\u00e4r ist und zu einem ganz besonderen Erlebnis beitr\u00e4gt.</p>',
           'cta_name':'Video starten',
           'duration':'2',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '35809002-9467-480d-86f3-ef5e3eeafe09'
     },
     'group':{
        'id':70,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/70/'
        },
        'title':'Videos'
     },
     'challenge_done':false,
     'challenge_done_when':'False'
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/68/',
        'html_url':'https://localhost/academy2/challenges/video-start/',
        'slug':'video-start',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-28T13:09:47.926949+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Video Challenge How it started',
     'challenge_title':'Behind the scenes',
     'headline':'Die IQOS ILUMA Vision: Wie alles begann.',
     'content':'<p data-block-key="sh63k">Wie wir mit Hilfe der Wissenschaft unsere Vision eines revolution\u00e4ren neuen Produktes realisiert haben und welche \u00dcberlegungen bei der Entwicklung die gr\u00f6\u00dfte Rolle spielten, erf\u00e4hrst Du in diesem Video.</p>',
     'label':'Club Exclusives',
     'label_color':'#00d1d2',
     'points':'30',
     'points_reason':'Academy - Video',
     'points_type':'statuspoints',
     'main_image':{
        'id':87,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-05-howitstarted3x.webp',
        'title':'academy-teaser-image-05-howitstarted@3x',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-05-howitstarted3x.scale-50.png',
           'width':1755,
           'height':1755
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-05-howitstarted3x.scale-100.png',
           'width':3510,
           'height':3510
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-05-howitsta.2e16d0ba.fill-4320x2316.png',
           'width':3510,
           'height':1882
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-05-howitstarted3x.scale-15.png',
           'width':526,
           'height':526
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-05-howitstarted3x.scale-25.png',
           'width':877,
           'height':877
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-05-howitstarted3x.scale-35.png',
           'width':1228,
           'height':1228
        }
     },
     'challenge_content':{
        'type':'video',
        'value':{
           'video':{
              'id':19,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/B389347_PT1_IQOS4_ILUMA_Prime_Howitstartedlong_video004_A_1min36s_1080x1920_de-DE_PP006_S.mp4'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="kbp32">In diesem Video erf\u00e4hrst Du, welche Vision wir bei der Entwicklung von IQOS ILUMA verfolgt haben. Lass Dich inspirieren und tauche ein in die Welt unserer neusten Innovation.</p>',
           'cta_name':'Video starten',
           'duration':'2',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '35809002-9467-480d-86f3-ef5e3eeafe09'
     },
     'group':{
        'id':70,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/70/'
        },
        'title':'Videos'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/69/',
        'html_url':'https://localhost/academy2/challenges/video-creation/',
        'slug':'video-creation',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-28T13:36:15.842333+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Video Challenge How it was created',
     'challenge_title':'Behind the scenes',
     'headline':'Die Entwicklung von IQOS ILUMA.',
     'content':'<p data-block-key="sh63k">Sieh Dir an, welche schlauen K\u00f6pfe IQOS ILUMA zu dem gemacht haben, was es heute ist.</p>',
     'label':'Club Exclusives',
     'label_color':'#00d1d2',
     'points':'30',
     'points_reason':'Academy - Video',
     'points_type':'statuspoints',
     'main_image':{
        'id':86,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-06-howitwascreated3x.webp',
        'title':'academy-teaser-image-06-howitwascreated@3x',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-50.png',
           'width':1755,
           'height':1755
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-100.png',
           'width':3510,
           'height':3510
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwas.2e16d0ba.fill-4320x2316.png',
           'width':3510,
           'height':1882
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-15.png',
           'width':526,
           'height':526
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-25.png',
           'width':877,
           'height':877
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-35.png',
           'width':1228,
           'height':1228
        }
     },
     'challenge_content':{
        'type':'video',
        'value':{
           'video':{
              'id':20,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/B389359_IQOS4_ILUMA_Prime_Overarchingshort_video009_A_1080x1920_de_DE_99_PP005_S.mp4'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="kbp32">Steig gleich wieder ein und sammle 30 Statuspunkte f\u00fcr den IQOS Club. Starte dieses Video und finde heraus, wer bei der Entwicklung unseres neuen IQOS ILUMA mitgewirkt hat.</p>',
           'cta_name':'Jetzt Video starten',
           'duration':'2',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '35809002-9467-480d-86f3-ef5e3eeafe09'
     },
     'group':{
        'id':70,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/70/'
        },
        'title':'Videos'
     },
     'challenge_done':false
  },
  {
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/69/',
      'html_url':'https://localhost/academy2/challenges/video-creation/',
      'slug':'video-creation',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-28T13:36:15.842333+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Video Challenge How it was created',
   'challenge_title':'Behind the scenes',
   'headline':'Die Entwicklung von IQOS ILUMA.',
   'content':'<p data-block-key="sh63k">Sieh Dir an, welche schlauen K\u00f6pfe IQOS ILUMA zu dem gemacht haben, was es heute ist.</p>',
   'label':'Club Exclusives',
   'label_color':'#00d1d2',
   'points':'30',
   'points_reason':'Academy - Video',
   'points_type':'statuspoints',
   'main_image':{
      'id':86,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/academy-teaser-image-06-howitwascreated3x.webp',
      'title':'academy-teaser-image-06-howitwascreated@3x',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-50.png',
         'width':1755,
         'height':1755
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-100.png',
         'width':3510,
         'height':3510
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwas.2e16d0ba.fill-4320x2316.png',
         'width':3510,
         'height':1882
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-15.png',
         'width':526,
         'height':526
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-25.png',
         'width':877,
         'height':877
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/academy-teaser-image-06-howitwascreated3x.scale-35.png',
         'width':1228,
         'height':1228
      }
   },
   'challenge_content':{
      'type':'video',
      'value':{
         'video':{
            'id':20,
            'type':'media',
            'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/B389359_IQOS4_ILUMA_Prime_Overarchingshort_video009_A_1080x1920_de_DE_99_PP005_S.mp4'
         },
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="kbp32">Steig gleich wieder ein und sammle 30 Statuspunkte f\u00fcr den IQOS Club. Starte dieses Video und finde heraus, wer bei der Entwicklung unseres neuen IQOS ILUMA mitgewirkt hat.</p>',
         'cta_name':'Jetzt Video starten',
         'duration':'2',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': '35809002-9467-480d-86f3-ef5e3eeafe09'
   },
   'group':{
      'id':70,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/70/'
      },
      'title':'Videos'
   },
   'challenge_done':false
},
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/38/',
        'html_url':'https://localhost/academy2/challenges/iqos-journey/',
        'slug':'iqos-journey',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-12T14:55:03.220060+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Podcast Journey',
     'challenge_title':'Tips & Tricks zum Umstieg',
     'headline':'Starte Deine IQOS Journey.',
     'content':'<p data-block-key="93m2p">Was macht IQOS so besonders? Wieso riecht IQOS anders als eine Zigarette? Und wie gestaltest Du Deinen Umstieg auf IQOS? Diese und weitere Fragen beantwortet Dir Expertin Nouria Dost in dieser Folge.</p>',
     'label':'',
     'label_color':'',
     'points':'50',
     'points_reason':'Academy - Podcast',
     'points_type':'statuspoints',
     'main_image':{
        'id':94,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/podcast_journey-image-01-sizzle3x.webp',
        'title':'podcast_journey-image-01-sizzle@3x',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/podcast_journey-image-01-sizzle3x.scale-50.png',
           'width':1755,
           'height':1755
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/podcast_journey-image-01-sizzle3x.scale-100.png',
           'width':3510,
           'height':3510
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/podcast_journey-image-01-sizzle3.2e16d0ba.fill-4320x2316.png',
           'width':3510,
           'height':1882
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/podcast_journey-image-01-sizzle3x.scale-15.png',
           'width':526,
           'height':526
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/podcast_journey-image-01-sizzle3x.scale-25.png',
           'width':877,
           'height':877
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/podcast_journey-image-01-sizzle3x.scale-35.png',
           'width':1228,
           'height':1228
        }
     },
     'challenge_content':{
        'type':'podcast',
        'value':{
           'podcast':{
              'id':21,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/210709_Deine_IQOS_Journey.mp3'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="qma0d">Wie w\u00e4re es direkt mit der n\u00e4chsten Podcast-Folge? Hier erf\u00e4hrst Du alles Wissenswerte \u00fcber das Produkt IQOS und bekommst Tipps f\u00fcr die Umstellung. Lass uns starten und Deinem Wissen einen Schub geben.</p>',
           'cta_name':'Jetzt 30 Statuspunkte sichern.',
           'duration':'12',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '495d82c9-4e22-4a96-9124-52195851e314'
     },
     'group':{
        'id':43,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/43/'
        },
        'title':'Podcasts'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/55/',
        'html_url':'https://localhost/academy2/challenges/iqos-science/',
        'slug':'iqos-science',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T09:45:04.641752+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Podcast Science',
     'challenge_title':'IQOS Science 2.0',
     'headline':'Die Wissenschaft hinter IQOS.',
     'content':'<p data-block-key="93m2p">Bist Du neugierig auf die wissenschaftlichen Hintergr\u00fcnde zu IQOS? Dann solltest Du unbedingt diese Folge h\u00f6ren, spannende Infos zur Forschung zu erfahren.</p>',
     'label':'',
     'label_color':'',
     'points':'50',
     'points_reason':'Academy - Podcast',
     'points_type':'statuspoints',
     'main_image':{
        'id':79,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/image3x_iuYZn2x.jpg',
        'title':'Podcast2_1',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-50.jpg',
           'width':897,
           'height':897
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-100.jpg',
           'width':1794,
           'height':1794
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.2e16d0ba.fill-4320x2316.jpg',
           'width':1794,
           'height':962
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-15.jpg',
           'width':269,
           'height':269
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-25.jpg',
           'width':448,
           'height':448
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/image3x_iuYZn2x.scale-35.jpg',
           'width':627,
           'height':627
        }
     },
     'challenge_content':{
        'type':'podcast',
        'value':{
           'podcast':{
              'id':22,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/210709_Die_Wissenschaft_hinter_IQOS.mp3'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="qma0d">Willst Du etwas \u00fcber die Wissenschaft und Forschung hinter IQOS erfahren? Hier kannst Du Dein Wissen vertiefen und dabei noch 50 Statuspunkte sammeln. Klingt toll, oder? Also nichts wie los.</p>',
           'cta_name':'Jetzt 50 Statuspunkte sichern.',
           'duration':'16',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '495d82c9-4e22-4a96-9124-52195851e314'
     },
     'group':{
        'id':43,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/43/'
        },
        'title':'Podcasts'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/57/',
        'html_url':'https://localhost/academy2/challenges/together-do/',
        'slug':'together-do',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T09:53:34.090210+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Interview Beate Kunta',
     'challenge_title':'User Stories',
     'headline':'Im Interview: Beate und Kunta',
     'content':'<p data-block-key="93m2p">Beate und Kunta berichten aus erster Hand, wie sie Gesichter f\u00fcr IQOS wurden und berichten, vom Shooting und ihrem Wechsel zu IQOS.</p>',
     'label':'',
     'label_color':'',
     'points':'50',
     'points_reason':'Academy - Podcast',
     'points_type':'statuspoints',
     'main_image':{
        'id':93,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/club-podcast-beate-kunta.png',
        'title':'club-podcast-beate-kunta',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-beate-kunta.scale-50.png',
           'width':864,
           'height':864
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-beate-kunta.scale-100.png',
           'width':1728,
           'height':1728
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-beate-kunta.2e16d0ba.fill-4320x2316.png',
           'width':1728,
           'height':928
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-beate-kunta.scale-15.png',
           'width':259,
           'height':259
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-beate-kunta.scale-25.png',
           'width':432,
           'height':432
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-beate-kunta.scale-35.png',
           'width':604,
           'height':604
        }
     },
     'challenge_content':{
        'type':'podcast',
        'value':{
           'podcast':{
              'id':23,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221117_Interview_Beate_Kunta.mp3'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="qma0d">H\u00f6r doch mal in diese Podcast Episode hinein, in der wir Beate und Kunta interviewen, die echte Gesichter von IQOS sind und von ihren Erfahrungen beim Shooting und ihrem Umstieg zu IQOS erz\u00e4hlen. Hier geht&#x27;s zur Folge.</p>',
           'cta_name':'Podcast starten',
           'duration':'16',
           'no_ambassador':true,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '495d82c9-4e22-4a96-9124-52195851e314'
     },
     'group':{
        'id':43,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/43/'
        },
        'title':'Podcasts'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/58/',
        'html_url':'https://localhost/academy2/challenges/podcast-4/',
        'slug':'podcast-4',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T09:57:12.090820+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Podcast Interview Alessandro',
     'challenge_title':'User Stories',
     'headline':'Im Interview: Alessandro.',
     'content':'<p data-block-key="93m2p">Wie kam Alessandro zu IQOS und wurde das Gesicht von IQOS? Das und noch viel mehr spannende Einblicke verr\u00e4t er in dieser Folge.</p>',
     'label':'',
     'label_color':'',
     'points':'50',
     'points_reason':'Academy - Podcast',
     'points_type':'statuspoints',
     'main_image':{
        'id':92,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/club-podcast-alessandro.png',
        'title':'club-podcast-alessandro',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-alessandro.scale-50.png',
           'width':864,
           'height':864
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-alessandro.scale-100.png',
           'width':1728,
           'height':1728
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-alessandro.2e16d0ba.fill-4320x2316.png',
           'width':1728,
           'height':928
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-alessandro.scale-15.png',
           'width':259,
           'height':259
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-alessandro.scale-25.png',
           'width':432,
           'height':432
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/club-podcast-alessandro.scale-35.png',
           'width':604,
           'height':604
        }
     },
     'challenge_content':{
        'type':'podcast',
        'value':{
           'podcast':{
              'id':24,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221118_Interview_Alessandro.mp3'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="qma0d">Ich habe schon mal in die n\u00e4chste Folge reingeh\u00f6rt, in der wir Alessandro kennenlernen, der uns von seinen Erfahrungen als Gesicht f\u00fcr IQOS berichtet. Starte auch Du gleich rein und sichere Dir 50 Punkte.</p>',
           'cta_name':'Podcast starten',
           'duration':'16',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '495d82c9-4e22-4a96-9124-52195851e314'
     },
     'group':{
        'id':43,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/43/'
        },
        'title':'Podcasts'
     },
     'challenge_done':false
  },
  {
     'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
     'meta':{
        'type':'academy2.AcademyChallengePage',
        'detail_url':'https:///api/v2/drafts/59/',
        'html_url':'https://localhost/academy2/challenges/behind-the-scenes/',
        'slug':'behind-the-scenes',
        'show_in_menus':false,
        'seo_title':'',
        'search_description':'',
        'first_published_at':'2023-04-26T10:51:15.454488+02:00',
        'alias_of':null,
        'parent':{
           'id':50,
           'meta':{
              'type':'academy2.ChallengesPage',
              'detail_url':'https:///api/v2/drafts/50/',
              'html_url':'https://localhost/academy2/challenges/'
           },
           'title':'Challenges'
        },
        'locale':'en'
     },
     'title':'Podcast Behind the scenes',
     'challenge_title':'Together Do',
     'headline':'Behind the scenes mit Mischa & Julia',
     'content':'<p data-block-key="93m2p">Was und wer steckt hinter Together DO? Wie lief die Planung und Umsetzung im Hintergrund ab? All das und noch mehr h\u00f6rt ihr im Podcast mit Mischa und Julia.</p>',
     'label':'',
     'label_color':'',
     'points':'50',
     'points_reason':'Academy - Podcast',
     'points_type':'statuspoints',
     'main_image':{
        'id':76,
        'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Podcast6.png',
        'title':'Podcast5_1',
        'image_desktop_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-50.png',
           'width':500,
           'height':500
        },
        'image_desktop_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-100.png',
           'width':1000,
           'height':1000
        },
        'image_desktop_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.2e16d0ba.fill-4320x2316.png',
           'width':1000,
           'height':538
        },
        'image_mobile_1x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-15.png',
           'width':150,
           'height':150
        },
        'image_mobile_2x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-25.png',
           'width':250,
           'height':250
        },
        'image_mobile_3x':{
           'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-35.png',
           'width':350,
           'height':350
        }
     },
     'challenge_content':{
        'type':'podcast',
        'value':{
           'podcast':{
              'id':25,
              'type':'media',
              'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221129_IQOS_Behind_the_scenes_mit_Mischa_Julia.mp3'
           },
           'welcome_headline':'Gl\u00fcckwunsch',
           'welcome_message':'<p data-block-key="qma0d">M\u00f6chtest Du noch mehr Infos? Dann spring gleich in diese Folge und erfahre von Julia und Mischa, warum Together DO mehr als nur ein Kampagnen-Claim ist und wie sie den Together Day erlebt haben.</p>',
           'cta_name':'Jetzt 50 Statuspunkte sichern.',
           'duration':'16',
           'no_ambassador':false,
           'ambassador':{
              'id':36,
              'name':'Ambassador Ines'
           }
        },
        'id': '495d82c9-4e22-4a96-9124-52195851e314'
     },
     'group':{
        'id':43,
        'meta':{
           'type':'academy2.AcademyGroupPage',
           'detail_url':'https:///api/v2/drafts/43/'
        },
        'title':'Podcasts'
     },
     'challenge_done':false
  },
  {
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/59/',
      'html_url':'https://localhost/academy2/challenges/behind-the-scenes/',
      'slug':'behind-the-scenes',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-26T10:51:15.454488+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Podcast Behind the scenes',
   'challenge_title':'Together Do',
   'headline':'Behind the scenes mit Mischa & Julia',
   'content':'<p data-block-key="93m2p">Was und wer steckt hinter Together DO? Wie lief die Planung und Umsetzung im Hintergrund ab? All das und noch mehr h\u00f6rt ihr im Podcast mit Mischa und Julia.</p>',
   'label':'',
   'label_color':'',
   'points':'50',
   'points_reason':'Academy - Podcast',
   'points_type':'statuspoints',
   'main_image':{
      'id':76,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Podcast6.png',
      'title':'Podcast5_1',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-50.png',
         'width':500,
         'height':500
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-100.png',
         'width':1000,
         'height':1000
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.2e16d0ba.fill-4320x2316.png',
         'width':1000,
         'height':538
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-15.png',
         'width':150,
         'height':150
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-25.png',
         'width':250,
         'height':250
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-35.png',
         'width':350,
         'height':350
      }
   },
   'challenge_content':{
      'type':'podcast',
      'value':{
         'podcast':{
            'id':25,
            'type':'media',
            'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221129_IQOS_Behind_the_scenes_mit_Mischa_Julia.mp3'
         },
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="qma0d">M\u00f6chtest Du noch mehr Infos? Dann spring gleich in diese Folge und erfahre von Julia und Mischa, warum Together DO mehr als nur ein Kampagnen-Claim ist und wie sie den Together Day erlebt haben.</p>',
         'cta_name':'Jetzt 50 Statuspunkte sichern.',
         'duration':'16',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': '495d82c9-4e22-4a96-9124-52195851e314'
   },
   'group':{
      'id':43,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/43/'
      },
      'title':'Podcasts'
   },
   'challenge_done':false
},
{
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/59/',
      'html_url':'https://localhost/academy2/challenges/behind-the-scenes/',
      'slug':'behind-the-scenes',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-26T10:51:15.454488+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Podcast Behind the scenes',
   'challenge_title':'Together Do',
   'headline':'Behind the scenes mit Mischa & Julia',
   'content':'<p data-block-key="93m2p">Was und wer steckt hinter Together DO? Wie lief die Planung und Umsetzung im Hintergrund ab? All das und noch mehr h\u00f6rt ihr im Podcast mit Mischa und Julia.</p>',
   'label':'',
   'label_color':'',
   'points':'50',
   'points_reason':'Academy - Podcast',
   'points_type':'statuspoints',
   'main_image':{
      'id':76,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Podcast6.png',
      'title':'Podcast5_1',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-50.png',
         'width':500,
         'height':500
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-100.png',
         'width':1000,
         'height':1000
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.2e16d0ba.fill-4320x2316.png',
         'width':1000,
         'height':538
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-15.png',
         'width':150,
         'height':150
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-25.png',
         'width':250,
         'height':250
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-35.png',
         'width':350,
         'height':350
      }
   },
   'challenge_content':{
      'type':'podcast',
      'value':{
         'podcast':{
            'id':25,
            'type':'media',
            'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221129_IQOS_Behind_the_scenes_mit_Mischa_Julia.mp3'
         },
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="qma0d">M\u00f6chtest Du noch mehr Infos? Dann spring gleich in diese Folge und erfahre von Julia und Mischa, warum Together DO mehr als nur ein Kampagnen-Claim ist und wie sie den Together Day erlebt haben.</p>',
         'cta_name':'Jetzt 50 Statuspunkte sichern.',
         'duration':'16',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': '495d82c9-4e22-4a96-9124-52195851e314'
   },
   'group':{
      'id':43,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/43/'
      },
      'title':'Podcasts'
   },
   'challenge_done':false
},
{
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/59/',
      'html_url':'https://localhost/academy2/challenges/behind-the-scenes/',
      'slug':'behind-the-scenes',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-26T10:51:15.454488+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Podcast Behind the scenes',
   'challenge_title':'Together Do',
   'headline':'Behind the scenes mit Mischa & Julia',
   'content':'<p data-block-key="93m2p">Was und wer steckt hinter Together DO? Wie lief die Planung und Umsetzung im Hintergrund ab? All das und noch mehr h\u00f6rt ihr im Podcast mit Mischa und Julia.</p>',
   'label':'',
   'label_color':'',
   'points':'50',
   'points_reason':'Academy - Podcast',
   'points_type':'statuspoints',
   'main_image':{
      'id':76,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Podcast6.png',
      'title':'Podcast5_1',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-50.png',
         'width':500,
         'height':500
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-100.png',
         'width':1000,
         'height':1000
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.2e16d0ba.fill-4320x2316.png',
         'width':1000,
         'height':538
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-15.png',
         'width':150,
         'height':150
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-25.png',
         'width':250,
         'height':250
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-35.png',
         'width':350,
         'height':350
      }
   },
   'challenge_content':{
      'type':'podcast',
      'value':{
         'podcast':{
            'id':25,
            'type':'media',
            'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221129_IQOS_Behind_the_scenes_mit_Mischa_Julia.mp3'
         },
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="qma0d">M\u00f6chtest Du noch mehr Infos? Dann spring gleich in diese Folge und erfahre von Julia und Mischa, warum Together DO mehr als nur ein Kampagnen-Claim ist und wie sie den Together Day erlebt haben.</p>',
         'cta_name':'Jetzt 50 Statuspunkte sichern.',
         'duration':'16',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': '495d82c9-4e22-4a96-9124-52195851e314'
   },
   'group':{
      'id':43,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/43/'
      },
      'title':'Podcasts'
   },
   'challenge_done':false
},  {
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/59/',
      'html_url':'https://localhost/academy2/challenges/behind-the-scenes/',
      'slug':'behind-the-scenes',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-26T10:51:15.454488+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Podcast Behind the scenes',
   'challenge_title':'Together Do',
   'headline':'Behind the scenes mit Mischa & Julia',
   'content':'<p data-block-key="93m2p">Was und wer steckt hinter Together DO? Wie lief die Planung und Umsetzung im Hintergrund ab? All das und noch mehr h\u00f6rt ihr im Podcast mit Mischa und Julia.</p>',
   'label':'',
   'label_color':'',
   'points':'50',
   'points_reason':'Academy - Podcast',
   'points_type':'statuspoints',
   'main_image':{
      'id':76,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Podcast6.png',
      'title':'Podcast5_1',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-50.png',
         'width':500,
         'height':500
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-100.png',
         'width':1000,
         'height':1000
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.2e16d0ba.fill-4320x2316.png',
         'width':1000,
         'height':538
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-15.png',
         'width':150,
         'height':150
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-25.png',
         'width':250,
         'height':250
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-35.png',
         'width':350,
         'height':350
      }
   },
   'challenge_content':{
      'type':'podcast',
      'value':{
         'podcast':{
            'id':25,
            'type':'media',
            'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221129_IQOS_Behind_the_scenes_mit_Mischa_Julia.mp3'
         },
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="qma0d">M\u00f6chtest Du noch mehr Infos? Dann spring gleich in diese Folge und erfahre von Julia und Mischa, warum Together DO mehr als nur ein Kampagnen-Claim ist und wie sie den Together Day erlebt haben.</p>',
         'cta_name':'Jetzt 50 Statuspunkte sichern.',
         'duration':'16',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': '495d82c9-4e22-4a96-9124-52195851e314'
   },
   'group':{
      'id':43,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/43/'
      },
      'title':'Podcasts'
   },
   'challenge_done':false
},
{
   'id': `${mockId}${(Math.random()*1000000).toFixed(0)}`,
   'meta':{
      'type':'academy2.AcademyChallengePage',
      'detail_url':'https:///api/v2/drafts/59/',
      'html_url':'https://localhost/academy2/challenges/behind-the-scenes/',
      'slug':'behind-the-scenes',
      'show_in_menus':false,
      'seo_title':'',
      'search_description':'',
      'first_published_at':'2023-04-26T10:51:15.454488+02:00',
      'alias_of':null,
      'parent':{
         'id':50,
         'meta':{
            'type':'academy2.ChallengesPage',
            'detail_url':'https:///api/v2/drafts/50/',
            'html_url':'https://localhost/academy2/challenges/'
         },
         'title':'Challenges'
      },
      'locale':'en'
   },
   'title':'Podcast Behind the scenes',
   'challenge_title':'Together Do',
   'headline':'Behind the scenes mit Mischa & Julia',
   'content':'<p data-block-key="93m2p">Was und wer steckt hinter Together DO? Wie lief die Planung und Umsetzung im Hintergrund ab? All das und noch mehr h\u00f6rt ihr im Podcast mit Mischa und Julia.</p>',
   'label':'',
   'label_color':'',
   'points':'50',
   'points_reason':'Academy - Podcast',
   'points_type':'statuspoints',
   'main_image':{
      'id':76,
      'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/Podcast6.png',
      'title':'Podcast5_1',
      'image_desktop_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-50.png',
         'width':500,
         'height':500
      },
      'image_desktop_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-100.png',
         'width':1000,
         'height':1000
      },
      'image_desktop_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.2e16d0ba.fill-4320x2316.png',
         'width':1000,
         'height':538
      },
      'image_mobile_1x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-15.png',
         'width':150,
         'height':150
      },
      'image_mobile_2x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-25.png',
         'width':250,
         'height':250
      },
      'image_mobile_3x':{
         'url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/Podcast6.scale-35.png',
         'width':350,
         'height':350
      }
   },
   'challenge_content':{
      'type':'podcast',
      'value':{
         'podcast':{
            'id':25,
            'type':'media',
            'download_url':'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/media/221129_IQOS_Behind_the_scenes_mit_Mischa_Julia.mp3'
         },
         'welcome_headline':'Gl\u00fcckwunsch',
         'welcome_message':'<p data-block-key="qma0d">M\u00f6chtest Du noch mehr Infos? Dann spring gleich in diese Folge und erfahre von Julia und Mischa, warum Together DO mehr als nur ein Kampagnen-Claim ist und wie sie den Together Day erlebt haben.</p>',
         'cta_name':'Jetzt 50 Statuspunkte sichern.',
         'duration':'16',
         'no_ambassador':false,
         'ambassador':{
            'id':36,
            'name':'Ambassador Ines'
         }
      },
      'id': '495d82c9-4e22-4a96-9124-52195851e314'
   },
   'group':{
      'id':43,
      'meta':{
         'type':'academy2.AcademyGroupPage',
         'detail_url':'https:///api/v2/drafts/43/'
      },
      'title':'Podcasts'
   },
   'challenge_done':false
},
]

export default [
   ...hompepageGrid(1),
   // ...hompepageGrid(2),
];
