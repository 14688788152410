// Modules
import MockAdapter from 'axios-mock-adapter';
import ConsumerMock from '@/modules/consumer/api/mocks/setup';
import ShopMock from '@/modules/shop/api/mocks/setup';
import AcademyMock from '@/modules/academy/api/mocks/setup';
import PodcastsMock from '@/modules/podcasts/api/mocks/setup';
import MaintenanceMock from '@/modules/maintenance/api/mocks-setup';
import VoucherMock from '@/modules/voucher/api/mocks/setup';
import AdventMock from '@/modules/advent/api/mocks/setup';
import Advent2023Mock from '@/modules/advent2023/api/mocks/setup';
import StoriesMock from '@/modules/stories/api/mocks/setup';
import SpringRaffleMock from '@/modules/spring-raffle/api/mocks/setup';
import TogetherXMock from '@/modules/together-x/api/mocks/setup';

// Composables
import FeaturesMock from '@/composables/mocks/setup';

export const setupMocks = (axiosInstance, delay = 300) => {
  // Let unmocked requests through, but use
  // defined mocks for specific requests
  const mockHandler = new MockAdapter(axiosInstance, {
    onNoMatch: 'passthrough',
    delayResponse: delay,
  });

  // Modules
  ConsumerMock.setup(mockHandler);
  ShopMock.setup(mockHandler);
  AcademyMock.setup(mockHandler);
  PodcastsMock.setup(mockHandler);
  MaintenanceMock.setup(mockHandler);
  VoucherMock.setup(mockHandler);
  AdventMock.setup(mockHandler);
  StoriesMock.setup(mockHandler);
  SpringRaffleMock.setup(mockHandler);
  Advent2023Mock.setup(mockHandler);
  TogetherXMock.setup(mockHandler);

  // Composables
  FeaturesMock.setup(mockHandler);
};

export const makeResponse =
  (apiCallName, response, code = 200) =>
  () =>
    console.debug(`[MOCKED] ${apiCallName}`, response) || [code, response];
